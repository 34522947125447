/* eslint react/jsx-one-expression-per-line: 0 */

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  Block,
  Footer,
  NamedHeader,
  PageIntroKaravajchuk,
  SidesKaravajchuk,
  Spacer,
  TopImage,
  TypographyWrapper,
} from "../../../lib";

const { PUBLIC_URL } = process.env;

const StyledTopImage = styled(TopImage)`
  margin-top: 30px;
`;

const Ermitazh: React.FC = () => (
  <TypographyWrapper>
    <NamedHeader name="практикум" />

    <PageIntroKaravajchuk author="karavajchuk" name="ermitazh" />

    <StyledTopImage
      url={`${PUBLIC_URL}/assets/images/karavajchuk/ermitazh/top.jpg`}
    />

    <Spacer size={10} />

    <SidesKaravajchuk hide="ermitazh">
      <Spacer size={4} />

      <Block>
        <>настоящее рояльное я</>
        <>
          Когда город весь в снегу, во льду — идти невозможно, устаешь до
          пределов, потому что не убирают снег, и ты гребешь ногами. Входишь в
          Эрмитаж, особенно в древний отдел, видишь статуи, и происходит
          какое-то удивительное чудо — ты сразу абсолютно здоровый и
          отдохнувший. И все, я иду играть. Вот когда я входил в парадную, у
          меня было чувство, что сегодня я ничего сыграть не могу, я уж так
          устал от этого снега, от этих сугробов. Я ведь пешком хожу от Эрмитажа
          до метро. Вхожу — и совсем я опять такой как я! Вот что значит
          настоящее.
        </>
        <>
          Я играю на рояле Николая II, это же королевский рояль, который
          абсолютно передает мою игру и мою ритмичность. Настоящее рояльное я. Я
          к нему хожу каждый день, и каждый раз он меня награждает новой
          музыкой, формой, которой у меня еще не было. От этого тишина.
        </>
        <>
          И играю на рояле, который был подарен за две недели до расстрела. Это
          почти предрасстрельный рояль, то есть он на мне играет. Я всегда [это]
          чувствую.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—H]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>японская удочка. пустонотие</>
        <>
          Я сейчас играю на настоящем рояле в Эрмитаже. Именно на настоящем
          рояле. От него все пианисты убежали, гениальные и великие, сказали,
          что это уже мебель. А у него такие ноты — в них нету того, что
          выражает музыку. Пустонотие. Не за что зацепиться. Это японская
          удочка. Потому что японец не удочку делает рукотворную и культурную.
          Он обыскивает огромное количество бамбука и вдруг видит — это удочка.
          Берет, немного что-то делает и насаживает леску без крючка и червячка.
          А рыба сразу видит в этой леске и червячка, и вкус, и аромат, и
          трепет. И когда рыба тащит леску, то отпустить ее не может, она
          продолжает жить эт им червячком. И тащат рыбу на полный обман. Полный
          обман — это и есть настоящая музыка.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—E]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>талант</>
        <>
          Музыка — это вещь, которая мимо контроля идет. В этом ее величие. В
          этом ее высшее предначертание. Выше поэзии. И только любовь выше нее.
          Потому что она тоже ниспослана.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—M]</Link>
          </sub>
        </>
        <>
          Есть люди, которые созданы для того, чтобы осуществить этот спонтанный
          момент непрерывной сочинительности. Без осознания, без труда. Вот я
          сочиняю без труда. И вот, зная эту разницу между собой и людьми,
          которым ничего не дается, но которые к этому стремятся, зная эту
          разницу, я понимаю, что это вообще есть что-то особенное. А так бы я
          не понимал. Жил бы я на необитаемом острове, и было бы мое счастье,
          что я не понимаю и не сравниваю.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—M]</Link>
          </sub>
        </>
        <>
          У таланта есть путь. Он это делает через определенный процесс. А гений
          сразу как-то со всех стор он может. И с конца, и с начала, и с
          середины. Пути [у него] нет. Потому что он разом сочиняет, совершенно
          неожиданное для себя.
        </>
        <>
          Фантазии и мысль [таланта] всегда середнячна. Невозможно создать
          гениальное при помощи замысла. Гениальное создает Бог или случай. И
          вот сразу — необъяснимое, слепое — сразу. В этот момент идет какая-то
          зрячесть. Я не думаю, как мне сочинять. Если б я бы думал — я давно
          сдох от перенапряжения мозговой системы.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—G]</Link>
          </sub>
        </>
        <>
          Зачем я играю? Да потому что в каждом из вас заложен гений. Только
          затоптан. Я когда играю, в вас что-то начинает прорастать. Я играю, а
          вы слышите мир.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—O]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>рука гения</>
        <>
          Публика под гением подразумевает сумасшедшего или хулигана типа
          [Сергея] Есенина. Обязательно ищет какую-то патологию. [Зигмунд] Фрейд
          научил всех искать патологические причины гениальности. Вот все хотят
          обнаружить ненормальные причины моей игры: то ли он сумасброд, то ли
          сумасшедший, раз у него наволочка на голове. Публика одурачена.
          Публика не понимает, что гений — это самый здоровый человек. У него
          самая здоровая кровь и самая здоровая психика.
          <br />
          <sub>
            <Link to="/karavajchuk/source">[B—F]</Link>
          </sub>
        </>
        <>
          Вот почему я так грандиозно играю? Я чувствительный. Вот где-то под
          кожей у меня начинает не гений жить, гений это вообще с ума спятить, а
          взаимоотношения чувствительнейшего создания, как животное, птица,
          человек. И к Духу там куда-то!..{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—H]</Link>
          </sub>
        </>
        <>
          Гений никогда не пишет сам, мелодию ему диктуют свыше. Он просто
          пропускает ее через себя. В чем, собственно, была работа Чайковского?
          В том, что он упрощал небесную музыку для таких как вы. Чтобы деньги
          заработать, славу, в историю попасть. А те, кто не упрощает, они в
          историю не попадают. Они не могут стать оплотом, примером. А я?.. Меня
          же никуда нельзя пускать — я все разрушу, расстреляю своими нотами.
          Музыка, которую я играю, выливается одна из другой, и повторить ее
          никто не может.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—I]</Link>
          </sub>
        </>
        <>
          Я далеко не атеист, наоборот, скорей. Сверх веры. Абсолютно. Я очень
          широко и узко верю, через искусство. Это вообще сложно, это не просто
          «Господи, помилуй», это не такая ерунда. Не я пишу, а моей рукой, моим
          пером пишет Бог. Я считаю, что не я играю, за меня ангелы работают, а
          я ничего не делаю. Так что тут не только вера.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—G]</Link>
          </sub>
        </>
        <>
          У меня «слепые» руки, они играют помимо меня и создают формы, которые
          разум не может придумать. Иоганн Гёте определял гений человека не по
          тому, как тот говорит или пишет стихи, а по тому, как двигаютсяего
          руки. […] Голова думает чересчур примитивно, а руки находятся в
          лабиринтах.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—D]</Link>
          </sub>{" "}
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>нота. целое</>
        <>
          Рыдают шаманы на снегах. Там как ни сделай — все свято. Не нужно
          лютеранизировать. Вот вы берете ноту, вторую. Человек здесь — это
          совсем другое, чем служитель Филармонии. У него не должно быть чувства
          воли, [чувства], что над н им кто-то есть. Над н им только ветер,
          шквал и он, еще не родившийся. То есть нет выше обязанности, чем взять
          просто так [ноту]. А там, что [он] придумывает, это все фигня. Вот
          взять [ноту] и все. Вечный ребенок взял. Если вы непринужденно дали
          ноту — вы дали еще пять лет жизни, долголетия… Свободное русло,
          ветвящаяся река.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—E]</Link>
          </sub>
        </>
        <>
          Я сочиняю все произведение сразу — от первой ноты до последней. Либо
          на бумаге, либо прямо за фортепиано. От начала до конца, не
          останавливаясь. Самая лучшая моя музыка так написана. Над музыкой
          раздумывать ни в коем случае нельзя, иначе она становится выражением
          твоих мыслей. А должна выражать только одну ноту, больше ничего.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—E]</Link>
          </sub>
        </>
        <>
          Надо вовремя брать ноту, надо все быстро. Нельзя не делать быстро на
          Земле. Земля этого не выдерживает. Все делается на ней быстро, но
          вовремя.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—C]</Link>
          </sub>
        </>
        <>
          Я просто играю себе и играю. Я не импровизирую, я сразу делаю целое.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—I]</Link>
          </sub>
        </>
        <>
          Чем музыка сильнее любого слова? Тем, что она себя не провозвещает
          великой. Она велика и все.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—L]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>трехголовый</>
        <>
          У всех композиторов конструктивная музыка. У них все сомкнуто, и
          видно, где швы. А у меня мотетная музыка: абсолютно разные сочинения
          соединены в единую чудовищной красоты гармонию. Я играю, например,
          сначала какую-то свою музыку, потом вступает Чайковский, затем Вагнер,
          но никто не узнает его, потому что это мелодия, которая в небесах. Ты
          делаешься как бы с тремя головами и одновременно живешь как
          трехголовый. Это такая сказка!{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—I]</Link>
          </sub>
        </>
        <>
          Мне никогда в жизни не было интересно заниматься новаторством. Вначале
          я находился под влиянием Шостаковича. Это была моя юность. А потом я
          почувствовал стандартизацию. Понял, что набрел на какой-то удобный
          путь для производства формы.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—O]</Link>
          </sub>
        </>
        <>
          Шостакович мне посоветовал такую вещь, которая была совсем против
          меня, и я к нему не пошел, хотя он несколько раз меня спрашивал.
          Шостакович входит в общую музыку, которую я отрицаю. Он сознательно
          производил из предыдущей музыки последующую. Потом — еще последующую,
          и еще. И он все время сознательно производил музыку за столом. Так что
          всегда можно понять, как она получается. А у меня никогда не поймешь.
          Ну никак. Музыка и не должна пониматься.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—L]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>атлант</>
        <>
          Вообще все то, что для человека является вершиной, это не вершина.
          Потому что он не атлант. Чтобы это было вершиной, он должен быть как
          монастырь. Должна быть пустота — это его сила. Что такое атлант? Это
          беспричинная способность поднять, не чувствуя меры тяжести. А для всех
          это мера. И все постепенно понимают, сколько они там подняли.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—M]</Link>
          </sub>
        </>
        <>
          Главное — не чувствовать себя гением, это поощряет внутреннее
          благоухание. Работать нужно, как Малевич и Тициан, просто и точно.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—R]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>Пустота и случай. Свобода</>
        <>
          Тишина ваяет состояние, созерцание продлевает годы жизни. […] Вообще
          везде надо молчать.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—G]</Link>
          </sub>
        </>
        <>
          Наверно, любимое — это пустота. Пауза. Это даже более, чем сон. Это
          высший дар для меня — пустота. […] У Платона хорошо сказано: самое
          лучшее — случай или Бог. […] Вот случай мне нравится. Пустота и
          случай. Вот идешь — пустота, и р-аз — мелодия.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—G]</Link>
          </sub>
        </>
        <>
          Я из отчаяния не выхожу. Я привык к нему. К такому отчаянию, которого
          вы даже и представить не можете. И навряд ли кроме таких, как я, такое
          отчаяние кто-то знает. В нашей галактике, может, я один. Мне хорошо,
          когда прибежал в свою лачугу, заперся, никого не пускаю, сижу и
          сочиняю. Все. Больше мне ничего не надо. Поэтому отчаяние — это мое
          все.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—M]</Link>
          </sub>
        </>
        <>
          Вообще музыку надо писать, чтоб человек был бы не только радостный, а
          свободный от себя. И даже от всего: и от Бога, и от мироздания. Еще и
          от души, кстати. Вот когда свободен от души, тогда душа ты и есть.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—C]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>излучение молодости</>
        <>
          Прихожу я к врачу, который сердце лечит, он слушает меня и говорит: «У
          вас сердце чистое. Совсем юное». Представляете?! Это потому, что я не
          живу воспоминаниями. Это противоестественно. У меня такая подвижность,
          легкость тела поэтому осталась. Я сам ничего не вспоминаю, кроме мамы
          и папы.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—R]</Link>
          </sub>
        </>
        <>
          Цветок, чем он более распустился, тем более он строг, каноничен. Он
          поэтому и красив, что он становится менее развязным. Поэтому он и
          цветок. Золотая середина. Чем старше человек, тем он моложе. Потому
          что он менее развязный. В нем нет подросткового амикошонства.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—D]</Link>
          </sub>
        </>
        <>
          Старость обладает излучением молодости, ртути ее, ею уже отобранной,
          отфиллигранированной, отантикваренной чистоты.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—B]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>Посмотреть вдаль</>
        <>
          Я не могу понять, как это произошло, почему это произошло. Почему
          плоды не пахнут, на рынке все без запаха, без аромата. А люди — без
          духа. Почему они делами занимаются, когда дела надо отбросить и сесть
          просто на стул.
        </>
        <>
          И посмотреть вдаль.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—H]</Link>
          </sub>
        </>
        <>
          Я вдруг понял, что я с больш им бы удовольствием тут на всю смерть
          остался. И сочинял бы. И никуда бы не возвращался.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—G]</Link>
          </sub>{" "}
        </>
        <>
          Бог не любит смерть. Он бессмертен, и все бессмертны. А в этом — и
          жизнь, и смерть одновременно. Уже ничего не будет, а так как музыка
          есть, то все будет, в какой-то новой материи. Никогда не кончится.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—I]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={48} />
    </SidesKaravajchuk>
    <Footer />
  </TypographyWrapper>
);

export default Ermitazh;
