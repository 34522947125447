/* eslint react/jsx-one-expression-per-line: 0 */

import React from "react";
import styled, { css } from "styled-components";
import { Col, Container, Row } from "react-bootstrap";

import {
  Footer,
  NamedHeader,
  PageIntroKaravajchuk,
  SidesKaravajchuk,
  Spacer,
  Text,
  TypographyWrapper,
} from "../../../lib";
import { mediaQuery } from "../../../utils";

import cinemas from "./conts/cinemas";
import concerts from "./conts/concerts";

const StyledCol = styled(Col)`
  padding-top: 60px;

  ${mediaQuery(
    "phone",
    css`
      padding-top: 0px;
    `,
  )}
`;

const StyledRow = styled(Row)`
  ${mediaQuery(
    "phone",
    css`
      margin-bottom: 50px;
    `,
  )}
`;

const StyledText = styled(Text)<{ style?: Record<string, string> }>`
  display: inline;
  margin-right: 3px;

  ${mediaQuery(
    "phone",
    css`
      font-size: 12px;
      line-height: 8px;
    `,
  )}
`;

const Concerts = styled.div`
  padding-left: 40px;

  ${mediaQuery(
    "phone",
    css`
      padding-left: 0;
    `,
  )}
`;

const Year = styled(Text)`
  font-size: 230px;
  margin-top: -50px;

  ${mediaQuery(
    "phone",
    css`
      font-size: 60px;
      margin-top: 0;
    `,
  )}
`;

const Time: React.FC = () => {
  const renderItem = React.useCallback(
    (title: string, index: number, length: number) => (
      <>
        <StyledText size={14} style={{ borderBottom: "1px solid #CCC" }}>
          {title}
        </StyledText>
        {index !== length - 1 && "/"}
      </>
    ),
    [],
  );

  const renderCinemaSide = React.useCallback((year: string) => {
    const filtered = cinemas.filter((cinema) => cinema.year === year);

    return (
      <div>
        {filtered.map((item, index) =>
          (item.url ? (
            <a
              key={index}
              href={item.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <StyledText size={14}>
                {item.title} {index !== filtered.length - 1 && "/"}{" "}
              </StyledText>
            </a>
          ) : (
            <StyledText size={14}>
              {item.title} {index !== filtered.length - 1 && "/"}{" "}
            </StyledText>
          )))}
      </div>
    );
  }, []);

  const renderConcertsSide = React.useCallback(
    (year: string) => {
      const filtered = concerts.filter((item) => item.year === year);

      return (
        <Concerts>
          {filtered.map((item, index) =>
            (item.url ? (
              <a
                key={index}
                href={item.url}
                rel="noopener noreferrer"
                target="_blank"
              >
                {renderItem(item.title, index, filtered.length)}
              </a>
            ) : (
              <StyledText size={14}>
                {renderItem(item.title, index, filtered.length)}
              </StyledText>
            )))}
        </Concerts>
      );
    },
    [renderItem],
  );

  const years = React.useMemo(
    () =>
      Array.from(
        new Set(
          [concerts.map((c) => c.year), cinemas.map((c) => c.year)]
            .flat()
            .sort(),
        ),
      ),
    [],
  );

  return (
    <TypographyWrapper>
      <NamedHeader name="время" />

      <PageIntroKaravajchuk author="karavajchuk" name="time" />

      {/* <TopImage url={`${PUBLIC_URL}/assets/images/shnitke/cinema/top.jpg`} /> */}

      <Spacer size={20} />

      <SidesKaravajchuk hideRight="time">
        <Spacer size={2} />
        <Container>
          {years.map((year, index) => (
            <StyledRow key={index}>
              <StyledCol lg={4} md={4} sm={4} xs={4}>
                {renderCinemaSide(`${year}`)}
              </StyledCol>
              <StyledCol lg={4} md={4} sm={4} xs={4}>
                <Year>&#39;{year.split("").splice(2, 4)}</Year>
              </StyledCol>
              <StyledCol lg={4} md={4} sm={4} xs={4}>
                {renderConcertsSide(`${year}`)}
              </StyledCol>
            </StyledRow>
          ))}
        </Container>
      </SidesKaravajchuk>
      <Spacer size={20} />
      <Footer />
    </TypographyWrapper>
  );
};

export default Time;
