import React from "react";
import styled, { css } from "styled-components";
import { ParallaxBanner } from "react-scroll-parallax";

import { mediaQuery } from "../utils";

type Props = {
  amount?: number;
  style?: React.CSSProperties;
  url: string;
};

const Desktop = styled.div`
  display: block;

  ${mediaQuery(
    "phone",
    css`
      display: none;
    `,
  )}
`;

const Phone = styled.div`
  display: none;

  ${mediaQuery(
    "phone",
    css`
      display: block;
    `,
  )}
`;

const TopImage: React.FC<Props> = ({
  amount = 0.05,
  style = {},
  url,
  ...rest
}: Props) => (
  <>
    <Desktop>
      <ParallaxBanner
        layers={[
          {
            amount,
            image: url,
          },
        ]}
        style={{
          height: "100vh",
          width: "100%",
          ...style,
        }}
        {...rest}
      />
    </Desktop>
    <Phone>
      <ParallaxBanner
        layers={[
          {
            amount,
            image: url,
          },
        ]}
        style={{
          height: "30vh",
          width: "100%",
          ...style,
        }}
        {...rest}
      />
    </Phone>
  </>
);

export default TopImage;
