import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";

import { Header, NavMenu, ScrollToTopArrow } from "./lib";
import { About, Home } from "./pages";
import * as KaravajchukPages from "./pages/karavajchuk";
import * as ShnitkePages from "./pages/shnitke";

function HideMenu({
  setIsMenuShowing,
}: {
  setIsMenuShowing: (value: boolean) => void;
}) {
  const { pathname } = useLocation();

  React.useEffect(() => {
    setIsMenuShowing(false);
  }, [pathname, setIsMenuShowing]);

  return null;
}

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App: React.FC = () => {
  const [isMenuShowing, setIsMenuShowing] = React.useState(false);

  const handleMenu = React.useCallback(() => {
    setIsMenuShowing(!isMenuShowing);
  }, [isMenuShowing]);

  React.useEffect(() => {
    document.body.style.overflow = isMenuShowing ? "hidden" : "scroll";
    // document.body.style.position = isMenuShowing ? "fixed" : "block";
  }, [isMenuShowing]);

  return (
    <Router>
      <Header handleMenu={handleMenu} isMenuShowing={isMenuShowing} />
      <NavMenu isMenuShowing={isMenuShowing} />
      <ParallaxProvider>
        <Switch>
          {/* Common */}
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          {/* Karavajchuk */}
          <Route path="/karavajchuk/cinema">
            <KaravajchukPages.Cinema />
          </Route>
          <Route path="/karavajchuk/ermitazh">
            <KaravajchukPages.Ermitazh />
          </Route>
          <Route path="/karavajchuk/music">
            <KaravajchukPages.Music />
          </Route>
          <Route path="/karavajchuk/source">
            <KaravajchukPages.Source />
          </Route>
          <Route path="/karavajchuk/time">
            <KaravajchukPages.Time />
          </Route>
          <Route path="/karavajchuk/vunderkind">
            <KaravajchukPages.Vunderkind />
          </Route>
          {/* Shnitke */}
          <Route path="/shnitke/cinema">
            <ShnitkePages.Cinema />
          </Route>
          <Route path="/shnitke/music">
            <ShnitkePages.Music />
          </Route>
          <Route path="/shnitke/philosophy">
            <ShnitkePages.Philosophy />
          </Route>
          <Route path="/shnitke/practicum">
            <ShnitkePages.Practicum />
          </Route>
          <Route path="/shnitke/source">
            <ShnitkePages.Source />
          </Route>
          <Route path="/shnitke/time">
            <ShnitkePages.Time />
          </Route>
          <Route path="/shnitke/ussr">
            <ShnitkePages.USSR />
          </Route>
        </Switch>
      </ParallaxProvider>
      <ScrollToTop />
      <HideMenu setIsMenuShowing={setIsMenuShowing} />
      <ScrollToTopArrow />
    </Router>
  );
};

export default App;
