/* eslint react/jsx-one-expression-per-line: 0 */

import React from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import {
  Block,
  Footer,
  NamedHeader,
  PageIntroShnitke,
  Quote,
  SidesShnitke,
  Spacer,
  SubTitle,
  Text,
  Title,
  TopImage,
  TypographyWrapper,
} from "../../../lib";

const { PUBLIC_URL } = process.env;

const StyledQuote = styled(Quote)`
  padding-left: 0;
  /* text-align: right; */
`;

const StyledTopImage = styled(TopImage)``;

const Practicum: React.FC = () => (
  <TypographyWrapper>
    <NamedHeader name="практикум" />

    <PageIntroShnitke name="practicum" />

    <StyledTopImage url={`${PUBLIC_URL}/assets/images/shnitke/praktikum.jpg`} />

    <Spacer size={10} />

    <SidesShnitke hideRight="practicum">
      <Container>
        <Row>
          <Col lg={{ offset: 1, span: 9 }}>
            <Title>что такое работа в кино?</Title>
          </Col>
        </Row>
      </Container>

      <Spacer size={4} />

      <Block>
        <>самое главное – это режиссер </>
        <>
          Если идешь работать в кино, то надо не только понять – всем существом
          почувствовать, что ему нужно. Не из угодничества, не из желания потом
          снова получить работу, а просто для пользы дела. Важны такие
          взаимоотношения с режиссером, при которыхего идеи,его планы,его
          замысел мгновенно становятся твоими, чтобы ты не воспринимал все это
          как чужое. Самый идеальный случай – когда происходит вроде бы
          нечаянное совпадение намерений режиссера и всех остальных. И это
          зависит не от формальной добросовестности. Можно быть благонамеренно
          добросовестным и ни черта не сделать, если есть какая-то антипатия к
          режиссеру или непониманиеего или если вы совершенно противоположного
          склада люди. Есть, например, режиссеры, про которых, при всех личных
          симпатиях и легкости контакта, думаешь: не надо мне с ним работать,
          работать должен кто-то другой. А есть режиссеры, с которыми на первый
          взгляд у тебя нет ничего общего – ни в интересах, ни в характере, – но
          есть какое-то сущностное совпадение, дающее возможность делать то,
          чего от тебя ждут. То есть нужно добровольно, без ощущения тягости или
          какого-то подавления своего «Я», заразиться тем, чего хочет режиссер.
          Это с одной стороны. С другой – нужно также делать и самостоятельные
          предложения режиссеру, понимая, как они могут отразиться не только на
          музыкальном строе фильма, а и на фильме в целом – повлиять на монтаж,
          внести изменения в задуманные режиссером линии. Особенно интересно
          работать с режиссерами, которые понимают, что музыка может выражать
          форму, определять в каком-то смысле поэтику. Точная расстановка
          акцентов, кульминаций, эпизодов разной длительности – здесь очень
          сильна роль музыки. Все это может сообщить форме фильма напряженность,
          собранность. И в этом смысле я вспоминаю работу с Климовым, с
          Шепитько, с Хржановским, с Таланкиным, с Авербахом.{" "}
          <Link to="/shnitke/source">[4]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>три самых близких режиссера</>
        <>
          Хржановский знает и понимает музыку, наверное, лучше чем я. Элем и
          Лариса совсем ее не понимают, но работа с ними заряжала меня на
          необычные решения. Я боюсь разговорчивых людей. Контакт с людьми у
          меня возникает без слов. Он или есть, илиего нет. И я не могу
          объяснить, почему он возникает и почему прекращается.{" "}
          <Link to="/shnitke/source">[8]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block right>
        <SubTitle>три начала четвертой симфонии</SubTitle>
        <StyledQuote right>
          <Text>
            Наум Клейман,
            <br />
            киновед и историк кино:
          </Text>
          <>
            Шнитке работал с разными режиссерами по-разному. Я знаю, например,
            что Альфред писал для Элема Климова музыку совершенно не так, как он
            писал для Андрея Хржановского или для Ларисы Шепитько, хотя Климов и
            Шепитько – близкие люди, но – абсолютно разные режиссеры.
          </>
          <br />
          <br />
          <>
            Мне кажется, что он почувствовал в Ларисе близость к мистириальному
            складу, который потом возник в «Прощании с Матерой», где он оставил
            Ларисину музыку, хотя фильм доделывал Климов. А у Климова такой
            трагический гротеск, но я не думаю, что он там доходил до
            трансцендентности, как у Ларисы. И совершенно другое дело у Андрея
            Хржановского, где он очень много работает коллажным методом, и у
            него он потрясающий.
          </>
          <br />
          <br />
          <>
            А Смирнов – это, по-моему, случайность, я думаю, что была слава
            Андрея как гонимого, которую он потом очень хорошо использовал.
            Альфред был человеком очень отзывчивым. У Андрея была слава как у
            Ларисы, после того, как закрыли их «Ангела» и «Родину
            электричества». Понимаете, это были наши мученики, а мы мученикам,
            как можем, помогаем.
            <Link to="/shnitke/source">[5]</Link>
          </>
        </StyledQuote>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>когда не надо ничего объяснять </>
        <>
          А вообще в процессе сотрудничества самое лучшее – когда не надо ничего
          объяснять. Вот идеал отношений режиссера с композитором и композитора
          с дирижером на записи. Бывают дирижеры, которые без дополнительных
          объяснений понимают, где и какой сделать акцент и как строить
          фразировку. И бывают дирижеры, с которыми полная несовместимость. Вот
          есть один такой в кино, не стану его называть, у него всегда похабная
          фразировка. Его можно попросить о чем угодно – быстрее, медленнее, тут
          акцент, там, – он все выполнит, но все равно будет непристойно.
          Приходилось мне работать и с дирижерами, которые прекрасно все делают,
          но в них нет дополнительного качества, от которого музыка начинает
          играть. Вроде все есть, а чего-то главного нет. Был такой эпизод в
          «Спорте...», где мы всю музыку записали с Эри Класом, ему ничего
          объяснять не надо... Кульминационный кусок, самый важный для нас, мы
          записали с Класом очень хорошо.
        </>
        <>
          Но был плохой зал, и и технически вышло плохо. Мы решили переписать.
          Класа в тот момент не было, пригласили Эмина Хачатуряна.
        </>
        <>
          Все было прекрасно записано, на перезаписи посмотрели и увидели, что
          фильм «дохнет». Не знаю, что случилось, Эмин даже быстрее как будто
          играл. Но это было другое исполнение музыки. В результате оставили
          фонограмму Класа, которая технически хуже. Но никто не заметил, что
          она плохо записана.
          <Link to="/shnitke/source">[4]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block right>
        <SubTitle>дирижер – хулиган </SubTitle>
        <StyledQuote right>
          <Text>
            Элем Климов,
            <br />
            режиссер:
          </Text>
          <>
            Он пригласил замечательного эстонского дирижера – хулигана Эри Класа
            ( он был боксер и джазист)... Я ему говорю: «Обычно дирижер палочкой
            дирижерской работает, а у тебя по-моему, резиновая дубинка в руках».
            Его оркестр боялся. А еще надо было в какой-то мере импровизировать
            во время записи. Вот это была запись! С наш им кинооркестром только
            так надо было работать. Он из них все силы вынул. Нужно было 12
            минут записать. У них получалось записать только одну минуту, и они
            падали в обморок, все в поту.
          </>
          <br />
          <br />
          <>
            Я помню, как мы записывали музыку с наш им Оркестром кинематографии.
            Дирижером был Э. Хачатурян. Я был поражен, как ведет себя оркестр и,
            самое главное, как ведет себя дирижер, который сидел в кресле – там
            экран, идут какие-то кадры, здесь оркестр, он дирижирует, и все
            время на часы подглядывает – скоро обед. Ближе к обеду он
            дирижирует, глядя на часы. Они все – тоже на часы. И он прерывает на
            полуфразе – обед. <Link to="/shnitke/source">[12]</Link>
          </>
        </StyledQuote>
      </Block>

      <Spacer half size={8} />

      <Block right>
        <></>
        <StyledQuote>
          <SubTitle>оркестры</SubTitle>
          <Text>
            Эри Клас,
            <br />
            дирижер:
          </Text>
          <>
            Оркестры Союза кинематографии, Мосфильма, Ленфильма были уникальными
            коллективами. Их музыканты обладали поразительной способностью читки
            с листа. Часто музыкальные фрагменты приносились буквально за
            несколько минут до начало записи.
          </>
          <br />
          <br />
          <>
            Зато через полчаса после такого исполнения музыкант не помнит ничего
            из того, что только что исполнял. Эти оркестранты поразительно
            изобретательны во всем, что касается молниеносного нахождения
            решений в проблемных ситуациях. Например, композитор не дописал
            музыку на секунду – две, или режиссер что-то там убавил или
            прибавил. Оркестранты были теми, кто предлагал решения: сократить
            или добавить пару тактов, или повторить еще раз. Как-то и мы со
            Шнитке попали в такой переплет.
          </>
          <>
            Снимался документальный фильм о революционерах не то Чили, не то
            Боливии. Шнитке не очень вдавался в суть самого фильма. Было только
            известно, что есть положительные и отрицательные персонажи, и время
            исполнения по фрагментам. Когда мы уже закончили репетиции и были
            готовы к записи, прибежал режиссер и сказал, что все никуда не
            годится, потому что мы перепутали, кто плохой, а кто хороший. При
            появление сил реакции звучал энергичный марш. Тогда музыканты
            предложили эту тему сыграть в миноре и в два раза быстрее. И все
            устроилось наилучш им образом.
            <Link to="/shnitke/source">[14]</Link>
          </>
        </StyledQuote>
      </Block>

      <Spacer half size={8} />

      <Block right>
        <SubTitle>лучший оркестр москвы</SubTitle>
        <StyledQuote>
          <Text>
            Сергей Скрипка,
            <br />
            дирижер:
          </Text>
          <>
            Золотой период в Оркестре кинематографии – о нем все вспоминали –
            это примерно с шестьдесят пятого и где-топо семьдесят пятый, целое
            десятилетие. Начались все эти хозрасчетные дела, и все музыканты,
            как они говорят, ходили в шоколаде. Тогда в оркестре и на первом
            месте, и на втором, и на третьем, и на четвертом сидели одни
            солисты. Это был лучший оркестр по составу… Лучший оркестр Москвы,
            потому что тут все зарабатывали деньги, несравнимые с другими
            оркестрами.
            <Link to="/shnitke/source">[26]</Link>
          </>
        </StyledQuote>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>эволюция музыки в кино </>
        <>
          Есть перемены – и в том, чего хотят режиссеры, и в том, что делают
          композиторы. У режиссеров обычно идет такая полоса, когда все хотят
          одного и того же. Все были под впечатлением «8 1/2», и все хотели Нино
          Роту, и так было довольно долго. Или а-ля Таривердиев. Через некоторое
          время все обнаружили вдруг стремление к чему-то неожиданному, новому:
          можно было пользоваться авангардными приемами, появилось много
          электронной музыки. Это длилось несколько лет, потом стало казаться
          наивным, при этом накладывало некоторый детективный оттенок на
          характер фильма, потому что особенно демонстративно всей этой
          «новизной» грешили именно детективные фильмы. Резкие акценты,
          вытеснение музыкой естественных шумов – все это вскоре стало
          напоминать таперское оформление немых картин. Сейчас заметно общее
          стремление к академизму, классичности. Оно отразилось и на музыке. Был
          еще период стилизации, когда музыка сочинялась в стиле ХVII века, мне
          самому приходилось к двум-трем фильмам это делать. Мне это доставляло
          большое удовольствие, потому что гораздо приятнее писать в стиле
          Вивальди или Баха. Так я делал музыку к фильмам Э.Климова «Похождения
          зубного врача» в 1965 году и «Спорт, спорт, спорт» в 1970-м.
          <Link to="/shnitke/source">[4]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>до сих пор я не могу сказать, что понял что-нибудь про кино</>
        <>
          Про то, как снимается кино. Потому что есть несколько постоянно
          возникающих проблем. Одна из них такая: должна ли музыка ритмически и
          эмоционально совпадать с изображением? Как будто бы не должна, потому
          что это тавтология. Но бывают случаи, когда это нужно. Где? Вроде бы в
          кульминации. Если узлы сходятся в одну точку. Но может быть, и нет. И
          вот каждый раз, когда делается картина, заново встает эта проблема.
          Вторая проблема – должна ли музыка быть на некоей комментирующей
          дистанции или она должна просто подкреплять изображение? И это тоже
          никогда заранее не известно – каждый раз проверяется экспериментально.
          Поэтому и случается, что режиссер в конце концов выкидывает музыку.
          Это всегда кажется варварством, но я понимаю режиссера и никогда не
          сопротивляюсь, если на перезаписи выкидывают куски.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>коварная кульминация </>
        <>
          Есть такая подлая закономерность: самый лучший музыкальный эпизод
          фильма обычно приходится на кульминацию, и если удается, то идеально с
          ней совпадает. На записи все счастливы, но, как правило, именно эта
          музыка в результате выбрасывается, и именно потому, что идеально
          совпадает, дублирует изображение, переводит на язык звуков – как для
          слепых – то, что в этот момент происходит на экране. Когда этот эпизод
          смотрится отдельно от диалогов и шумов и, главное, отдельно от фильма,
          кажется, что вот теперь благодаря совпадению музыки и экрана вся идея
          картины в нем уместилась. Но в контексте всего фильма все выглядит
          иначе. Точное совпадение рядов начинает мешать. Такие истории были со
          мной много раз.
          <Link to="/shnitke/source">[4]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>«фантазии фарятьева»</>
        <>
          Там есть кульминационный эпизод, где героиня уходит к таинственному
          персонажу по фамилии Бетхузов (почти Бетховен), – страшная
          истерическая сцена, когда она мечется по комнате в пьяном
          эмоциональном возбуждении. Была написана музыка. Когда мы посмотрели
          на столе, выяснилось, что все движения Нееловой, ее реплики и
          музыкальный фон абсолютно, как в балете, совпадали, хотя мы писали без
          экрана.
        </>
        <>
          И Авербах мне сказал, что нужно переделать. Это закономерность,
          совершенно четкая. Эпизод, в котором достигнуто максимальное
          совпадение музыки и изображения, не работает.
          <Link to="/shnitke/source">[4]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>«осень» </>
        <>
          Такой же случай был с картиной «Осень» – я написал музыку до съемок,
          снимали под черновую фонограмму. И хотя Андрей Смирнов ничего не
          выкинул, у меня позже было ощущение, что от иных музыкальных эпизодов
          нужно было отказаться, их точное совпадение с изображением –
          наложение, калька – было неприятно. Ритм все равно непроизвольно
          задается движениями актеров, и дублироватьего музыкой очень опасно.
          <Link to="/shnitke/source">[4]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>«восхождение» </>
        <>
          Кульминационная сцена – казнь. Когда смотрели материал на экране, мне
          казалось, что все хорошо, и Лариса так считала. Звукооператору тоже
          нравилось. А Элему совсем не нравилось. Что-то он видел
          подозрительное. Позже я и сам понял, что все действительно ни к черту
          не годится, потому что там и было вот это совпадение, пусть нечаянное,
          скажем, жеста или мимического движения с музыкой.
        </>
        <>
          Сцена в монтаже без музыки не казалась чрезмерно напряженной, поэтому
          нужен был какой-то допинг, какой-то сквозной импульс. Его не может
          дать тематическая музыка – было необходимо не внешнее, литературное, а
          внутреннее, какое-то физиологически-эмоциональное совпадение, что-то
          неартикулируемое.
        </>
        <>
          Я написал второй неполный, написал третий полный вариант, не имеющий
          ничего общего с первым. Я понял, что не годится тематизм, что нужна
          некая меняющаяся эмоциональная педаль. Бывают фильмы или эпизоды, где
          тематическая музыка, хотя она внешне подходит, сразу придает
          изображению да и всему происходящему на экране банальный
          мелодраматический характер. Я вовремя почуял опасность и написал такую
          многослойную сонористическую партитуру. Иначе на перезаписи пришлось
          бы просто выкинуть весь музыкальный эпизод и искать другое решение –
          шумовое или еще какое-то.
          <Link to="/shnitke/source">[4]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>сквозная линия: тема и тональность</>
        <>
          Я постоянно стараюсь иметь некую сквозную линию, которая держится либо
          на монотематизме, либо на нескольких темах, но как-то соединенных,
          где-нибудь взаимодействующих, чтобы был чисто музыкальный порядок в
          этом деле. Но есть еще одна очень важная вещь – тональный план.
          Совершенно вроде бы не ощущается, в разных или в одной тональности
          написаны эпизоды, разведенные минутами действия. На самом деле это
          важно. Опорные моменты фильма я выстраиваю на одной тональности – не
          на нарастающей, а на одной. Определенная тональность выполняет роль
          рефрена или чего-то организующего.
        </>
        <>
          И это особенно важно в таком кино, где неизбежна полижанровость,
          полистилистика. Как правило, нужен и какой-то центральный музыкальный
          эпизод – либо в начале, и тогда он источник, либо в кульминации.
          <Link to="/shnitke/source">[3]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>этапы</>
        <>
          Самое главное для начала работы – взаимопонимание с режиссером, потом
          находятся темы, эскизы. Все проверяется на рояле. И очень важны
          просмотры материала, когда я начинаю примеривать на себя эти темы и
          сразу понимаю, подходят они или не подходят. Кроме того, стараюсь в
          этот момент прикинуть, сколько проведений тем должно быть, и так им
          образом схронометрировать музыку с экраном, а не с секундомером.
        </>
        <>
          Может быть, я слишком кустарно работаю, непрофессионально. Я очень не
          люблю эту сетку, поэтому в момент записи бывает много изменений.
          Что-то сокращаю, что-то прибавляю, какие-то акценты меняю. На студии,
          конечно, протестуют против этого. Но так лучше, чем заранее точно все
          высчитывать.
          <Link to="/shnitke/source">[3]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>мистическая связь</>
        <>
          У американцев существует еще неизвестная нам профессия – разметчик.
          Этот человек берет заказанный кусок и по секундам размечает все
          акценты, необходимые режиссеру. Композитор там пишет такую партитуру,
          в которой все отклонения от периодичности, нужные для акцента или еще
          для чего-то, выражены нотными знаками. Триоли, например. Вдруг акцент
          на вторую ноту.
        </>
        <>
          Но мне эта система кажется неподходящей, потому что кроме временного
          совпадения и связи между музыкальным акцентом и зрителем бывает связь,
          которую я объяснить опять же не берусь, но которая важнее всего. Можно
          взять два дубля, формально кажущиеся совершенно одинаковыми, если
          послушать их отдельно. Оба хорошие. И если приложить их к изображению,
          они тоже покажутся формально, в смысле совпадения акцентов, абсолютно
          одинаковыми, и даже эмоциональная линия будет. Но выяснится, что звук
          с экраном почему-то не срослись. Вот это сращивание музыки с
          изображением – вещь непонятная. Необходима органика, которой добиться
          всегда трудно. Эта органика обычно гибнет от чрезмерно точной
          разметки, и по возможности я стараюсь точную разметку не делать.
        </>
        <>
          Здесь есть какая-то мистическая связь, которую проконтролировать
          никакие приборы не могут. И если она возникла в каком-то дубле, то
          лучше взять этот дубль, пусть даже есть какие-то дефекты в записи или
          киксы в оркестре. Все равно это лучше, чем идеально сделанное, но не
          органично соединяющееся с экраном.
          <Link to="/shnitke/source">[3]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>иногда режиссеру кажется что оркестр – опасность</>
        <>
          Необходимо, чтобы режиссер понимал: музыка неотделима от изображения,
          она как бы растворяется в нем, усиливая его. А то бывает так.
          Режиссер, придя на запись музыки к фильму и впервые услышав в оркестре
          то, что ранее слышал на рояле, иногда музыку как бы не узнает.
        </>
        <>
          Она представляется ему слишком доминирующей. Она звучит на
          изобразительных отрывках, идущих без текста, и режиссеру кажется –
          берет на себя слишком много, «тянет на себя». Некоторые режиссеры
          начинают опасаться, что музыка будет слишком «торчать», и передают это
          опасения композитору в завуалированной комплементарной форме:
          изображение, мол, «не дотягивает» до звучания. Лишь постановщики,
          которые очень точно чувствуют внутри себя целое будущего фильма,
          понимают, что это эффект временный, возникающий оттого, что
          отсутствуют другие звуковые компоненты, что все идетпо кускам и
          многократно повторяется.
          <Link to="/shnitke/source">[3]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>кино и мультипликация: два разных метода</>
        <>
          Работа композитора в игровом и в мультипликационном кино имеет
          существенные различия. В мультипликации получаешь, как правило, нечто
          вроде графической партитуры будущей музыки, поскольку экспликация и
          есть для музыки графическая партитура. И она интересна. Она как бы
          сразу и есть музыка, то есть, глядя на нее, уже можно иллюзорно
          представить себе музыку, записанную так вот графически, и остается
          только ее расшифровать, то есть можно перенести на нотную бумагу все
          эти временные пропорции в виде тактов, скажем, секунды разметить
          тактами, четвертями или еще как-то, и потом все это заполнить нотами.
          Очень просто.
        </>
        <>
          И это действительно как-то помогает, потому что пугает больше всего,
          когда не знаешь, какой будет следующий шаг. То есть самое рискованное
          и в то же время самое интересное при сочинении музыки – это именно тот
          момент, факт – когда не знаешь, какой будет следующий шаг, куда ногу
          поставишь дальше... Здесь ясно, куда ее поставить, а в игровом кино
          сочиняется так, как обычно, там акценты довольно редки, поэтому
          невозможно себе точную сетку выписать и потом ее заполнить. Совершенно
          разный метод работы. В мультипликации он напоминает какую-то точную
          музыкальную технику, скажем, то ли серийную, то ли сочинение фуги, где
          более или менее заранее ясна конструкция, ее надо только заполнить и
          оживить, что очень важно. В этом отличие и привлекательность работы в
          мультипликационном кино, потому что она вынуждает к крайней точности.
          <Link to="/shnitke/source">[10]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>заполнение секунды и относительность времени</>
        <>
          Потом есть еще понимаешь относительность времени. Ибо секунда в
          мультипликации может очень много вместить, она страшно насыщена, в
          отличие от секунды в игровом кино, которая, наоборот, тяготеет к тому,
          чтобы сделать краткой не то что секунду, а даже сделать час кратким. В
          игровом кино ощущение точного метрического времени преодолевается одн
          им способом, а в мультипликации – совершенно противоположным. Какой из
          них ближе к написанию собственной музыки? Оба. Потому что можно
          по-разному представить себе музыкальную форму. Можно представить ее, в
          частности, и как взаимодействие разных ощущений времени: нормального,
          быстрого и потом – более медленного, более нормального хода времени.
          По этому поводу многие высказывались, и это оценивается композиторами
          индивидуально.
          <Link to="/shnitke/source">[10]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>быстрая и медленная музыка</>
        <>
          Например, существует мнение, что быстрая музыка вмещает в себя больше
          событий и также как бы больше времени. То есть мы за две минуты
          переживаем гораздо больше, чем сами две минуты. Медленная, наоборот,
          как бы размывает время, и тут мы вроде переживаем меньше, то есть, мы
          можем ощутить некую секунду, превращенную в бесконечность. Точка
          соприкосновения вечности и мгновения – это медленная музыка.
          <Link to="/shnitke/source">[10]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>музыкальные решения на основе возможностей звукозаписи</>
        <>
          В кино часто возникают музыкальные решения, в значительной степени
          основанные на использовании возможностей звукозаписи. Например,
          сведение нескольких оркестровых или хоровых фонограмм, которые мало
          координированы друг с другом, но вместе создают супердинамическую
          суммарную картину. Для фонограммы к фильму М. Ромма «И все-таки я
          верю» я должен был дать музыкальный, звуковой эквивалент взрыва
          атомной бомбы.
        </>
        <>
          Мне уже доводилось сталкиваться с такой трудной задачей и в театре, и
          в оратории «Нагасаки». В фильме я задумал соединить много хоров, чтобы
          создать ощущение некого хорового вселенского вопля. Но сведя
          одновременно кульминационные аккорды из записи классических сочинений
          (из баховских «Страстей», «Торжественной мессы» Бетховена, «Реквиема»
          Моцарта и многого другого), не получил того выразительного эффекта, на
          который рассчитывал.
        </>
        <>
          Возник некий объемный, тонально не уточненный вопль, но чего-то в нем
          не хватало. Как потом я понял, напротив, было лишнее, он был
          перенагружен, а человеческое ухо не воспринимало как осмысленное это
          избыточное сочетание.
          <Link to="/shnitke/source">[3]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>многослойные фактуры </>
        <>
          Есть много приемов в современной музыке, подсказанных звукозаписью.
          Очевидно, в какой-то степени поиски в области соединения разных
          фактур, характерные для кино, отразились на музыке, не связанной
          впрямую с киноискусством. Так, первая моя симфония написана примерно в
          то время, когда я работал на картине Ромма. Она содержит очень много
          многослойных фактур, где параллельно играется разная музыка, иногда
          цитируемая, иногда сочиненная. Эта идея многоэтажного пространства
          возникла, безусловно, благодаря звукозаписи. Хотя еще в студенческие
          годы мой сокурсник, чрезвычайно одаренный композитор А.Карманов
          высказывал идею о подлинной полифонии как единовременном сочетании
          разных произведений, «разных музык».
          <Link to="/shnitke/source">[3]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>айвз и марширующие оркестры </>
        <>
          Потом я узнал, что американский композитор Ч.Айвз делал независимо от
          звукозаписи сходные вещи многими десятилетиями раньше, еще в конце
          прошлого века. Оказывается, он опирался на реальную стереофонию
          доступных ему средств. Его отец, который заведовал всей военной
          музыкой армии Северных штатов, мог экспериментировать как угодно с
          марширующими оркестрами. Он поднимался на колокольню и заставлял
          оркестры проходить в разных направлениях с различными маршами. Вот эта
          меняющаяся звуковая сумма напоминала, вероятно, такие многослойные
          фонограммы, которые сейчас можно получить путем наложения, с помощью
          средств звукозаписи...
          <Link to="/shnitke/source">[3]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>вариабельность эха </>
        <>
          Были в моей практике и более сложные случаи выразительного
          использования средств звукозаписи. Так, в картине Климова «Прощание»,
          в финале, наряду с сочиненной мной хоровой музыкой цитируется как бы
          звуковой тенью несколько отголосков из классических хоров. Это было
          воплощением еще давно – в 1966-м году, в Первом струнном квартете –
          задуманной идеей создать «вариабельность эха», то есть воплотить такую
          ситуацию, когда на музыку возникает ответ – эхо как бы в волшебном
          зеркале, и отражение оказывается совсем иной музыкой. Но в квартете,
          при живом исполнении, это не получилось. А в фильме эффект «другого
          эха» возник.
          <Link to="/shnitke/source">[3]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block right>
        <SubTitle>ансамбль аstreja</SubTitle>
        <StyledQuote>
          <Text>
            София Губайдулина,
            <br />
            композитор:
          </Text>
          <>
            До сих пор для меня очень важную роль играют попытки
            импровизировать, освобождать свою фантазию от слушателя, от
            исполнителя – когда моя душа выражена в линии, которую я провожу,
            или в звуке, рождающемся сейчас. А не в том, что записано на бумаге
            и потом исполнено.Когда-то была культура устного творчества, не
            записанного. Она представляла очень большую ценность, хотя без
            письменной культуры мы не можем, и это вполне естественно. Но
            ощущение устной, непосредственной передачи хотелось бы возвратить –
            и у нас была возможность это осуществить, пусть даже среди только
            трех человек.
          </>
          <br />
          <br />
          <>
            Собираться втроем мы стали с середины 70-х годов. Это был не тип
            совместного музицирования, а нечто вроде духовной школы...
          </>
          <br />
          <br />
          <>
            Нас интересуют в первую очередь инструменты, на которых мы не умеем
            играть. Мы их собираем специально — будь то японский инструмент
            кото, или пипа, или тамбуро.
            <Link to="/shnitke/source">[28]</Link>
          </>
        </StyledQuote>
      </Block>

      <Spacer half size={8} />

      <Block right>
        <SubTitle>в начале было слово и слово было «играть»</SubTitle>
        <StyledQuote>
          <Text>
            Вячеслав Артёмов,
            <br />
            композитор:
          </Text>
          <>
            Играть собственные композиции, используя необычные источники звука.
            Не просто играть – исполнять, но исследовать и использовать
            возможности экзотических для европейской музыки инструментов, в
            поисках необычных звучаний, редких сочетаний тембров и выразительных
            музыкальных деталей. Коллекция преимущественно восточных и
            закавказских инструментов, которую мне удалось собрать, – духовых
            струнных и ударных, – стала объектом исследования ансамбля «Астрея»,
            который образовался около 1975 года и включал композиторов В.
            Артемова, С. Губайдулину и В. Суслина.
          </>
          <br />
          <br />
          <>
            Сначала мы импровизировали на основе созданных каждым участником
            ансамбля схематических композиций, которые отражали индивидуальные
            намерения их авторов, однако вскоре мы нашли в себе новые
            возможности, перестали быть просто «исполнителями», но
            сосредоточились исключительно на спонтанной, неподготовленной
            заранее импровизации, что требует максимальной сосредоточенности,
            внутренней свободы и самозабвения. Выступления перед публикой
            нарушали эти условия, требовали «исполнения», а не творения и от них
            пришлось отказаться в пользу записи в студии. Монтаж мы отвергали.
            Наша игра была формой непосредственного выражения состояния и
            реакцией на «сообщения» партнеров – как бы беседой на случайные темы
            трех совершенно разных по темпераменту, вкусам, взглядам и
            творческим устремлениям композиторов. И важна была, очевидно, не
            сама тема, а проявление этих темпераментов, взаимоотношения которых
            и явились основной формообразующей силой. Поэтому творчество
            «Астреи» – это драма с признаками ностальгии (или ностальгия с
            признаками драмы), отражение трагичности бытия и предчувствие
            разлома. Но именно присущая ансамблю внутренняя противоречивость
            была источником резких контрастов музыкального материала, дающих
            возможность развития и создания динамичной формы.
          </>
          <br />
          <br />
          <>
            Так мы записали на едином дыхании ряд довольно продолжительных
            вещей, оказавшихся настолько разнообразными по выразительности, и
            обладающими такой естественной логикой движения и смен характеров,
            что даже наши коллеги с трудом верили в возможность достижения
            такого единства без предварительных репетиций и монтажа. Это был
            особый, наиболее откровенный и искренний из всех известных вид
            импровизации, вероятно доступный лишь композиторам, к тому же
            прошедш им особую «настройку». На самом деле это не было построением
            формы посредством звуков, но проявлением жизни в звуке.
          </>
          <br />
          <br />
          <>
            Однако, привычка к «сочинению», созданию условных графических схем –
            композиций оказалась необходимой при привлечении дополнительных
            исполнительских сил, например, голосов. Очень плодотворным и
            приятным оказалось сотрудничество с фольклорным ансамблем Д.
            Покровского, с н им мы записали музыку к фильму Э. Климова
            «Прощание».Вот некоторые из наших обычных инструментов: дудук,
            саламури, тар, кяманча, чонгури, канон, разные барабаны,
            колокольчики.
            <Link to="/shnitke/source">[28]</Link>
          </>
        </StyledQuote>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>оркестр как ансамбль</>
        <>
          Структуры оркестра должны быть изменены. Оркестр не должен
          восприниматься лишь как нивелирующее сообщество или как арена
          столкновений между индивидуальностями и массой, но прежде всего как
          огромный театр всеобщей индивидуализации. Контакт между музыкантами
          обеспечивается не только общей партитурой и дирижером над ними – между
          музыкантами должны возникать все более тонкие, непредвиденные,
          спонтанные связи (как, например, в джазе). Произвольная иерархия
          партитурных функций, как и разница в ставках исполнителей, должна быть
          дополнена и подправлена подвижной иерархией духовных и интуитивных
          различий.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>пробуждение интуитивных возможностей</>
        <>
          Образование музыкантов (и композиторов тоже!) должно измениться,
          расширившись в следующем направлении:
        </>
        <>
          а) Каждый музыкант должен научиться овладеть всем (то есть не только
          традиционно-авангардистской техникой, но и психологически чужой, –
          например, джазом, битом, фольклорной импровизацией, восточной
          музыкальной медитацией, магической ритуальной примитивностью и
          прочим).
        </>
        <>
          б) Поскольку рационально-методическ им путем этого не достичь, следует
          отыскать путь к пробуждению «интуитивных» возможностей усвоения. Все
          музыкальное воспитание должно измениться принципиально в смысле
          десхематизации, возврата к детскому «бессознательному» усвоению новых
          знаний и умений. Проблема эта не музыкальная, а всеобщая – сознание
          должно выйти из личинки и научиться летать. Но для того, чтобы
          научиться летать, методика «шаг за шагом» непригодна, здесь нужно
          отважиться на прыжок.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>две важные вещи, осознанные в кино</>
        <>
          Есть две очень важные вещи, которые я осознал именно в кино.
          Во-первых, когда работал с Элемом Климовым над «Похождениями зубного
          врача» и с Андреем Хржановск им над мультфильмами, особенно
          «Стеклянной гармоникой», то столкнулся с полистилистикой, гармоничной
          эклектикой, где суммарный результат давал новое единство.
        </>
        <>
          Такой подход к пестрому по стилю материалу очень повлиял на меня.
          Многое и в истории музыки, что раньше казалось разнородным, – Малер,
          Айвз, многое у Шостаковича, Берио, Циммерман, Пуссен – перестало
          казаться органическ им недостатком, с которым надо было бороться, а
          представилось, наоборот, естественным.
        </>
        <>
          Во-вторых то, что я делал в киномузыке, было вызвано необходимостью
          создавать фонограммы как бы теневые, вроде бы неслышимые, по
          номинальной первоначальной логике словно ненужные.
        </>
        <>
          …Смотришь картину, слышишь фонограмму – вроде бы звучит один план. Но
          теневые звучания, стереофонические призвучия придают ему
          многомерность… в виде варьированного эха.
          <Link to="/shnitke/source">[19]</Link>
        </>
      </Block>

      <Spacer half size={8} />

      <Block>
        <>влияние работы в кино</>
        <>
          …Мне приходилось довольно много работать в кино и театре, и я
          чувствую, что эта моя работа влияет на работу в других жанрах, то есть
          камерном и симфоническом… Звучит тематический материал, частично
          использованный мною раньше в качестве музыки для кино… Но влияние…
          кино может быть не столь прямым и проявляться не только в цитировании
          тем, но также и в том, как строится так называемая музыкальная
          драматургия сочинения…
        </>
        <>
          Мне представляется, что некоторые сочинения я бы не написал, если бы
          не опыт работы в кино…
          <Link to="/shnitke/source">[29]</Link>
        </>
      </Block>

      <Spacer size={48} />
    </SidesShnitke>
    <Footer />
  </TypographyWrapper>
);

export default Practicum;
