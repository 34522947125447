import React from "react";
import styled, { css } from "styled-components";
import { Col, Row } from "react-bootstrap";

import {
  Footer,
  Nav,
  PhoneMenu,
  SidesHome,
  Spacer,
  Text,
  TypographyWrapper,
} from "../../lib";
import { mediaQuery } from "../../utils";

import Photo from "./Photo";

const Author = styled(Text)`
  line-height: 0.9;
`;

const Intro = styled(Text)`
  ${mediaQuery(
    "phone",
    css`
      font-size: 24px;
    `,
  )}
`;

const StyledSpacer = styled(Spacer)`
  display: none;

  ${mediaQuery(
    "phone",
    css`
      display: block;
    `,
  )}
`;

const Home: React.FC = () => (
  <TypographyWrapper>
    <Nav />

    <PhoneMenu />

    <Photo />

    <Spacer size={8} />

    <SidesHome>
      <Row>
        <Col lg={{ span: 7 }}>
          <Intro lowercase size={36}>
            Советская киномузыка — мировой феномен. Очень редко на Западе
            композиторы столь высокого уровня работали в кино. При этом
            киномузыка в СССР часто являлась самым свободным проявлением в
            искусстве. Существуя официально, в то же время она могла не
            вписываться в каноны «социалистического реализма».
          </Intro>
        </Col>

        <StyledSpacer size={8} />

        <Col lg={{ offset: 1, span: 3 }}>
          <Author family="thin" size={36}>
            Альфред Шнитке, композитор:
          </Author>
          <Text>
            Композитор, работающий в кино, неизбежно подвергается риску. Недаром
            в Америке есть профессия composer, и Hollywood-composer — это
            совершенно другая профессия. На современном Западе ни один
            приличный, уважающий себя композитор в кино не работает. Кино не
            может не диктовать композитору своих условий.
          </Text>

          <Spacer size={8} />

          <Author family="thin" size={36}>
            Наум Клейман, киновед и историк кино:
          </Author>
          <Text>
            Почему надо благодарить советскую власть за хороший уровень нашей
            киномузыки? Признание кино важнейшим из искусств привело к тому, что
            режиссер всегда мог сказать — мне не нужен халтурщик, который будет
            повторять штампы. Мне нужен новый композитор.
          </Text>
        </Col>
      </Row>
      <Spacer size={38} />
    </SidesHome>
    <Footer />
  </TypographyWrapper>
);

export default Home;
