import React from "react";
import styled from "styled-components";

import { useOnScreen } from "../hooks";

type Props = {
  children: React.ReactNode;
  delay?: number;
  duration?: number;
  isShowing?: boolean;
};

const Root = styled.div<{
  delay: number;
  duration: number;
  isShowing: boolean;
}>`
  opacity: ${({ isShowing }) => (isShowing ? 1 : 0)};
  transform: ${({ isShowing }) => `translateY(${isShowing ? 0 : 20}px)`};
  transition: ${({ duration }) => `${duration}s ease-out`};
  transition-delay: ${({ delay }) => `${delay}s`};
`;

const TransitionOnScreen: React.FC<Props> = ({
  children,
  delay = 0,
  duration = 0.3,
  isShowing: isShowingProp = false,
  ...rest
}: Props) => {
  const ref = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  const [isShowing, setIsShowing] = React.useState<boolean>(isShowingProp);

  const onScreen = useOnScreen(ref, "0px");

  React.useEffect(() => {
    if (onScreen && !isShowing) {
      setIsShowing(true);
    }
  }, [isShowing, onScreen]);

  return (
    <Root
      ref={ref}
      {...{
        ...rest,
        delay,
        duration,
        isShowing,
      }}
    >
      {children}
    </Root>
  );
};

export default TransitionOnScreen;
