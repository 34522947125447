import React from "react";
import styled, { css } from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import Dots from "./SidesOtherDots";
import Spacer from "./Spacer";
import { mediaQuery } from "../utils";

type Props = {
  children: React.ReactNode;
  hide?: "cinema" | "music" | "time" | undefined;
  hideRight?: "philosophy" | "practicum" | "source" | undefined;
};

const LeftSide = styled.div`
  margin-left: -230px;
  margin-top: 270px;
  position: absolute;
  text-align: right;
  transform: rotate(-90deg);
  width: 500px;
  z-index: 10;

  ${mediaQuery(
    "phone",
    css`
      display: none;
    `,
  )}
`;

const Item = styled.div`
  display: inline-block;
  font-size: 24px;
  text-align: center;
`;

const RightSide = styled.div`
  margin-left: -230px;
  margin-top: 320px;
  text-align: right;
  transform: rotate(-90deg);
  width: 600px;

  ${mediaQuery(
    "phone",
    css`
      display: none;
    `,
  )}
`;

const StyledLink = styled(Link)`
  display: inline-block;
`;

const SidesShnitke: React.FC<Props> = ({
  children,
  hide = undefined,
  hideRight = undefined,
}: Props) => (
  <Container>
    <Row>
      <Col lg={{ span: 1 }}>
        <LeftSide>
          <Item>
            {hide !== "music" && (
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <Link to="/shnitke/music">
                    музыка
                    <Dots />
                  </Link>
                </Item>
              </>
            )}
            {hide !== "cinema" && (
              <>
                <Spacer size={18} vertical={false} />
                <StyledLink to="/shnitke/cinema">
                  кино
                  <Dots />
                </StyledLink>
              </>
            )}
          </Item>
          {hide !== "time" && (
            <>
              <Spacer size={18} vertical={false} />
              <Item>
                <Link to="/shnitke/time">
                  время
                  <Dots />
                </Link>
              </Item>
            </>
          )}
        </LeftSide>
      </Col>

      <Col>{children}</Col>

      <Col lg={{ span: 1 }}>
        <RightSide>
          {hideRight !== "source" && (
            <Item>
              <Link to="/shnitke/source">источники</Link>
              <Dots />
            </Item>
          )}
          {hideRight !== "philosophy" && (
            <>
              <Spacer size={18} vertical={false} />
              <Item>
                <Link to="/shnitke/philosophy">философия</Link>
                <Dots />
              </Item>
            </>
          )}
          {hideRight !== "practicum" && (
            <>
              <Spacer size={18} vertical={false} />
              <Item>
                <Link to="/shnitke/practicum">практиктикум</Link>
                <Dots />
              </Item>
            </>
          )}
        </RightSide>
      </Col>
    </Row>
  </Container>
);

export default SidesShnitke;
