import React from "react";
import styled, { css } from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";

import { Text } from "../../lib";
import { mediaQuery } from "../../utils";

const { PUBLIC_URL } = process.env;

const Arrow = styled.button`
  background: none;
  border: none;
  outline: none;
  position: absolute;
  top: calc(50% - 60px);
  z-index: 2000;

  ${mediaQuery(
    "phone",
    css`
      top: calc(50% - 20px);
    `,
  )}
`;

const ArrowImage = styled.img``;

const ArrowNext = styled(Arrow)`
  right: 20px;

  img {
    transform: rotate(180deg);
    width: 40px;

    ${mediaQuery(
    "phone",
    css`
        margin-top: 11vh;
        width: 20px;
      `,
  )}
  }

  ${mediaQuery(
    "phone",
    css`
      right: 4px;
    `,
  )}
`;

const ArrowPrev = styled(Arrow)`
  left: 20px;

  img {
    width: 40px;

    ${mediaQuery(
    "phone",
    css`
        margin-top: 11vh;
        width: 20px;
      `,
  )}
  }

  ${mediaQuery(
    "phone",
    css`
      left: 4px;
    `,
  )}
`;

const One = styled(Text)`
  transform: translateY(-140px);

  ${mediaQuery(
    "phone",
    css`
      font-size: 36px;
      transform: translateY(-20px);
    `,
  )}
`;

const ResponsiveCarousel = styled(Carousel)<{ autoHeight: boolean }>`
  height: ${(props) => (props.autoHeight ? "auto" : "100vh")};
  overflow: hidden;
  position: relative;

  ${mediaQuery(
    "phone",
    css`
      height: 50vh;
      margin: 0px 0 0px;
      min-width: 100vw;
    `,
  )}
`;

const RightCol = styled(Col)`
  text-align: right;
`;

const Root = styled.div`
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 100vh;
  margin: 50px 0 100px;
  position: relative;
  width: 100%;

  ${mediaQuery(
    "phone",
    css`
      height: 50vh;
      margin: 0px 0 40px;
      min-width: 100vw;
    `,
  )}
`;

const StyledText = styled(Text)`
  ${mediaQuery(
    "phone",
    css`
      font-size: 24px;
    `,
  )}
`;

const TextWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
`;

const HomePhoto: React.FC = () => (
  <ResponsiveCarousel
    autoHeight={false}
    centerMode={false}
    renderArrowNext={(onClickHandler, hasNext, label) =>
      hasNext && (
        <ArrowNext onClick={onClickHandler} title={label} type="button">
          <ArrowImage
            alt="Image"
            src={`${PUBLIC_URL}/assets/images/ui/arrow.png`}
          />
        </ArrowNext>
      )
    }
    renderArrowPrev={(onClickHandler, hasPrev, label) =>
      hasPrev && (
        <ArrowPrev onClick={onClickHandler} title={label} type="button">
          <ArrowImage
            alt="Image"
            src={`${PUBLIC_URL}/assets/images/ui/arrow.png`}
          />
        </ArrowPrev>
      )
    }
    showIndicators
    showStatus={false}
    showThumbs={false}
  >
    <Link to="/karavajchuk/music">
      <Root
        style={{
          backgroundImage:
            // eslint-ignore-next-line
            `url(${PUBLIC_URL}/assets/images/home/karavajchuk.jpg)`,
        }}
      >
        <TextWrapper>
          <Container>
            <Row>
              <RightCol lg={{ offset: 6, span: 6 }}>
                <One inverted size={64}>
                  (2)
                </One>
              </RightCol>
            </Row>
            <Row>
              <Col
                lg={{ span: 6 }}
                sm={{ span: 6 }}
                style={{ textAlign: "left" }}
                xs={{ span: 6 }}
              >
                <StyledText inverted size={84}>
                  олег
                </StyledText>
              </Col>
              <RightCol lg={{ span: 6 }} sm={{ span: 6 }} xs={{ span: 6 }}>
                <StyledText inverted size={84}>
                  каравайчук
                </StyledText>
              </RightCol>
            </Row>
          </Container>
        </TextWrapper>
      </Root>
    </Link>
    <Link to="/shnitke/music">
      <Root
        style={{
          backgroundImage: `url(${PUBLIC_URL}/assets/images/home/shnitke.jpg)`,
        }}
      >
        <TextWrapper>
          <Container>
            <Row>
              <RightCol lg={{ offset: 6, span: 6 }}>
                <One inverted size={64}>
                  (1)
                </One>
              </RightCol>
            </Row>
            <Row>
              <Col
                lg={{ span: 6 }}
                sm={{ span: 6 }}
                style={{ textAlign: "left" }}
                xs={{ span: 6 }}
              >
                <StyledText inverted size={84}>
                  альфред
                </StyledText>
              </Col>
              <RightCol lg={{ span: 6 }} sm={{ span: 6 }} xs={{ span: 6 }}>
                <StyledText inverted size={84}>
                  шнитке
                </StyledText>
              </RightCol>
            </Row>
          </Container>
        </TextWrapper>
      </Root>
    </Link>
  </ResponsiveCarousel>
);

export default HomePhoto;
