
const cinemas = [
  {
    title: "ВСТУПЛЕНИЕ",
    url: "https://www.youtube.com/watch?v=qkurvKhe9ec",
    year: "1962",
  },
  {
    title: "ВЫЗЫВАЕМ ОГОНЬ НА СЕБЯ",
    url: "https://cinema.mosfilm.ru/films/34878/",
    year: "1964",
  },
  {
    title: "ПОХОЖДЕНИЯ ЗУБНОГО ВРАЧА",
    url: "https://cinema.mosfilm.ru/films/35210/",
    year: "1965",
  },
  {
    title: "ДНЕВНЫЕ ЗВЕЗДЫ ",
    url: "https://cinema.mosfilm.ru/films/34660/",
    year: "1966",
  },
  {
    title: "ШУТОЧКА (короткометражный)",
    url: undefined,
    year: "1966",
  },
  {
    title: "КОМИССАР",
    url: "https://www.youtube.com/watch?v=RoJIhadFY1Q",
    year: "1967",
  },
  {
    title: "ДОМ И ХОЗЯИН",
    url: undefined,
    year: "1967",
  },
  {
    title: "АНГЕЛ (киноальманах «Начало неведомого века», новелла)",
    url: "https://cinema.mosfilm.ru/films/34543/",
    year: "1967",
  },
  {
    title: "СТРЕЛЯНЫЕ ГИЛЬЗЫ (короткометражный) ",
    url: undefined,
    year: "1968",
  },
  {
    title: "ШЕСТОЕ ИЮЛЯ ",
    url: "https://www.youtube.com/watch?v=LgB_xnOFUNo",
    year: "1968",
  },
  {
    title: "СТЕКЛЯННАЯ ГАРМОНИКА (мультфильм)",
    url: "https://hd.kinopoisk.ru/film/4d129820c6b58880923c788c31d2107e/",
    year: "1968",
  },
  {
    title: "ВАЛЬС",
    url: "https://www.youtube.com/watch?v=JItRcCD5lt8",
    year: "1969",
  },
  {
    title: "БАЛЕРИНА НА КОРАБЛЕ (мультфильм)",
    url: "https://hd.kinopoisk.ru/film/4c4697c174639d8bb19b465eff49f31e/",
    year: "1969",
  },
  {
    title: "НОЧНОЙ ЗВОНОК ",
    url: "https://www.youtube.com/watch?v=Srka5GhKIPU",
    year: "1969",
  },
  {
    title: "ТОСКА (короткометражный)",
    url: undefined,
    year: "1969",
  },
  {
    title: "СПОРТ, СПОРТ, СПОРТ",
    url: "https://cinema.mosfilm.ru/films/34321/",
    year: "1970",
  },
  {
    title: "ДЯДЯ ВАНЯ",
    url: "https://cinema.mosfilm.ru/films/35733/",
    year: "1970",
  },
  {
    title: "ЧАЙКА",
    url: "https://cinema.mosfilm.ru/films/34537/",
    year: "1970",
  },
  {
    title: "ТЫ И Я ",
    url: "https://www.youtube.com/watch?v=x4_jw8enrOI",
    year: "1971",
  },
  {
    title: "ПОСЛЕДНИЙ РЕЙС «АЛЬБАТРОСА» ",
    url: "https://www.youtube.com/watch?v=5Ly8DE9Als0  ",
    year: "1971",
  },
  {
    title: "ШКАФ (мультфильм)",
    url: undefined,
    year: "1971",
  },
  {
    title: "НАШ ГАГАРИН (документальный)",
    url: "https://www.net-film.ru/film-7025/?search=v1tt1001|78|22ft1|5|7|8|9qнаш%20гагарин",
    year: "1971",
  },
  {
    title: "И ВСЕ-ТАКИ Я ВЕРЮ (МИР СЕГОДНЯ)",
    url: "https://www.youtube.com/watch?v=dHIGtB13vYQ",
    year: "1972",
  },
  {
    title: "ГОРЯЧИЙ СНЕГ",
    url: "https://cinema.mosfilm.ru/films/35579/",
    year: "1972",
  },
  {
    title: "НА УГЛУ АРБАТА И УЛИЦЫ БУБУЛИНАС ",
    url: undefined,
    year: "1972",
  },
  {
    title: "ПРАВО НА ПРЫЖОК ",
    url: undefined,
    year: "1972",
  },
  {
    title: "БАБОЧКА (мультфильм)",
    url: undefined,
    year: "1972",
  },
  {
    title: "ВЫШЕ ГОЛОВУ (мультфильм)",
    url: "https://hd.kinopoisk.ru/film/4949e9344d9071ea9115cdc7ebf84aae/?experiments=control&from_block=person_page&from_position=5&watch=",
    year: "1972",
  },
  {
    title: "ЧИЛИ В БОРЬБЕ, НАДЕЖДЕ И ТРЕВОГЕ (документальный)",
    url: undefined,
    year: "1972",
  },
  {
    title: "ГОРОДА И ГОДЫ ",
    url: undefined,
    year: "1973",
  },
  {
    title: "БЫЛОЕ И ДУМЫ ",
    url: "https://www.youtube.com/playlist?list=PL40OiiJw6-gda_IvBfZ15VSJXtBp9b-uh",
    year: "1973",
  },
  {
    title: "В МИРЕ БАСЕН (мультфильм)",
    url: "https://hd.kinopoisk.ru/film/4d97504af1f2f8a08e534766d0743b65/?experiments=control&from_block=person_page&from_position=3&watch=",
    year: "1973",
  },
  {
    title: "РАВНОВЕСИЕ СТРАХА (мультфильм)",
    url: undefined,
    year: "1973",
  },
  {
    title: "ТРУДНЫЕ ДОРОГИ МИРА (документальный)",
    url: undefined,
    year: "1973",
  },
  {
    title: "ОСЕНЬ",
    url: "https://www.youtube.com/watch?v=hwYIg2l71Nc",
    year: "1974",
  },
  {
    title: "АГОНИЯ",
    url: "https://cinema.mosfilm.ru/films/35276/",
    year: "1974",
  },
  {
    title: "ВЫБОР ЦЕЛИ",
    url: "https://www.youtube.com/watch?v=3EOQedVMzRg",
    year: "1974",
  },
  {
    title: "ДОМИК В КОЛОМНЕ (телеспектакль)",
    url: "https://www.youtube.com/watch?v=nQqu_kL8xbQ",
    year: "1974",
  },
  {
    title: "РИККИ-ТИККИ-ТАВИ",
    url: undefined,
    year: "1975",
  },
  {
    title: "БЕЛЫЙ ПАРОХОД",
    url: "https://www.youtube.com/watch?v=TH__yG9gz5M",
    year: "1975",
  },
  {
    title: "СКАЗ ПРО ТО, КАК ЦАРЬ ПЕТР АРАПА ЖЕНИЛ",
    url: "https://cinema.mosfilm.ru/films/34765/",
    year: "1976",
  },
  {
    title: "ВОСХОЖДЕНИЕ",
    url: "https://cinema.mosfilm.ru/films/35148/",
    year: "1976",
  },
  {
    title: "ПОВЕСТЬ О НЕИЗВЕСТНОМ АКТЕРЕ",
    url: undefined,
    year: "1976",
  },
  {
    title: "ПРИКЛЮЧЕНИЯ ТРАВКИ",
    url: undefined,
    year: "1976",
  },
  {
    title: "КЛОУНЫ И ДЕТИ (короткометражный)",
    url: undefined,
    year: "1976",
  },
  {
    title: "ДРЕССИРОВЩИКИ (киноальманах)",
    url: "https://www.youtube.com/watch?v=jO6nuyVaXJU",
    year: "1976",
  },
  {
    title: "СЧЕТ ЧЕЛОВЕЧЕСКИЙ ",
    url: undefined,
    year: "1977",
  },
  {
    title: "Я К ВАМ ЛЕЧУ ВОСПОМИНАНЬЕМ... (мультфильм)",
    url: undefined,
    year: "1977",
  },
  {
    title: "ОТЕЦ СЕРГИЙ",
    url: undefined,
    year: "1978",
  },
  {
    title: "КАПИТАНСКАЯ ДОЧКА (телеспектакль)",
    url: "https://www.youtube.com/watch?v=d2su6AVDHFg",
    year: "1978",
  },
  {
    title: "МАЛЕНЬКИЕ ТРАГЕДИИ",
    url: "https://www.youtube.com/watch?v=1Ke33_1de_Y ",
    year: "1979",
  },
  {
    title: "ЭКИПАЖ",
    url: "https://cinema.mosfilm.ru/films/34490/",
    year: "1979",
  },
  {
    title: "ФАНТАЗИИ ФАРЯТЬЕВА",
    url: "https://www.youtube.com/watch?v=rIxNB5d1PnQ ",
    year: "1979",
  },
  {
    title: "ЗАЧЕМ БАБИРУССЕ КЛЫКИ? (документальный)",
    url: undefined,
    year: "1979",
  },
  {
    title: "ЛАРИСА (документальный)",
    url: "https://www.youtube.com/watch?v=FIlDHBZ_HkY",
    year: "1980",
  },
  {
    title: "И С ВАМИ СНОВА Я (мультфильм)",
    url: "https://hd.kinopoisk.ru/film/43f6beaef06cd2b794020f6d92d7361b?from_block=kp-button-online&watch=",
    year: "1980",
  },
  {
    title: "ПРОЩАНИЕ",
    url: "https://www.youtube.com/watch?v=dAcYGqEKB4o ",
    year: "1981",
  },
  {
    title: "ЗВЕЗДОПАД",
    url: "https://cinema.mosfilm.ru/films/35631/",
    year: "1981",
  },
  {
    title: "КРЕПЫШ",
    url: "https://www.net-film.ru/film-33967/",
    year: "1981",
  },
  {
    title: "СКАЗКА СТРАНСТВИЙ",
    url: "https://cinema.mosfilm.ru/films/35252/",
    year: "1982",
  },
  {
    title: "ОСЕНЬ (мультфильм)",
    url: undefined,
    year: "1982",
  },
  {
    title: "МЕРТВЫЕ ДУШИ",
    url: "https://www.youtube.com/watch?v=385ezj2KUc8",
    year: "1984",
  },
  {
    title: "ЛЮБИМЕЦ ПУБЛИКИ",
    url: "https://www.net-film.ru/film-33966/",
    year: "1985",
  },
  {
    title: "МАСТЕР И МАРГАРИТА",
    url: undefined,
    year: "1994",
  },
];

export default cinemas;
