import React from "react";
import styled, { css } from "styled-components";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";

import { mediaQuery } from "../utils";

const { PUBLIC_URL } = process.env;

type Props = {
  autoHeight?: boolean;
  centerMode?: boolean;
  centerSlidePercentage?: number;
  slides: Array<{ url: string }>;
  showIndicators?: boolean;
};

const Arrow = styled.button`
  background: none;
  border: none;
  outline: none;
  position: absolute;
  top: calc(50% - 60px);
  z-index: 2;

  ${mediaQuery(
    "phone",
    css`
      top: calc(50% - 20px);
    `,
  )}
`;

const ArrowImage = styled.img``;

const ArrowNext = styled(Arrow)`
  right: 20px;

  img {
    transform: rotate(180deg);
    width: 40px;

    ${mediaQuery(
    "phone",
    css`
        width: 20px;
      `,
  )}
  }
`;

const ArrowPrev = styled(Arrow)`
  left: 20px;

  img {
    width: 40px;

    ${mediaQuery(
    "phone",
    css`
        width: 20px;
      `,
  )}
  }
`;

const Root = styled(ResponsiveCarousel)<{ autoHeight: boolean }>`
  height: ${(props) => (props.autoHeight ? "auto" : "100vh")};
  overflow: hidden;
`;

const Carousel: React.FC<Props> = ({
  autoHeight = false,
  centerMode = false,
  centerSlidePercentage = 100,
  slides,
  showIndicators = true,
  ...rest
}: Props) => (
  <Root
    autoHeight={autoHeight}
    centerMode={centerMode}
    centerSlidePercentage={centerSlidePercentage}
    renderArrowNext={(onClickHandler, hasNext, label) =>
      hasNext && (
        <ArrowNext onClick={onClickHandler} title={label} type="button">
          <ArrowImage
            alt="Image"
            src={`${PUBLIC_URL}/assets/images/ui/arrow.png`}
          />
        </ArrowNext>
      )
    }
    renderArrowPrev={(onClickHandler, hasPrev, label) =>
      hasPrev && (
        <ArrowPrev onClick={onClickHandler} title={label} type="button">
          <ArrowImage
            alt="Image"
            src={`${PUBLIC_URL}/assets/images/ui/arrow.png`}
          />
        </ArrowPrev>
      )
    }
    showIndicators={showIndicators}
    showStatus={false}
    showThumbs={false}
    {...rest}
  >
    {slides.map(({ url }, index) => (
      <div key={index}>
        <img alt="slide" src={url} />
      </div>
    ))}
  </Root>
);

export default Carousel;
