import React from "react";
import styled, { css } from "styled-components";

import { mediaQuery } from "../utils";

const { PUBLIC_URL } = process.env;

type Props = {
  author?: "karavajchuk" | "shnitke";
  name:
  | "cinema"
  | "ermitazh"
  | "music"
  | "philosophy"
  | "practicum"
  | "time"
  | "vunderkind";
};

const Name = styled.img`
  height: 7.5vw;
  position: absolute;
  right: 5px;
  top: 18vw;

  ${mediaQuery(
    "phone",
    css`
      top: 47vw;
    `,
  )}
`;

const Root = styled.div<Pick<Props, "author">>`
  background-image: ${(props) =>
    `url(${`${PUBLIC_URL}/assets/images/ui/top-sign-${props.author}.png`});`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: calc(100vw / 3.9);
  transform: translateY(-4vh);
  width: 100%;

  ${mediaQuery(
    "phone",
    css`
      margin-bottom: 30px;
      margin-top: 100px;
      transform: unset;
    `,
  )}
`;

const PageIntroShnitke: React.FC<Props> = ({
  author = "shnitke",
  name,
}: Props) => (
  <Root author={author}>
    <Name alt="Page name" src={`${PUBLIC_URL}/assets/images/ui/${name}.png`} />
  </Root>
);

export default PageIntroShnitke;
