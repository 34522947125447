/* eslint max-len:0 */

const concerts = [
  {
    title: "КОНЦЕРТ № 1 для скрипки с оркестром ",
    url: "https://music.yandex.ru/album/7545304 ",
    year: "1957",
  },
  {
    title: "КОНЦЕРТ для фортепиано с оркестром",
    url: "https://open.spotify.com/album/57oLA2RM4uvYx9iwE3IJZI?si=NnER5lzsRACj_ZaQOcLsgQ",
    year: "1960",
  },
  {
    title: "СОНАТА для скрипки и фортепиано № 1",
    url: "https://music.yandex.ru/album/9495594/track/43676962",
    year: "1963",
  },
  {
    title: "ПРЕЛЮДИЯ И ФУГА",
    url: "https://music.yandex.ru/album/14462258/track/29937680",
    year: "1963",
  },
  {
    title: "МУЗЫКА ДЛЯ ФОРТЕПИАНО И КАМЕРНОГО ОРКЕСТРА",
    url: "https://www.youtube.com/watch?v=2Pwc96FDGRE",
    year: "1964",
  },
  {
    title: "ТРИ СТИХОТВОРЕНИЯ МАРИНЫ ЦВЕТАЕВОЙ для меццо-сопрано и фортепиано",
    url: "",
    year: "1965",
  },
  {
    title: "ИМПРОВИЗАЦИЯ И ФУГА для фортепиано",
    url: "https://music.yandex.ru/album/10223942/track/63969420",
    year: "1965",
  },
  {
    title: "ВАРИАЦИИ НА ОДИН АККОРД для фортепиано",
    url: "https://music.yandex.ru/album/14462258/track/29937682",
    year: "1965",
  },
  {
    title: "ДИАЛОГ для виолончели и семи инструменталистов",
    url: "https://music.yandex.ru/album/10834985/track/66451765",
    year: "1965",
  },
  {
    title: "КОНЦЕРТ № 2 для скрипки и камерного оркестра",
    url: "https://music.yandex.ru/album/7545304/track/53028329",
    year: "1966",
  },
  {
    title:
      "ВАРИАЦИИ (коллективные) НА ТЕМЫ ШЕСТНАДЦАТОЙ СИМФОНИИ Н.Я. МЯСКОВСКОГО для симфонического оркестра",
    url: "",
    year: "1966",
  },
  {
    title: "СТРУННЫЙ КВАРТЕТ № 1 ",
    url: "https://music.yandex.ru/album/10617665/track/65558833",
    year: "1966",
  },
  {
    title: "СОНАТА для скрипки и фортепиано № 2 (Quasi una sonata)",
    url: "https://music.yandex.ru/album/9504784/track/61103266",
    year: "1968",
  },
  {
    title: "СЕРЕНАДА для скрипки, кларнета, контрабаса, фортепиано и ударных",
    url: "https://www.youtube.com/watch?v=FN6YIjh7Ri0",
    year: "1968",
  },
  {
    title: "PIANISSIMO для большого оркестра ",
    url: "",
    year: "1968",
  },
  {
    title: "ПОТОК",
    url: "https://music.yandex.ru/album/10306974/track/64285747",
    year: "1969",
  },
  {
    title: "ЛАБИРИНТЫ, балет в 5 эпизодах",
    url: "https://www.youtube.com/watch?v=528b_5vPQmE",
    year: "1971",
  },
  {
    title: "ШЕСТЬ ПЬЕС для фортепиано ",
    url: "https://music.yandex.ru/album/14462258/track/29937683",
    year: "1971",
  },
  {
    title: "КОНЦЕРТ для гобоя, арфы и струнных",
    url: "https://music.yandex.ru/album/13596999/track/76923354",
    year: "1971",
  },
  {
    title: "КАНОН ПАМЯТИ ИГОРЯ СТРАВИНСКОГО для струнного квартета",
    url: "https://music.yandex.ru/album/42523/track/414895",
    year: "1971",
  },
  {
    title: "СИМФОНИЯ № 1 ",
    url: "https://music.yandex.ru/album/10814243 ",
    year: "1972",
  },
  {
    title: "СЮИТА В СТАРИННОМ СТИЛЕ ",
    url: "https://www.youtube.com/watch?v=_3bPCCXXGKY&t=154s",
    year: "1972",
  },
  {
    title: "ФОРТЕПИАННЫЙ КВИНТЕТ",
    url: "https://music.yandex.ru/album/9504784/track/61103268",
    year: "1972",
  },
  {
    title: "IN MEMORIAM (оркестровая версия Фортепианного квинтета)",
    url: "https://music.yandex.ru/album/13593877",
    year: "1972",
  },
  {
    title: "ГОЛОСА ПРИРОДЫ для 10 женских голосов и вибрафона",
    url: "https://music.yandex.ru/album/13163239/track/75075260",
    year: "1972",
  },
  {
    title: "ПОЗДРАВИТЕЛЬНОЕ РОНДО для скрипки и фортепиано",
    url: "https://music.yandex.ru/album/18558010/track/92559554",
    year: "1973",
  },
  {
    title:
      "DER GELBE KLANG (Желтый звук), сценическая композиция для пантомимы, инструментального ансамбля, сопрано соло, смешанного хора, магнитной ленты и цвето-световых проекторов. ",
    url: "https://music.yandex.ru/album/10834985/track/66451770",
    year: "1974",
  },
  {
    title: "ГИМНЫ I, II, III, IV для камерного ансамбля",
    url: "https://www.youtube.com/watch?v=UEkQkGkx5zo",
    year: "1974",
  },
  {
    title: "ВОСЕМЬ ПЕСЕН из спектакля «Дон Карлос» для баритона и фортепиано",
    url: "",
    year: "1975",
  },
  {
    title: "РЕКВИЕМ из музыки к драме Шиллера «Дон Карлос» ",
    url: "https://open.spotify.com/album/3qctED5mW8ocA7w71AUGsW?si=7QjavX2xRCSSDNI-KNMxMw",
    year: "1975",
  },
  {
    title: "CANTUS PERPETUUS для клавишных инструментов и ударных",
    url: "",
    year: "1975",
  },
  {
    title:
      "ПРЕЛЮДИИ ПАМЯТИ Д. ШОСТАКОВИЧА для двух скрипок или одной скрипки и магнитофонной записи",
    url: "https://www.youtube.com/watch?v=vktnVVGJGuI",
    year: "1975",
  },
  {
    title:
      "КАДЕНЦИИ к Скрипичному концерту Бехтовена для скрипки соло, десяти скрипок и литавр",
    url: "",
    year: "1975",
  },
  {
    title: "КАДЕНЦИЯ к Клавирному концерту Моцарта до минор",
    url: "https://music.yandex.ru/album/14462258/track/79527069",
    year: "1975",
  },
  {
    title:
      "MOZ-ART для двух скрипок (Обработка Менуэта из Сюиты в старинном стиле) ",
    url: "",
    year: "1975",
  },
  {
    title:
      "MOZ-ART (По эскизам Моцарта К 416 d). Первая (новогодняя) версия для флейты, кларнета (in А), трех скрипок, альта, виолончели, контрабаса, органа, ударных",
    url: "",
    year: "1975",
  },
  {
    title: "DER SONNENGESANG DES FRANZ VON ASSISI",
    url: "https://www.youtube.com/watch?v=AJvzMGzqHpI",
    year: "1976",
  },
  {
    title: "MOZ-ART для двух скрипок (По эскизам Моцарта К 416 d)",
    url: "https://music.yandex.ru/album/2417320/track/21149686",
    year: "1976",
  },
  {
    title: "CONCERTO GROSSO № 1",
    url: "https://music.yandex.ru/album/13901461",
    year: "1977",
  },
  {
    title: "MOZ-ART à la Haydn",
    url: "https://music.yandex.ru/album/22159/track/217419",
    year: "1977",
  },
  {
    title: "МАГДАЛИНА для голоса и фортепиано",
    url: "https://music.yandex.ru/album/10834985/track/66451781",
    year: "1977",
  },
  {
    title: "СОНАТА для виолончели и фортепиано № 1 (посв. Наталье Гутман)",
    url: "https://music.yandex.ru/album/11146548/track/67691761",
    year: "1978",
  },
  {
    title: "КОНЦЕРТ № 3 для скрипки и камерного оркестра ",
    url: "https://music.yandex.ru/album/7545304/track/53028330",
    year: "1978",
  },
  {
    title: "STILLE NACHT для скрипки и фортепиано",
    url: "https://music.yandex.ru/album/18558010/track/92559553",
    year: "1978",
  },
  {
    title: "СИМФОНИЯ № 2 (St. Florian) ",
    url: "https://music.yandex.ru/album/8261250",
    year: "1979",
  },
  {
    title:
      "ПОСВЯЩЕНИЕ ИГОРЮ СТРАВИНСКОМУ, СЕРГЕЮ ПРОКОФЬЕВУ, ДМИТРИЮ ШОСТАКОВИЧУ для фортепиано в 6 рук",
    url: "https://music.yandex.ru/album/9504784/track/61103265",
    year: "1979",
  },
  {
    title: "КОНЦЕРТ для фортепиано и струнных ",
    url: "https://music.yandex.ru/album/9504784/track/61103274",
    year: "1979",
  },
  {
    title: "ПАССАКАЛИЯ для оркестра",
    url: "https://music.yandex.ru/album/13593900/track/76907400",
    year: "1979",
  },
  {
    title: "STILLE MUSIC для скрипки и виолончели",
    url: "https://music.yandex.ru/album/13203881/track/75250193",
    year: "1979",
  },
  {
    title: "СТРУННЫЙ КВАРТЕТ № 2 (памяти Ларисы Шепитько) ",
    url: "https://music.yandex.ru/album/42523/track/414881",
    year: "1980",
  },
  {
    title: "ТРИ МАДРИГАЛА",
    url: "https://www.youtube.com/watch?v=0MpP7cfIyUE ",
    year: "1980",
  },
  {
    title: "ТРИ СЦЕНЫ для сопрано и ансамбля",
    url: "",
    year: "1980",
  },
  {
    title: "МИННЕЗАНГ для 52 хористов",
    url: "",
    year: "1980",
  },
  {
    title: "MOZ-ART, версия для шести инструментов",
    url: "",
    year: "1980",
  },
  {
    title: "ДВЕ МАЛЕНЬКИЕ ПЬЕСЫ для органа",
    url: "https://music.yandex.ru/album/12054986/track/71022197",
    year: "1980",
  },
  {
    title: "ТРИ КАДЕНЦИИ к Клавирному концерту Моцарта до мажор ",
    url: "https://music.yandex.ru/album/14462258/track/79527067",
    year: "1980",
  },
  {
    title: "СИМФОНИЯ № 3 ",
    url: "https://music.yandex.ru/album/8282964/track/56128288",
    year: "1981",
  },
  {
    title: "CONCERTO GROSSO № 2",
    url: "https://music.yandex.ru/album/15288084",
    year: "1981",
  },
  {
    title: "ГОГОЛЬ-СЮИТА",
    url: "https://music.yandex.ru/album/13196597/track/75218049",
    year: "1981",
  },
  {
    title: "СЕПТЕТ",
    url: "",
    year: "1981",
  },
  {
    title: "A PAGANINIA",
    url: "https://music.yandex.ru/album/13260647/track/75478255",
    year: "1982",
  },
  {
    title:
      "LEBENSLAUF (Жизнеописание) для четырех метрономов, трех исполнителей на ударных инструментах и фортепиано",
    url: "https://www.youtube.com/watch?v=enr0h7sEZ-4",
    year: "1982",
  },
  {
    title: "СТРУННЫЙ КВАРТЕТ № 3",
    url: "https://music.yandex.ru/album/42523/track/414891",
    year: "1983",
  },
  {
    title:
      "SEID NUCHTERN UND WACHTED… (История доктора Иоганна Фауста), кантата для контртенора, контральто, тенора, баса, смешанного хора и оркестра ",
    url: "https://music.yandex.ru/album/18727448/track/93120403",
    year: "1983",
  },
  {
    title: "КАДЕНЦИИ к Клавирному концерту Моцарта до мажор",
    url: "https://music.yandex.ru/album/14462258/track/29937682",
    year: "1983",
  },
  {
    title: "ДВЕ КАДЕНЦИИ к Концерту Моцарта для фагота с оркестром",
    url: "",
    year: "1983",
  },
  {
    title: "SCHALL UND HALL (Звук и отзвук) для тромбона и органа ",
    url: "",
    year: "1983",
  },
  {
    title: "СИМФОНИЯ № 4 ",
    url: "https://music.yandex.ru/album/8282916",
    year: "1984",
  },
  {
    title: "КОНЦЕРТ № 4 для скрипки с оркестром",
    url: "https://music.yandex.ru/album/11859010",
    year: "1984",
  },
  {
    title: "РИТУАЛ",
    url: "https://music.yandex.ru/album/13593900/track/76907392",
    year: "1984",
  },
  {
    title: "КОНЦЕРТ для смешанного хора на стихи Г. Нарекаци ",
    url: "https://music.yandex.ru/album/16603484",
    year: "1984",
  },
  {
    title: "ТРИ ХОРА для смешанного хора a capella ",
    url: "https://music.yandex.ru/album/8764305/track/57990493",
    year: "1984",
  },
  {
    title: "КОНЦЕРТ для альта с оркестром",
    url: "https://music.yandex.ru/album/9504782/track/61103280",
    year: "1985",
  },
  {
    title: "КОНЦЕРТ № 1 для виолончели с оркестром",
    url: "https://music.yandex.ru/album/13133309",
    year: "1985",
  },
  {
    title: "(K)EIN SOMMERNACHTSTRAUM",
    url: "https://music.yandex.ru/album/13248265/track/75422035",
    year: "1985",
  },
  {
    title: "СТРУННОЕ ТРИО",
    url: "https://www.youtube.com/watch?v=W8DfhsezSLQ",
    year: "1985",
  },
  {
    title: "CONCERTO GROSSO № 3",
    url: "https://music.yandex.ru/album/13278638",
    year: "1985",
  },
  {
    title: "СЕПТЕТ",
    url: "",
    year: "1985",
  },
  {
    title: "ПЕР ГЮНТ балет в 3 актах с эпилогом",
    url: "https://music.yandex.ru/album/13273201",
    year: "1986",
  },
  {
    title: "КОНЦЕРТ для фортепиано (в 4 руки) и камерного оркестра",
    url: "https://music.yandex.ru/album/19818/track/197529",
    year: "1987",
  },
  {
    title: "ТРИО-СОНАТА",
    url: "https://music.yandex.ru/album/13278638/track/75568132",
    year: "1987",
  },
  {
    title: "СТИХИ ПОКАЯННЫЕ для смешанного хора без сопровождения",
    url: "https://www.youtube.com/watch?v=IUYTb7wWIbY",
    year: "1987",
  },
  {
    title: "СОНАТА № 1 для фортепиано",
    url: "https://music.yandex.ru/album/14462258",
    year: "1987",
  },
  {
    title: "CONCERTO GROSSO № 4 / СИМФОНИЯ № 5",
    url: "https://music.yandex.ru/album/9504784/track/61103276",
    year: "1988",
  },
  {
    title: "DREI GEDICHTE VON VIKTOR SCHNITTKE",
    url: "",
    year: "1988",
  },
  {
    title: "ЧЕТЫРЕ АФОРИЗМА для инструментального ансамбля",
    url: "https://www.youtube.com/watch?v=AJvzMGzqHpI",
    year: "1988",
  },
  {
    title: "ЗВУЧАЩИЕ БУКВЫ (Klngende Bucbstaben) для виолончели соло",
    url: "https://music.yandex.ru/album/4049412/track/33154554",
    year: "1988",
  },
  {
    title: "НАБРОСОК ко второй части Фортепианного квартета Г. Малера",
    url: "https://music.yandex.ru/album/11904772/track/70473858",
    year: "1988",
  },
  {
    title: "МОНОЛОГ для альта и струнного (камерного) оркестра",
    url: "https://open.spotify.com/track/2QFLce6OaWf1lHnjNdjrll?si=fd1e065cb6d5429a",
    year: "1989",
  },
  {
    title: "СТРУННЫЙ КВАРТЕТ № 4",
    url: "https://music.yandex.ru/album/42523/track/414888",
    year: "1989",
  },
  {
    title:
      "EROFFNUNG ZUM 1 FESTSPIELSONNTAG (Вступление к первому воскресному празднику) 3х7 для кларнета, валторны, тромбона, клавесина, скрипки, виолончели, контрабаса",
    url: "",
    year: "1989",
  },
  {
    title: "КОНЦЕРТ № 2 для виолончели с оркестром",
    url: "https://music.yandex.ru/album/1888737",
    year: "1990",
  },
  {
    title: "ТРИ ПЬЕСЫ для клавесина",
    url: "",
    year: "1990",
  },
  {
    title: "ПЯТЬ АФОРИЗМОВ для фортепиано",
    url: "https://music.yandex.ru/album/14462258/track/29937692",
    year: "1990",
  },
  {
    title: "СОНАТА № 2 для фортепиано",
    url: "https://music.yandex.ru/album/14462258/track/29937672",
    year: "1990",
  },
  {
    title: "MOZ-ART A LA MOZART версия для восьми флейт и арфы",
    url: "",
    year: "1990",
  },
  {
    title: "ДВЕ КАДЕНЦИИ к Клавирному концерту Моцарта",
    url: "https://music.yandex.ru/album/14462258/track/29937672",
    year: "1990",
  },
  {
    title: "ЖИЗНЬ С ИДИОТОМ опера в 2 актах",
    url: "",
    year: "1991",
  },
  {
    title: "CONCERTO GROSSO № 5",
    url: "https://music.yandex.ru/album/5935911/track/21818918",
    year: "1991",
  },
  {
    title: "SUTARTINES для струнного оркестра и ударных",
    url: "",
    year: "1991",
  },
  {
    title:
      "ТОРЖЕСТВЕННЫЙ КАНТ для скрипки, фортепиано, хора и большого симфонического оркестра",
    url: "",
    year: "1991",
  },
  {
    title: "AGNUS DEI для двух сопрано соло, женского хора и оркестра",
    url: "https://open.spotify.com/album/3qctED5mW8ocA7w71AUGsW?si=SaItLQkcQRyBrOiofIpcpg&dl_branch=1",
    year: "1991",
  },
  {
    title: "МАДРИГАЛ ПАМЯТИ ОЛЕГА КАГАНА для скрипки соло или виолончели соло",
    url: "https://music.yandex.ru/album/4049412/track/33154552",
    year: "1991",
  },
  {
    title: "К 90-ЛЕТИЮ АЛЬФРЕДА ШЛЕЕ для альта соло",
    url: "",
    year: "1991",
  },
  {
    title: "СИМФОНИЯ № 6",
    url: "https://music.yandex.ru/album/13174530",
    year: "1992",
  },
  {
    title: "HOMMAGE А GRIEG",
    url: "https://music.yandex.ru/album/13240914/track/75388546 ",
    year: "1992",
  },
  {
    title: "СОНАТА № 3 для фортепиано",
    url: "https://music.yandex.ru/album/14462258/track/29937675",
    year: "1992",
  },
  {
    title: "ТРИО для скрипки, виолончели и фортепиано",
    url: "https://music.yandex.ru/album/13260647/track/75478289",
    year: "1992",
  },
  {
    title: "ЭПИЛОГ из балета «Пер Гюнт» для виолончели, фортепиано и хора",
    url: "https://music.yandex.ru/album/12054986/track/71022200",
    year: "1992",
  },
  {
    title: "СИМФОНИЯ № 7",
    url: "https://music.yandex.ru/album/13204130",
    year: "1993",
  },
  {
    title: "CONCERTO GROSSO № 6",
    url: "https://music.yandex.ru/album/13270356",
    year: "1993",
  },
  {
    title: "MUTTER для меццо-сопрано и фортепиано",
    url: "",
    year: "1993",
  },
  {
    title: "ИМПРОВИЗАЦИЯ для виолончели соло",
    url: "https://open.spotify.com/album/4ml1gakPmFLEHbG1rMhcJX?si=kAeTWiKuRGOMZUnM7xUi8A&dl_branch=1",
    year: "1993",
  },
  {
    title: "ДЖЕЗУАЛЬДО опера в пяти картинах",
    url: "",
    year: "1994",
  },
  {
    title:
      "ИСТОРИЯ ДОКТОРА ИОГАННА ФАУСТА опера в 3 актах с прологом и эпилогом",
    url: "",
    year: "1994",
  },
  {
    title: "СИМФОНИЯ № 8",
    url: "https://music.yandex.ru/album/13270356/track/75522391",
    year: "1994",
  },
  {
    title: "КОНЦЕРТ НА ТРОИХ",
    url: "https://music.yandex.ru/album/4133493/track/33777575",
    year: "1994",
  },
  {
    title: "СИМФОНИЧЕСКИЙ ПРОЛОГ для оркестра",
    url: "",
    year: "1994",
  },
  {
    title: "FOR LIVERPOOL для оркестра",
    url: "https://music.yandex.ru/album/13161159/track/75063395",
    year: "1994",
  },
  {
    title: "КВАРТЕТ для ударных инструментов",
    url: "",
    year: "1994",
  },
  {
    title: "ПЯТЬ ФРАГМЕНТОВ ПО КАРТИНАМ И. БОСХА для тенора и малого оркестра",
    url: "",
    year: "1994",
  },
  {
    title: "СОНАТИНА для фортепиано в 4 руки",
    url: "https://music.yandex.ru/album/14462258/track/29937697",
    year: "1994",
  },
  {
    title: "СОНАТА для скрипки и фортепиано № 3",
    url: "https://music.yandex.ru/album/3416378/track/7773681",
    year: "1994",
  },
  {
    title: "МЕНУЭТ для скрипки, альта и виолончели",
    url: "https://music.yandex.ru/album/4133493/track/33777579",
    year: "1994",
  },
  {
    title: "MUSICA NOSTALGICA",
    url: "https://music.yandex.ru/album/13252025/track/75437700",
    year: "1994",
  },
  {
    title: "СИМФОНИЯ № 9",
    url: "https://music.yandex.ru/album/4816718/track/37897505",
    year: "1997",
  },
  {
    title: "КОНЦЕРТ для альта № 2",
    url: "",
    year: "1997",
  },
  {
    title: "ВАРИАЦИИ для струнного квартета",
    url: "https://music.yandex.ru/album/10834985 ",
    year: "1997",
  },
];

export default concerts;
