import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

import "react-responsive-carousel/lib/styles/carousel.min.css";

export default createGlobalStyle`
  ${normalize}

  html {
    overflow-x: hidden;
  }
  
  body {
    font-family: "Bandera-Pro-Regular";
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }

  a,
  a:hover {
    color: #000;
    text-decoration: none;
  }

  li {
    margin-bottom: 16px;
  }
`;
