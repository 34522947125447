import React from "react";
import styled, { css } from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import Dots from "./SidesOtherDots";
import Spacer from "./Spacer";
import { mediaQuery } from "../utils";

type Props = {
  children: React.ReactNode;
  hide?: "cinema" | "ermitazh" | "music" | "vunderkind" | undefined;
  hideRight?: "ermitazh" | "source" | "time" | undefined;
};

const LeftSide = styled.div`
  margin-left: -230px;
  margin-top: 270px;
  position: absolute;
  text-align: right;
  transform: rotate(-90deg);
  width: 520px;
  z-index: 10;

  ${mediaQuery(
    "phone",
    css`
      display: none;
    `,
  )}
`;

const Item = styled.div`
  display: inline-block;
  font-size: 24px;
  text-align: center;
`;

const RightSide = styled.div`
  margin-left: -230px;
  margin-top: 320px;
  text-align: right;
  transform: rotate(-90deg);
  width: 600px;

  ${mediaQuery(
    "phone",
    css`
      display: none;
    `,
  )}
`;

const StyledLink = styled(Link)`
  display: inline-block;
`;

const SidesKaravajchuk: React.FC<Props> = ({ children }: Props) => {
  const location = useLocation();

  const page = location.pathname.split("/")[2];

  if (page === "about" || page === "music") {
    return (
      <Container>
        <Row>
          <Col lg={{ span: 1 }}>
            <LeftSide>
              <Item>
                <>
                  <Spacer size={18} vertical={false} />
                  <Item>
                    <Link to="/karavajchuk/ermitazh">
                      эрмитаж
                      <Dots />
                    </Link>
                  </Item>
                </>
                <>
                  <Spacer size={18} vertical={false} />
                  <StyledLink to="/karavajchuk/cinema">
                    кино
                    <Dots />
                  </StyledLink>
                </>
                <>
                  <Spacer size={18} vertical={false} />
                  <Item>
                    <Link to="/karavajchuk/vunderkind">вундеркинд</Link>
                    <Dots />
                  </Item>
                </>
              </Item>
            </LeftSide>
          </Col>

          <Col>{children}</Col>

          <Col lg={{ span: 1 }}>
            <RightSide>
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <StyledLink to="/karavajchuk/source">источники</StyledLink>
                  <Dots />
                </Item>
              </>
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <Link to="/karavajchuk/time">
                    время
                    <Dots />
                  </Link>
                </Item>
              </>
            </RightSide>
          </Col>
        </Row>
      </Container>
    );
  }

  if (page === "vunderkind") {
    return (
      <Container>
        <Row>
          <Col lg={{ span: 1 }}>
            <LeftSide>
              <Item>
                <>
                  <Spacer size={18} vertical={false} />
                  <Item>
                    <Link to="/karavajchuk/ermitazh">
                      эрмитаж
                      <Dots />
                    </Link>
                  </Item>
                </>
                <>
                  <Spacer size={18} vertical={false} />
                  <StyledLink to="/karavajchuk/cinema">
                    кино
                    <Dots />
                  </StyledLink>
                </>
                <>
                  <Spacer size={18} vertical={false} />
                  <Item>
                    <Link to="/karavajchuk/music">
                      музыка
                      <Dots />
                    </Link>
                  </Item>
                </>
              </Item>
            </LeftSide>
          </Col>

          <Col>{children}</Col>

          <Col lg={{ span: 1 }}>
            <RightSide>
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <StyledLink to="/karavajchuk/source">источники</StyledLink>
                  <Dots />
                </Item>
              </>
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <Link to="/karavajchuk/time">
                    время
                    <Dots />
                  </Link>
                </Item>
              </>
            </RightSide>
          </Col>
        </Row>
      </Container>
    );
  }

  if (page === "time") {
    return (
      <Container>
        <Row>
          <Col lg={{ span: 1 }}>
            <LeftSide>
              <Item>
                <>
                  <Spacer size={18} vertical={false} />
                  <StyledLink to="/karavajchuk/cinema">
                    кино
                    <Dots />
                  </StyledLink>
                </>
              </Item>
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <Link to="/karavajchuk/vunderkind">вундеркинд</Link>
                  <Dots />
                </Item>
              </>
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <Link to="/karavajchuk/music">
                    музыка
                    <Dots />
                  </Link>
                </Item>
              </>
            </LeftSide>
          </Col>

          <Col>{children}</Col>

          <Col lg={{ span: 1 }}>
            <RightSide>
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <StyledLink to="/karavajchuk/source">источники</StyledLink>
                  <Dots />
                </Item>
              </>
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <Link to="/karavajchuk/ermitazh">
                    эрмитаж
                    <Dots />
                  </Link>
                </Item>
              </>
            </RightSide>
          </Col>
        </Row>
      </Container>
    );
  }

  if (page === "ermitazh") {
    return (
      <Container>
        <Row>
          <Col lg={{ span: 1 }}>
            <LeftSide>
              <Item>
                <>
                  <Spacer size={18} vertical={false} />
                  <StyledLink to="/karavajchuk/cinema">
                    кино
                    <Dots />
                  </StyledLink>
                </>
              </Item>
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <Link to="/karavajchuk/vunderkind">вундеркинд</Link>
                  <Dots />
                </Item>
              </>
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <Link to="/karavajchuk/music">
                    музыка
                    <Dots />
                  </Link>
                </Item>
              </>
            </LeftSide>
          </Col>

          <Col>{children}</Col>

          <Col lg={{ span: 1 }}>
            <RightSide>
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <StyledLink to="/karavajchuk/source">источники</StyledLink>
                  <Dots />
                </Item>
              </>
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <Link to="/karavajchuk/time">
                    время
                    <Dots />
                  </Link>
                </Item>
              </>
            </RightSide>
          </Col>
        </Row>
      </Container>
    );
  }

  if (page === "source") {
    return (
      <Container>
        <Row>
          <Col lg={{ span: 1 }}>
            <LeftSide>
              <Item>
                <>
                  <Spacer size={18} vertical={false} />
                  <StyledLink to="/karavajchuk/cinema">
                    кино
                    <Dots />
                  </StyledLink>
                </>
              </Item>
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <Link to="/karavajchuk/vunderkind">вундеркинд</Link>
                  <Dots />
                </Item>
              </>
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <Link to="/karavajchuk/music">
                    музыка
                    <Dots />
                  </Link>
                </Item>
              </>
            </LeftSide>
          </Col>

          <Col>{children}</Col>

          <Col lg={{ span: 1 }}>
            <RightSide>
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <Link to="/karavajchuk/time">
                    время
                    <Dots />
                  </Link>
                </Item>
              </>
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <Link to="/karavajchuk/ermitazh">
                    эрмитаж
                    <Dots />
                  </Link>
                </Item>
              </>
            </RightSide>
          </Col>
        </Row>
      </Container>
    );
  }

  if (page === "cinema") {
    return (
      <Container>
        <Row>
          <Col lg={{ span: 1 }}>
            <LeftSide>
              <Item>
                <>
                  <Spacer size={18} vertical={false} />
                  <StyledLink to="/karavajchuk/cinema">
                    кино
                    <Dots />
                  </StyledLink>
                </>
              </Item>
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <Link to="/karavajchuk/vunderkind">вундеркинд</Link>
                  <Dots />
                </Item>
              </>
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <Link to="/karavajchuk/music">
                    музыка
                    <Dots />
                  </Link>
                </Item>
              </>
            </LeftSide>
          </Col>

          <Col>{children}</Col>

          <Col lg={{ span: 1 }}>
            <RightSide>
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <StyledLink to="/karavajchuk/source">источники</StyledLink>
                  <Dots />
                </Item>
              </>
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <Link to="/karavajchuk/time">
                    время
                    <Dots />
                  </Link>
                </Item>
              </>
              <>
                <Spacer size={18} vertical={false} />
                <Item>
                  <Link to="/karavajchuk/ermitazh">
                    эрмитаж
                    <Dots />
                  </Link>
                </Item>
              </>
            </RightSide>
          </Col>
        </Row>
      </Container>
    );
  }

  return <></>;
};

export default SidesKaravajchuk;
