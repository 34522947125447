/* eslint no-param-reassign: 0 */
/* eslint no-plusplus: 0 */

import Typograf from "typograf";

import React, { ReactNode, useEffect, useRef } from "react";

interface TypographyWrapperProps {
  children: ReactNode;
}

const TypographyWrapper: React.FC<TypographyWrapperProps> = ({
  children,
  // typography,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const tp = React.useMemo(() => new Typograf({ locale: ["ru", "en-US"] }), []);

  const typography = React.useCallback(
    (text: string) => tp.execute(text),
    [tp],
  );

  const getTextNodes = React.useCallback((node: Node): Text[] => {
    const textNodes: Text[] = [];

    if (node.nodeType === Node.TEXT_NODE) {
      textNodes.push(node as Text);
    } else {
      const childrenNodes = node.childNodes;

      for (let i = 0; i < childrenNodes.length; i++) {
        textNodes.push(...getTextNodes(childrenNodes[i]));
      }
    }

    return textNodes;
  }, []);

  useEffect(() => {
    const wrapperNode = wrapperRef.current;

    if (!wrapperNode) {
      return;
    }

    const textNodes = getTextNodes(wrapperNode);

    textNodes.forEach((node) => {
      const originalText = node.nodeValue;

      const modifiedText = typography(originalText as string);

      if (node instanceof Text) {
        node.nodeValue = modifiedText;
      }
    });
  }, [getTextNodes, typography]);

  return <div ref={wrapperRef}>{children}</div>;
};

export default TypographyWrapper;
