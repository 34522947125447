/* eslint react/jsx-one-expression-per-line: 0 */

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  Block,
  Footer,
  NamedHeader,
  PageIntroKaravajchuk,
  SidesKaravajchuk,
  Spacer,
  TopImage,
  TypographyWrapper,
} from "../../../lib";

const { PUBLIC_URL } = process.env;

const StyledTopImage = styled(TopImage)`
  margin-top: 30px;
`;

const Ermitazh: React.FC = () => (
  <TypographyWrapper>
    <NamedHeader name="вундеркинд" />

    <PageIntroKaravajchuk author="karavajchuk" name="vunderkind" />

    <StyledTopImage
      url={`${PUBLIC_URL}/assets/images/karavajchuk/vunderkind/top.jpg`}
    />

    <Spacer size={10} />

    <SidesKaravajchuk hide="vunderkind" hideRight="ermitazh">
      <Spacer size={4} />

      <Block>
        <>киев. мамины рассказы</>
        <>
          Моя мама была настоящей женщиной, на четверть француженкой,
          французский был ее родным языком.
          <br />
          <sub>
            <Link to="/karavajchuk/source">[B—F]</Link>
          </sub>{" "}
        </>
        <>
          Мама была влюблена в папу и одновременно в военного. Она их обоих
          любила. Она пошла к цыганке. Цыганка сказала: «Женись только на
          артисте. У тебя будет только один ребенок — мальчик. Он будет
          артистом. Не женись на военном — будет плохо». Военного расстреляли
          очень быстро, в 1930-е годы.
        </>
        <>
          Моя мама была уроженка Киева, будучи девочкой, жила накануне революции
          рядом с [пианистом] Владимиром Горовицем на Владимирской улице, дом
          двенадцать. Володя очень часто ходил к ней, играл ей все, что давали в
          Консерватории, — она училась у [Мариана] Домбровского, а он учился у
          [Феликса] Блуменфельда, — они ходили вместе на концерты, вместе
          слушали очень больших пианистов. Об этом мне мама рассказала очень
          давно – еще в моем детстве.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—B]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>фисгармония. музыка внутри нас</>
        <>
          Я ничего не помню по Киеву, потому что скоро переехали в Ленинград,
          осталось у меня от Киева только какое-то страшное музыкальное чувство.
          Я вообще считаю, что музыка — это не та, которую играют на рояле или в
          оркестре, музыка внутри нас.
        </>
        <>
          Вот дело было так: у меня была большая детская комната, папу сослали,
          он был в Ашхабаде. А папа был когда-то скрипач-солист — до того, как
          ему прострелили руку на Первой мировой.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—B]</Link>
          </sub>
        </>
        <>
          Мне было около двух лет, не больше, у меня была няня. Был в Киеве
          голод, а среди игрушек стояла огромная фисгармония. Я и не знал тогда,
          что это такое, но понимал, что нужно просить няню жать на педали. Я к
          ней все время подходил, искал терцию, именно две ноты, терцию.
        </>
        <>
          Но в этот момент папа был сослан, и вот тут… Все, что во мне всегда
          бьет, — это тротуары Киева… Я шел по Крещатику и в этот момент убеждал
          маму, что нельзя продавать фисгармонию — папа наш музыкант. Другого у
          меня аргумента не было, я не понимал, что я музыкант. Вот я топал
          ногами об эти плиты, и плиты топали об мои ступни, и я чувствовал, что
          мое сердце соединяется с ногами, во мне нет сердца, есть только… это
          необъяснимо — чистая музыка.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—B]</Link>
          </sub>
        </>
        <>
          В четыре года я уже не смотрел на клавиши. И никогда не мазал. Как ни
          ударю — всегда туда, куда нужно. Даже и раньше так было, в два года,
          пожалуй, но тогда я еще не так махал. Уже в два с половиной я играл на
          фисгармонии и отдавал себе отчет, что это мой инструмент.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—P]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>ленинград. в яме рядом с арфой</>
        <>
          Папа мой играл в оркестре, он играл в кино и иногда в театрах — то в
          Михайловском, то Мариинском. Друзья папы, музыканты, начали меня
          любить, потому что я очень хорошо дома импровизировал. Мама
          преподавала домашние уроки по роялю, но меня не учила. Папа не хотел,
          чтобы я был бы музыкантом, да и мама тоже.
        </>
        <>
          Но папа очень часто водил меня в Мариинский театр, вернее водили
          меняего музыканты — они заворачивали меня в пальто, потому что я был
          очень маленький, и перед охранником торжественно проводили, сажали
          прямо в яму рядом с арфой.{" "}
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>музыканты и исполнители</>
        <>
          Шел «Царь Салтан» [Римского-Корсакова]. Дирижировал — папа мне на ухо
          сказал — Арий Пазовский, я помнюего руки, но я больше смотрел на
          Царевну-лебедь. В этот же вечер, когда я пришел с «Салтана», сейчас же
          подошел к роялю и начал играть по слуху эту оперу, но уже по-своему,
          мне не нравилось, как дирижировал Пазовский.
        </>
        <>
          Меня всю жизнь это очень мучает — я играю музыку по-своему, когда
          слушаю ее. Мне не нравятся исполнители, я только сейчас это понял, что
          они не должны нравиться. Плохо, если человеку нравится исполнитель,
          значит, он не рожден музыкантом.
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>ты композитор</>
        <>
          В эти дни как-то папа на кухне мыл посуду, а я что-то играл, и вдруг
          он вбежал ко мне и сказал: «Ты большой композитор». После этого мама
          начала меня учить. А он выхлопотал мне право ходить в Филармонию на
          репетиции и вечерние концерты.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—B]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>школа при консерватории</>
        <>
          Мне было около пяти лет, когда меня повели оформлять в детскую группу
          при консерватории. Я играл Шопена, и все плакали. И написали даже, что
          я сверхгениален. Профессор начал учить меня верным интерпретациям. Я
          ему показываю, как чувствую, а он мне — как положено. Вот так и
          начались мои мучения. Как он меня ни долбил, внутренняя мощь
          музыкального чувства не позволяла мне принимать его. И я перестал к
          профессору ходить, он после и сам сказал: «Первый раз [на
          вступительных экзаменах] ты играл лучше всего».{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—F]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>необыкновенный мальчик</>
        <>
          Когда в Нью-Йорке опубликовали письма Рахманинова, и они попали в
          Россию, то [Заруи] Апетян, музыковед в Москве, позвонила мне по
          телефону узнать мои данные — год рождения и прочее. Потому что в этих
          письмах из Ленинграда и Москвы Рахманинову писали друзья, что появился
          новый необыкновенный мальчик, описывали как я играю, и довольно
          подробно, что я играю.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—B]</Link>
          </sub>
        </>
        <>
          Меня все время донимали матери других вундеркиндов. Они говорили,
          будто я по двенадцать-четырнадцать часов играю на рояле, поэтому у
          меня так здорово получается. Будто меня мама мучает — ее даже из-за
          этого в КГБ вызывали. А я-то играл меньше всех.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—M]</Link>
          </sub>
        </>
        <>
          Мама всегда просто рядышком садилась и не трогала меня, понимала, что
          я так музыку чувствую. Нельзя учить тому, что от Бога дано. Таких
          учителей я больше не встречал. Все хотели сделать из меня «толкового»,
          «правильного».{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—F]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>ото всех ушел</>
        <>
          Я мог учиться только до тринадцати-четырнадцати лет. Потом я даже к
          профессору по роялю не ходил.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—O]</Link>
          </sub>
        </>
        <>
          В тринадцать лет я играл хуже, чем в пять. Стал скучнее.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—I]</Link>
          </sub>
        </>
        <>
          Самый обыкновенный ребенок куда талантливее Рихтера. Он почувствует
          там какое-то свое колыхание в клавишах, он даже не знает, но начнет
          играть. И он будет плавать спокойно. И не надо учиться даже, он сразу
          поплывет в океане, если рядом не будут ему говорить, что он утонет. Не
          утонет он. Музыканты, к сожалению, прирожденные даже музыканты,
          начинают калечится с самого начала из-за учителей. Все преподаватели
          начинают учить извне. Они как бы учат в океане плавать при помощи
          законов бассейна.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—I]</Link>
          </sub>
        </>
        <>
          Я ушел ото всех. Меня начали купировать, исправлять. Я понял, что это
          не соответствует движениям моей формы, и ушел. Ото всех ушел.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—M]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>концерты в больших залах</>
        <>
          А в это время уже вовсю шли концерты, особенно концерты
          правительственные. И в Мариинском театре, и в Большом, и в филиале, и
          в Колонном, и в еще каких-то больших залах. Они были регулярны, меня
          возили именно со взрослыми исполнителями: с [артистами балета Галиной]
          Улановой, [Наталией] Дудинской, [Вахтангом] Чабукиани, [Константином]
          Сергеевым, [актерами Николаем] Черкасовым, [Николаем] Симоновым. К нам
          присоединялся МХАТ и все звезды Москвы: [балерина Ольга] Лепешинская,
          [оперная певица Валерия] Барсова (я у нее сидел на коленях). И я уже
          чувствовал, что это мои коллеги, а они чувствовали, что я их коллега
          тоже.
        </>
        <>
          Одновременно шли концерты чисто детские. Обычно это были ученические
          концерты и вывозные. Нас вывозили в Москву на очень большие фестивали.
          Съезжались одаренные дети из Одессы, Киева, Харькова, отовсюду. Вот на
          таких фестивалях я играл. В газетах был назван не то Бахом, не то
          Моцартом, и считался тогда гением. Судя по тому, как папа встречал
          меня и маму из Москвы, я понимал, что обо мне пишут все газеты. Но сам
          статей не читал, хотя читать вполне умел. Считал, что плохо играю. Я и
          сейчас считаю, что плохо играю.
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>уланова</>
        <>
          Я Уланову тоже очень любил. То есть я ее настолько любил, что я помню,
          когда был такой юбилей [артиста балета Иосифа] Кшесинского, и этот
          старик танцевал мазурку с такой молодостью, с какой ни один молодой
          никогда не танцует, потому что аристократизм — это молодость.[…] Вот я
          сидел маленьк им под стулом Улановой внизу, она мне говорит: «Садись
          ко мне на закукорки», — это значит на колени. Я сел на ее колени и
          сидел, нежился, а потом она меня очень сильно поцеловала. Я ее очень
          любил. Это высшая любовь моей жизни.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—B]</Link>
          </sub>
        </>
        <>
          Я считаю, что музыка от нее очень сильно перешла на меня. Когда тебя
          обнимает гениальный человек — это больше, чем учить тебя музыке целые
          десять лет. Нужно обнять тебя гениальному человеку, и больше в этом
          будет, потому что научить музыке нельзя, обнять — этого будет
          достаточно.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—J]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>эвакуация. штейнберг. вайнберг</>
        <>
          Я тяжело заболел, то есть я страшно заболел. Заболел корью, заболел
          воспалением средних ушей, заболел воспалением легких и заболел
          туберкулезом. И валялся два года в разных районных больницах Ташкента.
          И полностью оглох. Ко мне приходил [композитор Максимилиан] Штейнберг
          и приносил банки меда и курагу. Глухой я сочинял. Я помню даже ту
          музыку, которую я тогда сочинял в больнице, а потом пришел в класс и
          по памяти сыграл ее Штейнбергу.{" "}
        </>
        <>
          В классе сидел удивительный человек: тонкий, с тонкими кистями,
          настоящая Польша. Оказалось, что это был Метек [Моисей] Вайнберг,
          замечательный композитор. Я играл Штейнбергу военный марш. А Штейнберг
          ему подмигивал, дескать, показывая — вот мальчик, это мальчик!
          Вайнберг подмигивал ему. Это был настоящий профессионал, прошедший
          школу польской композиции, оснащенный великолепной техникой,
          величайший и законченный мастер.
        </>
        <>
          Я в этот момент уже сочинял сонату, очень большую сонату. И Штейнберг,
          по-моему, не очень ее как-то понимал. Он все время чинил мои квинты.
          Помню, как-то в Ленинграде, на одном правительственном концерте,
          вбежал красный как рак, сказал, что я его опозорил. Потому что он мне
          переделал на правильное голосоведение, а я сыграл на концерте все
          точно так, как я сочинил. Но иногда Штейнберг мне все-таки говорил:
          «Ты вроде бы как Скрябин, вот возвратимся после войны, отдам тебя
          Шостаковичу».
        </>
        <>
          Но в этот момент в классе был Вайнберг, и я к нему пошел домой. Я
          помню комнату, где мне Вайнберг впервые сыграл свою симфонию.
        </>
        <>
          А я ему играл свою сонату. И ему очень нравилась одна мелодия. И вот
          на перроне в Ташкенте, когда я провожал Вайнберга в Москву, мы ходили
          между шпал и Вайнберг мне сказал, что у меня врожденная композиторская
          техника. И этого почти никогда не бывает.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—B]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>консерваторские будни </>
        <>
          Уже в 1944 году нам всем объявили, что хоть война еще не кончилась, но
          можно ехать обратно в Ленинград. И вот повезли нас всех на теплушках.
          Как-то быстро, быстро пошли будни — консерваторские обычные будни. Я
          даже не знаю, что мне об этом сказать, и я ничего не могу сказать
          кроме того, что я начал чувствовать, что меня начинают мучить.
          Элементарно мучить. А тут у меня еще и туберкулез.
        </>
        <>
          Тогда уже пахло преддвериями 1948 года. Ведь понимаете, 1948 год — это
          не постановление Жданова [«О журналах „Звезда“ и „Ленинград“»], потому
          что его создали не партия, не Жданов и не Сталин, а коллеги. Коллеги
          Шостаковича, коллеги Прокофьева. [Ректор Ленинградской консерватории
          Павел] Серебряков. Еще до постановления, я помню, Серебряков говорил
          мне, что ненавидит музыку Шостаковича. «Это вообще дрянь. Ну что это
          такое? Почему ты ее любишь?» А мне он, например, говорил: «Ты вот
          какой-то вот такой. Ты, конечно, необыкновенный, талантливый человек.
          Я могу сказать, что ты талант. Но ты всегда останешься у разбитого
          корыта».{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—B]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>вся музыка надоела </>
        <>
          Умер Штейнберг, и Шостакович собирался принять меня в класс. Он
          послушал одно мое сочинение, сказал так им голосом, немножко
          удивленным: «У вас талант». А потом: «У вас темперамент». И вот я в
          1947-м прибежал к нему и сказал: «Дмитрий Дмитриевич, я музыку больше
          сочинять не смогу. Мне вся музыка надоела, мне все надоело совершенно,
          вся музыка для меня — одна сплошная скука, одна сплошная рутина. Я
          ненавижу Баха, я ненавижу Моцарта, я ненавижу все! И даже ваша Восьмая
          симфония, которую я необыкновенно, необыкновенно любил, — сейчас мне
          нравится только до разработки, а как начинается разработка, так это
          одна только динамика, одна только рутина. И вообще — все». Шостакович
          наклонился, взял папиросу, откинулся обратно, сказал: «Вы просто
          устали, вам надо отдохнуть».{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—B]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>через тебя прорезается музыка</>
        <>
          Я не мог понять, как мне быть и что же мне делать, и вот мне в голову
          пришла мысль, что надо играть все время по-разному. Но это тоже не
          помогало. Тогда я начал играть авторов всех в разных тональностях,
          именно в разных тональностях, и вцепляться как бы в первый звук. Вот
          берешь новую тональность, вот берешь так, а потом так, и ты сразу
          чувствуешь, как из тебя идет какая-то пемза, то есть твое тело
          становится как бы скульптурным, и через тебя прорезается музыка. В
          этот момент она импровизируется, и она за тебя работает, то есть через
          тебя проходит музыка, — ты ее не сочиняешь, тебе надо только сидеть.
        </>
        <>
          Я вообще очень люблю стулья. Мне на рояли наплевать, я сажусь и
          засыпаю, на стул откинусь. Важны для меня не клавиши, а спинка.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—B]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>панцирь черепахи</>
        <>
          Так вот, когда я так импровизировал, — импровизировал бесконечно.
          Начнешь ноту, и от нее пойдет нота, сам не знаешь какая вторая,
          абсолютная, и это потрясающе чисто. А ты делаешься то пемзой, то
          панцирем черепахи. Ты делаешься совершенно другим, из другого тела, и
          музыка прорезается через тебя. И притом она делается такой идеальной,
          важно только не остановиться.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—B]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>рихтер</>
        <>
          [Пианист Святослав] Рихтер мне признавался, что не может слушать
          других пианистов — тут же засыпает. А меня слушал с одиннадцати утра
          до шести вечера. И просил еще, и еще, и еще. Забыть это невозможно:
          гостиница «Европейская», 23 номер, у Рихтера откинута голова, над ним
          какая-то картина, рояль — довольно поганый.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—C]</Link>
          </sub>{" "}
        </>
        <>
          Я ему играл, когда меня в Консерватории замучили уже до конца. Он про
          меня знал от Нейгауза, что я невероятной интуиции был ребенок, и
          полемизировал в пять лет абсолютно гармонично с музыкой.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—N]</Link>
          </sub>{" "}
        </>
        <>
          Рихтер меня понимал. Я при нем сочинял музыку, на его заданные темы.
          Ну и жуткие темы он мне давал — попробуй! Я сочинил, а он говорит:
          «Боже, великие композиторы пять лет продумывали, а ты сразу… И тебя
          хоть с постели сними, хоть с гроба. Хуже не будет». Вот так и говорил,
          слово в слово.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—L]</Link>
          </sub>
        </>
        <>
          Моя игра — она нерукотворная. Я сочиняю в железной форме, совершенно о
          ней не думая, — значит, за меня работают какие-то очень точные вещи. Я
          сам дивлюсь, как это получается. Рихтер лучшие места запомнил — у него
          прекрасная память была — и где-то повторил. Я обалдел. Меня тогда в
          Консерватории поносили, что я ломаю форму. А я не ломал, наоборот, я
          ее восстанавливал. Но она другая. Не разумная.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—N]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>руки прочь от каравайчука!</>
        <>
          Это еще было до постановления. И вот грохнуло это постановление.
          Шостаковича выгнали из Консерватории как профнепригодного
          преподавателя, и пошло все. Вот так. В общем, я в Консерваторию,
          грешным делом, не ходил, мне в этом даже помогал туберкулез, а ходил
          больше в театр. И как-то на очередной студенческий экзамен я просто
          влетел из театра, сыграл по слуху «Золушку» [Прокофьева], которую в
          этот момент репетировали в театре. Я вообще часто играл экзаменыпо
          слуху не читая нот. Но вот я играл «Золушку», и привел Консерваторию в
          такой восторг, что студенты, (в тот момент была война с Кореей), они
          стали кричать: «Руки прочь от Каравайчука! Руки прочь от Каравайчука!»
          И вот с так им хороводом я в Консерватории и ходил.
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>дирижерский факультет</>
        <>
          Когда меня уже вызвали по распределению и вручали мне бланк, на
          котором было написано мое назначение, — я должен был избрать, куда
          ехать. Мне в Ленинграде не давали играть, считали, что я плохо играю,
          и вообще, что я — плохой пианист. Мне предложили быть аккомпаниатором
          в вокальном классе Симферопольского училища, то есть играть гаммы. Но
          я, по совету одной девушки, пришедшую мне зарплату не взял — ее
          зачисляли и посылали заранее.
        </>
        <>
          И тут-то мне пришла ненормальная мысль поступать на дирижерский
          факультет, чтобы избавиться от Симферополя. Но в Ленинграде я
          продирижировал, был тогда председатель комиссии [профессор Сергей]
          Ельцин, который услышав в моем исполнении вступление к «Лебединому
          озеру», сказал, что все равно дирижером я не буду, потому что у меня
          такой ауфтакт, что разбежится весь оркестр. Но я не думаю, что это
          Ельцин сам решил, это все сделал сам Серебряков — я уже ему в
          Консерватории так надоел.
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>мравинский</>
        <>
          [Дирижер и худрук Ленинградской филармонии Евгений] Мравинский через
          какое-то время вызвал меня в Филармонию и предложил ходить к нему на
          репетиции. И, как ни странно, быть его стажером.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—B]</Link>
          </sub>
        </>
        <>
          На земле все прекрасно в итоге. Не надо, чтобы было много хорошего.
          Всего должно быть в меру. Этому меня Мравинский научил. Я должен был
          дирижировать пятую симфонию Бетховена. И вот я подхожу к Мравинскому:
          Евгений Александрович, правильно я беру такт? И рукой показываю.
          Евгений Александрович отскакивает, садится на кресло и говорит: вот
          так увидишь один раз такт, и вся жизнь прожита зря.{" "}
          <sub>
            <Link to="/karavajchuk/source">[B—M]</Link>
          </sub>
        </>
        <>
          Через дня три-четыре я пришел на репетицию, [помощник худрука] Оник
          Степанович Саркисов меня вызвал к себе и сказал, что меня, к
          сожалению, невозможно принять в стажеры, потому что у меня нет диплома
          Консерватории. А оркестранты мне показали, что я был вычеркнут в этом
          списке. Так что я в Филармонии не дирижировал и стажером не стал.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—B]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={8} />

      <Block>
        <>рухнула мечта</>
        <>
          И моя мечта быть дирижером навсегда рухнула. Я очень хотел быть
          дирижером, необыкновенно хотел быть дирижером, потому что для меня
          дирижирование — это прежде всего путь исполнения моей музыки. Моя
          музыка написана почти вся без тактов. Я пишу музыку, именно не
          тактируя ее. И очень многие ритмы я не записываю в виде
          противоположностей восьмых и четвертей. Как бы я пишу музыку для
          исполнения либо своего, либо таких же, как я, авторов.
        </>
        <>
          То есть я не рассчитываю на то, что мою музыку могут исполнять
          исполнители, потому что исполнители никакого отношения к прямой музыке
          не имеют. Когда-то Бах, когдаего спросили, как он играет, сказал: «Ну
          как играю, я просто…» Ведь что такое играть? Это надо чтобы палец брал
          ту ноту, которую он должен брать, и в то время, в которое он должен ее
          брать. Вот и вся музыка, больше ничего музыкант не делает. А
          исполнители делают еще тысячи вещей дополнительно, но этого времени,
          когда надо правильно брать ноту, они не чувствуют. Вот. И вообще
          музыка исполнителей уже немножечко уподобляется не клавишной музыке, а
          своего рода мозаике, сделанной из мозолей. Мозаика из отрезанных
          мозолей.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—B]</Link>
          </sub>
        </>
        <>
          Мравинский — гениальный дирижер. Но не ниспосланный. Это очень важно.
          Великий всегда боится ниспосланного. Он прав, что меня не пустил. Я бы
          два-три дня подирижировал, плюнул и ушел. Развалил бы Филармонию. И
          стал бы сочинять дальше. А он дирижировал всю жизнь.{" "}
          <sub>
            <Link to="/karavajchuk/source">[A—M]</Link>
          </sub>
        </>
      </Block>

      <Spacer size={48} />
    </SidesKaravajchuk>
    <Footer />
  </TypographyWrapper>
);

export default Ermitazh;
