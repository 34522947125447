import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const USSR: React.FC = () => (
  <>
    <Container>
      <Row>
        <Col lg={{ span: 12 }}>USSR</Col>
      </Row>
    </Container>
  </>
);

export default USSR;
