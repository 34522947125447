import { useEffect, useState } from "react";

const useOnScreen = (
  ref: React.RefObject<HTMLElement>,
  rootMargin = "0px",
): boolean => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    // return () => {
    // observer.unobserve(ref?.current);
    // };
  }, [ref, rootMargin]);

  return isIntersecting;
};

export default useOnScreen;
