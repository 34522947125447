import { FlattenSimpleInterpolation, css } from "styled-components";

export const flexCenter = (
  horizontal = true, vertical = true,
): FlattenSimpleInterpolation => css`
  align-items: ${vertical ? "center" : "flex-start"};
  display: flex;
  justify-content: ${horizontal ? "center" : "flex-start"};
`;

export const fullscreen = (): FlattenSimpleInterpolation => css`
  height: 100vh;
  width: 100vw;
`;
