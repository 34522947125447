/* eslint react/jsx-one-expression-per-line: 0 */

import React from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";

import {
  Footer,
  NamedHeader,
  PageIntroKaravajchuk,
  SidesKaravajchuk,
  Spacer,
  Text,
  TopImage,
} from "../../../lib";

const { PUBLIC_URL } = process.env;

const Cover = styled.img`
  border: 1px solid #000;
  width: 50%;
`;

const Frame = styled.iframe`
  border: none;
  height: 450px;
  width: 100%;
`;

const Links = styled.div`
  position: sticky;
  top: 0px;
`;

const StyledTopImage = styled(TopImage)`
  margin-top: 30px;
`;

const Music: React.FC = () => (
  <>
    <NamedHeader name="музыка" />

    <PageIntroKaravajchuk author="karavajchuk" name="music" />

    <StyledTopImage
      url={`${PUBLIC_URL}/assets/images/karavajchuk/music/top.jpg`}
    />

    <Spacer size={10} />

    <SidesKaravajchuk hide="music">
      <Row>
        <Col lg={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
          <Frame
            frameBorder="0"
            height="450"
            src="https://music.yandex.ru/iframe/#album/25676665"
            // style="border:none;width:100%;height:450px;"
            title="Yandex"
            width="100%"
          >
            Слушайте{" "}
            <a href="https://music.yandex.ru/album/19561139">
              Три степени свободы. Музыка кино СССР. (1) Альфред Шнитке
            </a>{" "}
            —{" "}
            <a href="https://music.yandex.ru/artist/3255058">
              Государственный симфонический оркестр кинематографии СССР
            </a>{" "}
            на Яндекс.Музыке
          </Frame>
        </Col>

        <Col lg={{ span: 4 }} sm={{ span: 4 }} xs={{ span: 4 }}>
          <Links>
            <Text size={24}>
              <a
                href="https://music.apple.com/album/1690648775"
                rel="noreferrer"
                target="_blank"
              >
                Apple Music
              </a>
              <br />
              <a
                href="https://open.spotify.com/album/2RE2yDObZPrgwHW1wnp2YL"
                rel="noreferrer"
                target="_blank"
              >
                Spotify
              </a>
              {/* <br />
              <a
                href="https://music.yandex.ru/album/19561139"
                rel="noreferrer"
                target="_blank"
              >
                Яндекс.Музыка
              </a> */}
              <br />
              <a
                href="https://music.vk.com/link/oha3"
                rel="noreferrer"
                target="_blank"
              >
                VK Music
              </a>
              <br />
              <a href="https://zvuk.com/release/29520526">Звук</a>
            </Text>
          </Links>

          <Spacer size={10} />

          <a
            href="https://planeta.ru/campaigns/olegkaravaychuk"
            rel="noreferrer"
            target="_blank"
          >
            <Cover
              src={`${PUBLIC_URL}/assets/images/karavajchuk/music/cover.jpg`}
            />
          </a>
          <Spacer size={2} />
          <a href="https://planeta.ru/campaigns/olegkaravaychuk">
            <Text family="sans" size={18}>
              LP: предзаказ
            </Text>
          </a>
        </Col>
      </Row>
    </SidesKaravajchuk>

    <Spacer size={140} />

    <Footer />
  </>
);

export default Music;
