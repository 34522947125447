/* eslint react/jsx-one-expression-per-line: 0 */

import React from "react";
import styled, { css } from "styled-components";
import { Col, Container, Row } from "react-bootstrap";

import {
  Footer,
  NamedHeader,
  PageIntroShnitke,
  SidesShnitke,
  Spacer,
  Text,
} from "../../../lib";
import { mediaQuery } from "../../../utils";

import cinemas from "./conts/cinemas";
import concerts from "./conts/concerts";

const StyledCol = styled(Col)`
  padding-top: 60px;

  ${mediaQuery(
    "phone",
    css`
      padding-top: 0px;
    `,
  )}
`;

const StyledRow = styled(Row)`
  ${mediaQuery(
    "phone",
    css`
      margin-bottom: 50px;
    `,
  )}
`;

const StyledText = styled(Text)<{ style?: Record<string, string> }>`
  display: inline;
  margin-right: 3px;

  ${mediaQuery(
    "phone",
    css`
      font-size: 12px;
      line-height: 8px;
    `,
  )}
`;

const Concerts = styled.div`
  padding-left: 40px;

  ${mediaQuery(
    "phone",
    css`
      padding-left: 0;
    `,
  )}
`;

const Year = styled(Text)`
  font-size: 230px;
  margin-top: -50px;

  ${mediaQuery(
    "phone",
    css`
      font-size: 60px;
      margin-top: 0;
    `,
  )}
`;

const Time: React.FC = () => {
  const renderItem = React.useCallback(
    (title: string, index: number, length: number) => (
      <>
        <StyledText size={14} style={{ borderBottom: "1px solid #CCC" }}>
          {title}
        </StyledText>
        {index !== length - 1 && "/"}
      </>
    ),
    [],
  );

  const renderCinemaSide = React.useCallback((year: string) => {
    const filtered = cinemas.filter((cinema) => cinema.year === year);

    return (
      <div>
        {filtered.map((item, index) =>
          (item.url ? (
            <a
              key={index}
              href={item.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <StyledText size={14}>
                {item.title} {index !== filtered.length - 1 && "/"}{" "}
              </StyledText>
            </a>
          ) : (
            <StyledText size={14}>
              {item.title} {index !== filtered.length - 1 && "/"}{" "}
            </StyledText>
          )))}
      </div>
    );
  }, []);

  const renderConcertsSide = React.useCallback(
    (year: string) => {
      const filtered = concerts.filter((item) => item.year === year);

      return (
        <Concerts>
          {filtered.map((item, index) =>
            (item.url ? (
              <a
                key={index}
                href={item.url}
                rel="noopener noreferrer"
                target="_blank"
              >
                {renderItem(item.title, index, filtered.length)}
              </a>
            ) : (
              <StyledText size={14}>
                {renderItem(item.title, index, filtered.length)}
              </StyledText>
            )))}
        </Concerts>
      );
    },
    [renderItem],
  );

  return (
    <>
      <NamedHeader name="время" />

      <PageIntroShnitke name="time" />

      {/* <TopImage url={`${PUBLIC_URL}/assets/images/shnitke/cinema/top.jpg`} /> */}

      <Spacer size={20} />

      <SidesShnitke hide="time">
        <Spacer size={2} />
        <Container>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4} />
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;57</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1957")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4} />
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;60</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1960")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1962")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;62</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4} />
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4} />
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;63</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1963")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1964")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;64</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1964")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1965")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;65</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1965")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1966")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;66</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1966")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1967")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;67</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4} />
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1968")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;68</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1968")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1969")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;69</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1969")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1970")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;70</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4} />
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1971")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;71</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1971")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1972")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;72</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1972")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1973")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;73</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1973")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1974")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;74</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1974")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1975")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;75</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1975")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1976")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;76</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1976")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1977")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;77</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1977")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1978")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;78</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1978")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1979")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;79</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1979")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1980")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;80</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1980")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1981")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;81</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1981")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1982")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;82</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1982")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4} />
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;83</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1983")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1984")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;84</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1984")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1985")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;85</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1985")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4} />
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;86</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1986")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4} />
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;87</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1987")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4} />
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;88</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1988")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4} />
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;89</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1989")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4} />
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;90</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1990")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4} />
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;91</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1991")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4} />
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;92</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1992")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4} />
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;93</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1993")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderCinemaSide("1994")}
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;94</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1994")}
            </StyledCol>
          </StyledRow>
          {/* -------------------------------------------------------------- */}
          <StyledRow>
            <StyledCol lg={4} md={4} sm={4} xs={4} />
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              <Year>&#39;97</Year>
            </StyledCol>
            <StyledCol lg={4} md={4} sm={4} xs={4}>
              {renderConcertsSide("1997")}
            </StyledCol>
          </StyledRow>
        </Container>
      </SidesShnitke>
      <Spacer size={20} />
      <Footer />
    </>
  );
};

export default Time;
