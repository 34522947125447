import React from "react";
import styled, { css } from "styled-components";

import { mediaQuery } from "../utils";

import Text from "./Text";

type Props = {
  children: React.ReactNode;
  inverted?: boolean;
  size?: number;
};

const StyledText = styled(Text)<{ inverted: boolean; size: number }>`
  color: ${(props) => (props.inverted ? "#fff" : "#000")};
  font-size: ${(props) => props.size}px;
  line-height: 0.8;
  text-transform: lowercase;

  ${mediaQuery(
    "phone",
    css`
      font-size: 36px;
    `,
  )}
`;

const Title: React.FC<Props> = ({
  children,
  inverted = false,
  size = 56,
  ...rest
}: Props) => (
  <StyledText
    {...{
      ...rest,
      inverted,
      size,
    }}
  >
    {children}
  </StyledText>
);

export default Title;
