/* eslint react/jsx-one-expression-per-line: 0 */

import React from "react";
import ReactPlayer from "react-player";
import styled, { css, keyframes } from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import {
  BlockCinema,
  Carousel,
  Footer,
  NamedHeader,
  PageIntroShnitke,
  QuoteCinema,
  SidesShnitke,
  Spacer,
  SubTitle,
  Text,
  Title,
  TypographyWrapper,
} from "../../../lib";
import { mediaQuery } from "../../../utils";

const { PUBLIC_URL } = process.env;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Freedom = styled.div`
  animation: ${rotate} 20s linear infinite;
  background-image: url(${
  // eslint-ignore-next-line
  `${PUBLIC_URL}/assets/images/shnitke/cinema/circle.jpg`
});
  background-repeat: no-repeat;
  background-size: contain;
  height: 180px;
  left: 35%;
  position: relative;
  transform: translateX(-35%);
  width: 180px;

  ${mediaQuery(
    "phone",
    css`
      left: 25%;
      transform: translateX(-25%);
    `,
  )}
`;

const BookSection = styled.div`
  ${mediaQuery(
    "desktop",
    css`
      margin-bottom: -240px;
      margin-top: -60px;
      position: relative;
      text-align: right;
      z-index: 111111;
      /* width: 40vw; */

      img {
        width: 10vw;
      }
    `,
  )}

  ${mediaQuery(
    "phone",
    css`
      margin-bottom: 80px;
      margin-top: -60px;
      text-align: center;
      /* width: 40vw; */

      img {
        width: 40vw;
      }
    `,
  )}
`;

const QuoteCinemaText = styled(Text)`
  /* opacity: 0.5; */
`;

const Image = styled.img`
  width: 100%;
`;

const StyledCol = styled(Col)`
  margin-top: -80px;
`;

const StyledReactPlayer = styled(ReactPlayer)`
  max-height: 40px;
  max-width: 100%;
`;

const StyledTitle = styled(Title)`
  color: #fff;
  display: inline-BlockCinema;
  font-size: 36px;
  margin-left: 60vw;
  right: 20px;
  transform: translateY(-12vw);

  ${mediaQuery(
    "phone",
    css`
      font-size: 16px;
      margin-left: 50vw;
      transform: scale(0.7) translateY(-32vw) translateX(6vw);
    `,
  )}
`;

const HelperIntro = styled(Title)`
  margin-left: -120px;

  ${mediaQuery(
    "phone",
    css`
      margin-left: -16px;
    `,
  )}
`;

const HelperFirstBlockCinema = styled(Title)`
  /* margin-top: 4vw; */

  ${mediaQuery(
    "phone",
    css`
      margin-top: -40px;
    `,
  )}
`;

// Film Cover

type FilmCoverProps = {
  director: string;
  name: string;
  url: string;
  year: string;
};

const FilmCoverRoot = styled.div<{ url: string }>`
  background-image: ${(props) => `url(${props.url})`};
  background-repeat: no-repeat;
  background-size: 100%;
  height: 68vh;
  padding: 40px 0;

  ${mediaQuery(
    "phone",
    css`
      height: 26vh;
    `,
  )}
`;

const FilmCoverText = styled(Text)`
  ${mediaQuery(
    "phone",
    css`
      font-size: 18px;
    `,
  )}
`;

const FilmCover: React.FC<FilmCoverProps> = ({
  director,
  name,
  url,
  year,
}: FilmCoverProps) => (
  <FilmCoverRoot url={url}>
    <Container>
      <Row>
        <Col
          lg={{ span: 9 }}
          md={{ span: 9 }}
          sm={{ span: 9 }}
          xs={{ span: 9 }}
        >
          <FilmCoverText inverted size={64}>
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: name }}
            />
            <br />
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: director }}
            />
          </FilmCoverText>
        </Col>
        <Col
          lg={{ span: 3 }}
          md={{ span: 3 }}
          sm={{ span: 3 }}
          style={{ textAlign: "right" }}
          xs={{ span: 3 }}
        >
          <FilmCoverText inverted size={54}>
            <div style={{ paddingTop: 10 }}>{year}</div>
          </FilmCoverText>
        </Col>
      </Row>
    </Container>
  </FilmCoverRoot>
);

const Cinema: React.FC = () => {
  document.body.style.overflowX = "hidden";

  return (
    <TypographyWrapper>
      <NamedHeader name="кино" />

      <PageIntroShnitke name="cinema" />

      <Carousel
        autoHeight
        showIndicators={false}
        slides={[{ url: `${PUBLIC_URL}/assets/images/shnitke/cinema.jpeg` }]}
      />

      {/* <TopImage url={`${PUBLIC_URL}/assets/images/shnitke/cinema/top.jpg`} /> */}

      <StyledTitle>
        <span style={{}}>только на пути</span>
        <br />
        <span style={{ marginLeft: window.innerWidth > 768 ? 50 : 40 }}>
          невозможного
        </span>
        <br />
        <span style={{ marginLeft: window.innerWidth > 768 ? 110 : 30 }}>
          может что-то
        </span>
        <br />
        <span style={{ marginLeft: 60 }}>получиться</span>
      </StyledTitle>

      <Spacer size={10} />

      <SidesShnitke>
        <HelperIntro>
          <BookSection>
            <a
              href="https://wordorder.ru/catalog-ru/kino/russkoe-kino/tri-stepeni-svobody.-muzyka-kino-sssr.-alfred-shnitke./"
              rel="noreferrer"
              target="_blank"
            >
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/ui/book.jpeg`}
              />
              <Spacer size={1} />
              <Text size={14}>
                <span style={{ borderBottom: "1px solid #000" }}>
                  полная версия в книге
                </span>
              </Text>
            </a>
          </BookSection>

          {/*  */}

          <HelperFirstBlockCinema>
            <BlockCinema>
              <>кино как единственный выход</>
              <>
                Мои сочинения игрались, но они не приобретались никакими
                организациями — ни концертными, ни издательскими, — мне ничего
                не оставалось, как работать в кино. И это в то время [1962–1979
                годы] приобрело для меня мучительный характер: мне приходилось
                иногда по семь-восемь месяцев в году работать в кино, а остаток
                времени выкраивать для собственной работы. Но, с другой стороны,
                это кое-что дало моей композиторской работе.
                <Link to="/shnitke/source">[1]</Link>
              </>
            </BlockCinema>
          </HelperFirstBlockCinema>

          <Spacer half size={8} />

          <BlockCinema>
            <>кино учит быстроте</>
            <>
              Во-первых, это вынуждает композитора к быстрому решению: обычно
              работа в кино носит авральный характер; хотя сначала известен
              сценарий, известно, какие темы будут нужны, и происходят долгие
              разговоры с режиссером — все это так, но, как правило, музыку надо
              срочно писать по смонтированному фильму; монтаж обычно бывает
              готов в последнюю секунду, где-то там за две-три недели, когда не
              спишь, когда сидишь ночи над партитурой, записываешь, тут же
              поправляешь — реж им совершенно каторжный, — и это нередко
              вынуждает к быстрой оперативной работе, к быстрому принятию
              решений, что вовсе не однозначно легк им решениям. Иногда это как
              раз какие-то резервы, о которых не подозреваешь; они вдруг
              «просыпаются» — в течение одной ночи можно сделать, написать очень
              много. Я помню, что за одну ночь я написал три разные партитуры к
              трем сериям одного четырехсерийного фильма. Абсолютно разные
              партитуры. Потом я понял, что это было совершенно бессмысленно,
              потому что нужно было повторять одну и ту же музыку в четырех
              сериях, как оно и принято, — но в тот момент мне было интересно
              писать разное.
              <Link to="/shnitke/source">[1]</Link>
            </>
          </BlockCinema>
        </HelperIntro>
      </SidesShnitke>

      <Spacer half size={8} />

      <BlockCinema>
        <>кино как творческая лаборатория</>
        <>
          Затем это частый контакт с оркестром, это возможность испробовать
          очень многие фактурные, оркестровые идеи: вот пришло в голову, написал
          — и завтра слышишь; не через год, не через двадцать лет, как бывает с
          сочинениями, написанными для себя, а сразу. И поэтому очень многое из
          того, что я потом в более детализированном, тщательном виде разработал
          в моих произведениях, было проверено в кино.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </BlockCinema>

      <Spacer half size={8} />

      <BlockCinema>
        <>кино и польза подчинения</>
        <>
          Говорят, что Шаляпин время от времени ходил петь в хор. Для того,
          чтобы почиститься, так сказать. Для того, чтобы оказаться не в
          лидерской роли, а в роли человека, который выполняет чужие указания. Я
          думаю, что композитору иногда тоже это нужно, потому что, независимо
          от того, как складывается его личная судьба, играютего или не играют,
          знаменит он или неизвестен, но он работает, он пишет — он сам себе,
          так сказать, высший судья. И время от времени ему необходима работа на
          заказ, необходимо подчинение. (В этом ничего позорного нет —
          подчинялись и писали на заказ Моцарт и Бах, и многие современные
          композиторы это делают.) Для того, чтобы снятьего с котурн и вернуть к
          трезвой и достаточно жесткой реальности, это время от времени нужно
          (если этого не слишком много).
          <Link to="/shnitke/source">[1]</Link>
        </>
      </BlockCinema>

      <Spacer half size={28} />

      <Container>
        <Row>
          <StyledCol lg={{ offset: 1, span: 9 }}>
            <Image
              alt="Альфред Шнитке"
              src={`${PUBLIC_URL}/assets/images/shnitke/cinema/001.jpg`}
            />
            <Spacer half size={10} />
          </StyledCol>
        </Row>
      </Container>

      <Spacer half size={8} />

      <BlockCinema>
        <>опасность подчинения</>
        <>
          Но, конечно, это несет и свою опасность — опасность потери
          собственного ощущения, чего ты хочешь. Дело в том, что, работаяв кино,
          не стоит, не нужно так уж все время себя выражать; это может быть, а
          может и вредить картине — нужно исходить из интересов фильма, из того,
          что должен собой представлять будущий фильм, из того, насколько
          доверяешь режиссеру. Это не значит, что нужно потакатьего вкусам и
          угождать ему, но все равно нужно какое-то подчинение себя идее фильма.
          И если эт им заниматься много и долго, то можно потерять ощущение
          того, что же ты хочешь сам: ты будешь все время искать и угадывать,
          что хочет режиссер. Это одна опасность. Вторая опасность, конечно, в
          потере творческого лица, в потере собственного стиля.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </BlockCinema>

      <Spacer half size={8} />

      <BlockCinema>
        <>кино – это тоже я!</>
        <>
          И я в определенные моменты ощущал эти опасности, ощущал это как
          тяжелое бремя и делил свою работу на две части: вот я — это то, что я
          делаю вне кино, а в кино — это другой, не я. Но потом я понял, что это
          невозможно, что это самообман, это все равно все — я, я буду отвечать
          и за то, что пишу вне кино, и за то, что написал в кино: это все равно
          сделано мной, и свалить на режиссера, или на картину, или на
          объективные условия то, что там было недостаточно хорошо или тщательно
          сделано, не удается.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </BlockCinema>

      <Spacer half size={8} />

      <BlockCinema>
        <>контакт со слушателем</>
        <>
          Кроме того, к тому моменту я понял, что то, что писал я — вот тот я,
          который писал то, что хотел, — это тоже требует какой-то корректуры.
          Время, когда я был увлечен чисто рациональными, конструктивными
          идеями, — прошло, и я понял, что необход им какой-то контакт между
          мной и слушателями. Но контакт не такой, при котором я уступил бы
          слушателю и подделался под него. Контакт этот возникнет тогда, когда я
          не стану рисоваться перед слушателем.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </BlockCinema>

      <Spacer half size={8} />

      <BlockCinema>
        <>не должно быть разрыва</>
        <>
          Композитор может себя, так сказать, делать — так им и другим. Он, как
          всякий человек, носит в себе некий музыкальный внутренний мир, который
          состоит далеко не только из его собственных композиторских идей —
          чистых, стерильных, прекрасных: там и вся музыкальная субкультура,
          которую он впитал, — и то, что он слышал в детстве, и то, что он
          слышит каждый день на улице, — это всеего окружает, это все живет в
          нем. И это иерархичное, многоступенчатое внутреннее музыкальное
          сознание, этот внутренний музыкальный мир весь может стать материалом
          для работы — или материалом может стать один слой: верхний, самый
          чистый.
        </>
        <Spacer half size={4} />
        <>
          Я не знаю, как для кого, но для меня — я понял, что все это должно
          быть материалом для моих сочинений: и то, что я пишу, так сказать, в
          прикладных жанрах, и то, что я пишу для себя; что между эт им не
          должно быть разрыва, пропасти. Поэтому и возникли идеи соединения
          элементов разных стилей в одном сочинении.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </BlockCinema>

      <Spacer half size={18} />

      <BlockCinema black>
        <Freedom />
        <Spacer half size={8} />
        <>компромиссы и худшая музыка</>
        <>
          Этот период — 1959–1961 годы — я бы охарактеризовал как время
          неудачных попыток войти в дружеские отношения с Союзом композиторов,
          как время, когда написал, наверное, худшее свое сочинение. Я попал в
          «черный список» и на съезде композиторов был назван в нем.{" "}
          <Link style={{ color: "#fff" }} to="/source">
            [22]
          </Link>
        </>
        <br />
        <QuoteCinema inverted>
          <QuoteCinemaText inverted>
            Тих он Хренников,
            <br />
            первый секретарь правления Союза композиторов СССР:
          </QuoteCinemaText>
          <>
            Вы знаете, Вы не талантливы, и Вам не стоило выбирать эту профессию,
            не стоит заниматься сочинением музыки.
            <Link style={{ color: "#fff" }} to="/source">
              [2]
            </Link>
          </>
        </QuoteCinema>
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. игорь таланкин"
        name="«вступление»"
        url={`${PUBLIC_URL}/assets/images/shnitke/cinema/002.jpg`}
        year="1963"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>доверие к самому себе</SubTitle>
        <>
          Я пришел в кино в 1962 году. Тогда господствовала тенденция к
          уменьшению числа звучащих инструментов, в моде была разреженная
          звуковая структура: стремились к тому, чтобы музыки было мало, а еще
          лучше, чтобы она стала как бы совсем незаметной. Первый мой киноопыт —
          музыка к фильму Игоря Таланкина «Вступление».
        </>
        <>
          Я попал на картину, ничего не умея и не понимая в кино, и у Таланкина
          было достаточно поводов отказаться от работы со мной, но он почему-то
          этого не сделал. Первые мои музыкальные эскизы не выходили за рамки
          каких-то стереотипных решений. Ну, скажем, к эпизоду страшной
          хаотической посадки на поезд во время войны я предложил режиссеру
          первое пришедшее мне в голову — оркестровый кусок с активной моторной
          ритмикой, диссонансы, отголоски «Весны священной» Стравинского.
        </>
        <>
          Таланкин это не принял и сказал, что он хочет совсем другое,
          графическое решение: «Нужен модерн, наглый, рваный». Ну я понялего
          указание слишком буквально и стал сочинять эскизы в додекафонной
          технике.
        </>

        <Spacer half size={2} />
        <Container>
          <Row>
            <Col lg={{ offset: 2, span: 8 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/003.jpg`}
              />
            </Col>
          </Row>
        </Container>
        <Spacer half size={2} />

        <>
          Эти эскизы были противоположной крайностью и несли в себе некую
          абстрактную стилизацию, не имеющую никакого отношения к фильму.
          Режиссер с некоторым недоумением отказался и от них… Лучше понимать
          свою задачу я начал лишь потом, посмотрев отснятый материал. После
          просмотра я услышал не столько тему, сколько некий ритм, который стал
          исходным и сквозным в фильме. А потом к нему прибавилась тема,
          мелодически более четкая, хотя, в общем-то, тоже в духе стандартов
          киномузыки того времени, то есть не развернутая, не с широк им
          дыханием.
          <Link to="/shnitke/source">[3]</Link>
        </>
        <>
          Таланкин вообще сторонник краткого и, так сказать, пунктирного
          музыкального высказывания в кино. После «Вступления» у меня возникло
          доверие к самому себе.
          <Link to="/shnitke/source">[4]</Link>
        </>

        <Spacer half size={1} />

        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/music/01 ВСТУПЛЕНИЕ.mp3`}
        />
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <Spacer half size={18} />

      <FilmCover
        director="реж. сергей колосов"
        name="«вызываем огонь на себя»"
        url={`${PUBLIC_URL}/assets/images/shnitke/cinema/004.jpg`}
        year="1964"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>начало экспериментов</SubTitle>

        <>
          В следующей картине — многосерийном телефильме «Вызываем огонь на
          себя» режиссера Сергея Колосова я начал экспериментировать.
        </>
        <>
          Остросюжетное, приключенческое кино терпит присутствие алогизмов,
          неожиданных поворотов, странных тембров, и, исходя из этого, в картине
          Колосова я испробовал очень много новых для меня приемов. В сюжете
          очень часто возникают опасные ситуации.
        </>
        <>
          Надо было разнообразно передать это в музыке. Но чтобы разнообразить
          звучание, следовало находить изобретательные решения. Две сцены
          запомнились особо. В одном случае я использовал хор без сопровождения,
          который быстро и неразборчиво проговаривает что-то тревожное, и это
          получилось на экране убедительно. В другом — после отринутых
          традиционных вариантов музыкального сопровождения я нечаянно пришел к
          алеаторике. Произошло это так: струнники-оркестранты не могли сыграть
          безумно неудобный, трудный фрагмент, и я сам им сказал на записи:
          «Играйте что хотите, но в заданном ритме». Это оказалось уместно...
          <Link to="/shnitke/source">[3]</Link>
        </>

        <Spacer half size={8} />
        <SubTitle>нашел у киномузыки</SubTitle>
        <>
          В фильме Колосова я как раз нашел то, что потом пригодилось мне в
          «автономных» сочинениях. И позже неоднократно доводилось перенимать у
          киномузыки нечто искомое или найденное случайно.
          <Link to="/shnitke/source">[3]</Link>
        </>
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <Spacer half size={18} />

      <FilmCover
        director="реж. элем климов"
        name="«похождения зубного врача»"
        url={`${PUBLIC_URL}/assets/images/shnitke/cinema/005.jpg`}
        year="1965"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>притча</SubTitle>
        <>
          «Похождения зубного врача» нуждались в стилизованной музыке. Действие
          фильма происходит в наши дни, однако по жанру это притча, и
          происходящему нужно было придать вечный характер. (Это была идея
          Климова. Володин, автор сценария, был очень против.)
          <Link to="/shnitke/source">[3]</Link>
        </>

        <Spacer half size={10} />
        <Container>
          <Row>
            <Col lg={{ offset: 2, span: 8 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/006.jpg`}
              />
            </Col>
          </Row>
        </Container>
        <Spacer half size={10} />

        <SubTitle>стилизация под сонату</SubTitle>
        <>
          Было решено стилизовать музыку под старинную сонату, отдельные части
          которой обозначали бы этапы в жизни героя. Но это не было сонатой в
          традиционном смысле слова, а произведением для меняющегося от части к
          части состава инструментов. Задача, поставленная передо мной
          режиссером, — создание целостной музыкальной формы в фильме —
          оказалась весьма необычной, но интересной.
        </>
        <>
          Я работал с увлечением. Потом большая часть музыки к этому фильму
          Климова вошла в Сюиту в старинном стиле.
          <Link to="/shnitke/source">[3]</Link>
        </>
      </BlockCinema>

      <Spacer half size={8} />

      <Carousel
        autoHeight
        slides={[
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/007.jpg` },
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/008.jpg` },
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/009.jpg` },
        ]}
      />

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>жить на чужие проценты</SubTitle>
        <>
          В то же время стилизаторская работа опасна, потому что, при всей ее
          привлекательности, берешь на душу грех какой-то внутренней
          недобросовестности. Не заимствуешь прямо, сочиняешь сам, но словно
          живешь на чужие проценты.
        </>

        <Spacer half size={2} />
        <Image
          alt="Альфред Шнитке"
          src={`${PUBLIC_URL}/assets/images/shnitke/cinema/010.jpg`}
        />
        <Spacer half size={2} />

        <>
          Впоследствии мне часто приходилось воссоздавать звуковую атмосферу
          эпохи (в мультфильмах, в частности «Стеклянная гармоника», «В мире
          басен», в трилогии по рисункам Пушкина режиссера Андрея Хржановского,
          в кинофильмах и в телефильмах — вплоть до «Мертвых душ» Швейцера).
        </>
        <>
          Теперь я стараюсь не просто стилизовать, а предлагать свое, образное
          слышание музыки прошлого. Но сначала был другой подход.
          <Link to="/shnitke/source">[3]</Link>
        </>

        <Spacer half size={1} />

        <StyledReactPlayer
          auto
          controls
          // eslint-disable-next-line max-len
          url={`${PUBLIC_URL}/assets/audio/music/02 ПОХОЖДЕНИЯ ЗУБНОГО ВРАЧА.mp3`}
        />
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <Spacer half size={18} />

      <FilmCover
        director="реж. александр аскольдов"
        name="«комиссар»"
        url={`${PUBLIC_URL}/assets/images/shnitke/cinema/011.jpg`}
        year="1967"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>три начала четвертой симфонии</SubTitle>
        <QuoteCinema>
          <QuoteCinemaText>
            Наум Клейман,
            <br />
            киновед и историк кино:
          </QuoteCinemaText>
          <>
            Музыка из этого фильма для меня странным образом соприкоснулась с
            Четвертой симфонией Шнитке. У нас с Аскольдовым был очень важный
            разговор по поводу вероисповеданий, этнических корней. Так вот,
            Альфред нес в себе причастность к трем нациям и трем религиям.
          </>
          <br />
          <br />
          <>
            Что касается Четвертой симфонии, то после ее премьеры в зале
            Чайковского мы шли совершенно обалдевшие от музыки. Я подошел к
            Альфреду, поздравил, сказал, что четко там слышу и протестантское
            начало, и иудейское, и православное. Он ответил: «Да, это вы
            правильно услышали».
          </>
          <Spacer half size={8} />
          <SubTitle>по принципу сообщающихся сосудов</SubTitle>
          <>
            Эта музыка – над всеми различиями. Это попытка найти момент их
            сочетания, не по принципу даже коллажа, а по принципу сообщающихся
            сосудов. Выстраивание системы сообщающихся сосудов – это тоже самое,
            что он сделал и в фильме.
          </>
          <Spacer half size={8} />
          <Container>
            <Row>
              <Col lg={{ offset: 2, span: 8 }}>
                <Image
                  alt="Альфред Шнитке"
                  src={`${PUBLIC_URL}/assets/images/shnitke/cinema/012.jpg`}
                />
              </Col>
            </Row>
          </Container>
          <Spacer half size={8} />
          <>
            Аскольдов, который никакого отношения к евреям не имел, сказал – я
            вдруг в какой-то момент понял, что еврей – это не есть этническое
            обозначение. Это обозначение нравственное, социальное. Гонимый, но
            тем не менее остающийся человеком.
            <Link to="/shnitke/source">[5]</Link>
          </>
        </QuoteCinema>

        <Spacer half size={10} />

        <QuoteCinema>
          <SubTitle>утопия универсальной религии</SubTitle>
          <QuoteCinemaText>
            Бернхард Кениг,
            <br />
            киновед:
          </QuoteCinemaText>
          <>
            Комиссар вскоре после родов, с новорожденным на руках проходит мимо
            католической и православной церкви, в конце концов и мимо синагоги,
            у каждого из этих храмов благословляют ее и ребенка. Настоящ им
            носителем действия здесь является музыка Шнитке. В то время, когда
            кадры этого эпизода производят странное, сдержанное впечатление, в
            ней развивается процесс конфронтации и примирения трех религий.
          </>
          <Spacer half size={4} />
          <Image
            alt="Альфред Шнитке"
            src={`${PUBLIC_URL}/assets/images/shnitke/cinema/013.jpg`}
          />
          <Spacer half size={4} />
          <>
            Идея, заложенная в фильме Аскольдова, повлияла на самого Шнитке:
            почти двадцать лет спустя, уже известным композитором, он пишет одно
            из самых известный своих произведений — Четвертую симфонию, которую
            посвящает служению «утопии универсальной религии». Это, может быть,
            единственный случай в истории музыки, в котором программа целой
            симфонии родилась на основе идеи кинорежиссера.
            <Link to="/shnitke/source">[6]</Link>
          </>
        </QuoteCinema>

        <Spacer half size={10} />
        <Container>
          <Row>
            <Col lg={{ offset: 0, span: 6 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/014.jpg`}
              />
            </Col>
            <Col lg={{ offset: 0, span: 6 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/015.jpg`}
              />
            </Col>
          </Row>
        </Container>

        <Spacer half size={10} />

        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/music/03 КОМИССАР.mp3`}
        />
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <Spacer half size={18} />

      <FilmCover
        // eslint-disable-next-line
        director="из киноальманаха «начало неведомого века»<br />реж. андрей смирнов"
        name="«ангел»"
        url={`${PUBLIC_URL}/assets/images/shnitke/cinema/angel.jpeg`}
        year="1967"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>один кандидат на музыку</SubTitle>
          <QuoteCinemaText>
            Андрей Смирнов,
            <br />
            режиссер:
          </QuoteCinemaText>
          <>
            Моя первая самостоятельная работа — короткометражный фильм «Ангел»,
            экранизация рассказа Юрия Олеши. У меня был только один кандидат на
            музыку — это, конечно, Альфред. К этому моменту мы уже были знакомы,
            немножко подружились, сблизились. Альфред брал меня с собой на
            некоторые концерты, благодаря ему мой музыкальный кругозор стал
            значительно шире и вкусы резко подвинулись.
          </>
          <br />
          <br />
          <>
            Снимал я картину под Выборгом, на материале гражданской войны.
            Альфред приехал, посмотрел материал, и мы договорились о музыке.
          </>
          <Spacer half size={8} />
          <SubTitle>организованный хаос</SubTitle>
          <>
            Тогда я впервые на собственной шкуре узнал, как музыка строится на
            контрапункте с изображением. Сейчас вот смотришь сериалыпо
            телевизору, там музыки немыслимое количество, причем очень часто она
            мешает слушать диалог, и вся эта музыка носит так называемый
            атмосферный характер. То есть музыка пытается создать атмосферу
            сцены, что уже принципиально неверно, потому что атмосфера сцены —
            это прямая задача режиссера и актеров. А музыка должна добавлять в
            изображение такое содержание, которого там нет, и тогда из
            соединения музыки и изображения рождается нечто третье. Так в
            «Ангеле» — вся картина идет полчаса, а музыки там минуты три с
            половиной или четыре, но стоит ее вынуть, и это будет совершенно
            другой фильм. Потому что музыка Альфреда совершенно безошибочна, она
            добавляет то содержание, которого в изображении нет — такой
            организованный хаос.
          </>

          <Spacer half size={8} />
          <Container>
            <Row>
              <Col lg={{ span: 4 }}>
                <Image
                  alt="Альфред Шнитке"
                  src={`${PUBLIC_URL}/assets/images/shnitke/cinema/017.jpg`}
                />
              </Col>
              <Col lg={{ span: 4 }}>
                <Image
                  alt="Альфред Шнитке"
                  src={`${PUBLIC_URL}/assets/images/shnitke/cinema/018.jpg`}
                />
              </Col>
              <Col lg={{ span: 4 }}>
                <Image
                  alt="Альфред Шнитке"
                  src={`${PUBLIC_URL}/assets/images/shnitke/cinema/019.jpg`}
                />
              </Col>
            </Row>
          </Container>
          <Spacer half size={10} />

          <>
            Там же материал гражданской войны — достаточно жесткий, уровень
            жестокости в картине довольно большой, хотя кое-что заставили
            обрезать. Но включается оркестр, который даже не сразу узнаешь, нота
            нарастает, нарастает, доходит до какого-то предела — и обрывается… И
            вот этот прием повторяется в фильме несколько раз. Эта музыка
            добавляет многое: ощущение некоторой безнадежности, жестокости к той
            реальности, которую мы видим. И изображение там черно-белое, это
            первая работа впоследствии выдающегося оператора, уже ныне покойного
            Павла Тимофеевича Лебешева, там великолепная фотография, контраст
            черно-белый. Вся картина снята без искусственного освещения, на
            натуре. И с этой жесткостью фотографической великолепно сочетается
            жесткость звуковая, музыкальная.
          </>
          <Spacer half size={8} />
          <Image
            alt="Альфред Шнитке"
            src={`${PUBLIC_URL}/assets/images/shnitke/cinema/020.jpg`}
          />
          <Spacer half size={8} />
          <>
            У Альфреда эта идея родилась сразу. Я говорил какие-то общие слова,
            не представляя себе, какой может быть музыка, но все время повторял,
            что она должна привнести то, чего в самом изображении нет. И Альфред
            сразу предложил решение, которое даже на фортепиано трудновато было
            показать, но он очень убедительно рассказал. И когда мы в Москве
            записывали музыку на студии, с первого звука, который я услышал, у
            меня было ощущение, что это абсолютное попадание!
            <Link to="/shnitke/source">[7]</Link>
          </>

          <Spacer half size={8} />
          <SubTitle>любимый композитор новозеландцев</SubTitle>
          <>
            Когда Шнитке приехал в Выборг, я стал его расспрашивать: как вообще
            ваши музыкальные дела? Он говорил: «Ну кино это — ремесло». То есть
            давал понять, что эт им он зарабатывает на хлеб. «Ну а музыка — это
            хобби». Его не исполняли в это время вообще. Про себя говорил, что
            он — любимый композитор новозеландцев, потому что один квартет в
            Новой Зеландии игралего Струнный квартет №1.
            <Link to="/shnitke/source">[7]</Link>
          </>
          <Spacer half size={4} />
          <Image
            alt="Альфред Шнитке"
            src={`${PUBLIC_URL}/assets/images/shnitke/cinema/021.jpg`}
          />
          <Spacer half size={4} />
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={8} />

      <BlockCinema black>
        <Freedom />
        <Spacer half size={8} />
        <QuoteCinema inverted>
          <SubTitle inverted>конфликт таланта и бездарностей</SubTitle>
          <QuoteCinemaText inverted>
            Эдис он Денисов, композитор:
          </QuoteCinemaText>
          <>
            У Шнитке не было споров с Союзом композиторов. Он вообще никогда ни
            с кем не спорил. Это был конфликт таланта и бездарностей.{" "}
            <Link style={{ color: "#fff" }} to="/source">
              [8]
            </Link>
          </>
        </QuoteCinema>

        <Spacer half size={8} />

        <QuoteCinema inverted>
          <SubTitle inverted>два главка – два подхода</SubTitle>
          <QuoteCinemaText inverted>Наум Клейман:</QuoteCinemaText>
          <>
            Союз композиторов очень жестко отвергал того же Шнитке. Но почему
            чиновники от кино тем не менее шли на поводу у режиссеров и
            позволяли им работать?
          </>
          <br />
          <br />
          <>
            Тут есть своя логика в традиции нашей культуры. Дело в том, что
            российская культура эгоцентрична — она вся построена на слове.
            Цензоры, все эти киноначальники — они в первую очередь слушали, что
            произносится. У них абсолютно отсутствовало визуальное мышление, у
            них глаз не был поставлен на то, чтобы считывать визуальную
            информацию. И музыка для них была эмоциональным фоном, они ее
            практически не различали за речами персонажей, за пейзажами, в
            которых не было действия, они не знали в силу своей низкой
            квалификации, как об этом судить. Были, конечно, стукачи, которые
            доносили, из своих. Это случалось.
            <Link style={{ color: "#fff" }} to="/source">
              [5]
            </Link>
          </>
        </QuoteCinema>
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. игорь таланкин"
        name="«дневные звезды»"
        url={`${PUBLIC_URL}/assets/images/shnitke/cinema/022.jpg`}
        year="1968"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>три временных пласта</SubTitle>
        <>
          Вскоре после работы с Климовым я вновь встретился с Таланкиным. Он
          ставил картину «Дневные звезды» по книге Ольги Берггольц.
        </>
        <>
          Действие картины происходит в нескольких исторических временах. Одно —
          Великая Отечественная война, блокада. Другое — Гражданская война,
          детство героини. И есть воображаемое прошлое, к которому мысленно
          обращается героиня, — эпоха смуты, эпоха царевича Дмитрия и Бориса
          Годунова. Тут мне пришлось снова заниматься стилизацией. Я знал от
          моего консерваторского профессора Евгения Кирилловича Голубева, что
          древнерусская церковная музыка тех времен существенно отличалась от
          нынешней.
        </>
      </BlockCinema>
      <Spacer half size={8} />
      <BlockCinema>
        <SubTitle>расшифровка древней русской музыки </SubTitle>
        <>
          У Голубева были небольшие отрывки, которые он показывал на лекциях по
          полифонии: буквально две-три строчки расшифровок, сделанных
          музыковедом Виктором Беляевым.
        </>
        <Spacer half size={2} />
        <Container>
          <Row>
            <Col lg={{ offset: 2, span: 8 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/026.jpg`}
              />
            </Col>
          </Row>
        </Container>
        <Spacer half size={2} />
        <>
          Я попробовал стилизовать согласно эт им фрагментам, когда писал музыку
          для эпизода отпевания царевича в Угличе. Интересно, что тогда же
          возникли споры с консультантом фильма Матвеевым. Он считал, что такого
          звучания — более «в духе Палестрины» — не было в церковной музыке. А
          через несколько лет, когда стали все чаще звучать расшифровки древней
          русской музыки, оказалось, что сочиненное мною во многом очень близко
          подлинным строям того времени. Матвеев потом даже показывал эпизод из
          фильма как образец музыкальной реставрации.
        </>
        <>
          Но теперь понимаю, что музыка, которую я написал, не была истинным
          образом старого времени.
          <Link to="/shnitke/source">[3]</Link>
        </>

        <Spacer half size={4} />
        <Image
          alt="Альфред Шнитке"
          src={`${PUBLIC_URL}/assets/images/shnitke/cinema/024.jpg`}
        />

        <Spacer half size={1} />

        <StyledReactPlayer
          auto
          controls
          // eslint-disable-next-line max-len
          url={`${PUBLIC_URL}/assets/audio/music/04 ДНЕВНЫЕ ЗВЁЗДЫ_ОТПЕВАНИЕ ЦАРЕВИЧА.mp3`}
        />
        <Spacer half size={8} />

        <SubTitle>по-варварски</SubTitle>
        <>
          Я тогда не имел представления о канонах этого музыкального стиля, не
          понимал, что каждая интонация несла в этой музыке определенный смысл,
          что все было в старом искусстве не случайно, а точно и размеренно. Я
          же, пользуясь эт им звуковым миром варварски, интересовался только
          результатом — красотой и необычностью фонических сочетаний.
        </>

        <Spacer half size={8} />

        <SubTitle>драматургическая значимость музыки в кино</SubTitle>
        <>
          Именно фильм «Дневные звезды» помог мне понять драматургическую
          значимость музыки в кино. Я увидел, как музыка может влиять на
          конструкцию фильма, а в итоге на его восприятие. Музыка способна
          служить как бы незримой опорой драматургической конструкции: отмечать
          кульминации, разделы в картине. Причем не только в условной,
          гротескной форме, как в «Похождениях зубного врача», но и более тонко.
          Музыка способна выразить эмоциональный рельеф фильма.
          <Link to="/shnitke/source">[3]</Link>
        </>

        <Spacer half size={8} />

        <QuoteCinema>
          <SubTitle>поперечный срез истории</SubTitle>
          <QuoteCinemaText>Наум Клейман:</QuoteCinemaText>
          <>
            Шнитке пишет музыку к фильму, который прямо построен на перекличках
            XVI и ХХ веков… Как и Прокофьев, он не стилизует «под русскую
            старину», а сталкивает строй старинного «крюкового письма» и свой
            очень индивидуальный и современный музыкальный язык. Шнитке
            поразительно удается выразить трагедию России, годуновской и
            сталинской, как и трагические судьбы людей.
            <Link to="/shnitke/source">[9]</Link>
          </>
        </QuoteCinema>

        <Spacer half size={4} />
        <Container>
          <Row>
            <Col lg={{ span: 6 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/025.jpg`}
              />
            </Col>
            <Col lg={{ span: 6 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/026.jpg`}
              />
            </Col>
          </Row>
        </Container>
        <Spacer half size={4} />

        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/music/04А ДНЕВНЫЕ ЗВЁЗДЫ.mp3`}
        />

        <Spacer half size={14} />

        <SubTitle>раздвоение</SubTitle>
        <>
          В музыкальном плане я находился в ситуации раздвоения. У меня были
          свои интересы — к современной музыкальной технике, к новым сочинениям,
          я все это изучал и использовал в своей музыке. Но жизнь сложилась так,
          что… я работал в кино гораздо больше и чаще, чем бы это следовало, и
          вовсе не только в тех фильмах, которые мне были интересны. В конце
          концов я стал ощущать некоторое неудобство, некоторое раздвоение.
          Сначала было так: то, что я делаю в кино, не имеет ко мне никакого
          отношения, а я — вот здесь, в своих собственных сочинениях.
        </>
        <Spacer half size={4} />
        <Image
          alt="Альфред Шнитке"
          src={`${PUBLIC_URL}/assets/images/shnitke/cinema/100.jpg`}
        />
        <Spacer half size={4} />
        <SubTitle>поиск выхода из тупика</SubTitle>
        <>
          Потом я понял, что этот номер не пройдет: я отвечаю за все, что
          написал. Такое раздвоение невозможно, и я должен как-то пересмотреть
          отношение к тому и к другому. Кроме того, меня перестало удовлетворять
          то, что я — скажу прямо — вычислял в музыке. Конечно, я упрощаю, там
          были не только вычисления. Я понял, что какая-то ненормальность
          коренится в самом разрыве, который есть в современном музыкальном
          языке, в пропасти между лабораторной «вершиной» и коммерческ им
          «дном». Необходимо — не только мне, исходя из моей личной ситуации, но
          в принципе — этот разрыв преодолеть. Музыкальный язык должен быть
          единым, как им он был всегда, он должен быть универсальным. У него
          может быть крен в ту или иную сторону, но не может быть двух
          музыкальных языков. Развитие же музыкального авангарда приводило
          именно к сознательному разрыву и нахождению иного, элитарного языка. И
          я стал искать универсальный музыкальный язык — в музыкальном плане моя
          эволюция выглядела именно так.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <Spacer half size={18} />

      <FilmCover
        director="реж. андрей хржановский"
        name="«стеклянная гармоника»"
        url={`${PUBLIC_URL}/assets/images/shnitke/cinema/027.jpg`}
        year="1968"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>привел случай</SubTitle>
        <>
          В мультипликацию меня привел случай, а именно встреча с Андреем
          Хржановским. В 1967-м году я встретился с н им на концерте Квартета
          имени Бородина.
        </>
        <>
          Он подошел ко мне, я зналего в лицо по каким-то прежн им концертам
          Квартета, на которых бывали мы оба, и сказал, что слышал мой Первый
          квартет и что, по его мнению, — это прямо музыка к его будущему
          фильму. Ну разумеется, это не была готовая музыка к фильму. Однако он
          предложил мне прочесть сценарий «Стеклянной гармоники».
        </>
        <>
          Сценарий очень удивил меня, потому что у меня до тех пор было
          представление о мультфильмах совсем другое. И удивил меня также [его
          автор] Шпаликов, которого я видел, может быть, две-три минуты, и
          рассказы Андрея о нем.
        </>
        <>
          В общем, в итоге я стал работать на этой картине. К тому моменту я в
          кино, в основном — игровом, работал пять-шесть лет, но тут все
          оказалось совсем другим. Музыку надо было сочинять не в конце
          съемочного периода, а, наоборот, до съемок, в начале — по клеточкам,
          по секундам…
        </>
        <>
          [Было] очень много изобразительного материала, что стало крайне важно
          для меня. Андрей сразу познакомил меня с художниками Юло Соостером,
          Юрой Соболевым, Володей Янкилевск им (он, правда, в этой работе не
          участвовал) и другими.
          <Link to="/shnitke/source">[10]</Link>
        </>

        <Spacer half size={4} />
        <Image
          alt="Альфред Шнитке"
          src={`${PUBLIC_URL}/assets/images/shnitke/cinema/028.jpg`}
        />
        <Spacer half size={4} />

        <SubTitle>точные задачи от художника</SubTitle>
        <>
          Кстати сказать, эскизы художников очень помогают мне в работе. Вопрос
          всегда в том, насколько точно нужно перенять то, что делает художник,
          и воспроизводить это, или нужно, наоборот, делать нечто совершенно
          контрастное. В принципе, все ограничения только идут на пользу,
          поскольку они являются точными задачами. Я ходил с Андреем несколько
          раз в мастерскую к Соостеру, который подбирал некий изобразительный
          материал, годящийся для дела. И сразу стало ясно, что основные
          пластические образы картины — это ожившие произведения
          изобразительного искусства. И вот, когда я увидел массу всех этих
          разных, разностильных образов, я подумал о том, как же все соединится,
          не будет ли пестро, не будет ли эклектично…
          <Link to="/shnitke/source">[10]</Link>
        </>

        <Spacer half size={4} />
        <Image
          alt="Альфред Шнитке"
          src={`${PUBLIC_URL}/assets/images/shnitke/cinema/029.jpg`}
        />

        <Spacer half size={4} />
        <SubTitle>идея универсальности культуры</SubTitle>
        <>
          К соединению элементов разного стиля я уже был где-то внутренне готов
          и даже знал, что такое существует не только в кинематографе, хотя это,
          конечно же, кинематографическая идея. Весь Феллини такой.
        </>
        <>
          Да и в музыке было много подобного, но сам для себя я об этом как-то
          не думал. И вдруг я это увидел, и сама по себе возникла необходимость,
          чтобы музыка была разнообразной, контрастной по стилю, чтобы она в
          конечном итоге подчеркивала идею художественной и духовной
          универсальности культуры, универсальность сути человека… И вот тут
          как-то все совпало: и моя готовность к такому подходу, и мое
          разочарование в несколько стерильном авангардном развитии музыки,
          которое приводило к искусственному усекновению целых музыкальных
          жанров, к их уничтожению как недостойных, дивертисментных или
          недостаточно точных и тонких — была вот такая пуристская полоса в
          развитии музыки, и я ее со многими вместе пережил. В такой вот момент
          и появилось это предложение Андрея. Помимо того, что фильм был
          чрезвычайно интересен по своей кинематографической сути, он был для
          меня еще важен как средство найти выход из той кризисной ситуации, в
          которой я находился. И выход был именно в сопоставлении элементов
          разного стиля, в том, что представлял зрительный ряд этой картины,
          прежде всего.
          <Link to="/shnitke/source">[10]</Link>
        </>

        <Spacer half size={8} />
        <SubTitle>сопоставление двух стилей</SubTitle>
        <>
          Музыка, которую я написал, строилась в основном на сопоставлении двух
          музыкальных стилей. Здесь не было попытки создать некую дробную
          многостилевую калейдоскопическую картину, а я решил сопоставить два
          типа музыки. Один — это некая диссонансно-хаотическая, иногда
          сведенная на почти биологически-импульсный уровень музыка. Другой —
          стилизованная как бы под Баха, но с несколько, так сказать, большими
          возможностями в смысле инструментовки и в гармоническом плане —
          несколько более острая музыка, которая как бы охватывала огромный
          культурный пласт, какой был в фильме, поскольку здесь необходимо было
          еще нечто, объединяющее все это. Ибо буквально продублировать эту идею
          и сделать такой стилистический калейдоскоп — означало бы пойти по
          тавтологическому пути. А здесь нужно было что-то еще добавить. Вот я и
          остановился на том, что для меня является вершиной в музыке — на
          музыке Баха или баховского времени. И пытался ее как-то стилизовать и
          лимитировать.
          <Link to="/shnitke/source">[10]</Link>
        </>
        <Spacer half size={4} />
        <Container>
          <Row>
            <Col lg={{ offset: 2, span: 8 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/030.jpg`}
              />
            </Col>
          </Row>
        </Container>

        <Spacer half size={1} />

        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/music/05 СТЕКЛЯННАЯ ГАРМОНИКА.mp3`}
        />

        <Spacer half size={8} />

        <SubTitle>в другие сочинения</SubTitle>
        <>
          Потом многое из этой музыки я использовал и в других сочинениях. И
          самое главное: вот эту концепцию — столкновениев лоб разных стилей и
          разных музыкальных времен — я потом постоянно, многократно повторял,
          варьировал как-то в своих сочинениях.
          <Link to="/shnitke/source">[10]</Link>
        </>

        <Spacer half size={8} />
        <SubTitle>выход — полистилистика</SubTitle>
        <>
          Выход был мною найден, решением для меня явилась полистилистика, то
          есть совмещение разных стилистических уровней. Это дало возможность
          встать на ноги, благодаря чему мне удалось написать Первую симфонию и
          продолжать дальше работу в кино.
          <Link to="/shnitke/source">[11]</Link>
        </>
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <Spacer half size={18} />

      <FilmCover
        director="реж. элем климов"
        name="«спорт, спорт, спорт!»"
        url={`${PUBLIC_URL}/assets/images/shnitke/cinema/031.jpg`}
        year="1970"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <QuoteCinema>
          <SubTitle>метод гармонической эклектики</SubTitle>
          <QuoteCinemaText>
            Элем Климов,
            <br />
            режиссер:
          </QuoteCinemaText>
          <>
            Альфред написал замечательную музыку. А фильм по жанру, по жанровому
            составу тоже был непростой. Как я это называл, метод гармонической
            эклектики, где все что угодно было: и хроника, и не хроника, и
            комедия — так много разной музыки он написал, и в результате все
            сложилось и завершилось совершенно великолепным апофеозом, и я знаю,
            что он сам этой музыки не стеснялся, порой гордился ею…
            <Link to="/shnitke/source">[12]</Link>
          </>
        </QuoteCinema>
        <></>
      </BlockCinema>

      <Spacer half size={4} />
      <Carousel
        autoHeight
        slides={[
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/032.jpg` },
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/033.jpg` },
        ]}
      />
      <Spacer half size={4} />

      <BlockCinema>
        <StyledReactPlayer
          auto
          controls
          // eslint-disable-next-line max-len
          url={`${PUBLIC_URL}/assets/audio/music/06 СПОРТ, СПОРТ, СПОРТ! МЕНУЭТ.mp3`}
        />

        <StyledReactPlayer
          auto
          controls
          // eslint-disable-next-line max-len
          url={`${PUBLIC_URL}/assets/audio/music/07 СПОРТ, СПОРТ, СПОРТ! ФУГА.mp3`}
        />
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <Spacer half size={18} />

      <FilmCover
        director="реж. андрей смирнов"
        name="«белорусский вокзал»"
        url={`${PUBLIC_URL}/assets/images/shnitke/cinema/034.jpg`}
        year="1971"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>не нужна тут музыка</SubTitle>
          <QuoteCinemaText>
            Андрей Смирнов,
            <br />
            режиссер:
          </QuoteCinemaText>
          <>
            Я считал, что в фильме должна быть музыка, и мне казалось, что это
            может быть фортепиано — какой-то, что ли, более интимный разговор.
            Альфред раза два смотрел по ходу съемок материал, с самого начала
            сомневался и говорил: не нужна тут музыка. А я все спорил и просил.
            Кончилось тем, что, когда картина была смонтирована вчистую, кроме
            последней сцены, я уговорилего сделать партитуру и записать ее на
            фортепиано, чтобы прикинуть, что к чему. Он с неохотой, но все-таки
            согласился и сделал, как всегда, очень хорошо. Нашелся определенный
            стиль, такой, как-бы сказать, стиль сокровенного монолога. Но лирики
            там не слишком много, скорее, претензия на философский монолог. Была
            парочка кусков — просто превосходныхпо музыке. Я подложил это в
            фонограмму, мы посмотрели вместе, и стало очевидно, что Альфред был
            прав: такой бытовой реализм не выдерживает музыки, вступает с ней в
            противоречие.
          </>
          <Spacer half size={8} />
          <SubTitle>я песен не пишу</SubTitle>
          <>
            Я настаивал на том, что нужна песня, которую будут петь в кадре.
            Альфред был категоричен: нет, об этом и речи быть не может, я песен
            не пишу, это тебе к Шаинскому.
          </>
          <br />
          <br />
          <>
            Автор сценария Вад им Трунин с самого начала предлагал обратиться к
            Булату Окуджаве, а я предлагал разные варианты известных песен
            военных лет. Но Трунин, который был старше меня и опытней, сказал:
            нужна новая песня. И я обратился к Окуджаве.
          </>
          <br />
          <br />
          <>
            Булат ответил, что он уже года три песен не пишет, и браться ему
            сейчас за это не хочется, но я очень просил — прочтите сценарий…
            Сценарий, на мой взгляд, был на голову выше, чем готовый фильм. Он
            прочел и все равно отказался. Тогда я его позвал на просмотр в зал
            на «Мосфильме».
          </>
          <br />
          <br />
          <>
            Было снято все, кроме последней сцены, и, к моему удивлению, когда
            зажегся свет, стало видно, что у Булата заблестели глаза. Он сказал:
            ну давайте я попробую. Через несколько дней показал текст, который у
            меня вызывал сомнения, мне он показался странным. Но Трунин сказал,
            что это абсолютно то что надо. Булат пожаловался, что песня у него
            не получается, лишь одну строчку удается напеть.
          </>
          <br />
          <br />
          <>
            Тогда мы собрались вместе на мосфильмовской тонстудии, у фортепиано
            — Альфред, Окуджава, Вад им Трунин и я. Булат сказал: у меня там
            начало слышится и кусочек из середины… Альфред взялся за дело, и
            через полчаса все получилось, к нашему общему удовольствию. Потом он
            написал партитуру, где на самом деле аккомпанировали две гитары.
          </>
          <Spacer half size={8} />
          <Container>
            <Row>
              <Col lg={{ offset: 2, span: 8 }}>
                <Image
                  alt="Альфред Шнитке"
                  src={`${PUBLIC_URL}/assets/images/shnitke/cinema/035.jpg`}
                />
              </Col>
            </Row>
          </Container>
          <Spacer half size={8} />
          <SubTitle>самое популярное произведение — анонимное</SubTitle>
          <>
            С Ниной Ургант записали песню, сняли сцену, а потом из этой песни
            Альфред сделал марш, категорически потребовав, чтобы я убралего
            фамилию из титров, потому что из оригинальной музыки там только этот
            марш, да и то в его основе лежит мелодия Окуджавы.
          </>
          <br />
          <br />
          <>
            Это было в его характере — в нем совершенно не было суетности. Даже
            когда он стал знаменитым, он перенес это совершенно спокойно, ничто
            не изменилось в его манере, в его поведении.
          </>
          <br />
          <br />
          <>
            Помню, когда записывали марш с оркестром, мне показалось при первом
            проигрыше, что рисунок слишком жесткий, и я кинулся к Альфреду: «Вы
            слышите? Получился марш эсэсовцев, это очень жестко, очень
            агрессивно!» И сейчас-то это достаточно жестко, а тогда звучала иная
            степень агрессии. Альфред тут же что-то чуть-чуть поправил в
            инструментовке, что-то приглушил, что-то выделил, и марш получился
            таким, как им он сегодня известен.
            <Link to="/shnitke/source">[7]</Link>
          </>
        </QuoteCinema>
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <Spacer half size={18} />

      <FilmCover
        director="реж. лариса шепитько"
        name="«ты и я»"
        url={`${PUBLIC_URL}/assets/images/shnitke/cinema/036.jpg`}
        year="1972"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>растерянность как состояние поколения</SubTitle>
          <QuoteCinemaText>Наум Клейман:</QuoteCinemaText>
          <>
            Это картина, которую Гена Шпаликов написал Ларисе в трудное для них
            обоих время. Всем им было плохо.
          </>
          <Spacer half size={4} />
          <Image
            alt="Альфред Шнитке"
            src={`${PUBLIC_URL}/assets/images/shnitke/cinema/037.jpg`}
          />
          <Spacer half size={4} />
          <>
            Ларису долбали по режиссерской линии. Гена Шпаликов едва оправился
            после «Заставы Ильича» и чуть-чуть реабилитировался на «Я шагаю по
            Москве».
          </>
          <br />
          <br />
          <>
            Но при этом возник такой парафраз: «Я шагаю по Москве» — облегченный
            фильм, приспособленный уже к новым реалиям. Милый человек Данелия,
            со сво им прекрасным жизнелюбием и чутким отношением к благополучию,
            сделал такую уютную, добрую Москву.
          </>
          <br />
          <br />
          <>
            Ужас был в том, что Шпаликову говорили: ты продался, ты сделал
            какую-то социалистическую белиберду. Ведь этот фильм вышел раньше,
            чем «Застава». Марлен и Гена оказались как бы в хвосте у Данелии.
          </>
          <br />
          <br />
          <>Генка был растерян абсолютно. </>
          <br />
          <br />
          <>
            Речь даже не о том, что им всем нужно было выжить за счет кино:
            Генка, который вообще был нищий, в вечных долгах; Альф, музыку
            которого не исполняли совсем; Лариса, которую не допускали ни к чему
            — она еще ничего не успела сделать по-настоящему, а уже была
            фактически запрещена.
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={4} />
      <Carousel
        autoHeight
        slides={[
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/038.jpg` },
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/039.jpg` },
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/040.jpg` },
        ]}
      />
      <Spacer half size={4} />

      <BlockCinema>
        <QuoteCinema>
          <>
            Это была попытка сделать картину посередине — между дозволенным и
            тем, что хочется. Но ужас промежуточного произведения в том, что в
            него может хлынуть канализация. Они позже это поняли. Почему Гена
            потом не шел на такие компромиссы. Лариса разбивала голову об
            стенку. Они не могли себе простить, что пошли на это…
          </>
          <br />
          <br />
          <>
            Там все недоговорено, недопроявлено. Не наведена резкость. Сейчас мы
            смотр им эти фильмы с совершенно друг им чувством и друг им
            отношением.
            <Link to="/shnitke/source">[5]</Link>
          </>
        </QuoteCinema>
        {null}
      </BlockCinema>

      <Spacer half size={4} />
      <Carousel
        autoHeight
        slides={[
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/041.jpg` },
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/042.jpg` },
        ]}
      />
      <Spacer half size={4} />

      <BlockCinema>
        <Image
          alt="Альфред Шнитке"
          // eslint-disable-next-line max-len
          src={`${PUBLIC_URL}/assets/images/shnitke/cinema/photo_2021-11-11 22.47.49.jpeg`}
        />
        <Spacer half size={4} />

        <SubTitle>Поднималась над собой</SubTitle>

        <>
          Лариса Шепитько всегда говорила очень интересно. Но речь ее была
          простой и не свидетельствовала о какой-то терминологической
          искушенности. Были важны не слова, которыми она пользовалась, а то,
          что она говорила. И тут она как бы поднималась над собой. Это
          интересно, когда человек над собой поднимается: он в принципе вроде
          как все. Но в каких-то ситуациях он становится больше, чем все. И это
          было присуще Ларисе Шепитько в очень большой степени.
        </>
        <>
          В «Ты и я» помню один эпизод: я ей сыграл эскиз, и она, буквально
          жестами показав, насколько длиннее должен быть кусок, точно заказала
          два-три акцента, которые просто один к одному, абсолютно по секундам
          были сделаны в партитуре.
          <Link to="/shnitke/source">[1]</Link>
        </>

        <Container>
          <Spacer half size={4} />
          <Row>
            <Col lg={{ offset: 2, span: 8 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/046.jpg`}
              />
            </Col>
          </Row>
        </Container>
        <Spacer half size={4} />

        <Spacer half size={1} />

        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/music/08 ТЫ И Я.mp3`}
        />
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <Spacer half size={18} />

      <FilmCover
        director="реж. михаил ромм, элем климов, марлен хуциев"
        name="«и все-таки я верю»<br />(«мир сегодня»)"
        url={`${PUBLIC_URL}/assets/images/shnitke/cinema/047.jpg`}
        year="1972–1974"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <QuoteCinema>
          <SubTitle>мир, катящийся к катастрофе</SubTitle>
          <QuoteCinemaText>
            Александр Бендицкий,
            <br />
            музыкант:
          </QuoteCinemaText>
          <>
            [Музыковед] Марк Арановский очень четко, по-моему, определил смысл
            Первой симфонии Шнитке: «Мир, катящийся к катастрофе». Как возникла
            симфония?
          </>
          <br />
          <br />
          <>
            Шнитке писал музыку к фильму Ромма «И все-таки я верю». Между Первой
            и Второй мировыми войнами прошло двадцать лет. Выросло новое
            поколение, не испытавшее войны на себе. И люди стали вновь убивать
            друг друга. К моменту создания фильма вновь прошло двадцать лет.
            Назревала новая война. Опасность была реальной. Названием фильма
            Ромм говорит — «И все-таки я верю, что катастрофы не произойдет».
            Фильм документальный. Другое название фильма — «Хроники ХХ века».
          </>
          <br />
          <br />
          <>
            Ромму нужно было показать цивилизацию как сумму достигнутого
            человечеством, цивилизацию как нечто целое. Шнитке стремился к тому
            же. Сам Шнитке рассказывал это так: «Когда я слушал звуковую дорожку
            к фильму, то понял, что это может стать симфонией».
          </>
          <Spacer half size={8} />
          <Image
            alt="Альфред Шнитке"
            src={`${PUBLIC_URL}/assets/images/shnitke/cinema/048.jpg`}
          />
          <Spacer half size={8} />
          <SubTitle>суммарный образ цивилизации</SubTitle>
          <>
            Чтобы представить суммарный образ цивилизации в целом, музыка
            симфонии должна взять на себя большую нагрузку, чем в фильме. Ведь в
            симфонии нет видеоряда. В формате цитат и квазицитат звучат все
            основные стили — барокко, классицизм, романизм, джаз, массовая и
            уличная песня, авангард.
            <Link to="/shnitke/source">[13]</Link>
          </>
        </QuoteCinema>

        <Spacer half size={8} />
        <QuoteCinema>
          <SubTitle>из фильма — в симфонию</SubTitle>
          <QuoteCinemaText>Эри Клас, дирижер:</QuoteCinemaText>
          <>
            Нашей первой [со Шнитке] совместной работой в кино была музыка к
            фильму Михаила Ромма «Мир сегодня». Там он попытался показать ужасы
            советской системы. Это была не совсем обычная работа. Я понимал, что
            речь идет о каком-то значительном музыкальном произведении. Как
            правило, для кино пишут большое количество коротких, до одной-двух
            минут, музыкальных фраз. Здесь же Шнитке работал над заказом
            необычайно для него долго. В результате были написаны
            восьми-десятиминутные симфонические фрагменты, под которые Ромм
            впоследствии уже монтировал фильм. Настолько музыка Шнитке оказалась
            формообразующ им материалом.
          </>
          <Spacer half size={4} />
          <Image
            alt="Альфред Шнитке"
            src={`${PUBLIC_URL}/assets/images/shnitke/cinema/049.jpg`}
          />

          <Spacer half size={4} />

          <StyledReactPlayer
            auto
            controls
            // eslint-disable-next-line max-len
            url={`${PUBLIC_URL}/assets/audio/music/09 А И ВСЁ-ТАКИ Я ВЕРЮ_ФРАГМЕНТ 1.mp3`}
          />

          <Spacer half size={4} />

          <StyledReactPlayer
            auto
            controls
            // eslint-disable-next-line max-len
            url={`${PUBLIC_URL}/assets/audio/music/09 В И ВСЁ-ТАКИ Я ВЕРЮ_ФРАГМЕНТ 2.mp3`}
          />

          <Spacer half size={4} />

          <StyledReactPlayer
            auto
            controls
            // eslint-disable-next-line max-len
            url={`${PUBLIC_URL}/assets/audio/music/09 С И ВСЁ-ТАКИ Я ВЕРЮ_ФРАГМЕНТ 3.mp3`}
          />

          <Spacer half size={4} />

          <>
            Моя интуиция меня не подвела. Эти фрагменты вошли в Первую симфонию.
            Оригинал партитуры этой симфонии имеет немецкий заголовок (K)eine
            Symphony, что означает Никакая (некая) симфония.
            <Link to="/shnitke/source">[14]</Link>
          </>
        </QuoteCinema>

        <Spacer half size={4} />
        <Container>
          <Row>
            <Col lg={{ offset: 2, span: 8 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/050.jpg`}
              />
            </Col>
          </Row>
        </Container>

        <Spacer half size={4} />

        <QuoteCinema>
          <SubTitle>из симфонии — в фильм</SubTitle>
          <QuoteCinemaText>Элем Климов:</QuoteCinemaText>
          <>
            Вышло так, что, когда Михаил Ромм умер, и хотели весь хроникальный
            материал, который он собрал, просто смыть, сжечь, уничтожить —
            хранить негде было, — мы взялись с Марленом Хуциевым эту работу
            привести в какой-то порядок… В результате возник фильм «И все-таки я
            верю», где я делал свои части, Марлен делал свои части.
          </>
          <Spacer half size={4} />
          <Image
            alt="Альфред Шнитке"
            src={`${PUBLIC_URL}/assets/images/shnitke/cinema/051.jpg`}
          />
          <Spacer half size={4} />
          <>
            Ромм пригласил работать на этом фильме Альфреда Шнитке, который к
            тому времени уже сделал какие-то наброски основной темы, она в
            результате и прозвучала в этом фильме — совершенно прекрасная тема,
            изумительной красоты.
          </>

          <Spacer half size={8} />

          <StyledReactPlayer
            auto
            controls
            url={`${PUBLIC_URL}/assets/audio/music/09 D И ВСЁ-ТАКИ Я ВЕРЮ.mp3`}
          />

          <Spacer half size={8} />

          <>
            А я монтировал части, которые сам придумал. Я хотел смонтировать два
            апокалипсиса из хроники: западный — общество потребления,
            обожравшееся, и восточный — маоизм в крайнихего проявлениях.
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={4} />
      <Carousel
        autoHeight
        slides={[
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/052.jpg` },
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/053.jpg` },
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/054.jpg` },
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/055.jpg` },
        ]}
      />
      <Spacer half size={4} />

      <BlockCinema>
        <QuoteCinema>
          <>Вся хроника у меня была.</>
          <>
            И вот я Альфреду сказал: «Хочу сделать два апокалипсиса; я знаю, что
            у вас есть симфония…» А как с композитором режиссер работает?
            Композитор ему наигрывает наброски какие-то, эскизы будущей музыки,
            потом это превращается в запись с оркестром или с ансамблем. Он мне
            сказал: «Ноты есть, записей никаких нет. Если сумеете добиться того,
            чтобы на „Мосфильме“ с очень больш им составом записать фрагменты
            этой симфонии, то тогда услышите только на записи». Я добился.
            <Link to="/shnitke/source">[12]</Link>
          </>

          <Spacer half size={8} />
          <SubTitle>Сверхмонтаж и тридцать пленок</SubTitle>

          <>
            Короче, была сложнейшая запись. И эти два апокалипсиса —
            сверхмонтаж. Первый опыт в моей жизни сверхмонтажа. По миллиметру,
            по кадрику все монтировали.
          </>
          <br />
          <br />
          <>
            И Альфред, который все же записал сложнейшую музыку, сложнейший
            кусок, он на него наложил еще, по-моему, тридцать пленок, то есть он
            это превратил в нечто такое, что мне даже трудно объяснить — что это
            было. Так я через него начал постепенно что-то понимать и в музыке,
            и, самое главное, в жизни.
            <Link to="/shnitke/source">[12]</Link>
          </>
          <Spacer half size={4} />

          <StyledReactPlayer
            auto
            controls
            // eslint-disable-next-line max-len
            url={`${PUBLIC_URL}/assets/audio/music/09 E И ВСЁ-ТАКИ Я ВЕРЮ_ФРАГМЕНТ 4.mp3`}
          />
        </QuoteCinema>
        {null}
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <Spacer half size={18} />

      <BlockCinema black>
        <Freedom />
        <Spacer half size={8} />
        <SubTitle inverted>первая симфония</SubTitle>
        <>
          Я писал эту симфонию четыре года. Она вместила меня всего — и
          киношного и некиношного.{" "}
          <Link style={{ color: "#fff" }} to="/source">
            [8]
          </Link>
        </>

        <Spacer half size={8} />

        <SubTitle inverted>многослойные фактуры</SubTitle>
        <>
          Первая моя симфония написана примерно в то время, когда я работал на
          картине Ромма. Она содержит очень много многослойных фактур, где
          параллельно играется разная музыка, иногда цитируемая, иногда
          сочиненная. Эта идея многоэтажного пространства возникла, безусловно,
          благодаря звукозаписи.
          <Link style={{ color: "#fff" }} to="/source">
            [3]
          </Link>
        </>

        <Spacer half size={8} />

        <QuoteCinema inverted>
          <SubTitle inverted>задав им молчанием!</SubTitle>
          <QuoteCinemaText inverted>
            Александр Митта,
            <br />
            режиссер:
          </QuoteCinemaText>
          <>
            Когда в Союзе композиторов решили обсудить это сенсационноепо тем
            временам исполнение, Кабалевский сказал: «Мы не будем об этом
            говорить. Мы музыкальная организация, а это сочинение к музыке
            отношения не имеет». Умный человек, он понимал, что Шнитке можно
            задавить только молчанием.{" "}
            <Link style={{ color: "#fff" }} to="/source">
              [8]
            </Link>
          </>
        </QuoteCinema>

        <Spacer half size={8} />

        <SubTitle inverted>два этапа</SubTitle>
        <>
          В начале 1970-х (именно в начале!) вот что произошло: все
          предшествующее время представилось мне как время расширения круга
          средств или учебы — не в смысле пребывания на учебе, но приобщения к
          чему-то… Я закончил Первую симфонию, которая стала для меня очень
          важным сочинением, в каком-то смысле мостиком между всем предыдущ им и
          всем последующим: все больше я стал сосредотачиваться не на том, как
          сказать, а на том, что сказать… Затем у меня было несколько
          малорезультативных лет…
        </>
        <>
          Вот это и был переход от внешнего накопительства (не в плохом смысле!)
          к тому, чтобы сказать не больше, а существенней, что ли. И поэтому,
          может быть, я скорее говорил бы о двух этапах — до 1972-го и после.
          <Link style={{ color: "#fff" }} to="/source">
            [15]
          </Link>
        </>
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. андрей смирнов"
        name="«осень»"
        url={`${PUBLIC_URL}/assets/images/shnitke/cinema/056.jpg`}
        year="1974"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>андрей, что случилось?</SubTitle>
          <QuoteCinemaText>Андрей Смирнов:</QuoteCinemaText>
          <>
            Я написал сценарий и дал прочестьего Альфреду. Уж теперь я был
            уверен, что нужна будет музыка, и много. Альфред прочел сценарий,
            пришел ко мне, я жил тогда на Никитском бульваре, рядом с домом
            журналиста, и мы стали говорить.
          </>
          <br />
          <br />
          <>
            Мне мерещился хор вроде церковного, женские голоса, некий внутренний
            голос героев, их размышления о жизни. Трагические ноты, хотя речь
            идет просто о трагизме любви, об отношениях двух людей. Мы
            поговорили с Альфредом, фортепиано у меня дома не было, и он поехал
            домой. Жил он тогда на улице Дмитрия Ульянова.
          </>
          <Spacer half size={4} />
          <Container>
            <Row>
              <Col lg={{ offset: 0, span: 6 }}>
                <Image
                  alt="Альфред Шнитке"
                  src={`${PUBLIC_URL}/assets/images/shnitke/cinema/057.jpg`}
                />
              </Col>
              <Col lg={{ offset: 0, span: 6 }}>
                <Image
                  alt="Альфред Шнитке"
                  src={`${PUBLIC_URL}/assets/images/shnitke/cinema/058.jpg`}
                />
              </Col>
            </Row>
          </Container>
          <Spacer half size={4} />
          <>
            Прошло, наверное, полчаса, ну может, минут сорок, пока он доехал до
            дома. Звонит: Андрей, говорит, послушай, это вот для основной
            мелодии, для хора будущего. И играет сразу все, что потом вошло в
            фильм. Но дальше мне приходит в голову, что это как бы божественный
            элемент того, что есть в душах. Но там же и дьявол слышен. И я по
            телефону стал Альфреду рассказывать, что нужна противоположность. Он
            говорит: «Зачем вторая тема, мы из этой сделаем». И тут же сел и
            сыграл это в стиле рок, то есть та же тема, трактованная совершенно
            противоположным образом.
          </>
          <br />
          <br />
          <>
            Но, когда он приехал смотреть «Осень» в павильон, он был
            разочарован.
          </>
          <br />
          <br />
          <>
            Посчитал, что я не справился, и в этом есть доля правды. Существуют
            картины, у которых не тот уровень, на который надо было
            рассчитывать. Он рассчитывал, и я рассчитывал. И я помню, у меня
            была съемка в павильоне на «Мосфильме», а Альфред в это время
            смотрел весь материал, и во время паузы я с ним разговаривать. Он
            был огорошен: «Куда все делось, Андрей? Что случилось?» Я знал, что
            случилось, но объяснять не стал.
          </>
          <br />
          <br />
          <>
            Для меня этот вечер был очень тяжелым, потому что я понимал —
            реакция Альфреда справедливая, заслуженная, я и сам чувствовал, что
            он прав.
            <Link to="/shnitke/source">[7]</Link>
          </>
        </QuoteCinema>

        <Spacer half size={2} />
        <Container>
          <Row>
            <Col lg={{ offset: 0, span: 4 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/061.jpg`}
              />
            </Col>
            <Col lg={{ offset: 0, span: 4 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/059.jpg`}
              />
            </Col>
            <Col lg={{ offset: 0, span: 4 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/060.jpg`}
              />
            </Col>
          </Row>
        </Container>
        <Spacer half size={2} />

        <SubTitle>выхожу один я на дорогу </SubTitle>
        <>
          Там была музыка, которая вошла в фильм без текста, в исполнении хора a
          cappella, а сочинена была как реализация стихотворения Лермонтова
          «Выхожу один я на дорогу». Музыка была сочинена с текстом, а записана
          для фильма без текста. Но, я думаю, это обращение к стихотворению
          Лермонтова имело большой смысл в фильме. Прямое появление
          стихотворения было бы слишком грубым. Но подсознательно оно как бы
          постоянно присутствует в фильме, сопутствуя тому, что в нем
          происходит. Любовная история, лежащая в основе сценария, — предельная
          банальность.
        </>
        <>
          Но существование этого второго слоя, намеренно проявляющегося и в
          изображении, и в том, как говорят люди, и в музыке, — слоя, который
          важнее, чем первый, — становится очень важной особенностью фильма.
          <Link to="/shnitke/source">[1]</Link>
        </>
        <Spacer half size={2} />
        <Image
          alt="Альфред Шнитке"
          src={`${PUBLIC_URL}/assets/images/shnitke/cinema/062.jpg`}
        />
        <Spacer half size={2} />
        <>
          Но существование этого второго слоя, намеренно проявляющегося и в
          изображении, и в том, как говорят люди, и в музыке, – слоя, который
          важнее, чем первый, – становится очень важной особенностью фильма.
          <Link to="/shnitke/source">[1]</Link>
        </>

        <Spacer half size={4} />
        <Container>
          <Row>
            <Col lg={{ offset: 0, span: 6 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/063.jpg`}
              />
            </Col>
            <Col lg={{ offset: 0, span: 6 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/064.jpg`}
              />
            </Col>
          </Row>
        </Container>
        <Spacer half size={4} />

        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/music/10 ОСЕНЬ.mp3`}
        />
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <Spacer half size={18} />

      <FilmCover
        director="реж. элем климов"
        name="«агония»"
        url={`${PUBLIC_URL}/assets/images/shnitke/cinema/065.jpg`}
        year="1974"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>возрастание музыки к финалу </SubTitle>
        <>
          Бывают случаи особого, нестандартного, необычного соотношения музыки и
          изображения. Вот, например, в картине Климова «Агония» и в картине
          Шепитько «Восхождение» музыка как бы прибывает, нарастает ее образная
          значимость. Поначалу она либо отсутствует, как в «Восхождении», либо,
          как в «Агонии», не является значительным образным элементом. Однако по
          мере развития действия музыка все более уплотняется, обнаруживает себя
          и в конце берет на себя важнейшие драматургические, содержательные
          функции. Но у Климова и у Шепитько этот процесс идет по-разному, имеет
          различный смысл.
          <Link to="/shnitke/source">[3]</Link>
        </>

        <Spacer half size={4} />
        <Container>
          <Row>
            <Col lg={{ offset: 2, span: 8 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/066.jpg`}
              />
            </Col>
          </Row>
        </Container>
        <Spacer half size={4} />

        <SubTitle>принцип расширяющегося музыкального пространства </SubTitle>
        <>
          В «Агонии» фактически две музыки: сначала сильнее музыка быта, фона и
          лишь постепенно начинает прорезаться иная, соответствующая основной
          концепции картины музыка «от автора», по-настоящему выявляясь в
          финальном эпизоде — громко и напряженно. Словом, здесь принцип
          расширяющегося музыкального пространства проведен четко, но не
          догматически, потому что и в самом начале есть большие тематически
          оформленные куски бытовой музыки.
          <Link to="/shnitke/source">[3]</Link>
        </>

        <Spacer half size={2} />
        <Container>
          <Row>
            <Col lg={{ offset: 2, span: 8 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/067.jpg`}
              />
            </Col>
          </Row>
        </Container>
        <Spacer half size={2} />

        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/music/11 АГОНИЯ_ВАЛЬС.mp3`}
        />

        <Spacer half size={2} />
        <Container>
          <Row>
            <Col lg={{ offset: 0, span: 6 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/068.jpg`}
              />
            </Col>
            <Col lg={{ offset: 0, span: 6 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/069.jpg`}
              />
            </Col>
          </Row>
        </Container>
        <Spacer half size={2} />

        <SubTitle>многоканальность мира: использование бытовой музыки</SubTitle>
        <>
          Сама наша жизнь невероятно эклектична, мы живем под воздействием
          многих факторов. Мы воспринимаем мир «многоканально», мы думаем
          параллельно несколько мыслей, слушаем радио, смотр им телепрограмму,
          занимаемся еще чем-то… Поэтому попытки отсекать «инородное» и
          «задавать» себе индивидуальность и стиль — нелепы… Раньше я считал
          недопустимым для себя использовать «вульгарные» интонации — что-то в
          духе бытовой музыки, марши, вальсы, танго. Я полагал, что все это — не
          материал искусства. Сейчас же уверен, что так им материалом может быть
          все что угодно. Весь мир звуков, причем не только музыкальных…
          <Link to="/shnitke/source">[16]</Link>
        </>

        <Spacer half size={1} />

        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/music/12 АГОНИЯ_ТАНГО.mp3`}
        />

        <>
          Моя настоящая музыка — продолжение киномузыки и наоборот!
          <Link to="/shnitke/source">[1]</Link>
        </>
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <Spacer half size={18} />

      <FilmCover
        director="реж. лариса шепитько"
        name="«восхождение»"
        url={`${PUBLIC_URL}/assets/images/shnitke/cinema/070.jpg`}
        year="1976"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>от шумов — к музыке</SubTitle>
        <>
          «Агония» и «Восхождение» — два особых случая в моей работе… В этих
          картинах воплотилась идея наползающего звукового облака.
        </>
        <>
          В «Восхождении» сначала музыки нет совсем. Последовательно проведена
          идея постепенного роста от шумовых и фоновых звучаний к музыке-теме.
          Мы вместе с режиссером и звукооператором пытались еще до начала съемок
          представить себе будущую фонограмму картины и решили, что лучше всего
          начать без музыки — одни шумы, а потом происходило бы постепенное
          перерастание шумов в музыку фонов, трелей. Затем звучание должно было
          приобрести тематические очертания, и в конце был бы эпизод с очень
          ярко звучащий темой. Эта наша идея была связана с глубок им замыслом
          картины: показать, как меняется отношение главного героя к окружающему
          миру. Духовный рост помогает ему перенести пытки, физические и
          моральные мучения. Сотников начинает понимать, что мир не
          исчерпывается предметной действительностью, осознает высокий смысл и
          цель своего существования.
        </>
        <>
          В окончательном варианте была реализована идея постепенного
          высветления темы — словно нечто, находящееся в тумане, проясняется до
          открытого, яркого контура.
          <Link to="/shnitke/source">[3]</Link>
        </>

        <Spacer half size={1} />

        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/music/13 ВОСХОЖДЕНИЕ.mp3`}
        />

        <Spacer half size={8} />

        <SubTitle>способность эмоционально заводить человека</SubTitle>

        <Spacer half size={2} />
        <Image
          alt="Альфред Шнитке"
          src={`${PUBLIC_URL}/assets/images/shnitke/cinema/071.jpg`}
        />
        <Spacer half size={2} />
        <>
          Наиболее точно выражала свои пожелания Шепитько, хотя она никогда
          никаких музыкальных примеров не приводила. Она много и подробно
          говорила. Я вообще это не люблю, и эти обсуждения будущего фильма
          всегда заранее меня тяготили, но потом, когда разговор заканчивался, я
          понимал, что в нем была польза.
        </>
        <>
          У Шепитько была способность эмоционально заводить человека. Поэтому
          люди, работавшие даже с предельной добросовестностью — знаешь, бывает
          так, человек просто пользует набор известных ему средств, — у Ларисы
          совершенно не проходили. Тут нужны были какие-то другие ресурсы.
        </>
        <>
          У Ларисы было очень точное внутреннее ощущение того, что должно потом
          получиться. Такое встречается довольно редко. Часто интересные идеи
          возникают на ходу. В своей собственной работе я, как правило, очень
          многое потом меняю, но, работая с Шепитько, я с самого начала знал,
          что нужно. Свое ощущение замысла она умела выразить.
          <Link to="/shnitke/source">[4]</Link>
        </>

        <Spacer half size={4} />
        <Image
          alt="Альфред Шнитке"
          src={`${PUBLIC_URL}/assets/images/shnitke/cinema/072.jpg`}
        />
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <Spacer half size={18} />

      <FilmCover
        director="реж. александр митта"
        name="«сказ про то, как царь пётр арапа женил»"
        url={`${PUBLIC_URL}/assets/images/shnitke/cinema/073.jpg`}
        year="1976"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>трагизм и ирония</SubTitle>
          <QuoteCinemaText>
            Александр Митта,
            <br />
            режиссер:
          </QuoteCinemaText>
          <>
            Помню, как сам пришел к нему с идеями для «Арапа Петра Великого» и
            стал объяснять, что хотел бы иметь в фильме музыкальную тему, где
            были бы трагизм и ирония, чтобы она могла звучать и как марш, и как
            полька, и как лирическая тема…
          </>
          <Spacer half size={4} />
          <Image
            alt="Альфред Шнитке"
            src={`${PUBLIC_URL}/assets/images/shnitke/cinema/074.jpg`}
          />
          <Spacer half size={4} />
          <>
            Словом, обычная режиссерская лабуда: хочу все сразу одновременно. Он
            без паузы спросил: «Что-нибудь вроде этого?» Сел за рояль и
            сымпровизировал мелодию, в которой было все, о чем я мечтал, и еще в
            сто раз больше. «Да! Да!! Да!!!» — заорал я в экстазе. «Ладно, я
            что-нибудь сочиню». — «Но ты уже сочинил!» — «Это я импровизировал.
            Я и не помню, что играл». — «А вот запись!» — я поднял карманный
            диктофон, который включил с первыми нотами. Так эта мгновенно
            возникшая музыка и стала главной темой картины.{" "}
            <Link to="/shnitke/source">[8]</Link>
          </>
        </QuoteCinema>

        <Spacer half size={1} />
        <StyledReactPlayer
          auto
          controls
          // eslint-disable-next-line max-len
          url={`${PUBLIC_URL}/assets/audio/music/14 СКАЗ ПРО ТО, КАК ЦАРЬ ПЁТР АРАПА ЖЕНИЛ_ТИТРЫ.mp3`}
        />
      </BlockCinema>

      <Spacer half size={4} />
      <Carousel
        autoHeight
        centerMode
        centerSlidePercentage={33}
        slides={[
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/075.jpg` },
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/076.jpg` },
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/077.jpg` },
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/078.jpg` },
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/079.jpg` },
        ]}
      />
      <Spacer half size={4} />

      <BlockCinema>
        <SubTitle>балаган</SubTitle>
        <>
          Бывает так, что абсолютно серьезная вещь теряет свою серьезность. А
          чтобы вновь сделать ее серьезной, нужно, чтобы она пару раз
          покачнулась и как бы провалилась в балаган.
          <Link to="/shnitke/source">[1]</Link>
        </>

        <Spacer half size={1} />

        <StyledReactPlayer
          auto
          controls
          // eslint-disable-next-line max-len
          url={`${PUBLIC_URL}/assets/audio/music/15 СКАЗ ПРО ТО, КАК ЦАРЬ ПЁТР АРАПА ЖЕНИЛ_ДУЭЛЬ.mp3`}
        />

        <StyledReactPlayer
          auto
          controls
          // eslint-disable-next-line max-len
          url={`${PUBLIC_URL}/assets/audio/music/16 СКАЗ ПРО ТО, КАК ЦАРЬ ПЁТР АРАПА ЖЕНИЛ_СТРАСТИ В БУДУАРЕ ГРАФИНИ.mp3`}
        />
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <Spacer half size={18} />

      <BlockCinema black>
        <Freedom />
        <Spacer half size={8} />
        <SubTitle inverted>задача моей жизни</SubTitle>
        <>
          Задача моей жизни — это преодоление разрыва между «Е» и «U» (E-Musik,
          Ernste Musik и U-Musik, Unterhaltungsmusik — серьезная и
          развлекательная), даже если я при этом сломаю себе шею.
          <Link style={{ color: "#fff" }} to="/source">
            [17]
          </Link>
        </>

        <Spacer half size={8} />

        <SubTitle inverted>киномузыка в concerto grosso </SubTitle>
        <>
          Огромное количество кинокусков есть в Первом Concerto Grosso. Он
          изобилует цитатами такого рода: из Баха, Вивальди, какое-то танго из
          фильма о Распутине, музыка из фильма Ларисы Шепитько «Восхождение», из
          фильма Саши Митты «Сказ о том, как царь Петр арапа женил», мультфильма
          Андрея Хржановского «Бабочка». Все это цитируется, но не буквально:
          цитаты обросли друг им «мясом» и друг им духом, они как бы выведены из
          локального кинопредназначения в более всеобъемлющее, музыкальное. Это
          — апелляция к духовному миру, представленному разными гранями, в том
          числе к миру, где сосуществуют классика и нечто уподобляющееся
          классике.
          <Link style={{ color: "#fff" }} to="/source">
            [18]
          </Link>
        </>

        {/* <Spacer half size={1} />

        <StyledReactPlayer
          auto
          controls
          // eslint-disable-next-line max-len
          url={`${PUBLIC_URL}/assets/audio/music/09 А И ВСЁ-ТАКИ Я ВЕРЮ_ФРАГМЕНТ 1.mp3`}
        /> */}
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. александр митта"
        name="«экипаж»"
        url={`${PUBLIC_URL}/assets/images/shnitke/cinema/080.jpg`}
        year="1979"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>стереотипы </SubTitle>
        <>
          Роль музыки в кино не может быть соотнесена с ролью музыки в опере;
          даже когда она очень важна для фильма, все равно выражается через
          стереотипы, хотя может обернуться и иначе. Задачи здесь
          противоположны: композитор в кино находит стереотипы, в опере –
          уникальные образы. Тем самым есть опасность становиться все лучш им
          кинокомпозитором и все худшим композитором.
        </>
        <>
          Поэтому, работая со стереотипами, автор должен все время их от себя
          отстранять. А в фильме, в конце концов, делается монтаж музыки и
          изображения, это суммирование дает нечто оригинальное и тонкое, что
          невозможно порознь!
          <Link to="/shnitke/source">[19]</Link>
        </>
      </BlockCinema>

      <Spacer half size={4} />
      <Carousel
        autoHeight
        slides={[
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/081.jpg` },
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/082.jpg` },
          { url: `${PUBLIC_URL}/assets/images/shnitke/cinema/083.jpg` },
        ]}
      />
      <Spacer half size={4} />

      <BlockCinema>
        <Container>
          <Row>
            <Col lg={{ offset: 4, span: 4 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/084.jpg`}
              />
            </Col>
          </Row>
        </Container>
        <Spacer half size={4} />
        <Container>
          <Row>
            <Col lg={{ offset: 0, span: 6 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/085.jpg`}
              />
            </Col>
            <Col lg={{ offset: 0, span: 6 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/086.jpg`}
              />
            </Col>
          </Row>
        </Container>
        <Spacer half size={4} />

        <StyledReactPlayer
          auto
          controls
          // eslint-disable-next-line max-len
          url={`${PUBLIC_URL}/assets/audio/music/17 ЭКИПАЖ.mp3`}
        />
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <Spacer half size={18} />

      <FilmCover
        director="реж. михаил швейцер"
        name="«маленькие трагедии»"
        url={`${PUBLIC_URL}/assets/images/shnitke/cinema/087.jpg`}
        year="1979"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>пушкинский карнавал</SubTitle>
          <QuoteCinemaText>Наум Клейман:</QuoteCinemaText>
          <>
            Швейцер обращается с «Маленькими трагедиями», по-моему, абсолютно
            правильно — к Шнитке. Я думаю, это был результат фильма Андрея
            Хржановского о Пушкине. Поскольку Швейцер обожал Пушкина и вообще
            любил поэзию, он очень хорошо отнесся к этому фильму, увидев там
            пушкинские качества, а именно полистилистику. Он ведь очень
            правильно не стал играть в испанцев, немцев или французских рыцарей,
            он взял Смоктуновского. И Смоктуновский — русский скупой рыцарь,
            конечно, стопроцентно. Это на самом деле пушкинский карнавал.
            Швейцер, безусловно, понимал, что Шнитке абсолютно точно сможет
            уловить степень стилизации средневековья — Францию, Испанию и
            русскую традицию. И он это сделал действительно гениально.
            <Link to="/shnitke/source">[5]</Link>
          </>
        </QuoteCinema>

        <Spacer half size={1} />

        <StyledReactPlayer
          auto
          controls
          // eslint-disable-next-line max-len
          url={`${PUBLIC_URL}/assets/audio/music/18 МАЛЕНЬКИЕ ТРАГЕДИИ_ТИТРЫ.mp3`}
        />

        <StyledReactPlayer
          auto
          controls
          // eslint-disable-next-line max-len
          url={`${PUBLIC_URL}/assets/audio/music/19 МАЛЕНЬКИЕ ТРАГЕДИИ_ПОЛЬКА.mp3`}
        />
      </BlockCinema>

      <Container>
        <Row>
          <Col lg={{ offset: 2, span: 4 }}>
            <Image
              alt="Альфред Шнитке"
              src={`${PUBLIC_URL}/assets/images/shnitke/cinema/088.jpg`}
            />
          </Col>
        </Row>
      </Container>
      <Spacer half size={4} />

      {/* ------------------------------------------------------------------ */}

      <Spacer half size={18} />

      <FilmCover
        director="реж. элем климов"
        name="«прощание»"
        url={`${PUBLIC_URL}/assets/images/shnitke/cinema/089.jpg`}
        year="1983"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>«прощание с матёрой»</SubTitle>
          <QuoteCinemaText>Элем Климов:</QuoteCinemaText>
          <>
            Еще одно испытание, следующее — это когда погибла моя любимая жена
            Лариса Шепитько. Он [Шнитке] с ней сделал «Ты и я» и «Восхождение».
            Она ушла в самом начале съемок фильмапо повести Валентина Распутина
            «Прощание с Матерой». Мне пришлось ее заменить и заново делать
            фильм, а Альфред уже был приглашен ею. В результате мне довелось
            этот фильм сделать, естественно, с Альфредом.
            <Link to="/shnitke/source">[12]</Link>
          </>
        </QuoteCinema>

        <Spacer half size={2} />
        <Image
          alt="Альфред Шнитке"
          src={`${PUBLIC_URL}/assets/images/shnitke/cinema/090.jpg`}
        />
        <Spacer half size={2} />

        <StyledReactPlayer
          auto
          controls
          // eslint-disable-next-line max-len
          url={`${PUBLIC_URL}/assets/audio/music/20 ПРОЩАНИЕ.mp3`}
        />

        <Spacer half size={8} />

        <SubTitle>теневая музыка</SubTitle>
        <>
          То, что я делал в киномузыке, было вызвано необходимостью создавать
          фонограммы как бы теневые, вроде бы неслышимые,по номинальной
          первоначальной логике словно ненужные. Вот пример из «Прощания». В
          конце фильма был эпизод, записанный с оркестром и хором, который
          «тянул» на суммарное воспроизведение нескольких музыкальных пластов.
        </>
        <Spacer half size={2} />
        <Container>
          <Row>
            <Col lg={{ offset: 2, span: 8 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/093.jpg`}
              />
            </Col>
          </Row>
        </Container>
        <Spacer half size={2} />
        <>
          Но эффекта суммарности никак не получалось. Он возник, лишь когда я
          решил взять только один пласт и добавить к нему теневые отголоски из
          другой музыки — русского церковного пения. И если смотришь картину,
          слышишь фонограмму — вроде бы звучит один план. Но теневые звучания,
          стереофонические призвучия придают ему многомерность… в виде
          варьированного эха. Этот принцип потом использовался и в эпилоге моего
          балета «Пер Гюнт». Хора там как бы нет, но наше ухо продолжает его
          слышать — эффект чисто кинематографического происхождения.
          <Link to="/shnitke/source">[19]</Link>
        </>

        <Spacer half size={1} />

        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/music/21 ПРОЩАНИЕ_ФИНАЛ.mp3`}
        />

        <Spacer half size={4} />
        <Image
          alt="Альфред Шнитке"
          src={`${PUBLIC_URL}/assets/images/shnitke/cinema/094.jpg`}
        />
        <Spacer half size={4} />

        <QuoteCinema>
          <></>
          <SubTitle>странная музыка. трио «astreja» </SubTitle>
          <QuoteCinemaText>Элем Климов:</QuoteCinemaText>
          <>
            Он написал прекрасную музыку. Но произошел такой случай. В повести
            есть образ — хозяин острова, некий зверек таинственный, который суть
            тайная жизнь, содержание природы, окружающей среды, тайное
            содержание людей, которые об этом содержании не знают. Как это было
            делать? У Ларисы не было решения, она не знала, надеялась в процессе
            работы найти.
          </>
          <br />
          <br />
          <>
            Мы сид им у Альфреда дома, в кабинете, где у него рояль и где мы,
            собственно, и обсуждали все. Я говорю: «Альфред, я не знаю, что
            делать. Ты понимаешь, только, наверно, музыкой можно как-то передать
            этот образ». Он говорит: «Знаете ли, я хочу вам дать одну
            рекомендацию. Я хочу вам поставить сейчас записи странной музыки,
            которая может сыграть эту роль». Я так понял, что это его музыка. Он
            говорит: «Это не моя музыка. Есть три композитора, которые собирали
            очень много старинных и малоизвестных инструментов», — даже назвал
            число какое-то, больше сотни, как я помню. «Они собираются втроем,
            медитируют и потом начинают импровизировать. Они прекрасно понимают
            друг друга без слов. И у них получается некая странная музыка,
            которая вам и нужна для этой темы». Я говорю: «Альфред Гарриевич, я
            вас умоляю, мне вас уже вот так хватит!» Он отвечает: «Я вас прошу —
            послушайте». И завел мне эти записи. Я услышал нечто действительно
            странное. Короче, я сказал: «Оставайтесь основным композитором на
            этом фильме». Он убедил меня, и мы занялись экспериментами. То была
            сложная и долгая работа. А эти трое — Софья Губайдулина, Вячеслав
            Артемов и третий — имяего я забыл… [Виктор Суслин]. Альфред потом
            приплюсовал к этой странной музыке хор Дмитрия Покровского.
            <Link to="/shnitke/source">[12]</Link>|
          </>
        </QuoteCinema>

        <Spacer half size={2} />
        <Container>
          <Row>
            <Col lg={{ offset: 0, span: 6 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/095.jpg`}
              />
            </Col>
            <Col lg={{ offset: 0, span: 6 }}>
              <Image
                alt="Альфред Шнитке"
                src={`${PUBLIC_URL}/assets/images/shnitke/cinema/096.jpg`}
              />
            </Col>
          </Row>
        </Container>
        <Spacer half size={2} />

        <SubTitle>интуиция</SubTitle>
        <>
          Интуиция — проявление надындивидуального знания, как бы подключение к
          внешнему чудесному источнику. Произведение как бы извечно существует,
          творец не создает его, а расшифровывает, улавливает. Поэтому так
          бесспорно и так знакомо каждое выдающееся произведение — мыего уже
          «знаем». Искусство особо зависимо от интуиции (история музыки —
          «антенна, направленная в будущее»).
          <Link to="/shnitke/source">[1]</Link>
        </>

        <Spacer half size={8} />
        <SubTitle>непознаваемость истины</SubTitle>
        <>
          Я вспоминаю ответы [композитора] Валентина Сильвестрова на встрече с н
          им Молодежного клуба в Доме композиторов: «Истинность не дается в
          руки. Она как бы всегда должна выступить в новом виде. Как только
          сознание закрепит ее за этой формой, она уходит в какую-нибудь другую.
          И в этом окончательная непознаваемость истины».
          <Link to="/shnitke/source">[1]</Link>
        </>

        <Spacer half size={8} />
        <SubTitle>конечность и бесконечность знания</SubTitle>
        <>
          Знание, когда оно приходит к кристаллизации, очень многое теряет,
          приобретая. Я понял, что опора на систематизированное знание с
          цитатами, именами, книгами, с долго выстраиваемым внутренн им миром
          (ты как бы строишь в себе целое государство, целый мир) — для меня
          была бы ошибкой.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <Spacer half size={18} />

      <BlockCinema black>
        <Freedom />
        <Spacer half size={8} />
        <SubTitle inverted>идеальная неполнота философии</SubTitle>
        <>
          Я как бы ежесекундно вижу всю идеальную неполноту философии. Все
          наивные мистики имеют для меня большее значение, чем возводящие
          стройное знание.
          <Link style={{ color: "#fff" }} to="/source">
            [1]
          </Link>
        </>
        <QuoteCinema inverted>
          <SubTitle inverted>единственное спасение</SubTitle>
          <QuoteCinemaText inverted>
            Солом он Волков,
            <br />
            журналист и литератор:
          </QuoteCinemaText>
          <>
            Йога, оккультизм, каббала, китайские мистические прозрения («И
            цзин») — чего только Шнитке не испробовал в поисках ответа на
            волновавшиеего загадки жизни и смерти… Погружаясь в недра
            иррационального, он испытал ужас, единственным спасением от которого
            представилось приобщение к христианству.
            <Link style={{ color: "#fff" }} to="/source">
              [20]
            </Link>
          </>
        </QuoteCinema>
        <Spacer half size={8} />
        <SubTitle inverted>крещение</SubTitle>
        <>
          В церкви при Хофбурге [в Вене], в возрасте 48 лет, пройдя все стадии
          скептицизма и иронического отношения, все же сделал этот шаг
          [крестился]. И когда шагнул, мне открылось нечто во мне самом. Точнее,
          то, что было во мне самом, в этот момент встретилось с чем-то вне меня
          существующ им и ко мне повернутым. В этот момент я чувствовал, что я
          не замкнут в себе.
        </>
        <>
          По языку молитвы, языку восприятия я принадлежу не к немецкому миру. Я
          принадлежу к русскому миру.
        </>
        <>
          А вместе с тем я католик. Я должен продолжить то, к чему я принадлежу.
          Ведь моя мать была католичкой от рождения.
          <Link style={{ color: "#fff" }} to="/source">
            [1]
          </Link>
        </>
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. михаил швейцер"
        name="«мёртвые души»"
        url={`${PUBLIC_URL}/assets/images/shnitke/cinema/souls.jpeg`}
        year="1984"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>русский композитор без капли русской крови</SubTitle>
        <>
          Сам я зачислил бы себя скорее в русские композиторы, хотя во мне нет
          ни капли русской крови… Но вся моя жизнь прошла в России, и поэтому,
          независимо от всего остального, я ощущаю себя русским.
          <Link to="/shnitke/source">[21]</Link>
        </>

        <Spacer half size={1} />

        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/music/22 МЁРТВЫЕ ДУШИ.mp3`}
        />

        <Spacer half size={8} />

        <QuoteCinema>
          <></>
          <SubTitle>феномен шнитке</SubTitle>
          <QuoteCinemaText>Солом он Волков:</QuoteCinemaText>
          <>
            Русский максимализм соседствует в произведениях Шнитке с еврейск им
            скепсисом, окрашенным в густые тона немецкой культурной традиции.
            Это «гремучая смесь» кажется такой же немыслимой, как столь
            характерные для музыки Шнитке динамические и стилистические
            контрасты. Однако без нее не существовало бы феномена Шнитке —
            «ученой» германской музыки, стремящейся говорить по русскому обычаю
            «от сердца — к сердцу».
            <Link to="/shnitke/source">[20]</Link>
          </>
        </QuoteCinema>
        <Spacer half size={10} />
        <SubTitle>трудно писать киномузыку</SubTitle>
        <>
          Ощущение неустроенности у меня, пожалуй, усилилось еще больше. Мне
          становится все труднее писать киномузыку — она отнимает очень много
          времени, не оставляя почти ничего для своей музыки.
        </>
        <>
          К этому привыкнуть невозможно. Десять лет назад я мог спать меньше и
          работать практически в любую минуту. Сейчас у меня два дня в неделю
          уходит на головную боль, а остальное время на киномузыку.
          <Link to="/shnitke/source">[22]</Link>
        </>
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director=""
        name="стоп"
        url={`${PUBLIC_URL}/assets/images/shnitke/cinema/098.jpg`}
        year="1985"
      />

      <Spacer half size={10} />

      <BlockCinema>
        {null}
        <QuoteCinema>
          <QuoteCinemaText>Солом он Волков:</QuoteCinemaText>
          <>
            Серия инсультов превратилаего из всюду поспевавшего жизнелюба в
            немого гамбургского затворника. Вся оставшаяся в Шнитке жизненная
            сила сконцентрировалась на одном — сочинении музыки…
            <Link to="/shnitke/source">[20]</Link>
          </>
        </QuoteCinema>
      </BlockCinema>

      <SubTitle inverted>на свободу</SubTitle>
      <BlockCinema>
        {null}
        <QuoteCinema>
          <QuoteCinemaText>Андрей Смирнов:</QuoteCinemaText>
          <>
            Он был как цветок, который придушен несколько, работал в тесных
            рамках, а потом выбрался на свободу… И с того момента он перестал
            писать музыку для кино. Я испытывал радость от того, что чувствовал
            — вот это Шнитке свободный, вот это то, что он может, и то, что он
            хочет.
            <Link style={{ color: "#fff" }} to="/source">
              [7]
            </Link>
          </>
          <Spacer half size={8} />
          <Image src={`${PUBLIC_URL}/assets/images/shnitke/cinema/099.jpg`} />
        </QuoteCinema>
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <Spacer half size={48} />

      <BlockCinema withMovieName>
        <Title>p.s:</Title>
        <Spacer half size={10} />
      </BlockCinema>

      <FilmCover
        director="реж. юрий кара"
        name="«мастер и маргарита»"
        url={`${PUBLIC_URL}/assets/images/shnitke/cinema/m+m.jpg`}
        year="1994"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <QuoteCinema>
          <></>
          <SubTitle>сверхусилия и сверхнагрузки</SubTitle>
          <QuoteCinemaText>
            Юрий Кара,
            <br />
            режиссер:
          </QuoteCinemaText>
          <>
            Я пригласил Альфреда Гарриевича посмотреть отснятый материал моего
            фильма «Мастер и Маргарита», в душе надеясь, что он согласится
            написать музыку к нему. Я знал, что Альфред Гарриевич уже десять лет
            не писал музыку для кино. Как он мне сказал, ему хотелось писать
            теперь только «чистую музыку», а музыку, сочиненную к 73-м
            кинокартинам, считал прикладной, написанной в основном ради денег.
            Для меня же всяего музыка была гениальной, и я с трепетом ждалего
            вердикта после просмотра.
          </>
          <br />
          <br />
          <>
            Огромная, но уютная, светлая квартира благодаря стараниям [жены]
            Ирины располагала к творчеству. Быстрота и точность оценок и
            суждений Альфреда Гарриевича меня просто изумляла, но эти
            сверхусилия и необычайное напряжение давали о себе знать: проработав
            полтора-два часа, он тихо вставал и уходил отдыхать примерно на час.
            Тех результатов, которые выдавалего поразительный мозг за час,
            другой человек при наличии таланта мог достичь в лучшем случае за
            день или два, но сверхнагрузки отнимали у него слишком много
            энергии.
            <Link to="/shnitke/source">[23]</Link>
          </>
        </QuoteCinema>

        <Spacer half size={1} />

        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/music/23 МАСТЕР И МАРГАРИТА.mp3`}
        />
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <Spacer half size={28} />

      <BlockCinema black>
        <Freedom />
        <Spacer half size={8} />
        <SubTitle inverted>зло не фатально</SubTitle>
        <>
          «Дух дышит, где хочет», — так сказано в одной из Евангельских книг.
          Для меня нет ощущения фатальности зла даже в самый страшной ситуации.
          Его нет, потому что остается неизменной всегда проявляющаяся в
          человеке некая добрая суть.
          <Link style={{ color: "#fff" }} to="/source">
            [19]
          </Link>
        </>

        <Spacer half size={8} />
        <SubTitle inverted>зло как несовершенная степень добра</SubTitle>
        <>
          Хотя добро и зло существуют как противоположные полюса и враждебные
          начала, они где-то сообщаются, и существует какая-то единая их
          природа. Августин писал, что зло есть несовершенная степень добра.
          <Link style={{ color: "#fff" }} to="/source">
            [1]
          </Link>
        </>

        <Spacer half size={8} />
        <SubTitle inverted>формула мировой гармонии</SubTitle>
        <>
          Формула мировой гармонии, вероятнее всего, связана не с нейтрализацией
          зла, а с тем, что, вовлеченное в стройную картину целого, даже зло
          меняется: негативные элементы погашают друг друга, и в итоге возникает
          нечто стройное и прекрасное.
          <Link style={{ color: "#fff" }} to="/source">
            [24]
          </Link>
        </>

        <Spacer half size={8} />
        <SubTitle inverted>добро</SubTitle>
        <>
          Можно задать вопрос: «Ну а почему не выражать добро?» Дело в том, что
          некосвенное, непосредственное выражение добра в музыке — это и есть
          самое трудное, а порой просто невозможное.
          <Link style={{ color: "#fff" }} to="/source">
            [1]
          </Link>
        </>

        <Spacer half size={8} />
        <SubTitle inverted>финал</SubTitle>
        <>
          В моих сочинениях все часто уходит в многоточие или просто
          прекращается, кончаясь без финала.
          <Link style={{ color: "#fff" }} to="/source">
            [1]
          </Link>
        </>
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <BlockCinema black>
        <Freedom />
        <Spacer half size={8} />
        <SubTitle inverted>если бы не кино</SubTitle>
        <Spacer half size={4} />
        <Image
          alt="Альфред Шнитке"
          src={`${PUBLIC_URL}/assets/images/shnitke/cinema/vne-kino.jpg`}
        />
        <Spacer half size={4} />
        <>
          Когда я пятнадцать лет писал преимущественно киномузыку (хотя мне было
          интересно ее писать, и работа была во многих случаях очень
          интересной), я все же это ощущал как вторую свою задачу и слишком мало
          времени и условий имел для реализации главной. Тут я бы высказался за
          прямую зависимость одного от другого.
        </>
        <>
          Я начинаю думать, как им бы в итоге был — нормально развивавшийся
          композитор, не связанный с киномиром, освобожденный от перегрузок, но
          неизбежно потерявший бы также и преодоленные перегрузки? Я думаю, что
          соотношение плюса и минуса в моей жизни в итоге — так как оно меня не
          согнуло совсем — оказалось полезным.
          <Link style={{ color: "#FFF" }} to="/source">
            [1]
          </Link>
        </>
        <>
          Я не могу найти этому объяснения, но то, что для других было гибелью,
          для меня оказалась благом.{" "}
          <Link style={{ color: "#FFF" }} to="/source">
            [8]
          </Link>
        </>

        <QuoteCinema inverted>
          <QuoteCinemaText inverted>Александр Митта:</QuoteCinemaText>
          <>
            Для композитора работа в кино, то есть в музыке прикладной,
            противостоит идее свободного творчества. Так для всех. Кроме гениев.
            Впрочем, найти выход из этой ситуации было непросто и для Шнитке: «В
            кино я все время отбрасывался к нулевой отметке. Не мог развиваться
            самостоятельно. Режиссер мог меня как угодно уважать, но ему нужно
            было делать свой фильм. И тогда я нашел решение: нарушал все
            существующие правила.
          </>
          <br />
          <br />
          <>
            Музыка к фильмам стала как бы тестом, из которого Шнитке формировал
            и выпекал музыку совершенно иную. Из отходов она превратилась в
            активный элемент.
          </>
          <br />
          <br />
          <>
            В эпоху, когдаего сочинения звучали в концертных залах несколько раз
            в год, неизменно вызывая при этом скандал, в тонстудиях «Мосфильма»
            оркестры не прекращали играть Шнитке. Все, что он сочинял,
            проверялось в живом звучании. В тонстудиях сформировался новый язык
            Шнитке.{" "}
            <Link style={{ color: "#FFF" }} to="/source">
              [8]
            </Link>
          </>
        </QuoteCinema>

        <QuoteCinema inverted>
          <QuoteCinemaText inverted>
            Александр Сокуров, режиссер:
          </QuoteCinemaText>
          <>
            Шнитке — это самый крупный из больших композиторов, который мог
            сформироваться, сложиться только благодаря существованию
            кинематографа. Влияние кинематографической рутины на него столь
            глубоко, столь всесторонне, что до конца дней своих он от этого
            кинематографического визуализированного стиля избавиться уже не мог.
            Как будто у него все время есть экран, на который он смотрит и для
            которого он пишет.
            <Link style={{ color: "#FFF" }} to="/source">
              [25]
            </Link>
            .
          </>
        </QuoteCinema>

        <Spacer half size={4} />
        <Image
          alt="Альфред Шнитке"
          src={`${PUBLIC_URL}/assets/images/shnitke/cinema/101.jpg`}
        />
        <Spacer half size={4} />
      </BlockCinema>

      <Footer />
    </TypographyWrapper>
  );
};

export default Cinema;
