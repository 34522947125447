import React from "react";
import styled, { css, keyframes } from "styled-components";

import { mediaQuery } from "../utils";

import Text from "./Text";

const { PUBLIC_URL } = process.env;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const GT = styled(Text)`
  display: inline-block;
  font-size: 200px;
  line-height: 0;
  transform: translateY(-10px);

  ${mediaQuery(
    "phone",
    css`
      font-size: 100px;
      transform: translate(0, 0);
    `,
  )}
`;

const Freedom = styled.div`
  animation: ${rotate} 20s linear infinite;
  background-image: url(${`${PUBLIC_URL}/assets/images/ui/freedom.png`});
  background-repeat: no-repeat;
  background-size: contain;
  height: 180px;
  margin-left: 940px;
  margin-top: -340px;
  position: absolute;
  width: 180px;
  z-index: 0;

  ${mediaQuery(
    "phone",
    css`
      height: 120px;
      transform: translate(370px, -240px);
      width: 120px;
    `,
  )}
`;

const NavItem = styled(Text)`
  font-size: 300px;
  line-height: 160px;
  position: relative;
  z-index: 3;

  ${mediaQuery(
    "phone",
    css`
      font-size: 100px;
      line-height: 1;
    `,
  )}
`;

const NavItemCinema = styled(NavItem)`
  transform: translateX(400px);

  ${mediaQuery(
    "phone",
    css`
      transform: translate(0, 0);
    `,
  )}
`;

const NavItemMusic = styled(NavItem)`
  ${mediaQuery(
    "phone",
    css`
      transform: translate(0, 0);
      /* transform: translate(100px, -40px); */
    `,
  )}
`;

const NavItemUSSR = styled(NavItem)`
  transform: translateX(100px);

  ${mediaQuery(
    "phone",
    css`
      transform: translate(0, 0);
      /* transform: translate(100px, -40px); */
    `,
  )}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100vw;
`;

const Root = styled.div`
  padding-bottom: 120px;
  padding-top: 30px;
  position: relative;

  ${mediaQuery(
    "phone",
    css`
      display: none;
    `,
  )}
`;

const Slash = styled.div`
  background-image: url(${`${PUBLIC_URL}/assets/images/ui/slash.png`});
  background-repeat: no-repeat;
  background-size: contain;
  height: 300px;
  position: absolute;
  transform: translate(980px, -250px);
  width: 300px;
  z-index: 1;

  ${mediaQuery(
    "phone",
    css`
      height: 120px;
      transform: translate(350px, -260px);
      width: 120px;
    `,
  )}
`;

const Nav: React.FC = () => (
  <Wrapper>
    <Root>
      <NavItemMusic family="normal">
        музыка
        <GT>&gt;</GT>
      </NavItemMusic>
      <NavItemCinema>кин</NavItemCinema>

      <NavItemUSSR>
        <GT>&gt;</GT>
        ссср
      </NavItemUSSR>

      <Freedom />
      <Slash />
    </Root>
  </Wrapper>
);

export default Nav;
