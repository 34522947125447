import React from "react";
import styled, { css } from "styled-components";
import { Col } from "react-bootstrap";

import {
  Block,
  Footer,
  Nav,
  PhoneMenu,
  SidesShnitke,
  Spacer,
  Text,
  Title,
  TypographyWrapper,
} from "../../../lib";
import { mediaQuery } from "../../../utils";

const PageTitle = styled(Text)`
  font-size: 80px;
  margin-bottom: 100px;
  margin-right: 7vw;
  text-align: right;

  ${mediaQuery(
    "phone",
    css`
      display: none;
    `,
  )}
`;

const Root = styled(TypographyWrapper)`
  ${mediaQuery(
    "phone",
    css`
      margin-top: 120px;
    `,
  )}
`;

const StyledPhoneMenu = styled(PhoneMenu)`
  margin-top: -50px;
`;

const Source: React.FC = () => (
  <Root>
    <Nav />
    <StyledPhoneMenu />
    <PageTitle>альфред шнитке (1)</PageTitle>

    <SidesShnitke hideRight="source">
      <Col
        lg={{ offset: 1, span: 10 }}
        style={{ transform: "translateX(20px)" }}
      >
        <Spacer size={10} />
        <Title size={72}>источники</Title>
        <Spacer size={4} />
      </Col>
      <Block>
        <Text>
          [1] Ивашкин А.В., Беседы с Альфредом Шнитке. Культура, 1994
          <Spacer size={4} />
          [2] Интервью Якубова М.А., Он был единственный, кто поднял руку...
          «Радио России», Публикация Домбровской О., 2004
          <Spacer size={4} />
          [3] Петрушанская Е.М., Шнитке А.Г., Изображение и музыка – возможности
          диалога. Искусство кино, 1999 №2.
          <Spacer size={4} />
          [4] Баранкин Е.С., Соединяя несоединимое. Искусство кино, 1999 №2.
          <Spacer size={4} />
          [5] Интервью Клеймана Н.И. Нестерову О.А., Июль 2019
          <Spacer size={4} />
          [6] Кёниг Б., Новая музыка к фильмам. Дипломная работа, отделение
          композиции. Кельнская консерватория.
          <Spacer size={4} />
          [7] Интервью Смирнова А.С. Нестерову О.А., Июль 2020
          <Spacer size={4} />
          [8] Митта А. Н., Музыка – это все, что я знаю. Обозреватель, 1994 №11.
          <Spacer size={4} />
          [9] Клейман Н.И., Альфред Шнитке: срез истории, текст написан
          специально для издания: Хржановский А.Ю., Альфред Шнитке. Статьи,
          интервью. Воспоминания о композиторе. Arcadia, 2014
          <Spacer size={4} />
          [10] Нейман М., История одного интервью, Сотворение мира., 1990
          <Spacer size={4} />
          [11] Интервью Шнитке А.Г. Цыпину Г.М., О «возвышенном» и «земном».
          Музыкальная жизнь, 1990 №8.
          <Spacer size={4} />
          [12] Климов Э.Г., Он держал в голове весь мир. Альфреду Шнитке
          посвящается, Вып.3. Композитор, 2003
          <Spacer size={4} />
          [13] Бендицкий А.С., Выпрыгнуть из времени, Альфред Шнитке в
          воспоминаниях современников. Композитор, 2013
          <Spacer size={4} />
          [14] Клас Э., Он боялся не успеть. Дирижер: Лицом к залу. Композитор,
          2006
          <Spacer size={4} />
          [15] Интервью Шнитке А. Макееву Ю., Советская музыка, 1998 №10.
          <Spacer size={4} />
          [16] Полистилистические тенденции современной музыки, из архива
          Петрова А.Е.: Альфреду Шнитке посвящается, вып.3. Композитор, 2003
          <Spacer size={4} />
          [17] Шнитке А.Г., О Concerto Grosso, Статьи о музыке. Композитор, 2004
          <Spacer size={4} />
          [18] Мейлах М.Б., Я начал как бы второй круг..., Разговор с Альфредом
          Шнитке. Москва – Лондон, 1990
          <Spacer size={4} />
          [19] Холопова В.Н., Шнитке А. Г., Дух дышит, где хочет.Наше наследие,
          1990 №3.
          <Spacer size={4} />
          [20] Волков С.М., Азбука Шнитке, впервые опубликовано: Литературная
          газета. 1998 №41.
          <Spacer size={4} />
          [21] Интeрвью АЛЬФРЕД ШНИТКЕ: «ВО МНЕ НЕТ НИ КАПЛИ РУССКОЙ КРОВИ, НО
          КОМПОЗИТОР Я РУССКИЙ» для RUSSIAN BAZAAR №38 (386),
          <a href="http://russian-bazaar.com/ru/content/3544.htm">
            http://russian-bazaar.com/ru/content/3544.htm
          </a>
          <Spacer size={4} />
          [22] Шульгин Д.И., Годы неизвестности Альфреда Шнитке (беседы с
          композитором). Деловая лига, 1993
          <Spacer size={4} />
          [23] Кара Ю.В., Отсутствие стиля – и есть мой стиль, текст написан
          специально для издания: Хржановский А.Ю., Альфред Шнитке. Статьи,
          интервью. Воспоминания о композиторе. ARCADIA, 2014
          <Spacer size={4} />
          [24] Башмет Ю. А., Вокзал Мечты. Вагриус, 2003
          <Spacer size={4} />
          [25] Уваров С.А., Интонация. Александр Сокуров. Новое литературное
          обозрение, 2019
          <Spacer size={4} />
          [26] Интервью Скрипки С.И. Нестерову О.А., 2020
          <Spacer size={4} />
          [27] Шлосман В., Серьезная музыка находится на грани исчезновения.
          Беседа с Софьей Губайдулиной. Портал ТК Культура, 2009
          <Spacer size={4} />
          [28] Андерсон М., Вячеслав Артёмов, София Губайдулина, Виктор Суслин.
          Из буклета к CD Астрея
          <Spacer size={4} />
          [29] Из архива Петрова А.Е., Альфреду Шнитке посвящается. Вып.3.
          Композитор, 2003
          <Spacer size={4} />
          [30] Шульгин Д.И., Годы неизвестности Альфреда Шнитке (беседы с
          композитором). Деловая лига, 1993
          <Spacer size={4} />
          [31] Из бесед Нестьевой М.И. с Шнитке А.Г. и Слонимским С.М., которая
          состоялась в 1982г., впервые опубликована в 1992
          <Spacer size={4} />
          [32] Брель В., Шнитке. Дело случая. Знание – сила, № 2, 2000
          <Spacer size={4} />
          [33] Интервью Шнитке А.Г. Самвеляну Н.Г., Дуэты на темы о кризисе
          ожидания, Журнал Театр №12,1989.
          <Spacer size={4} />
          [34] Интервью Шнитке А.Г. Цыпину Г.М., О «возвышенном» и «земном»,
          Музыкальная жизнь №8, 1990
          <Spacer size={4} />
          [35] Медведев А., Нужен поиск. Нужны изменения привычного, Советский
          джаз, М., 1987
          <Spacer size={4} />
          [36] Ивашкин А., Гудкова Е., Слово об Альфреде Шнитке. Фестиваль,
          Москва, 1994
          <Spacer size={4} />
          [37] Кузнецов А., Парадоксы Альфреда Шнитке. РИК Культура, 1994
          <Spacer size={4} />
          [38] Шнитке А.Г., Простая сложность и сложная простота. Из выступления
          на авторском вечере в клубе московского университета, 1987
          <Spacer size={4} />
          [39] Шнитке А.Г., На пути к воплощению новой идеи. Статьи о музыке.
          М.: Композитор, 2004
          <Spacer size={4} />
          [40] Нестьева М.И., А я горюю… Текст написан специально для издания:
          Хржановский А.Ю., Альфред Шнитке. Статьи, интервью. Воспоминания о
          композиторе. ARCADIA, 2014 Цитата Альфреда Шнитке в эпиграфе -
          Долинская Е., Богданова А.В., Альфред Шнитке в воспоминаниях
          современников. М: Композитор, 2013
        </Text>
        <></>
      </Block>
    </SidesShnitke>

    <Footer />
  </Root>
);

export default Source;
