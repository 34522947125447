import React from "react";
import styled, { css } from "styled-components";

import { mediaQuery } from "../utils";

const { PUBLIC_URL } = process.env;

const Root = styled.div`
  background-image: url(${`${PUBLIC_URL}/assets/images/menu.png`});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
  display: none;
  height: 63vw;
  margin-bottom: 20px;
  margin-top: 80px;
  width: 100%;

  ${mediaQuery(
    "phone",
    css`
      display: block;
    `,
  )}
`;

const PhoneMenu: React.FC = (props) => <Root {...props} />;

export default PhoneMenu;
