/* eslint @typescript-eslint/indent: 0 */
/* eslint no-confusing-arrow: 0 */

import React from "react";
import styled, { css } from "styled-components";
import { Col, Container, Row } from "react-bootstrap";

import Text from "./Text";
import Title from "./Title";
import TransitionOnScreen from "./TransitionOnScreen";
import { mediaQuery } from "../utils";

type Props = {
  children: React.ReactNode[];
  black?: boolean;
  right?: boolean;
  withMovieName?: boolean;
};

type RootProps = {
  black: boolean;
  right: boolean;
};

const Root = styled.div<RootProps>`
  background-color: ${(props) => (props.black ? "#000" : "#FFF")};
  color: ${(props) => (props.black ? "#FFF" : "#000")};
  text-align: ${(props) => (props.right ? "right" : "left")};

  ${mediaQuery(
    "phone",
    css`
      margin-bottom: 30px;
      margin-left: -46px;
      margin-right: -66px;
    `,
  )}

  ${(props) =>
    props.black &&
    css`
      margin-bottom: 0;
      padding: 80px 0;
    `}
`;

const StyledText = styled(Text)<RootProps>`
  ${mediaQuery(
    "phone",
    css`
      font-size: 16px;
      line-height: 1.2;
    `,
  )}

  ${(props) =>
    props.right
      ? css`
          padding-left: 10%;

          ${mediaQuery(
            "phone",
            css`
              /* padding-left: 10%; */
            `,
          )}
        `
      : css`
          padding-right: 10%;

          ${mediaQuery(
            "phone",
            css`
              /* padding-right: 10%; */
            `,
          )}
        `};
`;

const StyledTitle = styled(Title)<{ withMovieName: boolean }>`
  font-size: ${(props) => (props.withMovieName ? "52px" : "36px")};

  ${mediaQuery(
    "phone",
    css`
      font-size: 22px;
      line-height: 1.2;
    `,
  )}
`;

const Block: React.FC<Props> = ({
  children,
  black = false,
  right = false,
  withMovieName = false,
}: Props) => {
  const renderContent = React.useCallback(
    (node: React.ReactNode) =>
      black ? (
        <Container>
          <Row>
            <Col
              lg={{ offset: 1, span: withMovieName ? 12 : 9 }}
              md={{ offset: 1, span: withMovieName ? 12 : 9 }}
              sm={{ offset: 1, span: withMovieName ? 12 : 9 }}
              xs={{ offset: 1, span: withMovieName ? 12 : 9 }}
            >
              {node}
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <Row>
            <Col
              lg={{ offset: 1, span: withMovieName ? 12 : 9 }}
              md={{ offset: 1, span: withMovieName ? 12 : 9 }}
              sm={{ offset: 1, span: withMovieName ? 12 : 9 }}
              xs={{ offset: 1, span: withMovieName ? 12 : 9 }}
            >
              {node}
            </Col>
          </Row>
        </Container>
      ),
    [black, withMovieName],
  );

  return (
    <Root black={black} right={right}>
      <TransitionOnScreen>
        {renderContent(
          <>
            <StyledTitle inverted={black} withMovieName={withMovieName}>
              {children[0]}
            </StyledTitle>
            {children.slice(1).map((child, index) => (
              <StyledText
                key={index}
                black={black}
                inverted={black}
                right={right}
                size={24}
              >
                {child}
              </StyledText>
            ))}
          </>,
        )}
      </TransitionOnScreen>
    </Root>
  );
};

export default Block;
