import React from "react";
import styled, { css } from "styled-components";

type FontGamily = "normal" | "sans" | "thin";

type FontSize = number;

type Props = {
  children: React.ReactNode;
  family?: FontGamily;
  inverted?: boolean;
  lowercase?: boolean;
  size?: FontSize;
};

type RootProps = {
  family: FontGamily;
  inverted: boolean;
  lowercase?: boolean;
  size: FontSize;
};

const Root = styled.p<RootProps>`
  color: ${(props) => (props.inverted ? "#fff" : "#000")};
  font-family: "Bandera-Pro-Regular";
  font-size: ${(props) => props.size}px;
  line-height: 1.2;

  /**
   * Lowecase
   */
  ${({ lowercase = false }) =>
    lowercase &&
    css`
      text-transform: lowercase;
    `}

  /**
   * Thin
   */
  ${({ family }) =>
    family === "thin" &&
    css`
      font-family: "Bandera-Pro-Thin";
    `}

  /**
   * Sans
   */
  ${({ family }) =>
    family === "sans" &&
    css`
      font-family: "PT-Sans";
    `}
`;

const Text: React.FC<Props> = ({
  children,
  family = "normal",
  inverted = false,
  lowercase = false,
  size = 18,
  ...rest
}: Props) => (
  <Root
    {...{
      ...rest,
      family,
      inverted,
      lowercase,
      size,
    }}
  >
    {children}
  </Root>
);

export default Text;
