/* eslint jsx-a11y/media-has-caption: 0 */
/* eslint react/jsx-one-expression-per-line: 0 */

import React from "react";
import styled, { css, keyframes } from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

import {
  BlockCinema,
  Carousel,
  Footer,
  NamedHeader,
  PageIntroKaravajchuk,
  QuoteCinema,
  SidesKaravajchuk,
  Spacer,
  SubTitle,
  Text,
  Title,
  TypographyWrapper,
} from "../../../lib";
import { mediaQuery } from "../../../utils";

const { PUBLIC_URL } = process.env;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Freedom = styled.div`
  animation: ${rotate} 20s linear infinite;
  background-image: url(${
  // eslint-ignore-next-line
  `${PUBLIC_URL}/assets/images/shnitke/cinema/circle.jpg`
});
  background-repeat: no-repeat;
  background-size: contain;
  height: 180px;
  left: 35%;
  position: relative;
  transform: translateX(-35%);
  width: 180px;

  ${mediaQuery(
    "phone",
    css`
      left: 25%;
      transform: translateX(-25%);
    `,
  )}
`;

const BookSection = styled.div`
  ${mediaQuery(
    "desktop",
    css`
      margin-bottom: -200px;
      margin-top: 0px;
      position: relative;
      text-align: right;
      z-index: 111111;
      /* width: 40vw; */

      img {
        width: 10vw;
      }
    `,
  )}

  ${mediaQuery(
    "phone",
    css`
      margin-bottom: 40px;
      margin-top: 10px;
      text-align: center;
      /* width: 40vw; */

      img {
        width: 40vw;
      }
    `,
  )}
`;

const QuoteCinemaText = styled(Text)`
  text-transform: lowercase;
`;

const Image = styled.img`
  width: 100%;
`;

const StyledTitle = styled(Title)`
  color: #fff;
  display: inline-BlockCinema;
  font-size: 36px;
  margin-left: 60vw;
  right: 20px;
  transform: translateY(-12vw);

  ${mediaQuery(
    "phone",
    css`
      font-size: 16px;
      margin-left: 50vw;
      transform: scale(0.7) translateY(-32vw) translateX(6vw);
    `,
  )}
`;

const HelperIntro = styled(Title)`
  margin-left: -120px;

  ${mediaQuery(
    "phone",
    css`
      margin-left: -16px;
    `,
  )}
`;

const HelperFirstBlockCinema = styled(Title)`
  margin-top: 7vw;

  ${mediaQuery(
    "phone",
    css`
      margin-top: -150px;
    `,
  )}
`;

const StyledReactPlayer = styled(ReactPlayer)`
  max-height: 40px;
  max-width: 100%;
`;

// Film Cover

type FilmCoverProps = {
  additionalInfo?: string;
  director: string;
  name: string;
  url: string;
  year: string;
};

const FilmCoverRoot = styled.div<{ url: string }>`
  background-color: #000;
  background-image: ${(props) => `url(${props.url})`};
  background-repeat: no-repeat;
  background-size: cover;
  height: 68vh;
  opacity: 0.6;
  padding: 40px 0;
  position: absolute;
  width: 100%;

  ${mediaQuery(
    "phone",
    css`
      height: 26vh;
      max-height: 26vh;
    `,
  )}

  ${mediaQuery(
    "desktop",
    css`
      max-height: 68vh;
    `,
  )}
`;

const FilmCoverText = styled(Text)`
  ${mediaQuery(
    "phone",
    css`
      font-size: 16px;
    `,
  )}
`;

const FilmCoverWrapper = styled.div`
  background-color: #000;

  ${mediaQuery(
    "phone",
    css`
      font-size: 18px;
      height: 26vh;
    `,
  )}

  ${mediaQuery(
    "desktop",
    css`
      height: 68vh;
    `,
  )}
`;

const FilmCoverContainer = styled(Container)`
  padding: 40px 0;
  position: relative;
  zindex: 10;

  ${mediaQuery(
    "phone",
    css`
      padding: 40px 40px;
    `,
  )}
`;

const FilmCover: React.FC<FilmCoverProps> = ({
  additionalInfo,
  director,
  name,
  url,
  year,
}: FilmCoverProps) => (
  <FilmCoverWrapper>
    <FilmCoverRoot url={url} />
    <FilmCoverContainer>
      <Row>
        <Col
          lg={{ span: 7 }}
          md={{ span: 7 }}
          sm={{ span: 7 }}
          xs={{ span: 7 }}
        >
          <FilmCoverText inverted size={64}>
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: name }}
            />
            <br />
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: director }}
            />
          </FilmCoverText>
        </Col>
        <Col
          lg={{ span: 5 }}
          md={{ span: 5 }}
          sm={{ span: 5 }}
          style={{ textAlign: "right" }}
          xs={{ span: 5 }}
        >
          <FilmCoverText inverted size={54}>
            <div style={{ display: "grid" }}>
              {additionalInfo && (
                <>
                  <span>
                    {additionalInfo}
                    <br />
                  </span>
                </>
              )}
              {year}
            </div>
          </FilmCoverText>
        </Col>
      </Row>
    </FilmCoverContainer>
  </FilmCoverWrapper>
);

const Cinema: React.FC = () => {
  document.body.style.overflowX = "hidden";

  return (
    <TypographyWrapper>
      <NamedHeader name="кино" />

      <PageIntroKaravajchuk author="karavajchuk" name="cinema" />

      {/* <Spacer size={10} /> */}

      <Carousel
        autoHeight
        showIndicators={false}
        slides={[
          { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/top.jpg` },
        ]}
      />

      <StyledTitle>
        <div style={{ marginTop: window.innerWidth > 768 ? 0 : 34 }}>
          <span style={{}}>Я считаю,</span>
          <br />
          <span style={{ marginLeft: window.innerWidth > 768 ? 50 : 40 }}>
            что не я играю,
          </span>
          <br />
          <span style={{ marginLeft: window.innerWidth > 768 ? 110 : 30 }}>
            за меня ангелы
          </span>
          <br />
          <span style={{ marginLeft: 60 }}>работают</span>
        </div>
      </StyledTitle>

      <Spacer size={10} />

      <HelperIntro>
        <BookSection />

        {/*  */}
        <Spacer size={4} />
        <HelperFirstBlockCinema>
          <BlockCinema>
            <>«ленфильм» ночью</>
            <>
              «Ленфильм» очень странно для меня начался. Мне было семнадцать
              лет, когда я начал тут играть в оркестре, чтобы поддержать семью.
              И я все время играл по ночам, потому что днем в основном снимали
              фильмы. Музыку писали в больших ателье: то в пятом, то в третьем
              павильоне. Собирались музыканты второго или первого оркестра, и
              среди них я играл на рояле, как оркестровый музыкант. Ночью играл,
              а днем ходил в Консерваторию.{" "}
              <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
                <Link to="/karavajchuk/source">[A—A]</Link>
              </sub>
            </>
          </BlockCinema>
        </HelperFirstBlockCinema>
      </HelperIntro>

      <Spacer half size={8} />

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. анатолий граник"
        name="«алеша птицын вырабатывает характер»"
        url={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/2.jpg`}
        year="1953"
      />

      <SidesKaravajchuk hide="ermitazh">
        <Spacer half size={10} />

        <BookSection>
          <a
            href="https://wordorder.ru/catalog-ru/izdatelstvo-poryadok-slov/oleg-karavaychuk.-tri-stepeni-svobody.-muzyka-kino-sssr.-2/"
            rel="noreferrer"
            target="_blank"
          >
            <Image
              alt="Альфред Шнитке"
              src={`${PUBLIC_URL}/assets/images/karavajchuk/book.jpg`}
            />
            <Spacer size={1} />
            <Text size={14}>
              <span style={{ borderBottom: "1px solid #000" }}>
                полная версия в книге
              </span>
            </Text>
          </a>
        </BookSection>

        <BlockCinema>
          <SubTitle>барто. вайнберг</SubTitle>
          <>
            Но в этот момент, всегда в моей жизни бывает так, когда все рухнет,
            именно все рухнет, и вдруг — сразу замечательно! И в этот раз тоже —
            рухнуло все, и буквально на следующий день мой папа, который был в
            музыкальном отделе «Ленфильма», сказал, что [детская писательница]
            Агния Барто, по сценарию которой снимался новый фильм (это был
            1952-й — канун 1953-го), приехала на киностудию и забраковала музыку
            композитора, назначенного на картину. Говорит: «Такая бабка, она
            ничего… Работает. Не то, что там кого-нибудь пропустит, она очень
            требовательная».
          </>
          <>
            У нее там такие стихи: «Злодейка зима набирается сил, выходит мороз
            — никого не спросил…» Была песенка композитора [Михаила] Матвеева,
            он написал нормальную профессиональную бездарную песню, и она ни за
            что ее не принимала. А картину должны были отправлять в экспедицию,
            снимать зимнюю натуру, снег выпал, времени у них не было. Я сказал
            папе, именно сказал: «Папа, я не думаю ничего такого делать». Потому
            что молодых композиторов, тем более без шефов, никогда никуда не
            пускали. И в этот же момент сразу написал музыку.
          </>

          <Spacer half size={4} />
          <StyledReactPlayer
            auto
            controls
            url={`${PUBLIC_URL}/assets/audio/karavajchuk/01 МЫ МОСКВИЧИ.mp3`}
          />
          <Spacer half size={4} />

          <>
            Так как на «Ленфильме» не захотели ее прослушать, то поехал в Москву
            сам, папа узнал мне адрес Барто. Я пришел к Агнии Львовне, и у нее
            была [актриса] Рина Зелёная. Такая была большая квартира, она денег
            не экономила. Помню, говорила с Ленинградом очень долго, после того,
            как прослушала мою песенку. И была категорична: «Только он должен
            писать мою музыку!» Ей: «Нет, он не должен». Она: «Только он!» А
            потом ко мне оборачивается: «Ну что будем делать? Надо шефа искать.
            Есть ли у тебя какой-нибудь знакомый, хороший и уже немолодой
            композитор?» Я говорю: «Метек Вайнберг». Она оказывается его знала.
            И вот она сейчас же набрала номер Метека, и сказала, чтобы Метек
            меня послушал, и чтобы он стал моим шефом.
          </>
          <>
            И сейчас же от Агнии Львовны я пошел к Метеку. Метек меня встретил,
            я сел за рояль, и первое что я начал играть, я не понимаю почему —
            начал ему играть его симфонию. Я ему играл ее почти назубок,
            страницу за страницей. Через неделю-две мне сказали, что Вайнберг в
            тюрьме, и оказывается именно в тот день, когда я приходил, Вайнберг
            узнал, что его посадят, и посадят весь еврейский театр по порядку. И
            он ничего мне об этом не сказал, его лицо было светло, как будто
            ничего не произошло.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[A—B]</Link>
            </sub>
          </>

          <Spacer half size={10} />
          <Carousel
            autoHeight
            slides={[
              { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/3a.jpg` },
              { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/3b.jpg` },
              { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/3c.jpg` },
            ]}
          />

          <Spacer half size={10} />
        </BlockCinema>
      </SidesKaravajchuk>

      <BlockCinema>
        <SubTitle>ровная трудовая жизнь</SubTitle>
        <>
          И вот я написал [музыку к фильму] «Алеша Птицын вырабатывает
          характер». И с тех пор пошла довольно ровная моя трудовая жизнь. Ко
          мне подошел [режиссер Владимир] Венгеров и попросил написать музыку к
          «Двум капитанам» [1955]. Это была следующая моя картина. Я понял, что
          я могу писать для кино, содержать семью, папа старенький был совсем,
          он старше моей мамы на 25 лет. Мама получала очень мало в школе.
          Одновременно я сочинял для себя музыку.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[A-A; A-B]</Link>
          </sub>
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>поцелуй габриловича. фильм «овод». шостакович</SubTitle>
        <>
          Самое сильное, — то, что я буду перед смертью вспоминать о «Ленфильме»
          (если я буду тогда думать о «Ленфильме»), — это поцелуй [Евгения]
          Габриловича, великого сценариста. Его поцелуй после моей музыки к
          «Оводу» [1955].
        </>
        <>
          На этом фильме [Арам] Хачатурян заболел (он был у [режиссера
          Александра] Файнциммера композитором), болел очень тяжело, а надо было
          писать музыку. Кто-то посоветовал меня, и я сочинил. В [директорском
          кабинете] был рояль, я сыграл свою музыку вместо Хачатуряна.
          Габриловичу она так понравилась, что он выскочил из кабинета, обнял
          меня и поцеловал. Гениальный сценарист. Дар Божий.
        </>
        <>
          Но потом начались склоки. Союз композиторов был против меня. Если
          Хачатурян болен, нужно, чтобы Шостакович писал, или кто-нибудь еще, но
          не Каравайчук. И я написал письмо Шостаковичу. Я очень дружил с
          матерью Шостаковича, и она мне передала его слова: «Олег мне прислал
          истерическое письмо».
        </>
        <>
          Шостакович не хотел брать «Овода», потому что я уже его написал. Но
          вокруг меня устроили такую склоку, что Шостаковича заставили взять.
          Вопреки своей воле он написал «Овода», хотя потом очень каялся. Но все
          равно меня бы не подпустили к этому фильму, так что если бы не он,
          писал кто-то другой.
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>друг — оператор москвин</SubTitle>
        <>
          Меня спрашивают, какого режиссера я более всего чувствую как друга.
          Режиссера такого не было, но если говорить о друге, которому тоже
          очень понравилась моя музыка на «Оводе», — это [Андрей] Москвин. Он
          был хорошо знаком с Шостаковичем, они вместе росли на «Ленфильме», все
          фильмы [режиссера Григория] Козинцева снимал оператор Москвин, а
          музыку к ним писал Шостакович. Это он позвонил Мите [Шостаковичу],
          чтобы тот не делал музыку к «Оводу». И вот он стал моим другом. Андрей
          Николаевич Москвин был старше меня намного, он — гениальный оператор.
          Он всегда понимал мою музыку. А тут никто ее не понимал.
        </>
        <>
          Может быть с этого и начался по-настоящему мой «Ленфильм». С поцелуя
          Габриловича и этого рояля, на котором я играл «Овода».{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[A—A]</Link>
          </sub>
        </>
      </BlockCinema>

      <Spacer half size={10} />

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. александр иванов"
        name="«солдаты»"
        url={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/4.jpg`}
        year="1956"
      />

      <Spacer half size={8} />

      <BlockCinema>
        <SubTitle>первая честная книга о войне. некрасов</SubTitle>
        <>
          В этот момент произошли две удивительные встречи. Иванов, режиссер,
          маститый, такой Александр Гаврилович, с огромной фигурой, подошел ко
          мне и сказал: «Слушай, ты не напишешь мне музыку к военному фильму?» А
          я ни за что ни к военной, ни к коммунистической фильме музыку не
          писал. Я писал к любовным, каким угодно. Но чтобы я писал что-то
          такое… Даже если денег не будет — все равно не пишу. А он мне говорит:
          «Нет, эта картина настоящая. Это Виктор Платонович Некрасов, „В окопах
          Сталинграда“ — первая честная книга о войне».
        </>

        <>
          Виктор Платонович приходил ко мне часто, я ему импровизировал, и он
          меня научил писать музыку для кино. Именно он, а никто другой. Когда
          было отступление это его знаменитое — отступают, отступают, да
          отступают — я написал такую музыку, кстати говоря, в традициях
          Шостаковича, когда чувствуется душа народа, Родины и так далее. И
          эпос.
        </>
        <>
          А Некрасов сказал: «Не надо эпоса, пусть гнетет, гнетет, гнетет,
          гнетет». И я придумал четыре ноты, которые должны были повторять
          контрабасы минут десять, но так придумал, что когда они повторяются
          внутри говора отступающих, слякоти и грязи, то я понимал, что вместе с
          говором, будет жуткая тоска, жуткий гнет, жуткая каждодневщина. И
          действительно, потом так и происходило, когда на перезаписи микшером
          крутили: то отключали музыку, думали, ну зачем же эти контрабасы,
          ду-да-ду-да, — то включали. То как отключают — настоящая скука, то
          включают — настоящее или жуткое отступление.
        </>

        <Spacer half size={4} />
        <Carousel
          autoHeight
          slides={[
            { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/5a.jpg` },
            { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/5b.jpg` },
          ]}
        />
        <Spacer half size={4} />
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>музыка как продолжение шумов</SubTitle>
        <>
          Вот это навсегда стало моим приемом. Даже не приемом, а основой поиска
          музыки для кино. Я ее слышал всегда уже с шумами. И пишу только то,
          что не достает им.
        </>
        <>
          Я перестал писать музыку в том общепринятом смысле, в котором пишет
          весь мир для кино. Я, когда пишу музыку, если честно, даже валенки
          вкладываю в рояль, чем хуже звук, тем честнее. Потому что в кино
          сейчас занимаются звуками, звуком. Занимаются псевдомистикой.
          Занимаются своего рода переходом мистерии в шипучку.
        </>
        <>
          А вообще, написать музыку в кино — это перевести картину из обычной
          продукции в музейное измерение.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[A—B]</Link>
          </sub>
        </>
      </BlockCinema>

      <Spacer half size={10} />

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. александр иванов"
        name="«поднятая целина»"
        url={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/6.jpg`}
        year="1959–1961"
      />

      <Spacer half size={8} />

      <BlockCinema>
        <SubTitle>худсоветы. пахота. изгнание</SubTitle>
        <>
          Я сделал картину с Виктором Платоновичем Некрасовым и почувствовал
          себя большим профессионалом в этом деле. Я впервые понял, что надо
          делать. Потом я написал музыку в «Поднятую целину», за которую
          [Василий] Толстиков, будучи секретарем обкома, жутко меня разругал.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[A—B]</Link>
          </sub>
        </>
        <>
          На худсоветы я обычно не ходил, мне было наплевать, что они там трещат
          о моей музыке. Один раз пришел только на «Поднятую целину», и тут меня
          просто обхамили. Сидели эти жирные режиссеры, которые делали советские
          фильмы под диктовку советской власти.
        </>
        <>
          Я написал музыку к эпизоду пахоты, и тут выступил какой-то режиссер,
          не помню, [Иосиф] Хейфиц или [Фридрих] Эрмлер, сказал, что это не
          пахота, это Каравайчук гробит своей музыкой колхозное движение. Я
          защищал свою музыку, я врожденный ритор. Эрмлер вдруг сказал, что я —
          адвокат [Федор] Плевако, и дедушка мой был адвокат при Николае II.
          Такой вот с этой пахотой был скандал.
        </>
        <Spacer half size={4} />
        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/02_Поднятая целина_Пахота.mp3`}
        />
        <Spacer half size={4} />

        <>
          Меня пытался спасти [писатель Михаил] Шолохов. Он пришел на фильм и
          сказал: «Гениальная музыка. Это — не пахота, это — Давыдов хоронит
          свою любовь к Лушке». И хотя Шолохов сказал, что я написал гениальную
          музыку, все кончилось тем, что они меня выгнали с «Ленфильма». И я
          здесь не работал десять лет. Слонялся по разным студиям — в Одессе, на
          «Довженко», в Грузии, в Белоруссии. Везде я писал музыку к фильмам. А
          сюда меня не пускали, чтобы я не гробил колхозный строй.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[A—A]</Link>
          </sub>
        </>
      </BlockCinema>

      <Spacer half size={4} />

      <BlockCinema>
        <QuoteCinema>
          <SubTitle>предательство</SubTitle>
          <QuoteCinemaText>
            Виталий Мельников,
            <br />
            режиссер:
          </QuoteCinemaText>{" "}
          Был даже приказ по Госкино, запрещающий приглашать на «Ленфильм»
          композитора Каравайчука. Он должен был написать музыку к фильму
          «Поднятая целина». Фильм выпускали к важному юбилею. Как раз в день
          записи оркестра вдруг изменился порядок оплаты музыкантов. Прежде
          платили за время записи, и музыканты, не спеша, с перекурами,
          совершенствовали качество. Теперь им стали платить за количество
          записанной музыки, и все стали спешить и халтурить. Олег с презрением
          бросил им всем на пюпитры деньги из собственного кармана, забрал
          партитуру и удалился. За это он и был проклят с лишением права работы
          на «Ленфильме».{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[D—C]</Link>
          </sub>
        </QuoteCinema>

        <Spacer half size={1} />

        <Image
          alt="Олег Каравайчук"
          src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/7.jpg`}
        />
      </BlockCinema>

      <Spacer half size={10} />

      {/* ------------------------------------------------------------------ */}

      <BlockCinema black>
        <Freedom />
        <Spacer half size={8} />

        <SubTitle inverted>роковой концерт. стравинский</SubTitle>
        <>
          [В 1961 году] у меня был концерт, после которого я бисировал до трех
          ночи. Мне тогда было тридцать лет. До этого меня не выпускали.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link style={{ color: "#fff" }} to="/karavajchuk/source">
              [B—M]
            </Link>
          </sub>
        </>
        <>
          На концерте были [артисты балета Рудольф] Нуриев вместе с [Аллой]
          Сизовой, он пришел специально и просидел до глубокой ночи, когда я уж
          импровизировал на заданные темы. Когда раздался оглушающий взрыв
          аплодисментов, я невольно почувствовал, что нужно сказать, что не я
          это импровизировал, а что это гений Стравинского. Я сказал на весь
          зал, что Стравинский самый гениальный, самый величайший, самый
          потрясающий и трагичный композитор. И самый благородный человек. Это
          потому я сказал, что накануне было написано, что он белоэмигрант,
          такой-сякой и еще черт знает что.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link style={{ color: "#fff" }} to="/karavajchuk/source">
              [A—B]
            </Link>
          </sub>
        </>
        <>
          Потом все шли пешком. Там был [композитор Борис] Тищенко, был
          [композитор Валерий] Гаврилин. Они бегали по Консерватории после
          концерта и кричали: «Чему нас учат?» Ну, правильно, чему их учат?
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link style={{ color: "#fff" }} to="/karavajchuk/source">
              [B—M]
            </Link>
          </sub>
        </>
        <>
          После этого КГБ вызвало директора… вернее даже не КГБ — не такая уж
          страшная организация, кстати. Страшнее — Серебряков, это он пошел в
          КГБ. После этого туда директора Ленконцерта и вызвали, и тот умер от
          инфаркта. После Стравинского меня закрыли навсегда.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link style={{ color: "#fff" }} to="/karavajchuk/source">
              [A—B]
            </Link>
          </sub>
        </>
        <>
          За меня ходили все. Ходил [актер Владислав] Стржельчик. [Режиссер
          Георгий] Товстоногов ходил! А Серебряков Стржельчику сказал: «Ты
          понимаешь, я его слышал на госэкзамене. Я до сих пор эту музыку слышу.
          И я его не пущу».{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link style={{ color: "#fff" }} to="/karavajchuk/source">
              [B—M]
            </Link>
          </sub>
        </>
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. кира и александр муратовы"
        name="«у крутого яра»"
        url={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/8.jpg`}
        year="1962"
      />

      <Spacer half size={8} />

      <BlockCinema>
        <SubTitle>муратова</SubTitle>
        <>
          В общем, было так. Я в первый раз был приглашен Кирой Муратовой на
          студию имени Горького. Она там делала картину про волчат под названием
          «У Крутого Яра» со своим тогдашним мужем Александром Муратовым. Когда
          мы встретились и она мне рассказала про волчат, я увидел ее лицо. Оно
          было абсолютно. Я обожаю людей абсолютных. У нее глаза лучезарные
          абсолютно: абсолютная краска, абсолютная ясность, абсолютная
          тотальность. Я люблю тотальность. Я люблю тиранов. Не тех, кто
          научился быть ими на Земле, а родились такими, потому что они
          абсолютны, — в них сразу влюбляешься. […] Передо мной сидела лучезарно
          сияющая женщина — ее невозможно забыть… Чайковский говорил, что мало
          быть гением, важно еще быть великой натурой. Да, Муратова не только
          гениальна. Она главным образом еще и великая натура. Не великая —
          абсолютная. Последнее очень существенно. Только абсолютность спасает
          художника от проституции.
        </>
        <>
          Муратова и как режиссер абсолютна, пусть порой и косноязычна. Илья
          Авербах признавался мне, что не может делать такие фильмы, как она.
          Для этого надо быть совсем особенным человеком.
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>абсолютный вымысел — абсолютная правда</SubTitle>
        <>
          Кино — это ведь либо абсолютный вымысел, либо абсолютная правда.
          Вместе с тем, огромное количество картин делается где-то в промежутке
          между вымыслом и правдой. И получается болото, некая жижа для
          интеллигенции. Эта жижа проливается на экраны, хотя к искусству
          никакого отношения не имеет.
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>мелодия для фильма про волчат</SubTitle>
        <>
          Музыку мою Муратова поначалу не понимала, но одновременно почему-то
          жутко мне верила. Помню, я импровизируя сыграл Муратовой мелодию для
          фильма про волчат. Она ее не сразу восприняла. А вот когда соединили с
          изображением, то Кире очень, страшно понравилось. Но вмешался директор
          студии [Григорий] Бритиков: «Убрать, это не крематорий!» Меня подобная
          реакция не удивила.
        </>
        <>
          Такова была наша первая совместная работа.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[B—A]</Link>
          </sub>
        </>

        <Spacer half size={4} />
        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/03_У крутого яра.mp3`}
        />
        <Spacer half size={4} />
      </BlockCinema>

      <Spacer half size={10} />

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. петр тодоровский"
        name="«никогда»"
        url={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/9.jpg`}
        year="1962"
      />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>одесская студия 1960-х</SubTitle>
          <>
            <QuoteCinemaText>
              Петр Тодоровский,
              <br />
              режиссер:
            </QuoteCinemaText>
            Послевоенная Одесская киностудия влачила жалкое существование.
            Однако ее директору хватило фантазии и ума пригласить целый курс
            молодых режиссеров, только что окончивших Институт кинематографии,
            среди которых [Александр] Алов и [Владимир] Наумов, Хуциев. Таким
            образом, туда съехалось большое количество начинающих
            кинематографистов. В том числе там оказался и я, хотя был тогда еще
            оператором. Марлен Хуциев пригласил меня и моего однокурсника в
            качестве операторов на ставший потом знаменитым фильм «Весна на
            Заречной улице» [Марлена Хуциева и Феликса Миронера, 1956]. Это была
            замечательная жизнь. Своего рода ренессанс Одесской киностудии. Мы
            жили напротив киностудии в общежитии, где не было воды и света. Зато
            были долгие задушевные разговоры, совместные творческие искания. И
            длилось все это до тех пор, пока каждый не снял по первому фильму. В
            результате студия прогремела на весь Союз.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—A]</Link>
            </sub>
          </>

          <Spacer half size={14} />

          <SubTitle>«режиссером я стал совершенно случайно»</SubTitle>
          <>
            Собственно, режиссером я стал совершенно случайно. В портфеле
            Одесской киностудии лежал купленный сценарий поэта Григория Поженяна
            под странным названием «Никогда».{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—P]</Link>
            </sub>
          </>
          <Spacer half size={4} />
          <>
            В Николаеве на судостроительном заводе ему [Поженяну] рассказали
            историю директора Орешкина, покончившего с собой. Судьба этого
            человека так взволновала Поженяна, что он сел за сценарий и
            полностью себя «выписал». Новый сценарий долго пролежал в портфеле
            Одесской киностудии. Старикам свой сценарий он отдавать не хотел, а
            Хуциев уехал работать в Москву.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—Q]</Link>
            </sub>
          </>
          <Spacer half size={4} />
          <>
            По закону купленный сценарий подлежал реализации. На студии появился
            молодой, недавно окончивший институт кинематографии, режиссерский
            факультет, Владимир Дьяченко. Руководство студии решило именно ему
            поручить постановку фильма «Никогда». Для страховки — Дьяченко
            молодой, неопытный — предложили мне как опытному оператору
            участвовать в постановке вместе с Дьяченко в качестве сорежиссера и
            главного оператора.
          </>

          <Spacer half size={4} />
          <Image
            alt="Олег Каравайчук"
            src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/11.jpg`}
          />
          <Spacer half size={4} />

          <>
            Однако возникла проблема: как можно поручить постановку фильма
            недипломированному человеку, то есть мне. Директор студии Вилен
            Александрович Фёдоров, несмотря на наши дружеские отношения,
            громогласно заявил: «Мы имеем хорошего оператора, а какой из него
            режиссер — неизвестно».
          </>
          <Spacer half size={4} />
          <>
            Автор сценария Григорий Поженян — надо знать его характер —
            загорелся идеей тандема, улетел в Киев. Он стоял на коленях у
            замминистра кинематографии Украины, читал ему свои стихи, дарил свои
            книги и, в конце концов, выколотил разрешение на мое участие в
            постановке фильма «Никогда».{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—P]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={1} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>черноморская вольница</SubTitle>
          <>
            <QuoteCinemaText>
              Александр Шпагин,
              <br />
              киновед:
            </QuoteCinemaText>{" "}
            В Одессе в начале 1960-х годов сложилась вольница, туда старались
            попасть. Во-первых, знали, что там можно дебютировать, но пробиться
            было непросто, ты еще и отличником должен был стать. Общежитие, где
            были очень хорошие двухместные номера, прекрасная атмосфера, и все
            туда ездили как в Мекку, как в Академгородок, там и концерты свои
            были, там сложилась своя среда, которая сформировала сильный
            кинематограф начала и середины 1960-х. Каравайчука позвали, чтобы он
            там давал концерты, играл, а его бы подкармливали, он всем казался
            полубомжом.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—H]</Link>
            </sub>
          </>

          <Spacer half size={4} />
          <Image
            alt="Олег Каравайчук"
            src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/10.jpg`}
          />
          <Spacer half size={4} />
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={1} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>моя фамилия каравайчук</SubTitle>
          <>
            <QuoteCinemaText>Петр Тодоровский:</QuoteCinemaText> Не могу
            сказать, что он [Каравайчук] делал тогда в Одессе, но помню, что
            наше знакомство было забавным. Мы с Поженяном вернулись из Москвы и
            отправились на барахолку: я купил себе ратиновое пальто, шляпу, он —
            итальянское белье для жены, а вечером мы поехали гулять с актерами
            гастролировавшего в Одессе театра «Современник». В три часа ночи нас
            находят и говорят: «Вас обокрали». Ну и началось: в одну сторону
            бежит Евстигнеев за милиционером с собакой, Ефремов — в другую, все
            невменяемые — ведь мы уже хорошо «посидели», — в общем, ночь была
            бешеная. А утром, в себя еще не пришли, видим: сидит у нас во дворе
            какой-то странный человек в темных очках, в костюме, с беретом на
            голове — это в разгар лета! Вдруг он встает и идет за угол
            общежития, где как раз находится наше окно, из которого вытащили все
            вещи. Мы скорей звонить следователю. Он приезжает вместе с
            милиционером. Подходит к этому типу, спрашивает: «Вы что здесь
            делаете?» — «Сижу. Вон там общежитие, где я живу». — «А зачем за
            угол ходили?» — «Меня накормили арбузом, а туалет у нас не
            работает». — «Кто вы такой?» — «Композитор. Моя фамилия Каравайчук».
          </>
          <Spacer half size={4} />
          <>
            У нас [с Каравайчуком] сразу завязались дружеские отношения, хотя он
            человек на самом деле странный. Мы дали Каравайчуку почитать
            сценарий, ему показалось это интересным, и он согласился работать.
            Тянулось все долго, у нас были перерывы в съемках, мы дважды летали
            к Олегу в Ленинград, он показывал нам эскизы музыки. Главная тема
            нам сразу понравилась — она звучит с первых кадров, когда самолет
            идет на посадку.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—Q]</Link>
            </sub>
          </>
          <Spacer half size={4} />
          <StyledReactPlayer
            auto
            controls
            url={`${PUBLIC_URL}/assets/audio/karavajchuk/04_Никогда.mp3`}
          />
          <Spacer half size={10} />

          <SubTitle>картина об одиноком человеке</SubTitle>
          <>
            Мы всем говорили, что снимаем фильм о человеке, который полностью
            посвятил свою жизнь делу, и при этом знали, что на самом деле делаем
            картину об одиноком человеке, об одиночестве. Но по тем временам об
            этом нельзя было даже заикаться, иначе до запуска дело бы не дошло
            или картину «прикрыли» бы потом.
          </>
          <Spacer half size={4} />
          <>
            Нашу картину на студии приняли с легкой опаской, но, в принципе,
            спокойно. Акцент у нас был сделан на том, что герой —
            государственный человек, он хороший, а все, кто его окружает —
            плохие. Так буквально начальство и поняло картину. Как сценарий
            восприняли, так и на картину отреагировали. Хотя по походу дела в
            фильме много чего изменилось, нам пришлось какие-то сцены
            дорабатывать.
          </>
          <Spacer half size={4} />
          <>
            Картина прошла, совершенно не замеченная критиками. Фильму дали
            вторую категорию и сказали, чтобы мы были счастливы, потому что в
            Киеве все равно не понимают, зачем нужен такой директор, как Орешкин
            — с длинным носом и выпученными глазами.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—Q]</Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={4} />
        <Image
          alt="Олег Каравайчук"
          src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/12.jpg`}
        />
        <Spacer half size={4} />
      </BlockCinema>

      <Spacer half size={1} />

      <BlockCinema black>
        <Freedom />
        <Spacer half size={8} />
        <QuoteCinema inverted>
          <SubTitle inverted>одесса — ленинград — одесса</SubTitle>
          <QuoteCinemaText inverted>Александр Шпагин:</QuoteCinemaText>
          <>
            Каравайчук прижился в Одессе, на целых семь лет. Там понесся весь
            этот новый кинематограф, и, конечно за него зацепились. Он так вошел
            в тусовку, что в Ленинграде его практически не было. Конечно, он
            туда ездил, у него там была мама, квартира, театральные постановки и
            немереное количество никому не известных романов.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link style={{ color: "#fff" }} to="/karavajchuk/source">
                [C—H]
              </Link>
            </sub>
          </>
        </QuoteCinema>

        <QuoteCinema inverted>
          <QuoteCinemaText inverted>
            Галла Капицкая,
            <br />
            режиссёр:
          </QuoteCinemaText>
          <>
            Как ни странно, я на его картинах с ним вместе почти и не работала,
            только дружила. Мы познакомились в 1966 году, если говорить грубо,
            он меня просто склеил. Я сама была городским сумасшедшим: ходила в
            лаптях, длинных сарафанах, с корзиночкой. Я вызывала у него
            любопытство, а он у меня, поэтому мы с ним очень быстро сошлись.
          </>
          <Spacer half size={1} />
          <>
            Мы очень много времени проводили вместе — гуляли, он мне очень много
            рассказывал. Бывало, сидишь в Петропавловской крепости на травке, и,
            оказывается, прошло шесть часов, а ты сидишь и слушаешь. Мы
            разговаривали очень подолгу. В основном он, а я его слушала, потому
            что ему нужен был не просто кто-то, а тот, кто его выслушает. А мы
            же все-таки пятьдесят лет с ним просуществовали бок о бок.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link style={{ color: "#fff" }} to="/karavajchuk/source">
                [C—E]
              </Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={4} />

        <SubTitle inverted>ночной друг акимов</SubTitle>
        <>
          [Режиссер Николай] Акимов меня гениально рисовал и снимал. К тому же
          Акимов был мой ночной друг. Я обычно мало сплю — как Вагнер, часа
          четыре, и сочиняю по ночам. В три-четыре ночи Николай Павлович звонил
          мне, и так длилось десять лет [с 1958-го по 1968-й]. Все эти годы я
          писал музыку ко всем его спектаклям подряд. Но вот он уехал в Москву с
          театром на гастроли и неожиданно умер. Во сне. А я в то время писал
          музыку к его шекспировскому спектаклю [«Конец — делу венец»]. После
          родителей это, пожалуй, самая страшная моя потеря.
        </>
        <>
          Его не очень понимали при жизни, да и сейчас не очень ценят, а это был
          гениальный режиссер концепции. Они с Товстоноговым состязались.
          Товстоногов наяривал эмоцию. А Акимов давал такие решения озаренные. Я
          ему импровизировал — прямо на репетициях.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link style={{ color: "#fff" }} to="/karavajchuk/source">
              [B—P]
            </Link>
          </sub>
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>кира. профессиональные взаимоотношения</SubTitle>
        <>
          Потом Кира и Саша [Муратовы] жили в Одессе. Я обоих очень любил и,
          приезжая в их город, а меня туда часто вызывали писать разные картины,
          рано утром прямо с поезда бежал к Муратовым. Они были мне бесконечно,
          необыкновенно рады. И кошка Тишка — тоже. Но профессиональные дела
          наши были на нуле.
        </>
        <>
          Впрочем, профессиональные взаимоотношения всегда возникают между, так
          сказать, средними мастерами. Высокие, настоящие как-то это минуют. Вот
          так и Муратова: баламутно ей было бы заниматься со мной
          профессиональными заданиями, просто даже нелепо. Пошлятина! Уровень не
          тот. Ведь как обычно бывает? Режиссер стереотипно дает тему, и
          композитор что-то лудит. Не для нас это… Чтобы преодолеть шаблон, я
          должен был вывернуть все наизнанку, потому что меня постоянно загоняли
          в специфику профессиональных вопросов. Вообще, если подходить строго
          профессионально, вещь никогда не получится.
        </>
        <>
          Люди типа Муратовой, — я и сам такой, — обычно попадают во всякие
          ужасные перипетии. Нас обманывают и обкрадывают, с нами хитрят и
          жульничают. Спасает пренебрежение к житейским невзгодам и сознание
          своей призванности. Муратова помогла мне понять, что значит ясность.
          Кира — вся воплощенная ясность. А там, где гениальность и ясность,
          незачем вспоминать о профессии и мастерстве.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[B—A]</Link>
          </sub>
        </>
      </BlockCinema>

      <Spacer half size={10} />

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. владимир бычков"
        name="«город мастеров»"
        url={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/13.jpg`}
        year="1965"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>«средневековье — это я»</SubTitle>
        <>
          В этот момент я вдруг напоролся на ноты, которые в какой-то мере были
          близки тому, что я начал сочинять сам. Кстати говоря, такие вещи
          бывают. Поэтому, когда я увидел ноты расшифрованного средневековья, я
          понял, что это все дрянь, это все пустое место. Что современная
          расшифровка ничего общего [с той музыкой] не имеет. А Средневековье —
          это я. После этого я необычайно полюбил одного композитора. Для меня
          сейчас самый величайший, самый гениальный, самый абсолютный композитор
          — Гийом Машо.
        </>
        <>
          Одновременно у меня произошло полное охлаждение к филармонии, я
          перестал туда ходить, перестал ходить в оперные театры. Постепенно я
          понял, что я не хожу потому, что музыка приблизительно с конца XIX
          века уже вообще не существует. То есть классика «в исполнении», да и
          вообще последующие все новации в области неоклассики и неоромантизма,
          они точно так же относятся к классике, как мы к Адаму и Еве.
          Произошло, быть может, неизбежное — пресыщение. Так что получилось,
          что классики сейчас нет, а неоклассика — это не явление в искусстве.
          Это Адам и Ева в мини-юбках.
        </>
        <>
          Классическое целое, о котором нельзя писать музыковедческие трактаты,
          должно остаться, и дальше не вызывать ни малейших мыслей. Вот великая
          музыка, которая не вызывает никаких обсуждений, никаких толкований,
          кроме того, чтобы сказать, что она звучит.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[A—B]</Link>
          </sub>
        </>
      </BlockCinema>

      <Spacer half size={1} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>вольные мастера</SubTitle>
          <>
            <QuoteCinemaText>Александр Шпагин:</QuoteCinemaText> В 1960-х в
            Беларуси начинают снимать очень интересное кино, все благодаря
            заместителю председателя Госкино СССР Борису Павленку, который, как
            ни странно, создает свою фрондерскую киношколу вместе с Сергеем
            Скворцовым, знаменитым педагогом ВГИКа. Единственный национальный
            кадр — это режиссер Виктор Туров, остальных они приглашают. У них
            работают [Леонид] Нечаев, [Валерий] Рубинчик, [Ричард] Викторов. Они
            ищут талантливых студентов и находят [Владимира] Бычкова. Там
            складывается еще одна вольница, они и сами были похожи на вольных
            мастеров. Соответственно, судьба неизбежно вела Каравайчука к ним.
            Тем более в Беларуси было очень плохо с композиторами, они брали
            композиторов московских и ленинградских.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—H]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={1} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>одиннадцатый</SubTitle>
          <>
            <QuoteCinemaText>
              Владимир Бычков,
              <br />
              режиссер:
            </QuoteCinemaText>{" "}
            Автор музыки нашего фильма Олег Каравайчук был одиннадцатым из
            композиторов Минска, Москвы и Ленинграда, которым я предлагал
            работать над «Городом мастеров». Одни, более или менее добросовестно
            изучив сценарий, предлагали музыкальное оформление, повторявшее
            что-то уже знакомое, у других получалось нечто вроде конкретной
            музыки. Для всех десяти это был заказ. А у одиннадцатого задрожал
            голос в телефонной трубке, когда он услышал о моем предложении. Это
            был его предмет, его страсть.
          </>
          <>
            В тот же вечер я смог услышать речитативы, великолепные трудовые
            песни мастеров, гимны военных орденов — и все это были подлинники
            XIV–XV веков. А потом родилось и их талантливое переложение на звуки
            и ритмы наших дней.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—D]</Link>
            </sub>
          </>

          <Spacer half size={10} />

          <SubTitle>условность</SubTitle>
          <>
            От конкретных исторических дат с самого начала решено было
            отказаться. Но вот воссоздание обобщенного образа времени, в котором
            для каждого из нас синтезировались бы ассоциации, связанные с
            понятием «средние века», нам казалось делом обязательным.
          </>
          <>
            И тут пришлось задуматься над тем, что сегодняшний зритель весьма
            далек от того понимания природы вещей, прекрасного, представлений о
            сходстве или несходстве с натурой, которыми жил горожанин, скажем,
            средневекового Льежа. А нам обязательно хотелось создать на экране
            праздник гулкий, живописный.
          </>
          <>
            Но понятие праздника для каменщика или золотошвейки XV века одно, а
            для зрителя кинотеатра «Космос» совсем иное. Выход подсказала нам
            все та же милая сердцу условность, заложенная в самом жанре
            легенды-сказки. […] Нам нужна была та мера условного, которая
            позволяла обнаружить нормы современной эстетики в линиях,
            композициях, цветах далекого от нас времени.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—D]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={4} />
      <Carousel
        autoHeight
        slides={[
          { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/14.jpg` },
          {
            url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/14-dop.jpg`,
          },
        ]}
      />

      <Spacer half size={8} />

      <BlockCinema>
        <SubTitle>эоэо</SubTitle>
        <>
          «Город Мастеров» — там же грандиозный оркестр! Там гетерофония. Все
          играют разную музыку. Там такое было! Например, гетерофонный хор. Как
          они пели на латыни! Я их научил латинскому произношению, чтобы оно
          пело. Музыки там нету, но они говорят по-латински. Это страшно!
          Латинская речь вообще — это черная месса. Если по настоящему уметь
          петь, не поя, а произношая. Вот глубокое эоэо! Туда все уходит.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[B—M]</Link>
          </sub>
        </>
        <Spacer half size={4} />
        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/05_ГОРОД МАСТЕРОВ_УВЕРТЮРА.mp3`}
        />
      </BlockCinema>

      <Spacer half size={1} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>триумф</SubTitle>
          <QuoteCinemaText>Александр Шпагин:</QuoteCinemaText>
          <>
            С музыкой к фильму «Город мастеров» к Каравайчуку придет подлинная
            слава. Возникнет несколько статей о музыке в этой ленте — что для
            кинематографических изданий редкость редкостная. Происходит истинный
            триумф композитора — теперь о нем уже узнают все. […] Он напишет
            утонченно-хитренькие, слегка атональные стилизации под кельтскую
            музыку да ряд тягучих, но мелодичных баллад в старинном
            менестрельском жанре — такого авторского — да не просто авторского,
            а утонченно-стилизаторского музыкального подхода к фильму —
            советский кинематограф еще не знал.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[B—S]</Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={4} />
        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/06_ГОРОД МАСТЕРОВ_БОЙ.mp3`}
        />
        <Spacer half size={4} />
        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/06А ГОРОД МАСТЕРОВ | ФИНАЛ.mp3`}
        />
        <Spacer half size={4} />
      </BlockCinema>

      <Spacer half size={1} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <QuoteCinemaText>
            Петр Багров,
            <br />
            киновед:
          </QuoteCinemaText>{" "}
          После картины
          <>
            «Город мастеров» на Каравайчука положили глаз режиссеры
            экспериментальные, и Авербах, и Муратовы. Каравайчук стал вхож в
            приличные компании.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—B]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. кира муратова"
        name="«короткие встречи»"
        url={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/15.jpg`}
        year="1967"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>горе</SubTitle>
        <>
          Прошло время. Муратова позвала писать для «Коротких встреч». Помчался.
          Она улыбается: «Первым делом — на кухню!..» Кормила паштетом. Очень
          вкусный такой паштет. Паштет и кофе — любимые мои угощения. Я же
          обожал у нее на кухне сидеть.
        </>
        <>
          Кайфуем. Тут она и говорит: «Олежек, у меня негаданное и страшное
          горе. Господи, ты посмотри, что он прислал… — И показывает телеграмму
          от [актера] Станислава Любшина следующего содержания: «Кира, я не буду
          у тебя сниматься, потому что мне предложили роль в картине „Щит и меч“
          [Владимира Басова, 1967] — четыре серии. У тебя мало заработаю, а
          нужны деньги». Так, приблизительно, и было написано — в упор. Муратова
          в полном отчаянье: «Ты подумай, ты подумай!» Это звучало даже не как
          укор… Актер был отобран, наступил срок съемок — и такое!.. Раньше же
          все отмерялось, любая пролонгация оборачивалась скандалом — это ведь
          беда была!
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>захватила правдой</SubTitle>
        <>
          И тут ей явилась счастливая мысль взять на роль [Владимира] Высоцкого.
          По-моему, это был первый фильм, в который его пригласили серьезно
          сниматься. Пришлось ей заменить и героиню. Стала играть сама, да так
          изумительно, абсолютно. Не принимаю обычно актрис с таким нервом, с
          такой ртутной душевной подвижностью, с таким надчеловеческим обаянием
          и презрением одновременно. И, вместе с тем, умением прощать. Но она
          меня захватила правдой. Как и говорил: либо абсолютный вымысел, либо
          абсолютная правда.
        </>
        <Spacer half size={4} />
        <Image
          alt="Олег Каравайчук"
          src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/16.jpg`}
        />
        <Spacer half size={4} />
      </BlockCinema>

      <BlockCinema>
        <SubTitle>цыганка</SubTitle>
        <>
          Пора писать музыку. Помню, ходил по Гостиному двору в Ленинграде. Надо
          уже ехать в Одессу, а еще и не сочинял, — я в последнюю минуту только
          сочиняю. В Гостином же ко мне привязалась молодая цыганка: «Давай,
          погадаю!» Потом вгляделась: «Нет, — говорит, — не буду. У тебя очень
          печальные глаза». Я пошел прочь. Она следом, сует назад рубль, которым
          я позолотил ей руку. В сей момент, видно, цыганка соединилась с
          румынкой — Кирой Муратовой, и сочинилась прямо в нее музыка. Не в
          «Короткие встречи», именно в нее. Вот такая абсолютная получилась
          музыка.
        </>
        <>
          И я ее повез, считая, что Кира все у меня и примет. Я сыграл
          сочиненное и услышал: «Нет, это мне не подходит». С тем и уехал…
        </>
        <>
          Никогда не заключаю договоров, хотя много потом теряю. Всегда вперед
          показываю режиссерам эскизы. Если не понравятся, то могут и выкинуть.
          Так меня Петр Тодоровский выкинул из «Верности». Да меня столько
          выкидывали – жуть… Ну вот, уехал.
        </>
        <>
          И через месяц или полтора – звонок: Кира хочет, чтоб я посмотрел
          картину. Опять Одесса. Гляжу, Муратова уже подставила всю мою музыку
          совершенно самостоятельно, притом так, как и не предполагал никогда,
          притом так изумительно, что мне бы в жизни так и не догадаться!..
          Просто одно из лучших воспоминаний о том, как режиссер когда-либо
          распорядился совершенно свободно моей музыкой.
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>гонорар. мелодия без изъянов</SubTitle>
        <>
          Одновременно появился [актер] Владимир Высоцкий, напел песни. Потом
          очень обстоятельно, очень смешно со мной беседовали. Высоцкий говорил,
          что я ему должен гонорар за песни. Я с большим удовольствием ему
          ответил: «Да, да, да! Володька, песни — твои, конечно, — и гонорар…»
        </>
        <Spacer half size={4} />
        <Image
          alt="Олег Каравайчук"
          src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/17.jpg`}
        />
        <Spacer half size={4} />
        <>Тут подоспела музыкальный редактор.</>
        <>— Вы знаете, я видела, как вы показывали музыку. Это не сочинение…</>
        <>
          — Как не сочинение?.. Я же при вас сыграл мелодию. Абсолютная мелодия.
          Там абсолютная форма. Ни малейшей импровизации… Ну, просто вот — ваза,
          сразу получилась, точно у хорошего стеклодува. Ну, без изъянов. Я
          тридцать раз не пробую отливать вазу. Сразу видно: она есть — или я ее
          ломаю.
        </>
        <>— Да, но все равно платить вам не буду.</>
        <>
          И так за «Короткие встречи» мне не заплатили ничего. Потом, кажется, я
          получил за четыре минуты музыки, хотя там было ее очень много. Эта
          была одна из сенсаций в нашей среде: редактор, убедившись, что
          композитор легко творит, отказывал ему в вознаграждении. Кстати,
          Россини тоже сразу сочинил «Севильского цирюльника». Значит, это — не
          опера по такой логике?..
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>больше, чем ощущения в любви</SubTitle>
        <>
          Спустя какое-то время меня снова вызвали. Да, сделали полную запись с
          оркестром, включая финал и проходы. Немножечко по-другому уже звучало…
          Как ни старался, не мог повторить заключительный номер, как играл его
          в первый раз, а он Кире теперь очень нравился. Пришлось оставить
          черновик. Но к черновику я приписал такое там соло для трубы,
          виолончели и так далее.
        </>

        <Spacer half size={4} />
        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/07 КОРОТКИЕ ВСТРЕЧИ.mp3`}
        />
        <Spacer half size={4} />

        <>
          Минуло еще немного дней — и приходит из Одессы открытка: «Олежек,
          Олежек, ты знаешь, я когда слушаю твою музыку, то она мне больше
          нравится, чем ощущения в любви за всю мою жизнь. Муратова».
        </>
        <>
          Открытку эту храню до сих пор.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[B—A]</Link>
          </sub>
        </>
        <Spacer half size={4} />
        <Image
          alt="Олег Каравайчук"
          src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/18.jpg`}
        />
        <Spacer half size={4} />
      </BlockCinema>

      <Spacer half size={1} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>домашняя студия</SubTitle>
          <QuoteCinemaText>
            Борис Алексеев,
            <br />
            звукорежиссер:
          </QuoteCinemaText>
          <>
            Как только появились первые магнитофоны, он начал
            экспериментировать, тем более у него для этого были все условия. Он
            очень трепетно относился к процессу звукозаписи, старался найти для
            себя лучшие микрофоны и магнитофоны. Все, что было связано с
            фиксацией музыки, имелось у него дома. И конечно рояль, кабинетный
            Bechstein, легендарный инструмент какого-то музыканта. Он сам его
            купил, причем в комиссионном магазине. Именно он звучит в
            муратовских картинах, и в «Коротких встречах», и в «Долгих
            проводах». Записывал как эскиз, но в итоге это становилось лучшим
            вариантом.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—A]</Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={1} />

        <QuoteCinema>
          <QuoteCinemaText>Петр Багров:</QuoteCinemaText>
          <>
            После «Коротких встреч» с ним работают либо те, кто точно знают,
            чего хотят, либо те, кто хочет сделать нечто вычурное.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—B]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. владимир бычков"
        name="«житие и вознесение юрася братчика»"
        url={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/19.jpg`}
        year="1967"
      />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>бездарный фильм. важная работа</SubTitle>
          <QuoteCinemaText>Александр Шпагин:</QuoteCinemaText>
          <>
            На волне успеха каждый художник распушает перья и думает — сейчас я
            выдам. И замахнулись на Иисуса нашего Христа, в которого должен был
            перевоплотиться, — кто у нас самый любимый герой в средние века? В
            Скомороха.
          </>
          <Spacer half size={4} />
          <>
            Фильм очень плохо поставлен, потому что от большой радости к жизни
            Бычков на съемках пьет. Бывает, что и пьяные картины получаются
            удачными, но чаще не очень. Получилась совершенно разваленная
            картина, и это был еще один дополнительный аргумент, чтобы ее не
            выпустить. А главное — это, конечно, религиозная тема, картина
            вызывает на местном уровне жуткое раздражение у [главы ЦК компартии
            Беларуси Петра] Машерова: «Доигрались тут со своими притчами — полез
            Христос, известно, что Христос — это бабушкины бредни, никакого
            Христа не было, Бога нет на свете, и вообще почему Бог хороший
            здесь» и так далее. Картина пришлась не ко времени, нашла коса на
            камень. И ей не очень удалось защититься, она никому не нравилась,
            даже самому режиссеру.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—H]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={4} />
      <Carousel
        autoHeight
        slides={[
          { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/20a.jpg` },
          { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/20b.jpg` },
        ]}
      />
      <Spacer half size={4} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <QuoteCinemaText>
            Валерий Гаврилин,
            <br />
            композитор:
          </QuoteCinemaText>
          <>
            Вот у него такая штука — когда он делает абсолютно бездарный фильм,
            с его музыкой кажется, что картина гениальная.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[B—H]</Link>
            </sub>
          </>
        </QuoteCinema>
        <QuoteCinema>
          <QuoteCinemaText>Александр Шпагин:</QuoteCinemaText>
          <>
            Для Каравайчука это была важная работа, потому что здесь он впервые
            по-настоящему экспериментирует: и с народными песнопениями, в том
            числе белорусскими, и со средневековыми, использует народные
            инструменты, это такой гаврилинский вариант, выход в фолк, то, что
            потом будет использовано в «Драме из старинной жизни» [Ильи
            Авербаха, 1971].{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—H]</Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={4} />
        <Carousel
          autoHeight
          slides={[
            { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/21a.jpg` },
            { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/21b.jpg` },
            { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/21c.jpg` },
            { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/21d.jpg` },
            { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/21e.jpg` },
          ]}
        />

        <Spacer half size={4} />
        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/08 ЖИТИЕ И ВОЗНЕСЕНИЕ ЮРАСЯ БРАТЧИКА.mp3`}
        />
        <Spacer half size={4} />
      </BlockCinema>

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>«черная месса»</SubTitle>
          <QuoteCinemaText>Сергей Ландо:</QuoteCinemaText>
          <>
            В 1967 году Каравайчук работал на «Беларусьфильме» над картиной
            «Христос остановился в Гродно» — она же «Житие и вознесение Юрася
            Братчика», там он был не только композитором, но и снялся в роли
            Юродивого. Для этого фильма Каравайчук написал «Черную мессу». Фильм
            закрыли, не выпустили на экраны. Но «Черную мессу» использовал
            режиссер Анатолий Эфрос для спектакля «Ромео и Джульетта» [1970] в
            Театре на Малой Бронной. Причем сначала Эфрос не знал, чья это
            музыка, и поэтому на первых афишах имя композитора не было указано.
            Когда же Эфрос выяснил, что это музыка Каравайчука, то послал ему
            телеграмму: «Приглашаем на спектакль с Вашей музыкой».
          </>
          <>
            «Черная месса» стала чрезвычайно популярной, ее очень хорошо
            приняли, в том числе Дмитрий Шостакович и [писатель] Даниил Гранин.
            После этой музыки [режиссер] Виталий Мельников настоял, чтобы
            композитором на его фильме «Мама вышла замуж» [1969] был Каравайчук,
            и, таким образом, несмотря на сопротивление администрации,
            Каравайчук после нескольких лет «отлучения» вернулся на «Ленфильм».
          </>

          <Spacer half size={4} />
          <StyledReactPlayer
            auto
            controls
            url={`${PUBLIC_URL}/assets/audio/karavajchuk/09_Житие и вознесение_Черная месса.mp3`}
          />
          <Spacer half size={14} />

          <SubTitle>рождение в изгнании</SubTitle>
          <>
            Главное, что во время изгнания, во время странствий, он нашел свой
            стиль. Он нашел свою интонацию. Все, что будет потом — эти маленькие
            ансамбли, конкретная музыка, когда валенки в рояль засовывали,
            вместо сурдинок, презервативы использовали, и подпевания, и свист —
            это все оттуда. И все правда. Это началось именно в эти годы
            странствий.
          </>
          <Spacer half size={1} />
          <>
            Вместо того, чтобы быть нормальным композитором, который делал
            «Поднятую целину», он стал тем Каравайчуком, которого мы знаем.
            Родился в изгнании.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—F]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. виталий мельников"
        name="«мама вышла замуж»"
        url={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/22.jpg`}
        year="1969"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <>
          И вот тут меня спасали только евреи. Были такие художники — Белла
          Маневич и Исаак Каплан. Я с ними дружил, это были те, с кем можно было
          подумать. Они услышали у меня дома «Черную мессу», написанную для
          белорусского фильма «Житие Юрася Братчика». Даниил Гранин про эту
          музыку тогда сказал: «Это гениально, это Босх». С тех пор я понял, что
          я — Иероним Босх. Это было одно из ранних моих сочинений.
        </>
        <>
          Белла Маневич была восхищена и посоветовала режиссеру Мельникову взять
          меня на картину «Мама вышла замуж» [1969]. Она долго его убеждала,
          показала эту музыку, и он меня взял. [Юрий] Клепиков был сценарист, он
          очень хотел, чтобы я писал.
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>последняя зацепка</SubTitle>
        <>
          И опять начались скандалы. Никто мою музыку не понимал, будто бы она
          вычурная. А должна быть музыка простая. Мельников наставил там столько
          всякой грубой бытовой музыки, компиляции, что я ему все-таки сказал:
          «Вы и так сделали все банально, и это правильно, пусть будет, чем
          банальнее и пошлее, тем правильнее. Но моя музыка должна быть посреди
          этого капилляром. Иначе все бессмысленно». Опять был скандал. Но потом
          мы подружились, он все понял.
        </>
      </BlockCinema>

      <Spacer half size={4} />
      <Carousel
        autoHeight
        slides={[
          { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/23a.jpg` },
          { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/23b.jpg` },
          { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/23c.jpg` },
        ]}
      />

      <BlockCinema>
        <Spacer half size={4} />
        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/11 МАМА ВЫШЛА ЗАМУЖ | УВЕРТЮРА.mp3`}
        />
        <Spacer half size={2} />
        <>
          Мою музыку с первого раза и не должны принимать. Она вразрез с обычным
          человеческим, правильным мышлением. Если бы Бог создал человека все
          понимающим, то уже ни цветов, ни земли, ни небес уже бы не было. Бог
          правильно сделал человека полупридурком.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[A—A]</Link>
          </sub>
        </>

        <Spacer half size={4} />
        <Image
          alt="Олег Каравайчук"
          src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/24.jpg`}
        />
        <Spacer half size={4} />
        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/12 МАМА ВЫШЛА ЗАМУЖ | ГОРОД.mp3`}
        />
        <Spacer half size={4} />

        <QuoteCinema>
          <QuoteCinemaText>Александр Шпагин:</QuoteCinemaText>
          <>
            Клепиков рассказывал, что они создавали даже не лирическую, а
            максимально экзистенциальную драму. А их заставляли делать
            лирическую и оптимистическую картину, да еще цветную пленку
            навязали. Каравайчук был их последней зацепкой. Он не мог не
            появиться.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—H]</Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={4} />
        <Carousel
          autoHeight
          slides={[
            { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/25a.jpg` },
            { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/25b.jpg` },
          ]}
        />
        <Spacer half size={4} />

        <Image
          alt="Олег Каравайчук"
          src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/26.jpg`}
        />
        <Spacer half size={4} />
      </BlockCinema>

      <BlockCinema>
        <></>
        <QuoteCinema dots={false}>
          <SubTitle>одиозный и непредсказуемый</SubTitle>
          <QuoteCinemaText>Виталий Мельников:</QuoteCinemaText>
          <>
            Вспоминая об этой картине, я не могу не рассказать о человеке,
            который существенно ее обогатил. Это композитор Олег Каравайчук, с
            которым мы после «Мамы» сделали вместе еще несколько картин. В
            глазах начальства, Олег был личностью одиозной и непредсказуемой.
            Непременной частью его облачения были богемные береты. На
            полированной крышке рояля всегда стояли в ряд суповые тарелки с
            натянутыми на них для просушки беретами. Олег тщательно следил за
            тем, чтобы его головные уборы выглядели парадно. Из-под Олегова
            берета всегда ниспадали длинные волосы. Длинные волосы и бороды были
            почему-то особенно ненавистны начальникам тех времен. Борода у
            Олега, правда, росла не очень, но все равно, он вызывал подозрения.
          </>

          <Spacer half size={10} />

          <SubTitle>два килограмма парной говядины</SubTitle>
          <>
            Перед записью оркестра у Каравайчука иногда возникали
            экстравагантные требования. Однажды, записывая музыку к моей
            картине, в перечень ударных инструментов композитор включил два
            килограмма парной говядины. Каравайчука повезли на рынок, и он лично
            выбирал эту самую говядину. Оркестранты уже привыкли к его
            странностям, и ударник, заглядывая в ноты, деловито шлепал куском
            говядины по деревянной доске. Всем было интересно — Каравайчук
            добивался неожиданных звучаний и звуковых эффектов самыми
            необыкновенными способами. Несмотря на его странности и капризы, его
            любили. И любили его музыку, всегда привносившую нечто неповторимое,
            многозначное в каждый фильм.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[D—C]</Link>
            </sub>
          </>

          <Spacer half size={4} />
          <Image
            alt="Олег Каравайчук"
            src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/27.jpg`}
          />
          <Spacer half size={4} />
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={1} />

      <BlockCinema>
        <SubTitle>ночная капелла</SubTitle>
        <>
          Дальше произошло вот что — я сочинял, сочинял, сочинял. Мы довольно
          много записали музыки на магнитофон. Потому что я таскал магнитофон
          «Мелодия», по-моему так он назывался. Собирались с музыкантами
          Мравинского [из оркестра Ленинградской филармонии], они приходили
          поздно вечером, после концертов в Филармонии, и по ночам я с ними
          записывал музыку. Я ими дирижировал в зале Капеллы, и утром они
          уходили в семь часов утра. А ведь иногда приходили совершенно усталые
          после огромного концерта. Об этом пишет [волторнист Виталий]
          Буяновский в своих воспоминаниях.
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>душ шарко</SubTitle>
        <>
          Еще он пишет, что только я обладаю себе присущей дирижерской техникой,
          о том, что моя техника необычайно проста, написал все-все, что он
          чувствовал в те эти дни, когда находил наслаждение в этом
          музицировании. И мне как-то даже сказал, что моя музыка это не музыка,
          а душ Шарко: придешь усталый, а уходишь весь абсолютно… Это самое для
          меня дивное воспоминание — музыкант сказал, что это душ Шарко после
          Филармонии.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[A—B]</Link>
          </sub>
        </>
        <QuoteCinema>
          <QuoteCinemaText>Борис Алексеев:</QuoteCinemaText>
          <>
            Олег Николаевич был достаточно известной личностью в Ленинграде, он
            прекрасно знал всех музыкантов, музыканты знали его. Ему было легко
            собрать ансамбль для кино, тем более для каких-то своих
            экспериментов. И всегда это были самые лучшие исполнители, в
            основном солисты оркестра Мравинского. Их работу он оплачивал очень
            достойно. Музыканты в свою очередь его тоже весьма ценили, и им было
            всегда интересно с ним сотрудничать.
          </>
          <Spacer half size={4} />
          <>
            С конца 1960-х, когда Олег Николаевич стал работать в кино с малыми
            составами, в которых особую роль играли волторнист Виталий
            Буяновский, его ровесник, бывший в то время очень яркой звездой, и
            Вениамин Марголин, труба которого обладала особым, как тогда его
            называли — «русским» звуком, вероятно потому, что одним из его
            коронных номеров была «Поэма экстаза» Скрябина. Мравинский очень
            ценил его игру за яркость и блеск. Хотя сам Марголин относил понятие
            звука к нравственной и эстетической категории, говоря что главное в
            звуке — его истинная, идущая из глубин души, красота. Когда оркестр
            Мравинского в 1960-е годы впервые выехал на гастроли в Америку,
            знаменитое «марголинское» звучание трубы свело с ума и
            профессионалов, и простых зрителей. А молодые американские трубачи
            ездили за ним по городам США, чтобы еще раз услышать его услышать.
          </>
          <Spacer half size={4} />
          <>
            Эти два музыканта во многом определяли интонацию фильмов тех лет,
            вспомните трубу в «Секундомере» [1971], в картине «Мама вышла
            замуж», или знаменитый дуэт трубы с волторной в «Монологе».
          </>
        </QuoteCinema>

        <Spacer half size={4} />
        <Container>
          <Row>
            <Col lg={{ offset: 0, span: 6 }}>
              <Image
                alt="Олег Каравайчук"
                src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/28a.jpg`}
              />
            </Col>
            <Col lg={{ offset: 0, span: 6 }}>
              <Image
                alt="Олег Каравайчук"
                src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/28b.jpg`}
              />
            </Col>
          </Row>
        </Container>

        <Spacer half size={4} />
        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/13 СЕКУНДОМЕР | БАККАРА.mp3`}
        />

        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/14 МОНОЛОГ | ВАЛЬС.mp3`}
        />
        <Spacer half size={4} />

        <QuoteCinema>
          <SubTitle>абсолютный дирижер</SubTitle>
          <>
            Ноты были часто весьма условные, все больше в свободной форме. Олег
            Николаевич рассчитывал, что музыканты настолько высокого класса, что
            им будет достаточно просто дать намеки. А всем остальным руководил
            уже он сам. И из этого рождалась партитура в реальном времени.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—A]</Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={4} />
        <Container>
          <Row>
            <Col lg={{ offset: 12 }}>
              <Image
                alt="Олег Каравайчук"
                src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/29.jpg`}
              />
            </Col>
          </Row>
        </Container>
        <Spacer half size={4} />

        <>
          Я их учил импровизировать. У них были ноты, но я показывал им
          интервалы, паузы. Они играли по моим рукам. Поэтому моя музыка для
          кино — очень свободная.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[B—O]</Link>
          </sub>
        </>
        <>
          Я ведь абсолютный дирижер. Это никто не может, а мои руки могут. Это
          передается музыкантам.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[B—M]</Link>
          </sub>
        </>

        <Spacer half size={10} />

        <QuoteCinema>
          <QuoteCinemaText>Борис Алексеев:</QuoteCinemaText>
          <>
            Все записи музыки в основном происходили в Большом тон-ателье
            «Ленфильма» — БТА, с ним работал звукорежиссер Михаил Шмаров. Еще
            была Капелла, где по ночам арендовалась студия «Мелодия», был Белый
            зал на «Лендоке». Кроме того, Олег Николаевич еще много записывал
            дома, если для этого требовался просто рояль, делал там какие-то
            наброски.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—A]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. семен аранович"
        name="«люди земли и неба»"
        url={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/30.jpg`}
        year="1969"
      />

      <Spacer half size={8} />

      <BlockCinema>
        <SubTitle>«лендок»</SubTitle>
        <>
          Эта студия, «Ленхроника» [Ленинградская студия документальных фильмов]
          выпускала каждую неделю по киножурналу, он шел перед картиной в
          кинотеатре. Это было предвоенное время. Журнал начинался под
          «Торжественную увертюру» Глазунова. Кино было всегда битком. И у всех
          людей поддерживался оптимизм благодаря киножурналам. Люди понимали,
          что будет война и страна выдержит. Вот что такое значит это место.
        </>
        <>
          Когда шла война, непрерывно выпускали журналы с фронта. И когда шла
          блокада, то тут их продолжали выпускать. В блокаду! И в блокаду
          создали великолепный фильм «Блокада Ленинграда» [«Ленинград в борьбе»
          Романа Кармена, Валерия Соловцова, Ефима Учителя и Николая
          Комаревцева, 1942]. Там музыка Шостаковича была, компиляция, я помню.
          Когда засыпает город, гениальное место в Пятой симфонии.
        </>
        <>
          Все делали журналы. Делали великолепно. Там иногда бывала моя музыка,
          с восьми лет я туда попадал. Нашли мою съемку, мне там одиннадцать, я
          играю сонату Грига. В киножурнале — умение подать день, неделю.
          Мировоззрение.
        </>
      </BlockCinema>

      <Spacer half size={8} />

      <BlockCinema>
        <SubTitle>белый зал</SubTitle>
        <>
          После войны Хроника продолжала выпускать журналы. Но одновременно на
          Хронике, в этом зале писали музыку для «Ленфильма», так как там не
          было нормального тон-ателье. Или в Капелле, или в этом Белом зале. Так
          что в этом месте осуществлялась культура в целом, тут слышался пульс
          времени
        </>
      </BlockCinema>

      <Spacer half size={8} />

      <BlockCinema>
        <SubTitle>отважная студия</SubTitle>
        <>
          Надо еще вспомнить, что Хроника была отважной. Тут был директор
          [Валерий] Соловцев, бывший актер, и когда на «Ленфильме» [режиссеру
          Александру] Сокурову не давали ничего снимать, он его здесь приютил
          [на самом деле — Владилен Кузин, занимавший пост директора с 1971-го
          по 1996-й]. И Сокуров сделал здесь несколько великих фильмов.
        </>
        <>
          На Хронике создавал отважные фильмы [режиссер Семен] Аранович. Про
          поэта [Анну] Ахматову, когда про Ахматову нельзя было. Потом великий
          фильм про [Юрия] Гарнаева, это был герой-летчик. Я к нему писал
          музыку. И еще несколько фильмов, для которых я тоже писал музыку.
        </>
        <>
          Хроника делала то, что не проходило почему-то на «Ленфильме». Не
          обязательно сразу выпускать. Но вот дать сделать!.. Мне хочется
          поклониться и постоять перед этим залом.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[A—A]</Link>
          </sub>
        </>
      </BlockCinema>

      <Spacer half size={1} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>аранович. одиночество летчика</SubTitle>
          <>
            <QuoteCinemaText>Юрий Клепиков:</QuoteCinemaText> Взрослую жизнь
            Аранович начинал штурманом военной авиации. Однажды случилась
            авария. Остался в живых. Учился во ВГИКе у Кармена. Много снимал.
          </>
          <Spacer half size={4} />
          <>
            Аранович обладал обостренным чутьем к документу. Удивляло его
            знакомство с начинкой фильмохранилищ. Как опытная архивная крыса, он
            знал, где искать нужный сухарик. Поэтому он и был одним из лучших
            наших документалистов. В его неигровых фильмах можно увидеть
            сложные, тонко выстроенные структуры и композиции, превращающие факт
            в художественный образ.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—H]</Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={4} />
        <Container>
          <Row>
            <Col lg={{ offset: 3, span: 6 }}>
              <Image
                alt="Олег Каравайчук"
                src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/31.jpg`}
              />
            </Col>
          </Row>
        </Container>
        <Spacer half size={4} />

        <>
          Аранович был очень смелый человек, необычайно смелый. Он тюрьмой
          рисковал. Он мне рассказывал, как он снимал [похороны] Анны Ахматовой,
          и как они там перекрывали и дурачили кагэбэшников.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[A—F]</Link>
          </sub>
        </>

        <Spacer half size={10} />

        <QuoteCinema>
          <QuoteCinemaText>
            Ирина Павлова,
            <br />
            киновед:
          </QuoteCinemaText>
          <>
            Если воспользоваться названием этой картины, то обнаружится, что эти
            слова вбирают в себя едва ли полностью круг его интересов в
            документалистике: «Люди земли и неба».
          </>
          <Spacer half size={4} />
          <>
            Фильм о летчике Гарнаеве, погибшем во Франции, при тушении
            гигантского пожара в окрестностях Канна, Аранович построил на
            дневниковых записях героя. Но вновь перед зрителем возникает не
            только облик и судьба человека, которого уже нет. Возникает —
            нередко в контрапункте к изображению — душа этого человека. Много
            позже, уже знаменитым и титулованным режиссером, он рассказал
            иностранному журналисту, что ему всегда хотелось рассказать об
            «одиночестве летчика в полете».{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[D—A]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={1} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>как надо</SubTitle>
          <QuoteCinemaText>Александр Шпагин:</QuoteCinemaText>
          <>
            Изначально музыку к фильму «Люди земли и неба» написал Владлен
            Чистяков. Аранович пытался ее подложить, но ничего не получалось:
            это не то и это не то. Музыка не та, она не идет фильму. Она
            написана, записана с оркестром, она будет оплачена, но… Что делать?
            Вызвали Каравайчука.
          </>
          <Spacer half size={4} />
          <>
            Включили большой экран в Белом зале Ленинградской студии
            документальных фильмов, стали показывать то, что получилось. Тот
            почти сразу: «Остановите, это все [говно]! Смотрите как надо».
            Дальше садится к роялю, играет и насвистывает — и все! Больше ничего
            не будет, это будет повторяться.
          </>
          <Spacer half size={4} />
          <StyledReactPlayer
            auto
            controls
            url={`${PUBLIC_URL}/assets/audio/karavajchuk/15 ЛЮДИ ЗЕМЛИ И НЕБА.mp3`}
          />
          <Spacer half size={4} />
          <>
            Все спрашивают: «Кто это?» А это композитор Олег Каравайчук! Просто
            взял и насвистел, а все сказали: «О, это, действительно, то, что
            нужно». Ну и все, естественно, что после этого Аранович за него и
            зацепился.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—H]</Link>
            </sub>
          </>

          <Spacer half size={4} />
          <Container>
            <Row>
              <Col lg={{ offset: 3, span: 6 }}>
                <Image
                  alt="Олег Каравайчук"
                  src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/32.jpg`}
                />
              </Col>
            </Row>
          </Container>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. петр тодоровский"
        name="«городской романс»"
        url={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/33.jpg`}
        year="1970"
      />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <QuoteCinemaText>
            Александр Полынников,
            <br />
            оператор:
          </QuoteCinemaText>
          <>
            У Тодоровского картины все очень музыкальные, хотя музыкальных
            номеров нет, но само построение, ритм картины, в этом какая-то
            музыкальность есть. То, что редко встречается в режиссуре. В работе
            с актерами он больше внимания уделял ритмике, каким-то нюансам,
            пластике, нежели просто их игре.
          </>

          <Spacer half size={10} />
          <StyledReactPlayer
            auto
            controls
            url={`${PUBLIC_URL}/assets/audio/karavajchuk/16 ГОРОДСКОЙ РОМАНС | ГИТАРА.mp3`}
          />
          <Spacer half size={10} />

          <SubTitle>снег в черёмушках</SubTitle>
          <>
            Он всегда любил зиму. И почти во всех картинах у Тодоровского есть
            зима. Она обязательно присутствует, это белое, все наши грехи
            покрыты белым, оно как бы уравнивает и сближает людей. Он мне
            говорит: «Давай, бери камеру, завтра съемочного дня нет, ты езжай с
            ребятами и поснимай просто пейзажи, снежок в Москве».
          </>
          <Spacer half size={4} />
          <>
            Ну и вот мы со съемочной группой ездили в Черёмушках, там, кстати,
            [актер Зиновий] Гердт жил, Высоцкий, Гена Шпаликов. И я когда
            приехал, сначала не мог разобраться: а чего тут интересного? А для
            Тодоровского, он же часто бывал у них в гостях, и этот район знал.
            Каждый уголок у магазина знал, для него это было живое. И вот задача
            была оживить этот не очень выразительный по архитектуре район, чтобы
            все это одухотворилось. И пургу мы снимали, и включали ветродуи,
            чтобы в этой зиме и холоде люди все равно находили и согревали друг
            друга. Ну вот про это и кино собственно.
          </>
          <Spacer half size={10} />
          <StyledReactPlayer
            auto
            controls
            url={`${PUBLIC_URL}/assets/audio/karavajchuk/17 ГОРОДСКОЙ РОМАНС | РОЯЛЬ И ГОЛОС.mp3`}
          />
          <Spacer half size={10} />
          <Carousel
            autoHeight
            slides={[
              { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/34a.jpg` },
              { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/34b.jpg` },
              { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/34c.jpg` },
            ]}
          />
          <Spacer half size={4} />

          <></>
          <>
            Фильм Одесской киностудии, но снимали в его Москве, в Одессе снега
            нет, а в Питере он не тот. Там мокро, влажно, сырой снег, грязный. А
            в Москве все-таки успевал полежать снег до того, как его почистят.
            Главное — снег. Во-вторых, актеры. Тодоровский не очень любил
            ленинградских актеров. Он не любил Ленинград, любил Москву. И [тут]
            всегда были под рукой актеры, техника, если надо на «Мосфильме», на
            студии Горького.
          </>

          <Spacer half size={10} />

          <SubTitle>музыкальные встряски</SubTitle>
          <>
            Тодоровский сам же музыкальный человек, у него и песни были, на
            гитаре играл потрясающе. Ему хотелось, чтобы в картине были какие-то
            музыкальные встряски, не просто такая лирическая музыка, что вижу,
            то и играю. Можно ведь и без музыки обойтись. Зачем музыка, если и
            так пейзаж хороший? А вот если под такой пейзаж вдруг какой-нибудь
            барабан зазвучит — то сразу другое настроение. То есть музыка меняет
            восприятие изображения. Большинство картин тогда у нас музыка
            иллюстрировала, а не добавляла к ним что-то или переворачивала. А
            вот Каравайчук, со своей хорошей положительной шизой, он мог
            поломать это. И там есть такие моменты.
          </>

          <Spacer half size={4} />
          <StyledReactPlayer
            auto
            controls
            url={`${PUBLIC_URL}/assets/audio/karavajchuk/18 ГОРОДСКОЙ РОМАНС | ФЛЕЙТА И СТРУННЫЕ.mp3`}
          />
          <Spacer half size={4} />
          <StyledReactPlayer
            auto
            controls
            url={`${PUBLIC_URL}/assets/audio/karavajchuk/18А ГОРОДСКОЙ РОМАНС | ОРГАН И ТРУБА.mp3`}
          />
          <Spacer half size={10} />
          <Image
            alt="Олег Каравайчук"
            src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/35.jpg`}
          />
          <Spacer half size={10} />

          <SubTitle>мирей матьё</SubTitle>
          <>
            Есть несколько музыкальных тем в картине, неожиданных,
            пронзительных, но все равно Тодоровский хотел, чтобы было еще более
            пронзительно. У него и самого какая-то музыка была. Я думаю, что он
            своего не добился, и поэтому вставил модную на то время песню Мирей
            Матьё. И под эту песню я наснимал всяких снежных пейзажиков,
            сугробиков, людей в снегу. У Мирей Матьё эта песня про снег. С одной
            стороны в ней какая-то надежда, с другой — безысходность, женская
            тоска по счастью. То, чего не хватало в музыке Каравайчука.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—R]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. резо эсадзе"
        name="«секундомер»"
        url={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/36.jpg`}
        year="1970"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>резо</SubTitle>
        <>
          И один из великих режиссеров на «Ленфильме» — это Резо Эсадзе. Он был
          очень талантлив, очень дружил с Динарой Асановой, его «Ленфильм», как
          и меня, вышвырнул. И он уехал в Тбилиси.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[A—A]</Link>
          </sub>
        </>
        <QuoteCinema>
          <QuoteCinemaText>Галла Капицкая:</QuoteCinemaText>
          <>
            В картине Резо Эсадзе «Секундомер» Олег играл букиниста, он
            специально для него роль прописал. Олег — такая птичка, он там весь.
          </>
          <Spacer half size={1} />
          <>
            Резо Эсадзе очень любил Олега, и хотел снять с ним картину по
            Герберту Уэллсу, новеллу «Чудесное посещение». Кто-то из иностранных
            классиков снял эту картину, но она получилась очень жесткая и
            грубая, а они хотели сделать очень нежную. Олег должен был играть
            Ангела, который сошел на Землю.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—E]</Link>
            </sub>
          </>
        </QuoteCinema>
        <Spacer half size={4} />
        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/19 СЕКУНДОМЕР | ВАЛЬС.mp3`}
        />
      </BlockCinema>

      <Spacer half size={10} />

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. илья авербах"
        name="«драма из старинной жизни»"
        url={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/37.jpg`}
        year="1971"
      />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>аура нового кино</SubTitle>
          <QuoteCinemaText>Сергей Ландо:</QuoteCinemaText>
          <>
            Каравайчук вспоминал, что в какой-то момент на «Ленфильме» появились
            новые режиссеры: [Динара] Асанова, Авербах, Аранович — чуть
            постарше, Мельников. Возникла аура нового кино и новой жизни. Что-то
            там витало такое… Энергия «ленфильмовского» психодрома, запах
            сигарет, кофе, разговоры про кино.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—F]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema black>
        <Freedom />
        <Spacer half size={8} />
        <QuoteCinema inverted>
          <SubTitle inverted>гуру</SubTitle>
          <QuoteCinemaText inverted>
            Михаил Петров,
            <br />
            физик:
          </QuoteCinemaText>
          <>
            В толпе богемно-поэтической молодежи, [Авербах] формально был никем,
            и в общепринятом смысле, и в тогдашней своеобразной иерархии этой
            среды. […] Среда эта состояла из поэтов, литераторов, бардов,
            художников, фотографов и прочих творческих личностей, вплоть,
            кажется, даже до композиторов додекафонической музыки, официально не
            признанной.
          </>
          <Spacer half size={4} />
          <>
            Авербах занимал особое положение в этой среде. Он только что окончил
            медицинский институт, но врача из него не получилось, попытался
            сочинять стихи, но из этого тоже ничего не вышло. Какое-то время он
            выступал как спортивный журналист, но довольно неудачно. И все же не
            заметить его было невозможно.
          </>
          <Spacer half size={10} />
          <>
            У него, несомненно, было его собственное уникальное амплуа. Он, по
            своей сути, был гуру, наставник. То и дело слышалось: «Авербах
            сказал… Авербах оценил… Авербаху не понравилось…» Это бросалось в
            глаза.
          </>
          <Spacer half size={10} />
          <>
            Надо сказать, что Авербах имел все данные для такого амплуа. Он был
            очень умен, образован, остроумен, саркастичен, обладал
            безукоризненным вкусом, свободно ориентировался в мировой культуре,
            к тому же он был красив особой мужской красотой, высок ростом,
            атлетически сложен. Внешне он был очень похож на культового актера
            Жана-Поля Бельмондо, только что появившегося на экранах в закрытых
            кинопросмотрах.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link style={{ color: "#fff" }} to="/karavajchuk/source">
                [E—L]
              </Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={10} />

        <QuoteCinema inverted>
          <SubTitle inverted>незнайка</SubTitle>
          <QuoteCinemaText inverted>Александр Шпагин:</QuoteCinemaText>
          <>
            Каравайчук бравировал, что не читал ни одной книжки, но, судя по его
            образованности, — он часто сыпал всевозможными цитатами, это не так.
            А почему он бравировал, что знание не нужно, догадываюсь. Гений по
            его мнению должен брать какие-то смыслы из воздуха, от Бога, ему не
            нужны для этого знания, ему нужно вдохновение и проникновение. А
            знание — это в Знайку превращаешься из Незнайки. Ну и, конечно, это
            спор с советской властью, которая в отличие от сегодня заставляла
            человека знать и знать очень-очень много.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link style={{ color: "#fff" }} to="/karavajchuk/source">
                [C—H]
              </Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={10} />

        <>
          Живу как коза. Книжек не читаю. И ничего абсолютно не читал, все из
          себя.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link style={{ color: "#fff" }} to="/karavajchuk/source">
              [A—E]
            </Link>
          </sub>
        </>

        <Spacer half size={10} />

        <QuoteCinema inverted>
          <QuoteCinemaText inverted>Сергей Ландо:</QuoteCinemaText>
          <>
            Я неоднократно убеждался в том, что Олег Николаевич очень умный и
            эрудированный человек, с какой-то совершенно ненормальной памятью. У
            него не было телефонной книги, он все номера помнил наизусть. И в
            любой момент мог продиктовать номер, не ошибаясь. Я уже не говорю о
            его феноменальной музыкальной памяти.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link style={{ color: "#fff" }} to="/karavajchuk/source">
                [C—F]
              </Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={10} />

        <QuoteCinema dots={false} inverted>
          <QuoteCinemaText inverted>Галла Капицкая:</QuoteCinemaText>
          <>
            Я у Олега дома кроме нот и рояля не особо что-то из книг и видела.
            Но он был энциклопедически образован, мог разговаривать с кем
            угодно, и никогда бы не проиграл в беседе. Причем речь у него была
            совершенно дивная и легкая, никаких слов-паразитов.
          </>
          <Spacer half size={1} />
          <>
            Это, конечно, воспитание родителей. Папа работал у нас на студии до
            какого-то времени. Олег потом очень долго ходил в папином пальто.
            Олег вообще очень странно одевался, ему было абсолютно все равно.
          </>
        </QuoteCinema>

        <Spacer half size={10} />

        <QuoteCinema inverted>
          <SubTitle inverted>специально</SubTitle>
          <>
            Он ходил как рвань. В коричневом свитере, которому было лет сто.
            Какие-то портки. И все это в коричневой гамме жуткой. Обувь какая-то
            немыслимая. Как-будто папины вещи донашивал, впечатление, что с
            чужого плеча. Хотя он мог бы себе купить, но это для него не было
            важным. Мог прийти в одном черном, другом желтом ботинке и ответить:
            «Не обращай внимание, я специально». Берет уже он у меня слизал, я
            думаю. Ну или я у него, я так же ходила.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link style={{ color: "#fff" }} to="/karavajchuk/source">
                [C—E]
              </Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={10} />

        <QuoteCinema inverted>
          <>
            <QuoteCinemaText inverted>Сергей Соловьёв:</QuoteCinemaText> Он
            тщательнейшим образом относился к своему костюму. Это тоже чушь
            какая-то и мещанская отвратительная легенда, что ему было плевать в
            чем ходить.
          </>
          <Spacer half size={1} />
          <>
            Его действительно лет пять или восемь не пускали в поезд «Красная
            стрела», […] потому что весь вагон был набит бюрократическим
            крысятником в одинаковых костюмчиках, либо двубортных, либо
            однобортных. В одинаковых рубашечках, в одинаковых галстучках. И
            запустить туда Олега Николаевича в том виде, в котором он
            существовал, а другого вида у него просто не было, это значило
            нарушить все законы хорошего бюрократического тона. Потому что более
            бюрократического состава поезда, чем поезд «Красная стрела», в
            советские времена просто не существовало. И его туда не пускали.
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              {" "}
              <Link style={{ color: "#fff" }} to="/karavajchuk/source">
                [A—K]
              </Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={10} />

        <QuoteCinema inverted>
          <SubTitle inverted>твидовый пиджак</SubTitle>
          <QuoteCinemaText inverted>Михаил Петров:</QuoteCinemaText>
          <>
            Авербах был элегантен. Первый твидовый пиджак, увиденный мною не в
            трофейном кинофильме, а в реальной жизни, был именно на нем.
            Непостижимо, откуда мог тогда взяться тот пиджак. Среди паствы
            Авербаха поношенный джемпер финского туриста, купленный у
            фарцовщиков, или драная отцовская летная куртка времен войны были
            верхом элегантности.
          </>
          <Spacer half size={4} />
          <>
            Он одновременно привлекал и отпугивал. К нему относились не только с
            уважением, но и с долей подобострастия. Его побаивались. Даже
            Бродский слегка робел перед ним. А Бродский был парнем не из робких,
            это могут подтвердить все, кто знал его в то время.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link style={{ color: "#fff" }} to="/karavajchuk/source">
                [E—L]
              </Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={10} />

        <QuoteCinema inverted>
          <SubTitle inverted>любимый рассказ матери</SubTitle>
          <QuoteCinemaText inverted>
            Андрей Юрьев,
            <br />
            писатель:
          </QuoteCinemaText>
          <>
            Семья Авербаха принадлежала к узкому кругу петербургской
            интеллигенции высшей пробы. Илья Александрович унаследовал от
            родителей любовь к изящной словесности. «Драма из старинной жизни»,
            экранизация рассказа Николая Лескова «Тупейный художник» — один из
            любимых рассказов матери режиссера — Ксении Александровны. В
            бытность актрисой она с успехом читала его со сцены. Потомственная
            петербурженка, выпускница Смольного института, некоторое время
            числилась во вспомогательной труппе Большого драматического.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link style={{ color: "#fff" }} to="/karavajchuk/source">
                [E—S]
              </Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>страшная картина</SubTitle>
          <QuoteCinemaText>Александр Шпагин:</QuoteCinemaText>
          <>
            После первой картины «Степень риска» [1968] Авербаху как-то удалось
            пробить то, что он сам хотел ставить. Начальство чувствует, что
            пришел какой-то «не наш», который предлагает сценарий один за
            другим, и долго ничего не получается. «Драма из старинной жизни» —
            вещь как бы вынужденная. Он одним из первых сообразил, что нужно
            впрыгнуть в экранизацию. Уникальная картина, страшная, и это гораздо
            сильнее Лескова. Фильм совершенно сдвинутый, обогнавший свое время.
            То есть нечто, появившееся из ниоткуда. Сумасшедшие глаза артистки
            Елены Соловей под какую-то совершенно бешенную музыку. Никто кроме
            Каравайчука с этой задачей справится не мог.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—H]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>экран и маленькие иероглифы</SubTitle>
          <QuoteCinemaText>Галла Капицкая:</QuoteCinemaText>
          <>
            Сценарии он обычно не читал, ему просто показывали материал на
            экране. И, оговаривалось, для каких именно сцен нужна музыка. Олегу
            всегда легко работалось, мне так казалось во всяком случае. Вы
            можете себе представить: Авербах ему транслирует материал на экран,
            а он может тут же сидеть и подбирать музыку мгновенно, и она
            ложилась так, как надо. Стоит инструмент, он играет, и это все
            записывается.
          </>
          <Spacer half size={1} />
          <>
            Принцип работы был всегда одинаковый: экран, и вот этой трубочкой
            [стержнем от ручки] он записывает свои маленькие иероглифы.
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema black>
        <Freedom />
        <Spacer half size={8} />
        <QuoteCinema inverted>
          <SubTitle inverted>шпион</SubTitle>
          <QuoteCinemaText inverted>Галла Капицкая:</QuoteCinemaText>
          <>
            Он ходил с трубочкой от ручки обыкновенной, доставал клочок бумаги и
            писал свои иероглифы. Это был его личный способ записи, а потом он
            все уже расшифровывал на ноты.
          </>
          <Spacer half size={4} />
          <>
            Бывало он так стоит на улице, записывает, и милиция его забирает.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link style={{ color: "#fff" }} to="/karavajchuk/source">
                [C—E]
              </Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={10} />

        <QuoteCinema inverted>
          <QuoteCinemaText inverted>Александр Шпагин:</QuoteCinemaText>
          <>
            Он часто попадал в милицию, он любил заходить в поисках музыки в
            телефонную будку, видимо ему там очень нравилось — можно уединиться
            — и начинал сочинять там, его оттуда выгоняли, потому что возникала
            очередь. Он начинал матом орать, тут его и брали милиционеры.
          </>
          <Spacer half size={1} />
          <>
            По словам близких к нему режиссеров, [Саввы] Кулиша и Тодоровского,
            паспорт Каравайчук не носил, а носил сберкнижку, где было дикое
            количество денег, гонорары за фильмы, ведь он писал музыку к
            двум-трем картинам в год. И в милиции не понимали кто это такой. Ему
            эта ситуация очень нравилось, и так было не один раз.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link style={{ color: "#fff" }} to="/karavajchuk/source">
                [C—H]
              </Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={10} />

        <>
          В советское время я был настолько чужероден на улицах, что не мог
          выйти без паспорта. Всюду требовали документы. Причем и в Киеве, и в
          Минске. А в Ленинграде я вообще ходить не мог. Причем, что самое
          смешное, не всегда милиционеры, а какие-нибудь дедушки, дети-пионеры
          шли за мной, показывали пальцами и кричали: «Вон дядя-шпион!»{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link style={{ color: "#fff" }} to="/karavajchuk/source">
              [B—C]
            </Link>
          </sub>
        </>
      </BlockCinema>

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>вне шаблона</SubTitle>
          <QuoteCinemaText>Александр Шпагин:</QuoteCinemaText>
          <>
            [В фильме «Драма из старинной жизни»] Каравайчук очень интересно
            работает, сильно расходится в разные стороны, создает огромную
            партитуру из номеров совершенно разных стилей, не похожих на его
            прежние работы. Музыка конца XVIII века была очень неоднородна: с
            одной стороны, крайне немелодична и непонятна для нашего уха, но
            своеобразна, с другой — появляются такие мастера, как Матвей
            Березовский и композиторы екатерининского двора. Но именно с этими
            немелодическими мелосами, c этими ритмами, Каравайчук и
            экспериментирует, именно они его больше интересуют, хорошо их
            изучив, он постоянно их использует.
          </>
          <Spacer half size={4} />
          <StyledReactPlayer
            auto
            controls
            url={`${PUBLIC_URL}/assets/audio/karavajchuk/20 ДРАМА ИЗ СТАРИННОЙ ЖИЗНИ.mp3`}
          />
          <Spacer half size={4} />
          <>
            Картина выбивается из всех рамок, она настолько шокирующая, что на
            нее в тот момент практически нет рецензий, никто не знает, как к ней
            относиться. Надо было показывать прошлое время, и тут непонятно, где
            подвох. С одной стороны, вроде бы все по схеме — бедный народ
            помещики мучают, но в то же время все абсолютно вне этого шаблона.
            Ты проваливаешься в какую-то жуткую бездну. Тут что-то вообще
            отдельное, это не походит ни левым, ни правым, эта картина просто
            ломает мозг. Но если она сделана для того, чтобы сломать мозг
            программно, продуманно, выверено — кто должен быть? Конечно,
            Каравайчук.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—H]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={4} />
      <Carousel
        autoHeight
        slides={[
          { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/38a.jpg` },
          { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/38b.jpg` },
          { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/38c.jpg` },
        ]}
      />
      <Spacer half size={4} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <QuoteCinemaText>Петр Багров:</QuoteCinemaText>
          <>
            Козинцев говорил: «Если тебе нужно странное кино — бери
            Каравайчука».{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—B]</Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={10} />

        <QuoteCinema>
          <QuoteCinemaText>Галла Капицкая:</QuoteCinemaText>
          <>
            Я работала на «Короле Лире» у Козинцева помрежем. В функции входило
            встречать-провожать актеров и причастных к картине. Как-то я
            провожала и встречала Шостаковича, и была такая сцена. Кто-то из
            противных людей в группе, зная, что я дружу с Олегом, и меня уколоть
            желая, ведь его действительно воспринимали как сумасшедшего, спросил
            Шостаковича: «Неужели, он такой талант?» А Шостакович очень редко
            улыбался, он так посмотрел на этого пигмея мерзкого и говорит:
            «Каравайчук не талант, он — гений». Это я слышала своими ушами.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—E]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. кира муратова"
        name="«долгие проводы»"
        url={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/39.jpg`}
        year="1971"
      />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>пять вариантов</SubTitle>
          <QuoteCinemaText>
            Наталия Рязанцева,
            <br />
            сценарист:
          </QuoteCinemaText>
          <>
            Знатоки кино всегда ценили этот фильм и недоумевали: «За что? За что
            его сняли с экрана? За что казнили в печати?» Казнили, впрочем,
            как-то бережно и гуманно. Одним махом отрубили и больше не
            упоминали. Ни один из серьезных критиков, насколько помню, не
            покусился на эту картину, а Кире Муратовой на много лет запретили
            ставить кино.
          </>
          <Spacer half size={4} />
          <>
            Эта работа начиналась легко, впрочем, иначе никто бы ничего не
            начинал. Помню момент полный ясности: мы уезжали с моря, мой муж
            режиссер Илья Авербах сказал: «Кидай монету, Бог ведает, когда еще
            сюда попадем». И в самолете я от начала до конца проговорила про
            себя заявку, которая начиналась так: «Прощайся, — сказал отец, — Бог
            знает, когда еще сюда попадем».
          </>
          <Spacer half size={4} />
          <>
            Я ее записала в один день, на четырнадцати страницах. […] Заявка
            понравилась. И без промедления, с подозрительной легкостью была
            принята. […] Я радовалась, что сценарий такой гладкий, чувствовала
            себя отличницей: благополучный быт, ни жаргона, ни драк,
            традиционное построение сюжета. Я добросовестно сделала три
            варианта. Болтала воду в ступе: то улучшала, то ухудшала сценарий, и
            он был принят «Мосфильмом», послан на утверждение. И вдруг — полный
            отказ.
          </>
          <Spacer half size={4} />
          <>
            Корень сюжета всегда уходил в тему неисчерпаемую, неразрешимую.
            Юноша, рвущийся из своего круга, из детства, где все ему стало не по
            нраву, и тесным, и глупым. Про это писали и будут писать до
            скончания века, а ум чиновников пуще всего боится вечных тем. Его
            самодовольство не позволяет помыслить о трагизме человеческого
            существования и верит, что проинструктирует человека по всем
            вопросам, и все будет в порядке.
          </>
          <Spacer half size={4} />
          <>
            Тот раунд борьбы продолжался еще год. Четвертый вариант, поправки,
            еще поправочки. А потом и мне пришлось побывать в начальственных
            коридорах. […] Один меня пожурил по-отечески, прочел нотацию, а
            другой вдруг закричал: «Мы вам не позволим противопоставлять народ и
            интеллигенцию! Это не ваши умные мальчики войну выиграли, а народ!»
            […] Вышла я от Владимира Евтихианыча Баскакова, бывшего тогда
            зампредом комитета по кинематографии СССР, с заледеневшим
            позвоночником и с чувством какого-то зазеркалья, кривизны
            пространства и времени. Потом я проанализирую это профилактическое
            запугивание. […] Он вылечил меня от склонности к самоуничижению, я
            стала думать: «Значит, в этом сценарии что-то есть. И во мне что-то
            есть. Раз такой начальник на меня так кричит».
          </>
          <Spacer half size={4} />
          <>
            Сценарий тем временем закрыли и списали. Я постаралась про него
            забыть.
          </>
          <Spacer half size={14} />
          <SubTitle>второй раунд</SubTitle>
          <>
            С Кирой Муратовой мы давно дружили, но тут нас свела безнадежность.
            У нее закрыли очередной сценарий, у меня тоже дотлевали в поправках
            два сюжета. И в 1969 году начался второй раунд в Одессе. После
            некоторых стратегических маневров, поисков сторонников и защитников,
            после короткой артиллерийской подготовки на сверхрасширенном
            заседании в Одессе, шестой, уже вместе с Кирой собранный, вариант
            сценария вновь был пропущен сквозь строй равнодушных людей.
          </>{" "}
          <Spacer half size={4} />
          <>
            Подозрение, однажды запущенное, — опять-таки сюжет из нашей истории.
            Превосходящие силы противника устали видеть Муратову в министерских
            владениях в Гнездиковском [переулке, где находилось Госкино] и после
            месячной ее осады нанесли сокрушительный удар: «Что вы тут все
            ходите? Неужели гордости нет, унижаться тут из-за бумажки?» А
            заключение не подписали. Тут, наконец, Муратова пренебрегла своей
            гордостью и обратилась к учителю — [режиссеру Сергею] Герасимову. И
            он быстро уладил дело на самом верху, так сказать, поручился.
          </>{" "}
          <Spacer half size={4} />
          <>
            А кто мог предсказать холеру в Одессе? А ведь без этого карантина не
            было бы, наверное, и фильма, потому что его закрыли после проб. Киев
            снова проявил бдительность. Помню, Кира встретила меня на аэродроме
            и хохочет, аж пополам перегибается: «Нас закрыли! А что, плакать?
            Кричать — грабят, растаскивают наши лучшие годы?» Нет. Снова сидим
            втроем с редактором, поливаем линолеум в маленькой Кириной квартире.
            Жаркое лето, душные ночи, стучим на машинке, хохочем, выполняя
            седьмую обязательную поправку.
          </>{" "}
          <Spacer half size={4} />
          <>
            Бедный наш сценарий, временно переименованный в «Быть мужчиной» для
            полной маскировки, попадает в омут каких-то неведомых нам
            вышестоящих должностных распрей. Однако, отправляем на всякий случай
            поправки.
          </>
          <Spacer half size={4} />
          <Image
            alt="Олег Каравайчук"
            src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/40.jpg`}
          />
          <Spacer half size={14} />
          <SubTitle>карантин</SubTitle>
          <>
            Тут грянул холерный карантин. Все. Не только наша картина, все
            закрыто, весь город закрыт. Долго я выбиралась тогда из Одессы,
            поставив окончательно крест на этом сценарии.
          </>{" "}
          <Spacer half size={4} />
          <>
            А поздней осенью в опустевшем городе Муратовой удалось каким-то
            чудом приступить к съемкам. Это чудо производственной необходимости,
            она вдруг сломала все запреты. Сезон, конечно, был упущен, артисты
            мерзли, изображая лето, но именно благодаря холерному опустению,
            развалившему планы студии, Кире удалось сотворить картину, которую
            она же и окрестила так удачно — «Долгие проводы».
          </>{" "}
          <Spacer half size={4} />
          <>
            Я могла бы подробно вспомнить, как мы ссорились и спорили на
            последнем этапе при монтаже. Истина в тех спорах не рождалась.
            Напрасны были мои продуманные советы или попытки что-то исключить
            или добавить. И горючие слезы, и обиды. «Зачем ты меня тут держишь,
            если все равно все делаешь по-своему?»{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—O]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>первая категория</SubTitle>
          <QuoteCinemaText>Александр Шпагин:</QuoteCinemaText>
          <>
            Муратова сделала все вопреки всем правилам. Рязанцева была в ужасе,
            потому что для нее это был психологический интеллигентский фильм с
            внутренней фрондой, а Муратова сделала безумие. Она вспоминает, что
            ехала в поезде в Москву и проплакала всю дорогу, благо ехала одна в
            СВ и плакать можно было спокойно.
          </>{" "}
          <Spacer half size={4} />
          <>
            А дальше картину привозят в Москву, и на худсовете она получает
            первую категорию. Голосование было всегда тайное, из нормальных
            людей. Первая категория ничего не означала, кроме оплаты. Все хорошо
            получили, а дальше картина выходит на экран, и как только власти
            видят, что она проваливается у зрителей, говорят: проваливается,
            потому фильм ужасающий.
          </>
          <Spacer half size={10} />
          <SubTitle>непознаваемое</SubTitle>
          <>
            Как только картине дали первую категорию, Рязанцеву все начали
            поздравлять, как автора гениального фильма. Она стала помалкивать.
            Потому что раньше, как она говорила, у нее, была одна задача: чтобы
            эту картину смыли, чтобы ее никто никогда не видел.
          </>
          <Spacer half size={10} />
          <>
            Вот примерно такое ощущение было и у Советской власти. Если читать
            протоколы этих круглых столов, там никто ничего не может
            сформулировать, просто начальство все бесит и все несут ахинею: в
            фильме, дескать, никто не улыбается и не шутит. И тут же ставят в
            вину: в картине все постоянно улыбаются, шутят и хохочут.
          </>
          <Spacer half size={10} />
          <>
            Где непознаваемое — там, конечно, и Каравайчук рядом.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—H]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>абсолютная красота</SubTitle>
        <>
          Третьими были «Долгие проводы» — третьими у нас с Кирой,
          удивительнейшая работа, которая меня потрясла.
        </>
        <>
          Помню, под утро приехал, всю ночь мучился бессонницей, и начали мне
          показывать материал. Он настолько меня всего как-то… со мной какие-то
          чудеса стал вытворять — вывесил, что ли… Так и происходят в реальности
          чудеса… Тут-то я окончательно убедился: Муратова — абсолютная красота,
          самая негуманистическая дама, с неба сошедшая.
        </>
        <>
          Повторюсь: гуманисткой и демократкой абсолютная красота не может быть.
          Бог создал любовь для того, чтобы прекрасное не по Чарльзу Дарвину
          отбиралось, не путем каких-то гешефтов по принципу взаимной
          полезности, не путем каких-то там расовых предпочтений, а по страсти.
          Лицо — это не результат отбора. Это абсолютность, то есть
          Божественность. Не могу лучше объяснить… и ненавижу теоретиков,
          журналистов, потому что они пытаются осмыслить то, что мы не имеем
          права трогать, абсолютно нельзя трогать и нельзя над этим
          задумываться.
        </>

        <Spacer half size={4} />
        <Image
          alt="Олег Каравайчук"
          src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/41.jpg`}
        />
        <Spacer half size={4} />

        <>
          Тому, чем владеет Муратова, никто научиться не может, а вот, наоборот,
          утратить все — очень легко, достаточно только потерять натуру. Гений
          тогда гибнет сразу!..
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>пророк</SubTitle>
        <>
          Муратова не мудрствует. Она, даже когда позднее мыслительное стала
          кино делать, когда я уже не подходил ее картинам, потому что моя
          музыка иного рода, была беспощадна к своей ясности, ее картины совсем
          другие, чем у аналитиков наших или западных, препарирующих все
          страшное, нечистоплотное, омерзительное и гнусное, что водится на
          земле. Уникальность Муратовой не в том, что она откровенно про себя
          говорит: «Я тронутая». Может, не мне об этом судить, но как режиссер
          Муратова очень здорова, абсолютно нормальна.
        </>
        <>
          Изменения в кино и музыки в нем, если попытаться угадать будущее,
          видимо, пойдут по пути, который избрала Кира Муратова. Вот она —
          настоящий пророк, без мистики.
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>на одной ноте </SubTitle>
        <>Но возвращаюсь к «Долгим проводам» — совершенно незабываемым.</>
        <>
          Обычно написанное сдаю по телефону. Меня понимают, потому что
          предлагаю не какие-то там звуковые вещи, которые зависят от
          оркестровки. Моя музыка через любую трубку, на самом поганом монтажном
          столе забирает абсолютно: таков порядок нот…
        </>
        <>
          Кира после просмотра объяснила, что в «Долгих проводах» не будет
          музыки. А должно быть… на одной ноте что-то такое, потом немножечко
          полная тишина — и опять немного вот такого, да. Разговор происходил,
          как всегда на кухне. Ночью.
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>болеро</SubTitle>
        <>
          В Одессе в тот год случилась холера. Многие эвакуировались морем. А я
          остался. Стою на другой день на трамвайной остановке невыспавшийся —
          до утра ворочался после разговора с Кирой, все думал, заболею или не
          заболею, и вдруг сочинилось болеро. Сразу, конечно, не понял, что это
          болеро… А когда подложили по всей картине, то возникло некое
          сногсшибательное ощущение. Помните, там чайки?.. Они точно обратились
          в звуки… В монтажной находилась Наташа Рязанцева. Так она просто
          обалдела!.. Да и мне страшно понравилось, как это все легло.
        </>

        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/21 ДОЛГИЕ ПРОВОДЫ | БОЛЕРО.mp3`}
        />

        <>
          А потом, поскольку Муратова все-таки реалистка, то есть не реалистка —
          она ищет в кино не чистой поэзии — я тоже не выношу поэзии в кино, —
          то ей показалось, что моя музыка слишком все-таки… свободна от
          какой-то публичности.
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>западный образец</SubTitle>
        <>
          Да, кстати говоря, вот что интересно: «Долгие проводы» совершенно не
          понимали одесские студийцы, абсолютно. Дирекция студии жутко
          невзлюбила «Долгие проводы», считая, что это болезненная,
          патологическая и так далее, эротичная — и черт его знает что! —
          картина. В общем, западный образец. Я побежал тогда, помню, к
          директору после просмотра — директор довольно мило ко мне относился,
          хотя и считал смурным, — и выпалил: «Это гениальное кино!» Он на меня
          посмотрел такими вот глазами…{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[B—A]</Link>
          </sub>
        </>

        <Spacer half size={4} />
        <Image
          alt="Олег Каравайчук"
          src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/42.jpg`}
        />
        <Spacer half size={4} />
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>без музыки</SubTitle>
        <>
          Муратова почему-то не хотела моей музыки в «Долгих проводах» и
          оставила ее очень мало, заменив шумной компиляцией. По каким причинам,
          не знаю, но сохранилось только-только начало… Я довольно сильно
          пережил это. Особенно жаль было болеро. Оно, действительно,
          замечательно! И главное — Кира отказалась записывать его с оркестром —
          единственная возможность услышать себя тогда. […] Муратова в чем-то
          таинственна. Нежелание ее писать с оркестром музыку для «Долгих
          проводов» так и осталось загадкой. В итоге – моего там весьма мало, к
          тому же все сыграно на рояле, в эскизном варианте.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[B—A]</Link>
          </sub>
        </>
      </BlockCinema>

      <Spacer half size={10} />

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. савва кулиш"
        name="«комитет 19-ти»"
        url={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/43.jpg`}
        year="1971"
      />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>на грани бактериологической войны</SubTitle>
          <QuoteCinemaText>
            Варвара Арбузова-Кулиш,
            <br />
            жена режиссера:
          </QuoteCinemaText>
          <>
            Савву [Кулиша] всегда волновали проблемы незащищенности человечества
            от катастроф, порожденных людьми, желающими овладеть земным шаром.
            Он знал, что человечество стоит на грани бактериологической войны,
            рассказывал нам, что в Африке существует более 1400 официальных и
            тайных лабораторий, работающих над этой проблемой. Что нельзя не
            обращать внимание на столь чудовищные опыты с бактериями чумы,
            сибирской язвы, черной оспы… Что нужно всеми способами бороться с
            этой напастью.
          </>
          <Spacer half size={4} />
          <>
            Сценарий фильма «Комитет 19-ти» был ответом на эти вопросы. Савва,
            как сценарист и режиссер-постановщик, с головой окунулся в этот
            водоворот проблем, о которых даже рассказать было невозможно,
            настолько они были чудовищны.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[D—B]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>повторил свою музыку </SubTitle>
        <>
          Почти в то же самое время для «Комитета 19-ти» Савва Кулиш заказал мне
          болеро. И вот тут наступил момент, который часто бывает у нас,
          композиторов, — проклятый момент, когда мы не можем сразу что-то
          сделать во второй раз. [Поэт Алексей] Апухтин, к примеру, предложил
          Чайковскому после Шестой симфонии написать реквием, но Петр Ильич
          никак этого не мог, потому что чувствовал: в Шестой уже есть реквием.
        </>
        <>
          Кулиш предлагал огромный состав оркестра — все возможности. Как
          устоять человеку, который лишен права исполняться?.. Все мои концерты
          были закрыты. Мне не разрешали — главное — даже самому играть свою
          музыку. А дирижера для нее и исполнителя я до сих пор никак не найду.
          Причина, видимо, в том, что моя музыка очень трудно вписуема, она без
          тактов — точных тактов нет, поэтому даже часто не знаю, как ее
          изобразить на нотной бумаге.
        </>
        <>
          И вот в той ситуации мне дают оркестр. И, конечно, вот так, я не сумел
          создать новое болеро. И черт меня дернул написать на ту мелодию
          совершенно новую партитуру. В «Комитете 19-ти» она была широко
          развита, представлена в самых разных вариантах. Вот потом [режиссер]
          Лео Оскарович Арнштам, которому очень не понравились «Долгие проводы»,
          и сказал мне по телефону: «А ведь вы повторили свою музыку.
          Берегитесь… У этой дамы железная рука… монтажная».
        </>

        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/22 КОМИТЕТ 19 | ХРОНИКА.mp3`}
        />
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>настоящая нота</SubTitle>
        <>
          Шуберт тоже порой писал сначала песню, а потом превращал ее в квартет.
          Есть мелодии, что сами просятся звучать в двух-трех вариантах. А
          Дебюсси? А Бах? Последний продавал свою музыку по пять раз: сначала
          клавир для детей, потом какая-то пьеса, — он чувствует, что она не для
          клавесина, абсолютно ее как бы в реальности нет, и переписывает для
          хора…
        </>
        <>
          Да, случаются мелодии, которые все время в тебе копошатся. Какие-то…
          порядок нот, ощущаешь, неправильно выстроен, внешность не та — и нет
          на земле таких инструментов, чтоб это реализовать. Валенки засовываешь
          в рояль — против лишней акустики, потому что музыка значительно шире,
          чем инструментальный набор, и комбинация оркестровая сплошь и рядом —
          это муть, она только приблизительно воссоздает задуманное. Настоящей
          ноты и настоящего звука вообще на земле не бывает, как ни химичь, как
          ни хитри!..{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[B—A]</Link>
          </sub>
        </>
        <>
          Только условно ты можешь на столе простучать свою мелодию, достигая
          полной адекватности, потому что прели пальцы, в них есть что-то между
          суставами. Парапсихология ни при чем. Это проникает в человека, и он,
          не слыша реального, того, что реализовано как бы, все равно улавливает
          прямо из пальца — мимо уха, из пальца — в свои кости некие доли.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[B—J]</Link>
          </sub>
        </>
      </BlockCinema>

      <Spacer half size={18} />

      <BlockCinema black>
        <Freedom />
        <Spacer half size={8} />
        <QuoteCinema inverted>
          <SubTitle inverted>быт</SubTitle>
          <QuoteCinemaText inverted>Варвара Арбузова-Кулиш:</QuoteCinemaText>
          <>
            Среди Саввиных друзей незаурядным человеком был композитор Олег
            Каравайчук. Он никогда не останавливался в гостинице, потому что, по
            его словам, «там жили одни большевики». В поездах ездил только в
            плацкартном вагоне, а не в СВ по той же причине. Его часто
            арестовали на вокзалах за внешний вид. Он ходил в странном пальто,
            которое сшила его мама, в берете, с всклокоченном чубом и безумно
            горящими глазами. Олег написал музыку к нескольким Саввиным фильмам.
            Жил он у нас дома. Так как не мог прикасаться к еде, которую делали
            большевики, он покупал буханку бородинского хлеба, ломал ее и оттуда
            выковыривал мякоть. Олег заставлял меня варить ему тыквенную кашу,
            ел непрерывно чеснок и спал на полу на мохнатом болгарском ковре.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link style={{ color: "#FFF" }} to="/karavajchuk/source">
                [D—B]
              </Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={10} />

        <QuoteCinema inverted>
          <QuoteCinemaText inverted>Сергей Ландо:</QuoteCinemaText>
          <>
            Питался Олег Николаевич, казалось бы, крайне просто. Он не стал бы
            есть борщ в столовой или кусок мяса, но хорошие бананы, свежая
            капуста, клубника, которая пахнет клубникой, такая, которая с
            ароматом… Выпить если, то только чуть-чуть ликера «Биттнер». Не
            помню, чтобы он был вегетарианцем, но мясо он не любил. И хлеб: не
            то чтобы он зашел в первую попавшуюся булочную, нет-нет, вот в той
            булочной хороший хлеб ароматный, только его.
          </>
          <Spacer half size={4} />
          <>
            Он спал на твердом. У него было много историй о том, как он спал.
            Например, Варвара Арбузова-Кулиш рассказывала, что он спал в ванной.
            Отказался спать, как все люди, ему понравилось в ванной.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link style={{ color: "#FFF" }} to="/karavajchuk/source">
                [C—F]
              </Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={10} />

        <>
          Я сплю на полу или на досках, на сене, на старом матрасе. Когда спишь
          на сене — это здоровая упругость, природное, божественное воздействие
          на человека. Поэтому у меня здоровый организм. Если бы я спал на
          поролоне, то уже умер бы, наверное.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link style={{ color: "#FFF" }} to="/karavajchuk/source">
              [B—P]
            </Link>
          </sub>
        </>
        <Spacer half size={4} />
        <>
          Я принадлежу к тому типу ненормальных людей, которым ничего не мешает.
          Абсолютно. То есть я настолько сильно внутри сам по себе. Мне не нужно
          благодати, чтобы куда-то выехать и там творить. Мне только нужно,
          чтобы вовремя нашлась какая-то бумажка, чтобы записал и потом
          вспомнил, вот это мне важно.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link style={{ color: "#FFF" }} to="/karavajchuk/source">
              [B—G]
            </Link>
          </sub>
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>гробы, кости и презервативы</SubTitle>
          <QuoteCinemaText>Варвара Арбузова-Кулиш:</QuoteCinemaText>
          <>
            Во время первой записи музыки к фильму «Комитет 19-ти» Каравайчук
            бегал по металлической стружке в носках. Строго отбирал для работы
            музыкантов из оркестра самого Мравинского. Как ударные инструменты
            использовал гробы. Надувал презервативы, и, подвешивая их, бил по
            ним, извлекая разные звуки.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[D—B]</Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={4} />
        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/23 КОМИТЕТ 19 | УВЕРТЮРА.mp3`}
        />
      </BlockCinema>

      <BlockCinema>
        <></>
        <QuoteCinema>
          <></>
          <QuoteCinemaText>Галла Капицкая:</QuoteCinemaText>
          <>
            Для каких-то картин ему нужен был рояль особенного звучания, и мы
            покупали валенки, их разрезали, прокладывали между струн, как ему
            надо. Всякое бывало. Кости были для перекатывания на разных
            поверхностях, чтобы был стук. Чего только не было, вот только все
            время из-за этого на него злились.
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={4} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle> презерватив, натянутый на кремль</SubTitle>
          <>
            С «Комитетом 19-ти» была очень интересная история. Он заказал триста
            презервативов в ту-то пору. Администратор была возрастная женщина и
            говорит мне: «Галла, ну попросите его, чтобы я купила не
            презервативы, а шарики. Что обо мне подумают? Да и в какой аптеке
            триста презервативов лежит?» Я ему говорю: «Алечка, ну что же ты
            пожилую даму просишь купить триста презервативов? Пусть тебе шарики
            купят». А он отвечает: «Нет, мне моя музыка в этой картине
            напоминает презерватив, натянутый на Кремль». Музыканты их надували,
            делали определенные движения, которые он показывал, чтобы
            производить нужные звуки, типа писка.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—E]</Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={4} />
        <Image
          alt="Олег Каравайчук"
          src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/44.jpg`}
        />
      </BlockCinema>

      <Spacer half size={4} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle> ку-ку на красной площади</SubTitle>
          <QuoteCinemaText>Виталий Мельников:</QuoteCinemaText>
          <>
            Однажды на Красной площади произошла попытка самосожжения. Весь
            последующий день площадь была под особым вниманием агентов ГБ — мы
            ведь всегда были крепки задним умом. И вот, на главной площади
            столицы появился длинноволосый странный человек в легкомысленном
            берете и пальто-балахоне. Его попросили предъявить документы. В
            ответ человек пропел фальцетом: «Ку-ку!» — было у Олега такое
            шутливое приветствие, а паспорта он с собой никогда не носил.
            Каравайчука взяли под локотки и повели в особое помещение под ГУМом.
            Там неизвестного обыскали и нашли у него скомканный телеграфный
            бланк. В телеграмме было написано: «Просим срочно прибыть на
            переговоры. Комитет 19-ти». У Каравайчука стали сразу же спрашивать
            про этот комитет, но Каравайчук заявил, что он свободный человек и
            не желает ни перед кем отчитываться. Тогда его повезли в другой
            комитет — по слухам — на Лубянку. Еще бы! Накануне было
            самосожжение, а теперь вот объявился еще и таинственный комитет.
            Только к вечеру выяснилось, что Каравайчука пригласили писать музыку
            для фильма под названием: «Комитет 19-ти» и просили явиться на
            переговоры.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[D—С]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>кулиш</SubTitle>
        <>
          Мне важно, чтобы при записи музыки в зале никого не было, чтобы
          режиссер ушел. Например, Кулиша вынужден был выгонять, хотя он
          изумительный человек. Лучшего в восприятии музыки никого не было. Мне
          было так его жалко, а что делать: он был тяжел, толст, от режиссера
          всегда исходит какая-то волна, которая мешает.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[B—B]</Link>
          </sub>
        </>

        <Spacer half size={4} />
        <Image
          alt="Олег Каравайчук"
          src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/45.jpg`}
        />
      </BlockCinema>

      <Spacer half size={4} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <QuoteCinemaText>Александр Шпагин:</QuoteCinemaText>
          <>
            Они с Кулишом идеально нашли друг друга, один другому попал как в
            пазл, у них не было никаких конфликтов, более того — Каравайчук был
            безропотен. На всех картинах Кулиша композитор работал вдохновенно,
            экспериментировал во всех стилях, каких только можно, и все это в
            фильмы входило. Кулиш говорил: «Я всегда считал, что он дух музыки,
            Бог музыки, а он даже не Бог, а демон».{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—H]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema black>
        <Freedom />
        <Spacer half size={8} />
        <QuoteCinema inverted>
          <SubTitle inverted>путь отшельника</SubTitle>
          <QuoteCinemaText inverted>Арсений Занин:</QuoteCinemaText>
          <>
            Каравайчук выбрал для себя самый удобный и естественный путь — путь
            безобидного полусумасшедшего отшельника, внешний вид, манера общения
            и неожиданные жесты которого воспринимались как странные причуды, а
            не как камень в окно советского строя. Причуды такие, как свежая
            говяжья туша, что была ему необходима для специального звукового
            эффекта.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link style={{ color: "#fff" }} to="/karavajchuk/source">
                [B—I]
              </Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={10} />

        <QuoteCinema inverted>
          <QuoteCinemaText inverted>Сергей Ландо:</QuoteCinemaText>
          <>
            Он выбрал себе маску, и это позволяло ему легко обманывать систему.
            Что взять с юродивого. Но потом маска приросла.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link style={{ color: "#fff" }} to="/karavajchuk/source">
                [A—I]
              </Link>
            </sub>
          </>
          <Spacer half size={4} />
          <>
            Маска юродивого, этакого городского сумасшедшего, позволяла
            композитору быть абсолютно свободным. А свобода и безответственность
            так необходимы для вольной музыки. При этом Каравайчук был человеком
            необычайно высокой культуры и глубоких знаний. Такое странное
            сочетание ума и юродства, которое мог позволить себе разве что
            гений. Культура не подавляла его, он жонглировал ей и использовал
            как подвернувшийся под руку инструмент.
          </>
          <Spacer half size={4} />
          <>
            Юродивый служит Богу вне системы, так и Олег Николаевич служил своим
            музам, выбрав себе место на окраине системы, не смотря на то, что
            являлся очень успешным советским композитором.
          </>
          <Spacer half size={4} />
          <>
            В его органике было юродство как неистовое, абсолютно честное, до
            самозабвения, служение музыкальному Богу. Безумная отвага — залог
            его музыкальных побед. […] Для того, чтобы быть воздушным и легким,
            нужно было решительно отречься от искушения комфортом.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link style={{ color: "#fff" }} to="/karavajchuk/source">
                [A—I]
              </Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={10} />

        <QuoteCinema inverted>
          <QuoteCinemaText inverted>Сергей Астахов:</QuoteCinemaText>
          <>
            Его же долго не принимали в Союз композиторов. Он мне рассказывал,
            как было какое-то заседание Союза композиторов. И там на повестке
            дня первый вопрос — распределение каких-то дачных участков в
            Грузино, а второй — рассмотрение кандидатуры Каравайчука на прием
            его в Союз. И его вызвали. И вот он ходит там по коридору, войти не
            может, он же не член еще. Долго ходит, потому что все распределяют
            участки, несколько часов. И наконец он устал, открыл дверь и пропел
            своим голоском:
          </>
          <Spacer half size={4} />
          <>— У-ро-ды!</>
          <Spacer half size={4} />
          <>
            И все.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link style={{ color: "#fff" }} to="/karavajchuk/source">
                [B—J]
              </Link>
            </sub>{" "}
          </>
        </QuoteCinema>
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        additionalInfo="продолжение"
        director="реж. кира муратова"
        name="«долгие проводы»"
        url={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/46.jpg`}
        year="1971"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>последний фильм с муратовой</SubTitle>
        <>
          В общем, записал для Кулиша. И тут пришла телеграмма от Муратовой:
          «Приезжай, сыграй мне на рояле то болеро». И отправился опять в
          Одессу. И сделал Кире окончательный вариант.
        </>
        <>
          Музыкальный редактор по прозвищу Карабас-Барабас не пожелал мне
          платить. Я вышел из себя, закатил истерику, выразился, по-моему, даже
          последними словами: совершенно забываюсь в таком состоянии… Кира
          сказала, что терпеть не может, когда при ней так ругаются. И так вот
          закончилось время, когда мы с ней работали. Больше она меня никогда не
          приглашала.
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>средостение</SubTitle>
        <>
          Из профессиональных вопросов, которые всегда задают: читал ли я
          сценарий? Писал ли музыку по нему? Отвечаю: сценариев никогда не
          читаю, а смотрю прямо материал — и по нему пишу. Но чаще — просто по
          глазам режиссера.
        </>
        <>
          И теперь — наиболее откровенное при всей той правде, что Муратова
          почти выкинула меня из «Долгих проводов»: моя музыка рождается не для
          режиссеров и их кино. Нет полного соответствия. Я пишу на данный день
          календарный, улавливая необыкновенные чувственные перемены в человеке
          в зависимости от времени. Вот сейчас [1995 год] — Чечня. Люди стали
          другими сразу. Может быть, подсознательно. Когда едут войска на
          Кавказ, как бы меняется календарный день, и человек меняется. Теперь и
          кино смотрят с сокрушенными сердцами. Моя музыка — средостение между
          фильмом и зрителем. Не вибрировать вместе с событийным календарем —
          значит обрекать наши картины на преждевременную устарелость. И только
          ощущение каждого дня сообщает непреходящий трепет. Может быть, мои
          музыкальные отклики на сотрясающее мир поэтому и подходят к разным
          фильмам.
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>важность окружения</SubTitle>
        <>
          Все действительное разумно. Значит, вот так и произошло, что я больше
          для Муратовой не пишу музыки. Вот и все. А для музыки, для
          импровизации, особенно сразу, ведь очень важно, какой человек рядом.
          Вот ты входишь в зал, и не так люди сели, и ты уже совсем играешь не
          так, как дома…
        </>
        <>
          Удивительная вещь — сесть в кресло перед тобой, когда гость или
          хозяин, который тебя пригласил. Или ты пришел на запись в студию.
          Важно — кто и как вокруг тебя сидит, кто и как сидит возле тебя.
        </>
        <>
          При Муратовой я замечательно сочинял. Вот это — иметь пять-шесть таких
          человек, и ты сочиняешь лучше, чем ты сам это и можешь. Она
          замечательно садится на стул! Может, даже лучше садится, чем ее
          фильмы!.. И так всегда. И Пушкин лучше садился, чем его стихи. И вот в
          тот момент, когда садится она, ты видишь, что вот тело все… И после
          этого у тебя рука в рояле — совсем другие ноты.
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>пустые стулья</SubTitle>
        <>
          Мне тут пришла мысль — очень часто картины писал — и был моложе, много
          моложе тех, ради кого писал. Я ведь очень рано начал… Первую картину
          Смоктуновского. Первую картину Олега Борисова «Город зажигает огни»
          [1958]. Первую картину Высоцкого. И — последнюю картину Василия
          Шукшина «Степан Разин», так и не запущенную…
        </>
        <>
          И вдруг мне захотелось поставить стулья, пустые стулья на своей даче и
          вспомнить, как они на них садились. И в этот момент сыграть музыку —
          так просто, не думая. И все они сразу будут на моей даче. Но реквием —
          не болтать
        </>
        <>
          Да, еще вспомнил. Муратова когда-то мне говорила: «Как я хочу снять,
          как ты рассказываешь про музыку! Ты как-то особенно про свои ритмы
          рассказываешь. И все понятно… А уж когда играешь!..»{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[B—A]</Link>
          </sub>
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>варьянт для солдат и режиссеров</SubTitle>
          <QuoteCinemaText>
            Кира Муратова,
            <br />
            режиссер:
          </QuoteCinemaText>
          <>
            Вечный вундеркинд. Пальцы прикасаются к клавишам. Сидишь, слушаешь
            гипнотическую импровизацию и забываешь, где ты и кто ты.
            Опоминаешься, вспоминаешь, что ты режиссер, находишься в звукоцехе
            Одесской киностудии и должна сказать свое мнение, утвердить или
            отвергнуть… Говоришь какие-то слова о том, что для кино надо
            что-нибудь попроще, подемократичнее, а Олег в ответ: «Хорошо!
            Варьянт для солдат и режиссеров!» И снова пальцы на клавиши… Но эту
            фразу про солдат и режиссеров надо произносить манерно, картаво,
            тогда будет похоже. И подпевает сам себе тоненьким сопрано, и
            заливается — хохочет, как ребенок.
          </>
          <Spacer half size={4} />
          <>
            Мы два фильма с ним сделали — «Короткие встречи» и «Долгие проводы».
            Потом мне некоторое время музыка казалась лишней в кино. Потом стала
            мешать ироничность в музыке Каравайчука, и мы больше не встречались.
            Но я всегда думала, что он должен пережить меня. Потому что я
            взрослая, а он ребенок.
          </>

          <Spacer half size={4} />
          <Image
            alt="Олег Каравайчук"
            src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/48.jpg`}
          />
          <Spacer half size={4} />

          <>
            Олег — гений. Ну и, конечно, чудак. Входит в комнату, стараясь войти
            спиной. Делает быстрый пируэт, бросая на тебя мимолетный взгляд, и
            говорит какой-нибудь комплимент. Потом некоторое время, привыкая,
            поглядывает исподлобья и при этом ведет непринужденную беседу и
            очень веселится, как дитя. Все это было так давно. Помню, однажды
            утром мы нашли его спящим в ванне. Это был так называемый «Куряж» —
            маленькая гостиница Одесской киностудии. Там была общая ванная
            комната, и ему по какой-то причине не понравился его номер. Вот ведь
            вспоминаются какие-то глупости, а ведь он был музыкальный гений, но
            это не умею выразить словами. Отрадно было думать, что где-то
            существует необыкновенный Каравайчук. Хорошо, что осталась музыка.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[B—K]</Link>
            </sub>
          </>

          <Spacer half size={14} />

          <SubTitle>прямолинейный детский взгляд</SubTitle>
          <>
            В его музыке всегда присутствует иронический комментарий,
            иронический взгляд. В первых фильмах меня это устраивало. А на фильм
            «Познавая белый свет» я уже Каравайчука не пригласила. Это не
            значит, что я в нем разочаровалась. Просто мне хотелось только
            серьезного, наивного, детского прямолинейного взгляда, и в музыке
            тоже.
          </>

          <Spacer half size={14} />

          <SubTitle>лишний компонент</SubTitle>
          <>
            Потом два фильма — «Среди серых камней» и «Перемену участи» — я
            сняла вообще без собственно авторской музыки. Я прекрасно отношусь к
            музыке в чужих фильмах, но в своих я начала считать ее лишней. Мне
            стало казаться, что музыка в кино иногда является паллиативом: если
            что-то не вполне получилось, она должна замазать огрехи, как бы
            позолотить пилюлю, чтоб ее сглотнули. Музыка часто используется как
            допинг, то есть нечто не из нутра предмета исходящее, а снаружи
            вторгающееся. Говорят, кино — искусство синтетическое и в нем очень
            много компонентов. Сейчас мне этот компонент, музыкальный, кажется
            лишним. У меня вообще возникла тенденция к аскетизму. Чем меньше
            компонентов, тем элегантнее, тем лучше. Может быть, в этом есть и
            некий экспериментальный ход режиссуры: сможем ли мы не использовать
            и это, и то и достичь нужного эффекта? Скорее всего, это
            одновременно и эксперимент, и какое-то глубокое чувственное желание,
            прихоть. Не хочется мне музыки. Я вижу, что без музыки лучше. Хотя
            это не означает, что я отныне буду поступать только так.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—K]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>1970-е. пение сирен</SubTitle>
          <QuoteCinemaText>Александр Шпагин:</QuoteCinemaText>
          <>
            Подлинный, воистину выдающийся Каравайчук состоится именно в 1970-е
            годы. Он уловит новые токи будущей эпохи — и откроется им навстречу.
            По-прежнему отделен от всех, он экспериментирует на своем острове.
            Вовсю фигачит с роялем, создавая на нем пронзительные и, конечно же,
            безумноватые вальсы, постепенно вырывающиеся в какие-то
            шизофренические танцы, киксующие, уходящие в атональность, как
            всегда, неостановимые, с трудом возвращающиеся в прежнее свое русло
            и снова из него вырывающиеся… И — удивительно красивые. Только
            красота эта страшноватая — как пение сирен — вальсы обнаруживают в
            себе странную, тревожную едкость, они всасывают слушателя, как
            хищный цветок мушку, они не отпускают от себя, они привязываются,
            они заставляют себя слушать, влезают в мозг, в подкорку, в
            подсознание.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[B—S]</Link>
            </sub>
          </>

          <Spacer half size={14} />

          <SubTitle>конец одесской киностудии</SubTitle>
          <>
            После «Долгих проводов» Каравайчук перестает работать в Одессе. Еще
            раньше, после того, как был снят фильм «Опасные гастроли» [Георгия
            Юнгвальд-Хилькевича, 1969], [режиссер Ефим] Дзиган пишет большой
            донос в «Искусство кино», открытое письмо властям, что надо
            разобраться с руководством Одесской студии, которое в итоге почти
            поголовно снимают. А тут еще стагнация начала 1970-х, да еще и
            «Долгие проводы» прискакали. Все. Новое руководство, и те старые,
            которые остались, трясутся, как цуцики. В итоге чуть ли не из лучшей
            студии страны, на которую все ориентируются, она превращается
            практически в худшую. Поэтому оттуда бегут все.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—H]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <QuoteCinemaText>
            Александр Муратов,
            <br />
            режиссер:
          </QuoteCinemaText>
          <>
            Закончилось все на Одесской киностудии потому, что были разгромлены
            наши с Кирою фильмы. К тому же к власти [на студии] пришел
            приспособленец Геннадий Збандут, который боялся конфликтов с
            украинским ЦК компартии. Что-то интересное появлялось, но оно
            главным образом снималось по заказу Центрального телевидения.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—G]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. илья авербах"
        name="«монолог»"
        url={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/49.jpg`}
        year="1972"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>певец вымирающего класса</SubTitle>
          <QuoteCinemaText>
            Андрей Плахов,
            <br />
            кинокритик:
          </QuoteCinemaText>
          <>
            После смерти Козинцева [в 1973 году], мастерскую которого он
            окончил, Авербах стал неформальным лидером «ленинградской школы»,
            главным качеством которой было в ту пору скромное обаяние
            интеллигентности.
          </>
          <Spacer half size={4} />
          <>
            В отличие от «Мосфильма» с его столичным блеском, всемирно
            известными именами, крупнейшей в Европе производственной базой
            включая собственный кавалерийский полк, «Ленфильм» если чем и мог
            гордиться, то своей провинциальностью. Но ведь быть в тени, в
            стороне от официозной коррумпированной элиты считалось в эпоху
            застоя хорошим тоном. Поскольку откровенным диссидентам делать в
            кино было нечего, его героями стали аутсайдеры, что в российских
            условиях означало принадлежность к вымирающему классу «истинных
            интеллигентов». Это они населяли фильмы Авербаха — обитатели
            пропахших книжной пылью питерских квартир; благородные старики,
            которых вечно бросали жены, дочери и внучки, подкидывая им на
            воспитание своих отпрысков; гордые учительницы, жившие в полной
            аскезе — с пустым холодильником и висящим над ним снимком Ахматовой.
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—M]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>обреченность как выбор</SubTitle>
          <QuoteCinemaText>Наталия Рязанцева:</QuoteCinemaText>
          <>
            Обреченность моих дедов и прадедов была для меня удручающей
            данностью, и вдруг воображением Ильи она окрасилась в романтические
            тона. То есть из России, которую мы потеряли, я знала много старух,
            старушек, несдающихся дам, но не мужчин — их истребили, они
            самоистребились, обратились в поэтические тени, а душа по ним тайно
            тосковала. Илья был оттуда, из всех миновавших эпох, он выбрал эту
            обреченность.
          </>
          <Spacer half size={4} />
          <>
            Вообще, ностальгия была частью его существа, не минутными
            приступами, а навязчивым состоянием, с которым он пытался бороться,
            иронизировал над собственной сентиментальностью.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—I]</Link>
            </sub>
          </>

          <Spacer half size={14} />

          <SubTitle>стойкий оловянный солдатик</SubTitle>
          <>
            Был странный случай: будучи в Киеве по какому-то делу, Авербах
            напросился к Виктору Платонычу Некрасову — просто познакомиться,
            поклониться. Просто режиссер из Ленинграда... Некрасов сам открыл
            дверь и спросил через порог: «Ты белогвардеец? Тогда входи».{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—N]</Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={4} />
        <Image
          alt="Олег Каравайчук"
          src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/50.jpg`}
        />
      </BlockCinema>

      <BlockCinema>
        <></>
        <QuoteCinema>
          <QuoteCinemaText>
            Любовь Аркус,
            <br />
            киновед, режиссер:
          </QuoteCinemaText>
          <>
            Любимым символом Авербаха — наиболее наглядно явленным в «Монологе»
            — был стойкий оловянный солдатик. Самому режиссеру не всегда
            удавалась эта стойкость — он не мог предавать или продаваться, но
            открытому противостоянию предпочитал уход: внутрь себя, вглубь себя,
            подальше от искусов и соблазнов. Сама идея героизма как
            насильственного — извне — испытания человека на прочность была ему
            органически чужда и отвратительна. Он признавал лишь индивидуальные
            и интимные счеты человека с самим собою. Однако тонкость, и
            проницаемость, и опасность этой грани всегда сознавал. Потому своего
            лирического героя наделял чуть большим мужеством и чуть большей
            определенностью в поступках, нежели та, на которую сам был способен.
          </>
          <Spacer half size={4} />
          <>
            Авербах собирался делать картину о том, о чем и так снимал всю
            жизнь: никаких истин, кроме собственного кодекса чести, нет… Он был
            одним из немногих истинных аристократов времен заката империи,
            хорошо понимавших, что без четкого замысла и столь же четкого
            понятия о долге искусства не существует.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—B]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>растерянный бог</SubTitle>
          <QuoteCinemaText>Александр Шпагин:</QuoteCinemaText>
          <>
            Предполагалось нервное черно-белое кино. Нет, говорит начальство,
            забудьте о ч. б., и в эту цветную пленку — как кутят. «Монолог» —
            притча о растерянном Боге. Габрилович пишет сценарий на Бруно
            Фрейндлиха. И как только появляется цветная пленка, Авербах —
            человек очень структурный, где-то начинает менять стилистику, и
            вдруг заменяет Фрейндлиха на [Михаила] Глузского. Габрилович в
            совершеннейшем ужасе, ну какой Глузский? Он рациональный, злой, а
            это совершенно не то. Здесь растерянный Бог должен быть.
          </>
          <Spacer half size={4} />
          <Image
            alt="Олег Каравайчук"
            src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/51.jpg`}
          />
          <Spacer half size={4} />
          <>
            И картина дубеет, там все дубеет, кроме музыки. Каравайчук — это
            последнее спасение в этом фильме.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—H]</Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={4} />
        <Carousel
          autoHeight
          slides={[
            { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/52a.jpg` },
            { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/52b.jpg` },
            { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/52c.jpg` },
            { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/52d.jpg` },
          ]}
        />

        <Spacer half size={4} />
        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/24 МОНОЛОГ | ВОСПОМИНАНИЯ.mp3`}
        />
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>марш солдатиков. спектакль юрского</SubTitle>
        <>
          Авербах у меня музыку не принял. Был скандал жуткий. Он хотел, чтобы я
          написал петербургский марш в стиле XIX века, который соответствовал бы
          старому интеллигенту. Когда я принес ему этот солдатский марш, он
          сказал: «Это пижонство! Это надругательство!» Он его чуть не
          растоптал.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[A—L]</Link>
          </sub>
        </>
        <Spacer half size={4} />
        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/25 МОНОЛОГ | МАРШ СОЛДАТИКОВ.mp3`}
        />
      </BlockCinema>

      <Spacer half size={4} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <QuoteCinemaText>
            Александр Плахов,
            <br />
            режиссер:
          </QuoteCinemaText>
          <>
            Сергей Юрский, услышав его музыку, просит ее у Каравайчука для
            своего спектакля «Мольер» [1973], который ставит в Большом
            драматическом театре. Авербах приходит на премьеру спектакля и
            различает отвергнутую им мелодию. В спектакле она дышит духом
            свободы и словно бы к небесам поднимает действие. И Авербах решает:
            без музыки Каравайчука фильму не быть.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[A—L]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <QuoteCinemaText>Наталия Рязанцева:</QuoteCinemaText>
          <>
            Каравайчук иногда еще носил большие пестрые варежки и ушанку —
            выглядел очень смешно, но совершенно этого не стеснялся. И очень
            любил поговорить про музыку, но я с ним в тот момент уже не
            разговаривала, обидевшись.
          </>

          <Spacer half size={4} />
          <Image
            alt="Олег Каравайчук"
            src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/53.jpg`}
          />
          <Spacer half size={4} />
          <>
            Он написал музыку для «Монолога», и оказалось, что в то же время, ту
            же музыку, он отдал Юрскому для театра. И когда Юрский пришел на
            «Ленфильм», то неожиданно для себя услышал, как идет запись той же
            самой музыки, которую он только что получил. Потом я не помню, кто
            из них победил.
          </>
          <Spacer half size={4} />
          <>
            Но кроме того, он еще жульничал. Он был вообще очень практичный, к
            тому же, ко всем своим странностям, еще умел какие-то жалобы писать,
            если чего не доплатят, но партитуры никогда вовремя не сдавал. А
            ведь на студии положено партитуру сдать, всю документацию. Это как
            раз отражено в фильме «Голос» [Авербаха по сценарию Рязанцевой,
            1982]: с него списан композитор Олег Петрович.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[D—D]</Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={4} />
        <Carousel
          autoHeight
          slides={[
            { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/54a.jpg` },
            { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/54b.jpg` },
            { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/54c.jpg` },
            { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/54d.jpg` },
          ]}
        />
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <QuoteCinemaText>Сергей Ландо:</QuoteCinemaText>
          <>
            «Марш солдатиков» предполагался в другом месте. И вдруг он на эти
            игрушки лег и все заиграло. То есть музыка Каравайчука очень часто
            ложилась не туда, куда она первоначально была предназначена. «От сих
            до сих будет музыка Каравайчука» — так работало не всегда. А вот
            сместили ее куда-то, появился зазор, и музыка начинала работать. Как
            говорят, контрапункт.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—F]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>«старая крепость»</SubTitle>
          <QuoteCinemaText>
            Александр Муратов,
            <br />
            режиссер:
          </QuoteCinemaText>
          <>
            [Я] пригласил [Олега Каравайчука] на сериал «Старая крепость»,
            который состоял из трех частей. К первой части писал музыку киевский
            композитор Вадим Харпачёв. А к двум другим — Олег. Со второй частью
            «Дом с привидениями» все было нормально. Но музыку к третьей части
            он все не писал и не писал. А фильм надо было вскоре сдавать. И тут
            он позвонил и сказал, что пересылает с проводником уже готовую
            музыку, записанную им в Ленинграде. И тут же последовал звонок Ильи
            Авербаха, с которым я был в прекрасных отношениях. Он попросил дать
            ему прослушать по телефону музыку, присланную мне Каравайчуком.
            Прослушав ее, он сказал, что это музыка к его только что
            законченному фильму и мне ее использовать нельзя.
          </>
          <Spacer half size={4} />
          <>
            В отчаянии я попросил Харпачёва, предупредив об этом Каравайчука,
            чтобы он срочно соединил музыку из своей первой части с
            Каравайчуковской из второй части. Другого выхода не было. К тому же
            он дописал несколько новых номеров. Фильм я благополучно сдал. Более
            того — он был очень популярен.
          </>
          <Spacer half size={10} />
          <SubTitle>
            молдавская мелодия, чешский композитор, израильский гимн
          </SubTitle>
          <>
            И вдруг меня вызывают в наше ЦК партии и говорят, что пришло письмо,
            в котором сообщается, что музыка Харпачёва является копией музыки
            израильского гимна. Учтите какой это был год! Начался жуткий
            скандал! Все стали ловить по радио этот гимн. И тут выяснилось, что,
            во-первых, музыка гимна — это старинная молдавская мелодия,
            использованная до этого чешским композитором Бедржихом Сметаной. А
            во-вторых, музыка Харпачёва на нее мало похожа. Оказалось, что донос
            написал Олег Каравайчук. Этого я уже простить ему не мог.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—G]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. илья авербах"
        name="«чужие письма»"
        url={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/55.jpg`}
        year="1975"
      />

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>в поисках верного тона </SubTitle>
          <QuoteCinemaText>
            Дмитрий Долинин,
            <br />
            режиссер, оператор:
          </QuoteCinemaText>
          <>
            Илья Авербах пригласил меня снимать «Чужие письма», когда за моей
            спиной было уже около десятка картин. Авербах, снявший, несомненно,
            удачные и общепризнанные «Степень риска» и «Монолог», спорную «Драму
            из старинной жизни», слыл самым талантливым молодым режиссером
            «Ленфильма». Вокруг него витала аура слухов, обвиняющих его в
            снобизме, интеллектуализме, диктаторстве. Как бы в их подтверждение
            он носил твидовый пиджак и курил трубку.
          </>
          <Spacer half size={4} />
          <>
            Перед свиданием я испытывал известную настороженность, однако первые
            контакты с ним оказались удивительны. Не было ни высокомерия, ни
            попытки диктаторства. Если быть точным — я увидел, что он ощущает
            неуверенность, желает быть правильно понятым, мучительно ищет точные
            слова. И уважает меня как профессионала, сподобившегося некоторых
            тайн ремесла. Не было холода — было всепоглощающее его желание
            сделать работу как можно лучше.
          </>
          <Spacer half size={4} />
          <>
            Общих рассуждений по поводу «Чужих писем» припомнить не могу, можно
            подумать, что их и не было вовсе, однако это не так — позиции
            выяснялись и уточнялись, просто быстро обнаружилось, что мы с
            Авербахом хорошо понимаем друг друга. «Чужие письма» — единственный
            его фильм, в основе которого лежит социальная ненависть.
            Сговариваться по поводу отношения к главному персонажу — Зинке —
            было незачем, мы оба одинаково понимали опасность победы в жизни
            таких, как она: энергичных, поверхностных представителей
            «антикультуры». Главная задача состояла в поисках верного тона всего
            повествования.
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={4} />
      <Carousel
        autoHeight
        slides={[
          { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/56a.jpg` },
          { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/56b.jpg` },
          { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/56c.jpg` },
        ]}
      />
      <Spacer half size={4} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <>
            Интонация всего рассказа строится Авербахом повествовательно, без
            нажима, без экспрессивных внешних приемов даже в кульминационных
            точках действия. Один только раз он отступает от своих принципов —
            падающую стопку старинных книг мы снимаем рапидом. Книги медленно,
            многозначительно медленно, парят в воздухе, осыпаясь на пол. Зрелище
            поддерживается соответствующей музыкой и превращается в символ,
            метафору гибели культуры... Вспоминая этот кадр впоследствии, Илья
            всегда морщился от стыда и отвращения.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—E]</Link>
            </sub>
          </>
        </QuoteCinema>
        <Spacer half size={4} />
        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/26 ЧУЖИЕ ПИСЬМА | ФИНАЛ.mp3`}
        />
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>пощечина торжествующему быдлу</SubTitle>
          <QuoteCinemaText>
            Дмитрий Быков
            <br />
            [ПРИЗНАН МИНЮСТОМ РФ ИНОАГЕНТОМ]:
          </QuoteCinemaText>
          <>
            Всю жизнь сравнивая интеллигенцию советскую с дореволюционной и любя
            свои «те», дореволюционные дворянские корни — по матери Авербах был
            русским, из славной дворянской семьи [Куракиных], — он не мог не
            ненавидеть от всей души навязанную «скромность» и искусственную
            «тихость», мягкотелость и беспомощность, трусость и
            приспособленчество.
          </>

          <Spacer half size={4} />
          <Image
            alt="Олег Каравайчук"
            src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/57.jpg`}
          />
          <Spacer half size={4} />

          <>
            В этом смысле «Чужие письма» — вершинная и наиболее принципиальная
            его работа — были, конечно, настоящей пощечиной торжествующему
            быдлу: люди без стержня были Авербаху неинтересны. Его герои —
            фанатики.
          </>
          <Spacer half size={4} />
          <>
            Авербаху интересен нонконформист, и тема его — бунт интеллигента,
            загнанного на задворки, в нишу тактичного, тихого, обаятельного
            труса. Это бунт тихий, но тем более язвительный.
          </>

          <Spacer half size={4} />
          <Image
            alt="Олег Каравайчук"
            src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/58.jpg`}
          />
          <Spacer half size={10} />

          <SubTitle>дождь под фортепиано</SubTitle>
          <>
            Главная интонация, бесспорная доминанта авербаховских фильмов,
            делающая их подлинным знаком эпохи, — острая тоска… Нигде не хорошо,
            ничто не ладится, всюду царит жалкая беспомощность… и дождь над всем
            этим плачет. Дождь под фортепианную музыку — Каравайчук, любимый
            композитор Авербаха, городской сумасшедший, виртуоз и отшельник —
            вот лейтмотив этого кино; стойкие оловянные солдатики в «Монологе»
            тоже всегда маршируют на фоне дождливого стекла.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—C]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={4} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>вальс, апокалипсис</SubTitle>
          <QuoteCinemaText>Александр Шпагин:</QuoteCinemaText>
          <>
            В «Чужих письмах» Каравайчук берет матрицу вальса и на все лады ее
            крутит, а в конце она оборачивается неким реквиемом, очень хочется
            плакать, потому что понимаешь, что пришел [конец]. Так они с
            Авербахом, собственно, и хотели. И это мне рассказывал сам
            Каравайчук.
          </>
          <Spacer half size={4} />
          <>
            И еще он говорил, что эта тема должна была быть закольцована, что
            очень важно, она должна была «сама себя петь». Когда люди поют, они
            не могут остановиться. Только закончил петь — она снова лезет.
            Впервые это было найдено в «Коротких встречах». Потом, по его
            словам, он повторил это в «Чужих письмах». И это действительно
            порождает потрясающий эффект — в начале музыка как бы расслабляющая,
            хоть и с внутренней тревогой, но вдруг этот вальсок превращается в
            апокалипсис. А это не так просто, из вальска апокалипсис сделать!
            Попробуй, сделай из вальса Георгия Свиридова в «Метели» апокалипсис!
            Не получится. А здесь идеально.
          </>

          <Spacer half size={4} />
          <StyledReactPlayer
            auto
            controls
            url={`${PUBLIC_URL}/assets/audio/karavajchuk/27 ЧУЖИЕ ПИСЬМА | УВЕРТЮРА.mp3`}
          />

          <Spacer half size={10} />

          <SubTitle>главное все ухудшить</SubTitle>
          <>
            В этот период Каравайчук начинает препарировать фортепиано,
            подкладывая под струны ластики, железки и даже валенки. Он говорил:
            главное — все нарушить. Все ухудшить, как только можно. Я должен все
            делать максимально не по правилам. В начале 1970-х это стало его
            жизненной позицией.
          </>
          <Spacer half size={4} />
          <>
            И появляется новое — элементы вальса путаются с элементами каких-то
            странных звуков и шумов, которые пролезают внутрь всплесками
            какофоний.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—H]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>препарированный рояль</SubTitle>
          <QuoteCinemaText>Борис Алексеев:</QuoteCinemaText>
          <>
            В Большом тон-ателье на «Ленфильме» у Каравайчука к этому моменту
            уже был свой рояль, препарированный, куда можно было и валенки под
            струны подложить, и металлический лист, и перестраивать как угодно,
            меняя звук. Все знали, что это его рояль, и только он на нем мог
            играть. Его так и называли — рояль Каравайчука.
          </>

          <Spacer half size={4} />
          <Image
            alt="Олег Каравайчук"
            src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/59.jpg`}
          />
          <Spacer half size={4} />
          <>
            Олегу Николаевичу хотелось выйти за рамки того звучания, которое по
            умолчанию есть у рояля. Поэтому он разными способами пытался придать
            ему какие-то эффекты, хотел достичь того звучания, которое слышал
            внутри себя, пытаясь через инструмент тем или иным способом его
            извлечь. Учитывая его безграничную фантазию, на записях можно было
            услышать все, что угодно.
          </>
        </QuoteCinema>
        <Spacer half size={4} />
        <Image
          alt="Олег Каравайчук"
          src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/60.jpg`}
        />
        <Spacer half size={4} />
        <Image
          alt="Олег Каравайчук"
          src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/61.jpg`}
        />
        <Spacer half size={4} />
        <Image
          alt="Олег Каравайчук"
          src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/62.jpg`}
        />
      </BlockCinema>

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>чудеса в капелле</SubTitle>
          <QuoteCinemaText>
            Игорь Дельгядо,
            <br />
            звукоинжинер:
          </QuoteCinemaText>
          <>
            Я работал в звукозаписывающей бригаде на фирме «Мелодия», у нас
            тогда была студия в Капелле. Обычно это были ночные записи, потому
            что Капелла днем была занята, Олег Николаевич приглашал на свое
            усмотрение самых разных музыкантов, от детей с воздушными шариками
            до музыкантов с народными инструментами. Дети приходили из хорового
            училища при Капелле, он им раздавал воздушные шарики, просил надуть,
            а потом растягивать горловину, чтобы звук с треском из них выходил.
          </>
          <Spacer half size={4} />
          <>
            Часов в десять начиналась смена, музыканты поднимались на сцену, и
            первые четыре часа была полная неразбериха. Директор картины
            нервничал, ругался, раздраженно говорил режиссеру: «К кому ты меня
            притащил? Мы скоро сдаем картину, а у нас нет ничего, прошло уже
            четыре часа…» Он пытался спросить у Каравайчука про ноты. Олег
            Николаевич говорил: «Ну сейчас я вам сделаю ноты». Брал листок
            бумаги, рисовал там какие-то каляки-маляки: «Вот вам мои ноты». И
            дальше уходил на сцену показывать музыкантам просто на словах, на
            руках.
          </>
          <Spacer half size={4} />
          <>
            И директор картины с каждым часом приходил во все больший и больший
            ужас, потому что все это было ни на что не похоже, ни на музыку, ни
            на что. И где-то через четыре-пять часов каких-то репетиций,
            подсказок, проб, вдруг происходило чудо. Начинала появляться музыка,
            ее записывали буквально за полтора часа, но ее хватало на всю
            картину.
          </>

          <Spacer half size={10} />

          <SubTitle>почему капелла</SubTitle>
          <>
            В каких-то случаях Каравайчуку хотелось акустики, там были иные
            акустические условия, чем в студии на «Ленфильме», довольно большой,
            неплохо оборудованной, но суховатой. Если речь шла о записи музыки,
            близкой к академической, то зал Капеллы был лучше — это во-первых.
          </>
          <Spacer half size={4} />
          <>
            Во-вторых в Капелле был специально для Каравайчука рояль, который
            вытаскивали из какой-то репетиционной комнаты, он был весь разбитый,
            раздолбанный, потому что Капелле было жалко хороший рояль для Олега
            Николаевича. Он запихивал туда конфеты, фантики, еще что-то, чтобы
            он звенел, гудел. Иными словами препарировал.
          </>

          <Spacer half size={4} />
          <Image
            alt="Олег Каравайчук"
            src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/63.jpg`}
          />

          <Spacer half size={10} />

          <SubTitle>план</SubTitle>
          <>
            Олег Николаевич всегда знал, что хочет, у него в голове был план на
            каждую запись, хотя часто он что-то менял на ходу. Этим планом он ни
            с кем не делился.
          </>
          <Spacer half size={4} />
          <>
            Все «ленфильмовские» режиссеры примерно представляли, кто такой
            Каравайчук, и как с ним работать. Они с ним что-то обсуждали в
            паузе, но все зависело от того, насколько у них были дружеские
            отношения. Олега Николаевича в те годы нельзя было в чем-то
            переубедить.
          </>
          <Spacer half size={4} />
          <>
            Он просто записывал большие фрагменты музыки. Я принимал участие в
            работе над двенадцатью его картинами, но только в двух случаях
            Каравайчуку показывали видеоряд. Подчас и тогда он и не смотрел на
            экран, записывая музыку.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—D]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>контрстиль</SubTitle>
        <>
          [Режиссер] мне ставил экран, но я в него никогда не смотрел. Сажусь,
          передо мной рояль, я только на рояль смотрел. И в этот момент как-то
          улавливал, в начале картины, стиль режиссера. Я делаю контрстиль,
          потому что стиль кино очень примитивен.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[B—H]</Link>
          </sub>
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>«ночные»</SubTitle>
          <QuoteCinemaText>Игорь Дельгядо:</QuoteCinemaText>
          <>
            По сути, я могу сказать, что для «Мелодии» это был вид халтуры,
            «ночные». Мы не за зарплату работали, Олег Николаевич потом с нами и
            музыкантами рассчитывался отдельно. Не директор картины. Может, он
            рассчитывался из аванса своего, не знаю.
          </>
          <Spacer half size={10} />

          <SubTitle>вовлечение в игру</SubTitle>
          <>
            Иногда они срывались на длинные импровизации, тут Олега Николаевича
            трудно было остановить. Находясь в процессе записи, несмотря на то,
            что у него был план, ему все равно нужно было прийти в какое-то
            определенное состояние. Чтобы создать настроение, атмосферу, для
            себя и для музыкантов, важно было поиграть, поимпровизировать. При
            этом все это записывалось, и частью могло войти в фильм.
          </>
          <Spacer half size={4} />
          <>
            Вовлечение в игру — выглядело это именно так. Он показывал на рояле,
            что он хочет, какой-нибудь кусочек, репетиция — что-то ему не
            нравилось. Начинали играть. И вот из этого хаоса первоначального
            музыка и получалась.
          </>

          <Spacer half size={4} />
          <Carousel
            autoHeight
            slides={[
              {
                url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/64a.jpg`,
              },
              {
                url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/64b.jpg`,
              },
            ]}
          />
          <Spacer half size={4} />

          <>
            Музыканты, которых он приглашал, всегда очень тепло к нему
            относились, с пониманием к его методике. Они, правильно было бы
            сказать, — вовлекались в историю. И мне кажется, они сами
            удивлялись, как у них из этого хаоса вдруг что-то получилось. Им
            самим было непривычно.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—D]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. семен аранович"
        name="«летняя поездка к морю»"
        url={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/65.jpg`}
        year="1978"
      />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>заговорщики в кафе «ленфильма»</SubTitle>
          <QuoteCinemaText>Юрий Клепиков:</QuoteCinemaText>
          <>
            К началу 1970-х вся наша компания, ученики Козинцева и другие
            молодые режиссеры, были уже старожилами «Ленфильма». У каждого по
            фильму. А то и больше. Торчали на редсоветах, на худсоветах. Но
            главным образом в кафе. Здесь договаривалось то, что не годилось для
            стенограммы. Восторги были горячей, претензии язвительней. Все
            немного заговорщики. При неизбежном сотрудничестве с властью
            испытывали боязнь испачкаться ее идеологией. Хождение по канату,
            сохранение баланса было привычным состоянием.
          </>
          <Spacer half size={4} />
          <>
            Не заглядывая в справочники, приведу далеко не полный список
            фильмов, снятых к тому времени молодыми режиссерами студии. [Виктор]
            Соколов — «Друзья и годы» [1965]. Авербах — «Степень риска».
            [Виктор] Трегубович — «На войне как на войне» [1968]. Кулиш —
            «Мертвый сезон» [1968]. [Владимир] Мотыль — «Белое солнце пустыни»
            [1969]. Герман — «Операция „С Новым годом!“» [«Проверка на дорогах»,
            1971]. [Глеб] Панфилов — «В огне брода нет» [1967] и «Начало»
            [1970].
          </>

          <Spacer half size={14} />

          <SubTitle>опытный новичок</SubTitle>
          <>
            В это время и в этот круг явился Семен Аранович. В документальном
            кино у него было имя. В игровом пришлось все начинать заново.
            Аранович подружился с Авербахом. Столь качественные отношения
            выписывали пропуск: проход всюду. Новичок стал своим.
          </>

          <Spacer half size={4} />
          <Image
            alt="Олег Каравайчук"
            src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/66.jpg`}
          />
          <Spacer half size={4} />

          <>
            Сценарий «Летняя поездка к морю», едва написав, я тут же отнес
            Арановичу. Он отклонил предложение. Сослался на занятость. Но
            пообещал вернуться к нему в будущем. Тут я мог бы затаить обиду. Но
            я сам создал эту ситуацию. Не помню, по какой причине «Летняя
            поездка» сочинялась втайне от всех. Сценарий никто не ждал. Будто
            упал с неба. К тому же его реализация ставила сложные
            производственные и технические задачи. Предстояла адская работа. Ее
            попробовал сперва одолеть один молодой режиссер, потом другой. Оба
            обломали зубы и сбежали, не выдержав нагрузки. Сценарий, как зверь,
            поджидал своего дрессировщика. И тот пришел. Талантливый, опытный,
            выносливый.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—H]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={4} />
      <Carousel
        autoHeight
        slides={[
          { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/67a.jpg` },
          { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/67b.jpg` },
          { url: `${PUBLIC_URL}/assets/images/karavajchuk/cinema/67c.jpg` },
        ]}
      />

      <Spacer half size={4} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>робинзоны 1942-го</SubTitle>
          <QuoteCinemaText>
            Сергей Доморощенов,
            <br />
            журналист:
          </QuoteCinemaText>
          <>
            Юрий Николаевич Клепиков прочитал в газете «Комсомольская правда»
            [1973] заметку участника экспедиции Николая Вурдова «Робинзоны
            1942-го», и взялся за работу над сценарием.
          </>
          <Spacer half size={4} />
          <>
            1942 год был самым тяжелым для Архангельска… Было принято
            постановление бюро обкома ВКП(б) «О заготовке яиц, тушек кайры и
            добыче рыбы на острове Новая Земля и вывозе заготовленной продукции
            в гор. Архангельск в навигацию 1942 года». Требовалось «изыскать
            внутренние резервы для расширения продовольственной базы». Вот
            почему и родилась идея экспедиции на архипелаг с его птичьими
            базарами на отвесных скалах. Яйца кайры, похожей на пингвина —
            спинка черная, белая манишка на груди, — значительно крупнее
            куриных, по вкусу не уступают им. Вес арктической птицы — около
            килограмма. Для экспедиции на Новую Землю были подобраны 150
            школьников и студентов техникумов.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—F]</Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={4} />
        <Image
          alt="Олег Каравайчук"
          src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/68.jpg`}
        />

        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/28 ЛЕТНЯЯ ПОЕЗДКА К МОРЮ | ОТСТРЕЛ ПТИЦ.mp3`}
        />
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>сон о фильме</SubTitle>
          <QuoteCinemaText>Юрий Клепиков:</QuoteCinemaText>
          <>
            Сценарий — это сон о фильме. Его видишь бодрствуя, в состоянии
            сильного возбуждения. Оказалось важно, что мы сверстники, что в
            войну были пацанами, оба драпали от немца, знали, что такое голод,
            бездомье, холод, вши. Затем он уехал на съемки и в одиночку прошел
            путь мучений, положенных режиссеру.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—H]</Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={10} />
        <QuoteCinema>
          <SubTitle>необитаемый остров</SubTitle>
          <QuoteCinemaText>
            Семен Аранович,
            <br />
            режиссер:
          </QuoteCinemaText>
          <>
            Я вырос в военное время. Тогда я был даже младше, чем мои герои, но
            помню отчетливо недетское выражение в глазах детей. Они и были тогда
            маленькими взрослыми, с характерами, с жизненным опытом. Поэтому я
            отправился в колонию для несовершеннолетних, в ПТУ, в интернаты,
            выбрал нескольких подростков и отвез их на необитаемый остров в
            Баренцевом море. Океан, тяжелые волны, иногда до шести метров
            высотой. Суровые условия работы ввели ребят в обстановку жизни их
            сверстников военной поры почти моментально. Детей приходилось
            пересаживать на лодки с помощью веревок и спасательных поясов. И я
            не разрешал им переодеваться. Они постоянно находились в «съемочных
            костюмах», обтрепанных, залатанных. Чтобы приросли как кожа. Для
            начала мне пришлось зашить карманы их брюк. Острые мокрые скалы,
            скользко. Ребята имели привычку разгуливать «руки в брюки» и если бы
            кто-то оступился, не успел бы даже вытащить рук из карманов…
          </>

          <Spacer half size={4} />
          <Image
            alt="Олег Каравайчук"
            src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/69.jpg`}
          />
          <Spacer half size={4} />
          <StyledReactPlayer
            auto
            controls
            url={`${PUBLIC_URL}/assets/audio/karavajchuk/29 ЛЕТНЯЯ ПОЕЗДКА К МОРЮ | УВЕРТЮРА.mp3`}
          />
          <Spacer half size={4} />

          <>
            В фильме есть эпизод, где ласточка проглатывает чужого птенца. Я
            видел это на острове, и потому нанял оператора с документальной
            студии и привез его на остров. Мы построили на утесе шалаш, и он там
            просидел шесть дней, пока не случилось то, что я хотел снять: дети —
            жертвы… Мне этот образ был необходим. Мы хотели создать ощущение
            мира, который живет по законам войны, и война неумолимо подчиняет
            себе все: в нее втянуты дети, острова, океан, птицы, небо, земля…
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[D—A]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>в прокатный угол</SubTitle>
          <QuoteCinemaText>Юрий Клепиков:</QuoteCinemaText>
          <>
            У «Летней поездки» не вышло удачной судьбы. Грубо травмированный
            поправками, фильм был задвинут в прокатный угол, как психопатический
            оборванец. Оттуда он уже никогда не вышел. Замечу попутно,
            Арановичу, как многим, приходилось испытывать затруднения с
            прохождением своих фильмов. Случалось и ему загреметь на полку. Но
            демонстрировать шрамы, заламывать руки, театрализовывать отчаяние и
            обиду никогда себе не позволял. Не его поза.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—H]</Link>
            </sub>
          </>
        </QuoteCinema>
        <Spacer half size={4} />
        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/30 ЛЕТНЯЯ ПОЕЗДКА К МОРЮ | ГИБЕЛЬ "ЗУБАТКИ".mp3`}
        />
        <Spacer half size={4} />
      </BlockCinema>

      <BlockCinema>
        <SubTitle>согревательное место</SubTitle>
        <>
          В [1990-е] я ему [Арановичу] звонил каждый день, чтобы его
          подбадривать, а он мне говорил, что у него жутко болит голова. И он
          снимал [свой последний фильм] с такой головной болью, вы даже не
          знаете, что это было. […] Заходил к нему тоже. У меня мама в тот
          момент умерла, и для меня Аранович — это было таким согревательным
          местом. Везде как-то так безжизненно, «Ленфильм» безжизненный уже был.
          А так пойдёшь к нему, сядешь и все, хорошо. И я придумывал для него
          новые сценарии, что бы ему снять. Один я придумал — про Зою
          [Космодемьянскую] — это моя придумка.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[A—F]</Link>
          </sub>
        </>
      </BlockCinema>

      <Spacer half size={10} />

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. иосиф хейфиц"
        name="«впервые замужем»"
        url={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/70.jpg`}
        year="1979"
      />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>композитор — и режиссеры</SubTitle>
          <QuoteCinemaText>Галла Капицкая:</QuoteCinemaText>
          <>
            Почему Олег расставался с режиссерами? Дело в том, что он другой
            человек. Он — небожитель, а они — приземленные люди, как бы там ни
            было. Талантливые, безусловно, но не его группы крови. Его всегда
            интересовал в первую очередь материал. Если материал был хороший, то
            пусть Олег и терпеть не мог режиссера, он вкладывал в картину все,
            что мог.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—E]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <QuoteCinemaText>Сергей Ландо:</QuoteCinemaText>
          <>
            Режиссеры — конфликтные люди. Но для того, чтобы работать с
            Каравайчуком, нужно было иметь хорошие нервы. Потому что он
            неизвестно когда и что напишет, и совсем не поддается дрессировке. У
            него обязательно будет что-то свое, не то, что ждешь от цифры до
            цифры. Неожиданное. Не говоря уже о том, что он попросит
            какие-нибудь железки со свалки, будет по ним бить, завывать,
            музыканты тоже будут завывать. Рискованно слишком. Не всякий пойдет
            на такой риск. И кроме того, отпугивали свободолюбие и некоторая
            необязательность.
          </>
          <Spacer half size={4} />
          <>
            Мощный Авербах, бывший боксер, бегал за несчастным Каравайчуком,
            который вовремя не сдал музыку, а хрупкий маленький Каравайчук от
            него ускользал, боясь физического воздействия. Отношения были
            сложные.
          </>
          <Spacer half size={4} />
          <>
            Рискну заявить, что чтобы работать с Каравайчуком, режиссеру нужно
            было его любить.
          </>
          <Spacer half size={4} />
          <>
            Он никогда не работал с режиссерами-диктаторами. Ни с Германом, ни с
            Сокуровым. Наверное потому, что они изначально знали, чего хотят и
            пытались сделать то, что задумали. А с Каравайчуком требовалось
            отказаться от первоначального замысла.
          </>
          <Spacer half size={4} />
          <>
            С молодыми режиссерами работал неслучайно, как раз потому, что они
            не проявляли в силу юности диктаторских наклонностей. И для того,
            чтобы добиться результата, терпели многое, чего мэтры бы не
            выдержали.
          </>
          <Spacer half size={4} />
          <>
            В целом Каравайчук недолюбливал режиссеров. За исключением может
            быть только тех, с кем ему не удалось поработать: Тарковского,
            Бондарчука, Параджанова, Шукшина. Более того, иногда говорил: «Ну
            что это за профессия? Вот оператор — истинный творец, а режиссер
            только комбинирует чего-то».{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—F]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>хейфиц</SubTitle>
        <>
          Про мою музыку, что она нарушает пищеварение, твердил в своем
          «ленфильмовском» объединении Иосиф Хейфиц на худсоветах, изгоняя ее из
          всех картин.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[B—A]</Link>
          </sub>
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <QuoteCinemaText>Александр Шпагин:</QuoteCinemaText>
          <>
            Хейфиц, классик, перед которым все трепетали, послушав его вальсовый
            букет в предыдущих картинах, вдруг приглашает Каравайчука писать
            музыку к своей картине «Ася» [1977]. Ему был нужен вальс, что-то
            въедливое и запоминающееся. И, надо сказать, что ход был очень
            точный. Хейфиц понимает, что находит своего композитора. И сделает с
            ним еще два фильма.
          </>
          <Spacer half size={4} />
          <Image
            alt="Олег Каравайчук"
            src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/71.jpg`}
          />
          <Spacer half size={4} />
          <StyledReactPlayer
            auto
            controls
            url={`${PUBLIC_URL}/assets/audio/karavajchuk/31 АСЯ | ФИНАЛ.mp3`}
          />
          <Spacer half size={4} />
          <StyledReactPlayer
            auto
            controls
            url={`${PUBLIC_URL}/assets/audio/karavajchuk/32 АСЯ | ВАЛЬС ДЛЯ ШАРМАНКИ.mp3`}
          />
          <Spacer half size={4} />
          <>
            «Впервые замужем» — картина очень своеобразная. Не случайно берется
            [Павел] Нилин, который превращается в 1960-е годы в одного из самых
            передовых писателей. Картина сложная, хотя сделана нарочито
            однозначно, и в этой однозначности, и в этих морально-этических
            знаках, дикая фронда авторов по отношению к совку.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—H]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>гребни для лошадиных хвостов</SubTitle>
          <QuoteCinemaText>
            Сергей Гельвер,
            <br />
            архивист:
          </QuoteCinemaText>
          <>
            В 1979 году я устроился на «Ленфильм» рабочим, будучи студентом
            института киноинженеров. Каждый день нужно было что-то делать:
            привозить аппаратуру, декорации. Я был увлечен кино и пристал к
            одной группе. Рабочее название картины было «Из жизни женщины».
          </>
          <Spacer half size={4} />
          <>
            Еще до записи музыки мне дали задание как ассистенту, поручили пойти
            в зоопарк, чтобы найти особенные гребни, которыми расчесывают хвосты
            лошадям — якобы они сделаны из особого дерева. Задание дал Олег
            Николаевич. Я пришел в зоопарк, ничего подобного там не оказалось,
            нужно было найти то, чего не было.
          </>
          <Spacer half size={4} />
          <>
            Когда наступил момент записи музыки, на две ночи был арендован зал
            Капеллы, со студией звукозаписи — комнатой, где стоял не очень
            большой микшерский пульт. В зале, на сцене, располагался женский хор
            и оркестр, там были длинные двухметровые трубы, они издавали рев и
            было похоже на мычание стада коров. Основная тема начиналась именно
            с этого звука. Потом вступал хор с очень красивой мелодией. Но когда
            начался монтаж музыки, этих коров отрезали. Это было очень интересно
            с точки зрения музыки, но в картину на титры не вставало, слишком
            был активный звук. Причем то, что это звучание инструментов, а не
            коров, можно было понять только потом.
          </>

          <Spacer half size={4} />
          <Image
            alt="Олег Каравайчук"
            src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/72.jpg`}
          />
          <Spacer half size={4} />
          <StyledReactPlayer
            auto
            controls
            url={`${PUBLIC_URL}/assets/audio/karavajchuk/33 ВПЕРВЫЕ ЗАМУЖЕМ.mp3`}
          />
          <Spacer half size={10} />

          <SubTitle>«ритмичная музыка вторична»</SubTitle>
          <>
            Помимо хора записывали рояль. Там был молодой парень, который
            вставлял резинки в струны, они были порезаны на треугольники.
          </>
          <Spacer half size={4} />
          <>
            Каравайчук был то на сцене, то в аппаратной, очень подвижный, все
            время куда-то убегал, испарялся и опять возникал, Хейфиц даже хотел
            приставить к нему ассистента. На ходу Олег Николаевич делал какие-то
            замечания девушкам из хора, я помню только одно из них: «Ритмичная
            музыка вторична». Он очень много чего объяснял хору еще на
            репетиции, и в целом, и кому-то отдельно, работал с ним. Он и
            дирижировал, а потом играл партию на рояле.
          </>
          <Spacer half size={4} />
          <>
            Это были все музыканты, которых он сам позвал, и было видно, что он
            с ними накоротке, это не было общением «сверху вниз». Я ощутил, что
            все получают удовольствие.
          </>
          <Spacer half size={4} />
          <>
            Хейфиц тоже был очень доволен музыкой и во время записи в процессы
            не вмешивался, всем командовал Каравайчук. Хотя Хейфиц мог сказать
            очень острые слова. И довольно часто говорил неприятные вещи многим
            людям.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—C]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={14} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>1980-е</SubTitle>
          <QuoteCinemaText>Александр Шпагин:</QuoteCinemaText>
          <>
            В 1980-е ирреальность его начинает зашкаливать. С каждым годом он
            становится капризнее и капризнее — работать с ним становится все
            сложнее. Теперь на что попало он не соглашается — выбирает только те
            проекты, которые ему интересны. Если войдет в тему по уши, напишет
            потрясающий саундтрек (как, например, в «Черной курице» [1980], где
            он погрузится в русское мелодическое начало допушкинских времен, или
            в «Новых приключениях янки при дворе короля Артура» [1988], где мы
            нырнем с головой в некое мистическое Средневековье). Но чаще —
            засунет в очередной фильм ту музыку, которая уже где-то была
            использована.
          </>
          <Spacer half size={4} />
          <>
            Возмущенный Авербах выкинет его за это из окна «Ленфильма» со
            второго этажа — с Каравайчуком ничего не случится, другие просто
            будут недоумевать: как же так! Он же делал вид, что писал — приносил
            варианты, творил, выдумывал, пробовал…{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[B—S]</Link>
            </sub>
          </>
          <Spacer half size={4} />
          <>
            Просто Авербах зашел в павильон «Ленфильма» и услышал в другой
            картине ровно ту же музыку, какую Каравайчук принес ему. И это его
            вывело совершенно из себя.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—H]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle> камень и хрусталь</SubTitle>
          <QuoteCinemaText>Сергей Астахов:</QuoteCinemaText>
          <>
            С ним было тяжело… Многие его не брали, хотя очень хотели, потому
            что с ним было очень трудно. Ну вот на «Ленфильме» есть легенда не
            легенда про камень. Он сказал, что для записи ему необходимо
            принести в тон-студию камень, большой такой валун с залива. Для
            особого звука. Ну, если Каравайчук сказал — нужно камень, значит,
            нужно камень. И вот организовали доставку этого валуна с залива,
            нанимали кран, специальный грузовик, бригаду, привезли его на
            «Ленфильм». А он пришел, ударил, послушал и говорит — нет, здесь не
            звучит. Тащите его обратно.
          </>
          <Spacer half size={4} />
          <>
            Или вот для какой-то картины Авербаха он должен был написать музыку,
            уже сроки подходят, на него наседают — когда же будет музыка, когда…
            И вот осталось совсем немного времени, и Каравайчук попросил
            съемочную группу принести из дома хрусталь, какой есть. И вот все
            принесли, он его отобрал, проверил, расставил в студии, где-то налил
            водички, где-то нет. И карандашиком сыграл музыку для фильма. Сразу.
            Всю. Гениальную музыку…{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[B—J]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>господин оформитель</SubTitle>
          <QuoteCinemaText>Александр Шпагин:</QuoteCinemaText>
          <>
            Он станет фантастическим — тут это слово очень подходит — мастером
            саунда в кино. Господином Оформителем. Будет создавать невероятно
            творческое закадровое звуковое пространство. Пространство
            запредельно свободное, открытое всем ветрам и стилям, и при этом
            абсолютно ирреальное, мистифицированное. Впрочем, при этом, как
            правило, точно работающее на образ фильма и не отходящее в сторону
            от доминирующего мелодического начала.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[B—S]</Link>
            </sub>
          </>
          <Spacer half size={10} />
          <QuoteCinemaText>Надежда Кожушаная:</QuoteCinemaText>
          <>
            Автор [музыки к фильму «Нога» Никиты Тягунова, 1991] — «моя любовь»
            Олег Николаевич Каравайчук. Он прекрасно чувствует материал. Никита
            Тягунов рассказывал, как для своих экспериментов Каравайчук требовал
            записи шумов, от горящей мечети до гремучей змеи, ползущей по песку.
            Нашему звукооператору, когда он прочитал список таких шумов, стало
            дурно. А Олег Николаевич загонял их на дискету и воспроизводил ту
            музыку, которая звучит. Как ни странно, своей «дурной» музыкой из
            машины он сделал фильм прокатным. На ней мы с ним сошлись, потому
            что у меня такое же отношение к этой самой войне. Это что-то вне
            человеческого разума.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—I]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema black>
        <Freedom />
        <Spacer half size={8} />
        <SubTitle inverted>заветная мечта</SubTitle>
        <>
          В этот момент мне уже очень хотелось играть. На рояле — потому что я
          понял, что так никто не играет. Главное, что у меня руки становятся
          все лучше и лучше. Я никак не могу это понять, я совершенно не
          занимаюсь, не играю, не бегаю, ни физкультуры, ни йоги. Разве только
          мало ем, я очень мало ем. Вешу всю жизнь 42 кило, а главное, что мои
          руки настолько легкие — настолько они поднимаются. Я даже сам не
          понимаю, как это все происходит и, главное, совершенно без устали, они
          крутятся как колесо, совершенно точно по циркулю. И я никогда их не
          чувствую.
        </>
        <>
          И, конечно, мне захотелось играть, очень хотелось поиграть людям. Это
          стала моя заветная мечта. Чтобы люди поняли, что они ходят в общем-то
          не на музыку.
        </>

        <Spacer half size={10} />

        <SubTitle inverted>не остаться в истории </SubTitle>
        <>
          Вообще, я как-то не хочу оставаться в истории, не хочу оставаться на
          Земле. Я очень много не записываю [из того], что сочиняю. Как-то я был
          у Софьи Васильевны [мать Дмитрия Шостаковича], и Шостакович мне
          сказал: «Ой, говорят, что вы не любите записывать?» Но я счастлив, что
          очень часто не люблю записывать, потому что когда в один день очень
          много наделаешь, насочиняешь, то проживаешь целую тысячу лет. Потому
          что человек делает себя рабом своих сочинений и часто торчит на месте.
        </>
        <>
          Но сыграть именно пока я живу, пока у меня такие руки — играю я без
          всякой усталости, — очень мне хочется, конечно. Тем более, что люди, с
          которыми я все время сталкивался — то Кира Муратова, то какой-нибудь
          помреж, то еще кто-то, обязательно говорили — в вас гибнет великий
          пианист. Да еще папе все время говорили, и маме.
        </>

        <Spacer half size={10} />

        <SubTitle inverted>человек-добро</SubTitle>
        <>
          И тут удивительная пошла вещь, как-то Стржельчик, мой большой друг,
          решил мне помочь и пошел к начальнику Ленинградского управления по
          музыке — [Борису] Скворцову. Это такая личность, которая могла
          сказать: «Я смотрю ваш спектакль и даю добро, и спектаклю, и вашей
          музыке — добро». Вот этот самый человек-добро, оказывается слышал меня
          еще в студенческие годы. И когда Стржельчик пришел к нему с просьбой,
          чтобы я все-таки играл, потому что актеры этого хотят, и все этого
          хотят, то Скворцов сказал: «Да, да, конечно, его игру я никогда не
          забуду! Я даже точно помню, что он играл — всю программу помню, он так
          играл!... Но я ведь за пределы отвечаю, а он играет за всеми
          пределами, я его не пущу. К тому же он нетарифицированный музыкант, он
          не хочет идти на комиссию тарифицироваться».
        </>

        <Spacer half size={10} />

        <SubTitle inverted>нетарифицированный музыкант</SubTitle>
        <>
          А я не хочу идти на комиссию, и не хотел, и не пойду, потому что
          комиссии эти все… В основе их лежит звание. А я не хочу быть народным,
          не хочу быть заслуженным, героем труда не хочу быть, и вообще я не
          хочу никем быть, и членом союза композиторов я не хочу быть. Никем не
          хочу быть.
        </>
        <>
          И если у меня нет тарификации, то не имею я права идти на эстраду,
          поэтому мне нужна тарификация. А если у меня будет тарификация, мне
          дадут пять рублей за концерт. А с биркой в пять рублей я не хочу идти,
          я вообще не хочу играть за деньги. Поэтому я говорю — я буду играть
          бесплатно. Нет, это тоже нельзя.
        </>
        <>
          А потом он под предлогом моей нетарифицированности срывал мне все
          афиши, потому что концертные организации хотели, чтобы я играл. С
          1961-го по 1981-й — он сидел там почти все это время непрерывно и
          непрерывно срывал все, что кто-либо хотел делать.
        </>
        <>
          С тех пор опять начался большой перерыв, опять мои афиши снимали, и с
          тех пор я уже по сути и не хотел больше играть. Не хотел, и все.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link style={{ color: "#fff" }} to="/karavajchuk/source">
              [A—B]
            </Link>
          </sub>
        </>
      </BlockCinema>

      {/* ------------------------------------------------------------------ */}

      <FilmCover
        director="реж. виктор гресь"
        name="«черная курица, или подземные жители»"
        url={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/73.jpg`}
        year="1980"
      />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <QuoteCinemaText>Александр Шпагин:</QuoteCinemaText>
          <>
            В этой картине Каравайчук находит отдушину и становится прекрасным
            иллюстратором самодостаточных художественных миров. «Черная курица»
            для него — любимый фильм 1980-х годов, в котором ему дается
            карт-бланш делать все что угодно, но в стилистических рамках музыки
            конца XVIII века.
          </>
          <Spacer half size={4} />
          <StyledReactPlayer
            auto
            controls
            url={`${PUBLIC_URL}/assets/audio/karavajchuk/35_Черная курица_Музыканты.mp3`}
          />
          <Spacer half size={10} />
          <SubTitle>гресь</SubTitle>
          <>
            И ему это ужасно нравится, и очень нравится [режиссер Виктор] Гресь,
            о котором Каравайчук все время говорит в превосходных тонах: Гресь —
            гений, который улавливает весь смысл, потому он такой
            неорганизованный, поэтому он так мало поставил и никто ему не даст
            больше снимать.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—H]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <QuoteCinemaText>
            Евгений Марголит,
            <br />
            киновед:
          </QuoteCinemaText>
          <>
            Более чем за тридцать лет в кино Виктор Гресь поставил три
            среднеметражных и два полнометражных фильма. Придя на Киностудию им.
            Довженко в период расцвета «поэтического кино», он все же не может
            быть к этому направлению причислен. Гресь — сам себе направление и
            не способен снимать никакое другое кино, кроме собственного. Лишь
            «сверхизобразительность» его картин свидетельствует об общих с
            «поэтическим кино» традициях, выдает их, как мягкий украинский
            акцент.
          </>
          <Spacer half size={4} />
          <>
            Несмотря на обескураживающую скромность послужного списка, Виктор
            Гресь — один из самых ярких авторов 1980-х. Таковым его сделала
            прежде всего совершенно необузданная, невероятная и непредсказуемая
            в своей прихотливости творческая фантазия.
          </>

          <Spacer half size={10} />
          <SubTitle>ответственность художника</SubTitle>
          <>
            При воплощении причудливый мир отделяется от автора и существует по
            законам, уже не зависящим от творца, который вступает с ним в
            сложные отношения. По сути, эти отношения и составляют глубинный,
            сущностный сюжет картин Греся, особенно в 1980-е годы. Отказ от
            ответственности за сотворенный мир равносилен гибели этого мира,
            что, в свою очередь, ведет и к внутреннему опустошению художника.
          </>
          <Spacer half size={1} />
          <>
            Потому трогательная, изобретательная, в меру дидактичная сказка
            Антония Погорельского, написанная им для любимого племянника,
            превращается у Греся в недетски серьезную, напряженную философскую
            притчу, где казарменный режим распаляет потаенную поэтическую
            фантазию, с одной стороны, и стремится уничтожить, вытравить ее
            любыми средствами — с другой.
          </>

          <Spacer half size={10} />
          <SubTitle>бескомпромиссность</SubTitle>
          <>
            В советской системе кинопроизводства, всеми силами пытавшейся в
            последний свой период избавиться от авторского кино, для таких
            художников, как Гресь, все же оставался шанс на постановку — хотя бы
            потому, что студия, как правило, обеспечивала работой штатных
            режиссеров. В рыночной же ситуации подобный шанс бесконечно мал.
          </>
          <Spacer half size={4} />
          <>
            Случай Виктора Греся, не знавшего и не желающего знать компромиссов
            в осуществлении своих фантазий, особенно труден. Ему необходимо,
            чтобы замысел был воплощен в полной мере, иначе само воплощение не
            имеет смысла. Понятно, почему блистательные авторские затеи Греся
            остаются нереализованными. Декорации к его фильмам почти невозможно
            представить в нынешних павильонах Киностудии им. Довженко.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—J]</Link>
            </sub>
          </>
        </QuoteCinema>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <></>
        <QuoteCinema>
          <SubTitle>отрегулированные дети</SubTitle>
          <QuoteCinemaText>
            Валерий Кичин,
            <br />
            кинокритик [в рецензии на фильм, 1981]:{" "}
          </QuoteCinemaText>
          <>
            Получился рассказ о том, без чего не живет детская душа, без чего
            она вянет и мучается. Рассказ о любви. Строгий век делает из Алеши
            аскета. Папенька и маменька далеко, их можно увидеть только во сне.
            В пансионе безрадостно, потому что все регламентировано, загнано в
            рамки. Призрак солдатчины витает над романтической стариной — за
            дырявым забором топочут роты. В пудреных паричках маленькие взрослые
            — отрегулированные дети. Дети, возводимые, как Петербург, по плану.
            А хочется так многого! Так много нужно успеть понять и
            почувствовать, пока детство. Тяжесть рыцарских доспехов и нежность
            материнской руки.
          </>

          <Spacer half size={10} />

          <SubTitle>судьба вундеркинда </SubTitle>
          <>
            Удивительно звучание фильма. Работа композитора Каравайчука:
            хрустальные перезвоны, клавесин, Перголези, чистое пение трубы,
            громыхание военных маршей — все, едва запев, тут же возвращается к
            первым тактам, все как бы разучивается, репетируется, здесь начала
            многого, разного и, как каждое начало, прекрасного. А когда в жизнь
            героя входит ложь, когда незаслуженные почести ведут его к гордыне —
            тут и начинается суета. Фильм показывает стремительную «карьеру»
            маленького вундеркинда в ритме галопа. С суетой теряется главное —
            чистота и гармония детства.
          </>
          <Spacer half size={4} />
          <>
            Трижды повторится в фильме финальный кадр: из ослепительного сияния
            неизвестности, как на фотокарточке, проявятся лица далеких маменьки
            и папеньки.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[E—G]</Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={4} />
        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/36_Черная курица_Хор.mp3`}
        />
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema black>
        <Freedom />
        <Spacer half size={8} />
        <SubTitle inverted>1990-е.</SubTitle>
        <SubTitle inverted>конец хрустальной эпохи</SubTitle>
        <>
          У меня всю жизнь была учительница. Когда в 82 года ее разбил инсульт,
          она прожила еще почти десять лет, хотя была полностью парализована. И
          продолжала помнить все-все телефоны наизусть. Когда она первый раз
          забыла телефон, я понял, что она умрет.
        </>
        <>
          Последние семь лет я всегда должен был успеть вечером вовремя
          выключить радио, чтобы она не услышала этот гимн. Я понял, что
          советский гимн — это не просто гимн, а это несоответствие эпохи
          железной эпохе хрустальной. Мама моя была как стекло — прозрачной,
          хрустальной, а когда проводишь по стеклу железным гвоздем, то боль
          стекла передается его звуком.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link style={{ color: "#fff" }} to="/karavajchuk/source">
              [A—L]
            </Link>
          </sub>
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <QuoteCinema>
          <SubTitle>дряхлеющее время</SubTitle>
          <QuoteCinemaText>Сергей Ландо:</QuoteCinemaText>
          <>
            <span style={{ textTransform: "uppercase" }}>В</span> 1990-х ушли те
            режиссеры, которые ему были близки, несмотря на конфликты, —
            достаточно крупные режиссеры. Золотой век «Ленфильма» закончился.
            Появились новые имена, но они были начиняющими для Каравайчука, а у
            него все же был определенный снобизм. Они его не очень интересовали.
            А что касается Александра Николаевича Сокурова и Алексея Юрьевича
            Германа, режиссеры-диктаторы с ним не могли сотрудничать.
          </>{" "}
          <Spacer half size={4} />
          <>
            Он сотрудничал с молодыми, иногда со случайными людьми, на мой
            взгляд. Но влюбляться в режиссерский замысел — в 1990-е годы это
            было не для него.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link to="/karavajchuk/source">[C—F]</Link>
            </sub>
          </>
        </QuoteCinema>

        <Spacer half size={10} />

        <>
          По сути дела все, что с «Ленфильмом» связано, кончено. В целом
          «Ленфильм» — загадочная историческая область. Ни плохая, ни хорошая.
          Время дряхлеет. Если бы часы не дряхлели, они бы не крутились, они бы
          полетели. Если бы человек не был дряхлым, он бы творил музыку, и кровь
          бы вылетала фонтаном, а потом — обратно втекала.
        </>
        <Spacer half size={4} />
        <>
          Но студия дает возможность для необычайных встреч. Такой незабываемый
          режиссер, как Резо Эсадзе, который делал «Секундомер». Он мой
          единственный друг. Резо Эсадзе так понял мою «Черную мессу»! И еще
          оператор [Валерий] Федосов. Он принял смерть моей мамы и сам
          неожиданно умер через три дня от разрыва сердца. Да, и я очень дружил
          с Динарой Асановой. А Динара Асанова была очень добрый человек, она из
          своих денег помогала Сокурову снимать. Киргизка. Киргизы,
          азербайджанцы — это совсем другое. И Москвин же был мой друг!
        </>
        <Spacer half size={4} />
        <>
          Студия давала понимать, что искусство — трудное дело. Ведь снимали по
          шесть-восемь дублей. А потом гениальная монтажница [Евгения]
          Маханькова, из-за которой умер Козинцев, все соединяла. Маханькову
          уволили по выслуге лет, и Козинцев расстроился, был инфаркт, Козинцев
          здесь упал, когда он пришел защищать Маханькову. Это была самая
          великая монтажница. Она Авербаху «Монолог» клеила, и все фильмы
          Козинцева. Формальность советской власти. Для того, чтобы кому-то
          увеличить зарплату, нужно кого-то уволить. Уволили Маханькову. И вот
          тут Козинцев сказал, что это скандально, мы теряем профессию, и упал.
          Инфаркт.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[A—A]</Link>
          </sub>
        </>

        <Spacer half size={4} />
        <Image
          alt="Олег Каравайчук"
          src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/74.jpg`}
        />
        <Spacer half size={4} />

        <SubTitle>порядок нот</SubTitle>

        <>
          Кино — это все-таки не прикладное искусство. И писание для него — не
          отхожий промысел. Я, действительно, музыку для кино быстро пишу. И
          слышу порой, дескать, халтурю. Но и свою домашнюю пишу страшно быстро.
          В кино писать даже сложнее, чем свой мадригал или мотет. К фильмам все
          более и более трудно музыка подходит. Там теперь ищут в виде звука
          слякоти. Это пустые искания, это как раз легкий путь, проституция. Не
          музыка то, что достигается с помощью акустики, электроники, прочих
          сходных изобретений.
        </>
        <Spacer half size={4} />
        <>
          Допустимы только ноты, чистые ноты. Сам порядок нот создает в человеке
          нужный звук. Он как бы детонирует. По нему бьют молоточком, а он
          передает столько звука, сколько требуется для данного случая.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[B—A]</Link>
          </sub>{" "}
        </>
        <Spacer half size={4} />
        <>
          Я к кино отношусь как к музыке. Экран должен просто показывать что-то.
          А когда там идеи начинаются — это уже не кино, а трибуна.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[B—C]</Link>
          </sub>{" "}
        </>
        <Spacer half size={4} />
        <>
          Я не люблю идеи. Меня мало кто в этом поддерживает.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[B—F]</Link>
          </sub>
        </>
        <Spacer half size={4} />
        <>
          Единственное, что я понял за свою жизнь, — что нота, обогащенная
          психологией, философией, пусть они даже великие, — она хуже, чем
          просто нота. Нота в миллионы раз выше человеческой психологии. Поэтому
          о музыке нельзя думать. Играешь себе, и все. Ни чувствовать, ни
          романтизировать, ни идеи вкладывать — взял и сыграл.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[B—C]</Link>
          </sub>
        </>
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema>
        <SubTitle>музыка умирает </SubTitle>

        <Image
          alt="Олег Каравайчук"
          src={`${PUBLIC_URL}/assets/images/karavajchuk/cinema/75.jpg`}
        />

        <>
          Музыка, в общем, умирает вместе с автором. И вместе со мной все равно
          моя музыка умрет — записывай ее, не записывай. […] После меня мою
          музыку никто не сыграет, как и после Рахманинова, не получается…
          Почему невозможно? Нота, рожденная настоящим композитором, отличается
          от той, которую берут исполнители. Это как женские бедра, торс, ноги —
          и купальник, принявший их линии.
        </>
        <>
          Поэтому я так дорожу тем из моего творчества, что сохранится с
          фильмами.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[B—A]</Link>
          </sub>
        </>
        <>
          Так что у меня, пожалуй, все. Я по-деловому рассказал все, что было. И
          мне абсолютно безразлично, я не испытываю сейчас ни малейшего
          мемуарного сладострастного и нарцисского к себе отношения. И мне
          совершенно безразлично слышали ли вы то, о чем я рассказал или не
          слышали, и рассказывал я об этом или не рассказывал.
        </>
        <>Я уйду и забуду об этом.</>
        <>И буду сочинять.</>
        <>
          Желаю вам.{" "}
          <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
            <Link to="/karavajchuk/source">[A—B]</Link>
          </sub>
        </>
        <Spacer half size={4} />
        <StyledReactPlayer
          auto
          controls
          url={`${PUBLIC_URL}/assets/audio/karavajchuk/37 ПОГОНЯ.mp3`}
        />
      </BlockCinema>

      <Spacer half size={10} />

      <BlockCinema black>
        <Freedom />
        <Spacer half size={8} />
        <QuoteCinema inverted>
          <QuoteCinemaText inverted>Сергей Ландо:</QuoteCinemaText>
          <>
            Когда мамы не стало, он закрыл квартиру на Пятнадцатой линии
            Васильевского острова, уехал на дачу и стал там жить, не смотря ни
            на что. И в квартиру практически не возвращался.{" "}
            <sub style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}>
              <Link style={{ color: "#fff" }} to="/karavajchuk/source">
                [C—F]
              </Link>
            </sub>{" "}
          </>
        </QuoteCinema>
        <Spacer half size={20} />
      </BlockCinema>

      <Footer />
    </TypographyWrapper>
  );
};

export default Cinema;
