/* eslint max-len:0 */

const concerts = [
  {
    title: "ЭЛЕГИЯ для фортепиано",
    url: "",
    year: "1933",
  },
  {
    title: "КОЛЫБЕЛЬНАЯ для виолончели и фортепиано",
    url: "",
    year: "1937",
  },
  {
    title: "НОВОГОДНЯЯ ЕЛКА (песня для голоса и фортепиано)",
    url: "",
    year: "1938",
  },
  {
    title: "БАТТЕРФЛЯЙ В КИТАЕ",
    url: "https://music.yandex.ru/album/3989211/track/32712068",
    year: "1998",
  },
  {
    title: "ПОСЛЕДНИЙ ВАЛЬС НИКОЛАЯ II",
    url: "https://music.yandex.ru/album/3989211/track/32712071",
    year: "1998",
  },
  {
    title: "ВАЛЬС ЕКАТЕРИНЫ ВЕЛИКОЙ С ФАВОРИТАМИ",
    url: "https://music.yandex.ru/album/3989211/track/32712070",
    year: "1990",
  },
  {
    title: "ВАЛЬС АВРОРА",
    url: "https://music.yandex.ru/album/3989211/track/32712073",
    year: "1990",
  },
  {
    title: "ВАЛЬС НОБЕЛЕВСКИХ ЛАУРЕАТОВ",
    url: "https://music.yandex.ru/album/3989211/track/32712066",
    year: "2001",
  },
  {
    title: "ВАРЬЕТЕ",
    url: "https://music.yandex.ru/album/3989211/track/32712067",
    year: "2001",
  },
  {
    title: "АВЕ (прелюдия)",
    url: "https://music.yandex.ru/album/3989211/track/32712069",
    year: "2001",
  },
  {
    title: "ОДА ПАМЯТНИКУ АЛЕКСАНДРУ III РАБОТЫ ПАОЛО ТРУБЕЦКОГО",
    url: "https://music.yandex.ru/album/3989211/track/32712075",
    year: "2001",
  },
  {
    title: "ЧАЙКОВСКИЙ, ГИПОТЕЗА",
    url: "https://music.yandex.ru/album/3989211/track/32712072",
    year: "2001",
  },
  {
    title: "СУЛИКО",
    url: "https://music.yandex.ru/album/3989211/track/32712074",
    year: "2003",
  },
  {
    title: "МАССАГЕТЫ (15 вальсов, о которых неизвестно ничего кроме сюжета)",
    url: "https://music.yandex.ru/album/4977824",
    year: "2003",
  },
  {
    title: "CONCERTO GROSSO",
    url: "https://music.yandex.ru/album/198043",
    year: "2007",
  },
  {
    title:
      "МУСАГЕТ (балет в 10-ти сценах, в котором либретто не объясняет сюжет)",
    url: "https://music.yandex.ru/album/4980813",
    year: "2007",
  },
  {
    title: "ПАРАНДЖА",
    url: "",
    year: "2010",
  },
  {
    title: "ТОККАТА",
    url: "",
    year: "2011",
  },
  {
    title: "ОЗЕРО",
    url: "",
    year: "2011",
  },
  {
    title: "СИРЕНЫ",
    url: "",
    year: "2011",
  },
  {
    title: "БЕТХОВЕН",
    url: "",
    year: "2011",
  },
  {
    title: "ПЕТР ВЕЛИКИЙ",
    url: "",
    year: "2012",
  },
  {
    title: "НЕБЕЛОЕ ADAGIO",
    url: "",
    year: "2012",
  },
  {
    title: "PA-DE-DE",
    url: "",
    year: "2012",
  },
  {
    title: "ИВАН ГРОЗНЫЙ",
    url: "",
    year: "2012",
  },
  {
    title: "МАМА РОМА",
    url: "",
    year: "2013",
  },
  {
    title: "МАМА РОМА РЕКВИЕМ",
    url: "",
    year: "2013",
  },
  {
    title: "ТАНГО",
    url: "",
    year: "2014",
  },
  {
    title: "ГРОБНЫЙ ВАЛЬС",
    url: "",
    year: "2014",
  },
  {
    title: "ЩЕЛКУНЧИК",
    url: "",
    year: "2014",
  },
  {
    title: "ТАНГО ВЕЛИКОГО АКТЕРА ГЕРМАНИИ КОНРАДА ВЕЙДТА",
    url: "",
    year: "2014",
  },
  {
    title: "ИТАЛЬЯНСКАЯ ШТУЧКА",
    url: "",
    year: "2014",
  },
  {
    title: "ВАЛЬС «ЭРМИТАЖ»",
    url: "",
    year: "2014",
  },
  {
    title: "БОСХ – СМЕРТЬ В РАЮ",
    url: "",
    year: "2015",
  },
  {
    title: "ВЕЛИКАЯ МУЗЫКА",
    url: "",
    year: "2015",
  },
  {
    title: "ВЕНСКИЙ ВАЛЬС",
    url: "",
    year: "2016",
  },
];

export default concerts;
