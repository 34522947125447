import React from "react";
import styled, { css } from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
// import { Link } from "react-router-dom";

// import Dots from "./SidesOtherDots";
import Spacer from "./Spacer";
import { mediaQuery } from "../utils";

type Props = {
  children: React.ReactNode;
};

const HyperLink = styled.a`
  display: inline-block;
`;

// const LeftSide = styled.div`
//   justify-content: space-between;
//   margin-left: -200px;
//   margin-top: 105px;
//   transform: rotate(-90deg);
//   width: 500px;

//   ${mediaQuery(
//     "phone",
//     css`
//       display: none;
//     `
//   )}
// `;

// const LeftSideItem = styled.div`
//   display: inline-block;
//   font-size: 24px;
//   text-align: center;
// `;

const RightSide = styled.div`
  display: none;
  justify-content: space-between;
  margin-left: -330px;
  margin-top: -20px;
  transform: rotate(-90deg);
  width: 600px;

  ${mediaQuery(
    "phone",
    css`
      display: none;
    `,
  )}
`;

// const StyledLink = styled(Link)`
//   display: inline-block;
// `;

const SidesHome: React.FC<Props> = ({ children }: Props) => (
  <Container>
    <Row>
      <Col lg={{ span: 1 }} xs={{ span: 1 }}>
        {/* <LeftSide>
          <LeftSideItem>
            <Link to="/shnitke/cinema">
              кино
              <Dots />
            </Link>
          </LeftSideItem>
          <Spacer size={18} vertical={false} />
          <LeftSideItem>
            <Link to="/shnitke/music">
              музыка
              <Dots />
            </Link>
          </LeftSideItem>
          <Spacer size={18} vertical={false} />
          <LeftSideItem>
            <Link to="/shnitke/time">
              время
              <Dots />
            </Link>
          </LeftSideItem>
        </LeftSide> */}
      </Col>

      <Col lg={{ span: 10 }} xs={{ span: 10 }}>
        {children}
      </Col>

      <Col lg={{ span: 1 }} xs={{ span: 1 }}>
        <RightSide>
          {/* <StyledLink to="/about">
            о проекте
          </StyledLink>
          <Spacer size={18} vertical={false} /> */}
          <HyperLink href="https://t.me/threedegreesoffreedom">
            telegram
          </HyperLink>
          {/* <Spacer size={18} vertical={false} /> */}
          {/* <HyperLink href="https://www.facebook.com/3degreesoffreedom">
            fb
          </HyperLink> */}
          <Spacer size={18} vertical={false} />
          <HyperLink href="https://vk.com/3degreesoffreedom">vk</HyperLink>
          <Spacer size={18} vertical={false} />
          <HyperLink href="https://www.youtube.com/channel/UC4sfaNktpPbzqPmoZfJjDtA">
            youtube
          </HyperLink>
        </RightSide>
      </Col>
    </Row>
  </Container>
);

export default SidesHome;
