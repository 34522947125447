import React from "react";
import styled from "styled-components";

const DotsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 3px;
`;

const DotRow = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    margin-right: 3px;
  }
`;

const Dot = styled.div`
  background-color: #000;
  border-radius: 100%;
  height: 6px;
  width: 6px;
`;

const SidesShnitkeDots: React.FC = () => (
  <DotsWrapper>
    <DotRow>
      <Dot />
      <Dot />
    </DotRow>
    <Dot />
  </DotsWrapper>
);

export default SidesShnitkeDots;
