/* eslint react/jsx-one-expression-per-line: 0 */

import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import {
  Block,
  Footer,
  NamedHeader,
  PageIntroShnitke,
  SidesShnitke,
  Spacer,
  Title,
  TopImage,
  TypographyWrapper,
} from "../../../lib";

const { PUBLIC_URL } = process.env;

const Philosophy: React.FC = () => (
  <TypographyWrapper>
    <NamedHeader name="философия" />

    <PageIntroShnitke name="philosophy" />

    <TopImage url={`${PUBLIC_URL}/assets/images/shnitke/philosophy.jpeg`} />

    <Spacer size={10} />

    <SidesShnitke hideRight="philosophy">
      <Col
        lg={{ offset: 1, span: 10 }}
        style={{ transform: "translateX(20px)" }}
      >
        <Title size={72}>творчество</Title>
        <Spacer size={2} />
      </Col>

      <Block>
        <>беспрерывный экзамен перед собой</>
        <>
          Творчество – это беспрерывный экзамен перед собой, беспрерывное
          «подозревание себя» в полной невозможности, но с этим надо
          справляться.<Link to="/shnitke/source">[22]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block right>
        <>критерий истины – совесть композитора</>
        <>
          Не хочется произносить громких слов, но подлинный критерий истины –
          только совесть композитора,его ответственность перед своими действиями
          и перед главным действием, которое он совершает, – сочинение музыки.
          Только это дает ему внутреннюю мотивировку для использования того или
          иного приема, а банален он или нет – обнаруживается слушателями. И
          может быть, значительно позже…
          <Link to="/shnitke/source">[31]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block>
        <>магия и таинство творчества</>
        <>
          Магия и таинство творческого процесса – это та область, в которую
          опасно углубляться. Если человек начинает разыскивать магию и таинство
          своего дела, то он как бы сам в себе искореняет то, на что он может
          надеяться. Другими словами, если работа на этой магии основана, то
          человек, сознательно о ней размышляющий, ставит ее тут же под
          удар...[32]
        </>
      </Block>

      <Spacer size={10} />

      <Block right>
        <>власть и творчество</>
        <>
          Если бы творчество можно было понять, вывестиего форму, творчество
          перестало бы быть творчеством. Но такое не случится. Такое не может
          случиться. Первопричина творчества не какая-нибудь одна, не что-то
          такое, что можно вывести на уровне физического закона или
          математической формулы, хотя и они, как мы теперь знаем, весьма
          относительны. И этой непонятности, этой загадочности панически боятся
          те, кто хотел бы разъять жизнь на элементы, оприходовать их в какой-то
          огромной всемирной амбарной книге, чтобы контролировать любую ситуацию
          и руководить ею.
          <br />
          <br />
          Неподдающееся военной или бюрократической дисциплине творчество (да
          оно вообще не поддается никакой регламентации и ангажированности!) для
          тех, кто рвется к любым формам абсолютной власти, выглядит
          таинственным пугающ им врагом партизанского толка, от которого
          неизвестно что можно ожидать, но в любом случае – ничего для власть
          предержащих удобного… Мне кажется, что одна из причин того, что во
          времена жестоких, опирающихся на демагогию диктатур первой
          подвергается ударам культура – кажущаяся безнаказанность. В
          перспективе придет и возмездие, но не сию минуту, не сейчас, а
          когда-нибудь.
          <Link to="/shnitke/source">[33]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block>
        <>отражение социального – не главное в музыке</>
        <>
          Музыка, безусловно, может отражать какие-либо идеи, в том числе
          социальные, и не только в массовых песнях, но и в инструментальных
          крупных формах. Но, с другой стороны, она в наименьшей степени, по
          сравнению с другими искусствами, выражает идеи, отражая внутренний мир
          человека и его психологический строй. Мне кажется, что отражение
          социального в музыке не главное. И именно эти качества, если они и
          есть, со временем как бы выдыхаются, в то время как другие, связанные
          с эмоционально-ассоциативным спектром, сохраняются на значительно
          долгий период, если не на сотни лет.
          <Link to="/shnitke/source">[22]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block right>
        <>искусство необъяснимо и не имеет цели</>
        <>
          Искусство само по себе не объяснимо и не имеет цели. Как только
          искусство приспосабливается к какой-то цели, оно гибнет.
          <Link to="/shnitke/source">[22]</Link>
        </>
      </Block>

      <Spacer size={8} />

      <Col
        lg={{ offset: 1, span: 10 }}
        style={{ transform: "translateX(20px)" }}
      >
        <Title size={72}>компромисс</Title>
        <Spacer size={8} />
      </Col>

      <Block>
        <></>
        <>
          Я думаю, что исключать что-либо, в том числе и компромисс, для
          художника было бы неправильным. И вместе с тем нет ничего губительнее,
          чем компромисс. Жизнь ставит перед художником сложную задачу и дает
          ему только шанс, но не рецепт верного решения. Поэтому я не позволил
          бы себе примкнуть к строг им блюстителям правды, которые исключают
          самую возможность компромисса. И вместе с тем я не с теми, кто
          каждодневно работает на этот очень актуальный, но никогда не точный
          компромисс. Между этими двумя крайними сферами и находится правда. Она
          вибрирует, она никогда не прикована кристаллически к чему-то.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={8} />

      <Col
        lg={{ offset: 1, span: 10 }}
        style={{ transform: "translateX(20px)" }}
      >
        <Title size={72}>правда</Title>
        <Spacer size={2} />
      </Col>

      <Block>
        <>вечная способность уцелеть</>
        <>
          Все те вопросы, которые жизнь перед художником ставит, всегда содержат
          в себе шанс и для правды, и для лжи. Распространение лжи исключает
          возможность ежесекундного возникновения правды. Но вдруг появляются
          люди – как это происходит сейчас, – которые вопреки ошибочному
          формулированию правды уцелели. Почему-то количественное
          распространение лжи их не погубило. Это как бы исключительные фигуры.
          Но это подтверждает вечную способность правды уцелеть и опять
          утвердиться. Окончательного решения никто, в том числе и художник,
          дать не может. Но шанс найти правильный ответ в каждом конкретном
          случае – сохраняется во всех ситуациях. Существует, конечно,
          способность лжи выглядеть как правда, изображать правду. Но это очень
          быстро распознается. Что-то в самом произведении искусства
          свидетельствует о правде или неправде, независимо от усилий лгущих.
          Поэтому в конечном итоге эта правда всегда проявится. Другое дело: как
          долго ее ждать.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block right>
        <>ускользающая правда</>
        <>
          ...По слову поэта, «мысль изреченная есть ложь»... Это так и не так и
          по отношению к мысли, потому что здесь некий бесконечный круг. Как
          только ты говоришь, что мысль изреченная есть ложь, она перестает быть
          ложью, а становится правдой. Но в ту же секунду, как ты осознаешь, что
          в данном случае догнал хвост, ложь перестала быть ложью и стала
          правдой, ты в этот момент обрушиваешься опять в ложь. Вот бесконечный
          путь.Или то же самое с музыкой, так как только ты достиг правды и
          знаешь, как писать музыку, – это верный знак того, что ты в эту
          секунду перестал понимать, как ее писать. Ты должен каждый раз
          мучиться и находить в сотый раз способ ее писать, хотя ты вроде бы
          ничего нового не находишь. Тут важно не то, что ты пишешь новое, а
          насколько абсолютно твое искание.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={8} />

      <Col
        lg={{ offset: 1, span: 10 }}
        style={{ transform: "translateX(20px)" }}
      >
        <Title size={72}>сомнение</Title>
        <Spacer size={8} />
      </Col>

      <Block>
        <></>
        <>
          Я знаю, что тут момент, когда я перестану в себе сомневаться, будет
          для меня гибельным. Я должен иметь в себе постоянные сомнения в том,
          что я делаю не для того, чтобы покрасоваться своей требовательностью
          перед читателями, а просто потому, что так есть. И, слава Богу, что
          пока меня не покидает чувство неопределенности, которая только и может
          родить определенность. Если помните, кто-то из древних, по-моему,
          Сократ, формулировал так, что сомневаться – значит мыслить. Сомнение
          заставляет мысль работать на максимуме какой-то своей интенсивности.
          Для художника именно сомнение, неудовлетворенность – он может это
          осознавать или не осознавать – выступает как движок, катализатор,
          который заставляетего мысль,его психику работать на максимальных
          оборотах...
          <Link to="/shnitke/source">[34]</Link>
        </>
      </Block>

      <Spacer size={8} />

      <Col
        lg={{ offset: 1, span: 10 }}
        style={{ transform: "translateX(20px)" }}
      >
        <Title size={72}>ошибка </Title>
        <Spacer size={2} />
      </Col>

      <Block>
        <>ошибка неизбежна</>
        <>
          Математики, решая некоторые уравнения, вводят так называемые «ложные
          цифры», которые, уничтожаясь по ходу решения, помогают в итоге найти
          верный результат. Нечто подобное происходит и в творчестве. Ошибка
          (вернее то, что мы по инерции считаем ошибкой) в творчестве неизбежна,
          а иной раз – необходима. Для образования жемчужины в раковине, лежащей
          на дне океана, нужна песчинка – что-то «неправильное», инородное.
          Совсем как в искусстве, где истинно великое часто рождается «не по
          правилам». Примеров тому множество.
          <Link to="/shnitke/source">[35]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block right>
        <>всё существует благодаря ошибке</>
        <>
          У меня лично отношение к ошибкам такое, что ошибки есть единственная
          животворная почва для существования искусства. Я замечал, что,
          приступая к какому-то расчету по тому или иному эпизоду, послеего
          записи обнаруживаешь какую-то ошибку, исправляешь ее и в соответствии
          с эт им меняется и многое другое, а в результате все становится
          несколько хуже, хотя, казалось, ошибка исправлена – это так. Вообще,
          все, очевидно, существует благодаря ошибке. Если все ошибки сложить и
          вычесть одну за другой, устранить их, то останется только ноль.
          Искусство наверняка существует благодаря этому.
          <Link to="/shnitke/source">[22]</Link>
        </>
      </Block>

      <Spacer size={8} />

      <Col
        lg={{ offset: 1, span: 10 }}
        style={{ transform: "translateX(20px)" }}
      >
        <Title size={72}>воля</Title>
        <Spacer size={8} />
      </Col>

      <Block>
        <></>
        <>
          Есть люди, которые ставят перед собой цель, выполняют ее и проявляют
          при этом волю. Есть люди, которые не в состоянии поставить перед собой
          цель, достичь ее и проявить при этом «инструментальную волю», но вот
          именно они часто оказываются более цельными, в итоге как гибкая лиана
          прорываются сквозь ветви деревьев, становясь более сильными, чем те,
          которые поставили себе задачу и сво им инструментом – волей – эту
          задачу выполнили. Я бы отнес к этому типу, казалось бы слабовольных по
          внешн им признакам композиторов. Шостаковича, который был так им по
          натуре, что, как бы он на себя ни воздействовал, а он делал много
          попыток выдать то, что от него требовали, он все равно оставался сам
          им собой. Шостакович не мог себя разрушить, не хотел и, когда это
          делалось под давлением, оно не получалось. У него, видимо, был
          недостаток первой воли – он не мог заставить себя сломаться, не мог
          себя перебороть, но он был носителем какого-то более сильного
          импульса, который жил не его волей, а был вложен в него изначально. И
          вот этаего вторая воля – не персональная, а предназначения – она
          оказалась гораздо важнее, чем осознанная воля самовоспитания. Если бы
          Шостакович был более волевым в первом смысле человеком и внял бы всей
          окружающей его критике и всем терзаниям, то он этой волей себя
          истребил, принимая во вниманиеего исключительную многогранную
          деятельность и в кино, и в театре и так далее. Но у него, слава Богу,
          не было ее. Вот это заложенное свойство, оно и генетическое, и
          рационального происхождения, может, наконец, и божественного, может,
          оно и необъяснимого порядка, но оно важнее всего.
          <Link to="/shnitke/source">[22]</Link>
        </>
      </Block>

      <Spacer size={8} />

      <Col
        lg={{ offset: 1, span: 10 }}
        style={{ transform: "translateX(20px)" }}
      >
        <Title size={72}>свобода</Title>
        <Spacer size={2} />
      </Col>

      <Block>
        <>свобода – бесконечное приближение к прообразу</>
        <>
          Что касается свободы, то она может существовать только в приложении к
          определенным обстоятельствам. В приложении к абсолютному нулю,
          абсолютной бесконечности, возможности свободы нет. Так же и в музыке.
          Поэтому, что касается свободы проявления в процессе сочинения, то я
          представляю ее не как абсолютную свободу, которой нет, а как
          естественное исследование какого-то внутреннего импульса.
          <br />
          <br />
          Поясню так им примером: всякая музыка, которая существует в мире,
          представляется мне существовавшей и до сочинения ее конкретным
          композитором. Она имеет видимость объективно существующего некого
          творения природы, а композитор по отношению к ней – «приемником».
          <br />
          <br />
          …Иначе я не могу объяснить себе такой феномен: всякий раз, когда мне
          приходится слышать произведение, которое производило на меня сильное
          впечатление, мне всегда казалось, что я его уже слышал. Это происходит
          и на примитивном уровне, когда Вы слышите очень липучую тему, мелодию,
          которая очень легко запоминается, и на уровне уже концепционных вещей.
          Здесь также возникает ощущение такого рода – это правда, поскольку я
          ее уже знал. Ощущение, что произведение существует помимо вас и вам
          остается только вытащитьего из темноты, очисть и представить, – вот
          только так я могу понять саму свободу в творческом акте, свободу лишь
          в том, чтобы быть свободным от замутняющих слух влияний чего угодно,
          от влияний абстрактных рациональных схем, от влияния услышанной
          музыки, профессиональных советов. Только вот такая свобода.
          <Link to="/shnitke/source">[22]</Link>
        </>
      </Block>

      <Spacer size={8} />

      <Col
        lg={{ offset: 1, span: 10 }}
        style={{ transform: "translateX(20px)" }}
      >
        <Title size={72}>талант</Title>
        <Spacer size={2} />
      </Col>

      <Block>
        <>превышение самого себя</>
        <>
          Вероятно, так же обстоит дело и с музыкальным талантом – и здесь дело
          решается не только способностью слышать фальшивую ноту четвертого
          пульта альтов в громком тутти или восстанавливать давно услышанные
          симфонии по слуховой памяти, а прежде всего готовностью к крайнему
          напряжению, приводящему к умножению сил, к «превышению самого себя».
          Конечно, самого себя не обгонишь, природные способности ставят
          непреодолимую преграду. Но как много значит догнать самого себя!
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={8} />

      <Col
        lg={{ offset: 1, span: 10 }}
        style={{ transform: "translateX(20px)" }}
      >
        <Title size={72}>метод</Title>
        <Spacer size={2} />
      </Col>

      <Block>
        <>не сочинить – услышать</>
        <>
          Чем дальше, тем больше я ощущаю: задача моя не в том, чтобы музыку
          придумать, сочинить, а в том, чтобы ее услышать. Задача – не помешать
          своему уху услышать существующее вне меня. Интонируемый композитором
          мир как бы существует вне его, композитор же может только с той или
          иной достоверностью к этому миру подключиться. И моя задача состоит в
          том, чтобы не помешать самому себе ложными идеями – от головы, – «не
          сбить» то, что я слышу.
          <br />
          <br />
          Адекватность – это второй вопрос. Всегда остается какой-то процент
          неточности. Всегда остается нечто, что я еще как бы не уловил. Я
          стремлюсь максимально приблизиться к идеальному слышанию, но никогда
          не могуего полностью достичь. Так или иначе, повторю, моя задача – не
          спугнуть итог, я только орудие: что-то вне меня стало слышно через
          меня...
          <Link to="/shnitke/source">[15]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block right>
        <>проявленная и непроявленная музыка. бесконечный моцарт</>
        <>
          Есть сфера теневая – то, что не записано в нотах, то, что не оставила
          следов. И есть область уже не теневого, а реального бытия – то, что
          стало. В этом смысле в реальной сфере живет то, что Моцарт успел
          написать, а в теневой, ирреальной – огромный звуковой мир, который он
          не успел осуществить… Можно даже предположить, что этот
          неосуществленный Моцарт бесконечен, как бесконечен Бах и все другие
          великие. Так им образом, реальная музыка есть лишь небольшая часть
          огромного музыкального мира, с которым человек имеет дело. Если он
          будет внимателен, он его почувствует, и этот огромный иллюзорный
          музыкальный мир.
          <Link to="/shnitke/source">[15]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block>
        <>прамузыка</>
        <>
          Внешний способ изложения мысли является лишь некоей конструкцией,
          сетью, которая помогает поймать замысел, но не самим носителем
          замысла. Каждый пытается прорваться к непосредственному выражению
          некоей слышимой им прамузыки, которая им еще не уловлена… Но
          «…воплощение замысла всегда является и нек им его ограничением».
          <Link to="/shnitke/source">[36]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block right>
        <>три этапа: слышание, черновик, партитура</>
        <>
          …У меня такое ощущение, что конкретные ритмы, интонации, гармонии,
          какие-то уже записанные нотами краткие детали, которые потом войдут в
          тексты, – это все имеет значение и смысл только тогда, когда опирается
          на подсознательное слышание целого. Начиная работу, я могу в первое
          время этого подсознательного слышания не ощущать. Я хочу к нему
          приблизиться, но его еще нет. И это время – самое беспокойное. Я знаю,
          что надо что-то написать, но не знаю как. Я могу делать наброски, а
          могу и не делать их, это все еще подготовка.
          <br />
          <br />
          Через некоторое время, в какой-то момент (это не момент, это несколько
          часов, иногда это бывает ночью), я вдруг как бы «слышу» целое. То есть
          остается так же мало набросков, сколько было вчера, но вокруг всего
          этого образовалось нечто, что я даже боюсь сказать «слышу». Каким-то
          ключом что-то в этом повернул, как бы для себя, и я могу надеяться,
          что с этого момента я уже смогу слышать больше. И вот этот момент
          складывается ощущение формы. Вот, когда я представил себе эту будущую
          форму, тогда первый этап закончился.
          <br />
          <br />
          На первом этапе произошло интуитивное приближение. Дальше начинается
          запись нотами. Тут я от этого интуитивного выслушивания должен перейти
          к разочаровывающей ежедневной работе, я перехожу к тому, что пишу
          черновик. Сама эта работа неровная: бывают дни, когда много всего в
          голову приходит, а бывают дни, когда ничего не происходит.
          <br />
          <br />
          Очень быстро сознание устает от того, что делает, и нужно как бы
          отойти немножко, на день, два, вроде забыть об этом, а потом можно
          опять вернуться. Но так пишется черновик. Иногда он пишется подряд,
          иногда и тут, и тут, и тут. Словом, может быть по-разному, но, как
          правило, сам процесс связан с четк им осознанием, что это есть
          взаимодействия сознательно работающий головы и чего-то
          бессознательного.
          <br />
          <br />
          И третий этап – когда я пишу партитуру. Это уже третий заход.
          <br />
          <br />
          Чтобы написать сочинение, я долженего в себе поселить, я долженего
          впустить, и это не сразу происходит. То есть я могу на это надеяться в
          каждом случае. Часто оно так и происходит, но я никогда не уверен, что
          это произойдет.
          <br />
          <br />
          Для того, чтобы произошел переход от первого ко второму этапу, нужнов
          какой-то момент услышать будущее сочинение иллюзорно: не как то, что
          ты сам будешь делать, а как бы оно существует вне тебя.
          <br />
          <br />
          Твоя задача не в том, чтобы это построить, как голове захочется, а
          услышать, как оно само по себе существует. Вот это – очень точное
          ощущение. Его можно считать какой-то разгадкой: ты воспринимаешь
          будущее сочинение, как уже существующее отдельно от тебя и что-то
          требующее. Оно и ограничивает эту бесконтрольную внутреннюю свободу.
          <Link to="/shnitke/source">[11]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block>
        <>интуиция и расчет: неуловимый баланс</>
        <>
          Происходящее в работе взаимодействие интуиции и расчета не может никак
          и никогда быть установленным точно: в такой-то пропорции интуиция, а
          такой-то расчет. И взаимодействие их всегда вещь заранее непостижимая,
          и в этом одна из главных опасностей в самой работе, то есть ключ как
          бы ускользает. Можно всю жизнь идти к этому ключу, и в нужный момент
          все-такиего у вас не будет, он как бы все время от вас уходит, вы всю
          жизньего ловите, а он всю жизнь от вас уходит. И я давно понял, что
          все надежды когда-то получить окончательный ключ к сочинению и потом
          дальше уже просто отпирать эт им ключом, эти надежды – они
          бессмысленны, потому что ключ – он как бы не дается в руки, не
          подлежит никакой формулировке.
          <br />
          <br />
          Как бы вы подробно ни сформулировали все, что вы имеете в виду, в тот
          момент, когда вы последнее сформулируете, откроется целое море
          несформулированного, и поэтому я бы не поверил ни в одном случае: ни
          при разговоре об опасностях легкой вдохновенной работы, ни при
          разговоре о сложностях и опасностях, которые несет в себе и
          рациональное работа. Во всем этом есть один смысл: не смешивая ничего
          в какой-то пропорции, а нек им образом, при каждом конкретном случае
          находить неожиданное решение. Вот, если непроизвольно – тут самый
          лучший результат. Это как бы обязывает композитора относиться к самому
          себе с вечным недоверием всю свою жизнь.
          <Link to="/shnitke/source">[11]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block right>
        <>задача – услышать</>
        <>
          …И все же решающее влияние на это оказало именно то, что мне легче
          стало сочинять оттого, что мне стало труднее писать. Я все больше и
          больше стал склоняться к тому, что моя основная задача состоит в
          контакте с сочинением, существующ им внутри меня, то есть в моей
          настройке, чтобы слышать то, что внутри меня объективно существует.
          То, что я сочиняю – это не субъективное, это не результат моего
          сочинения. Музыка как бы объективно существует, и моя задача только в
          том – я должен услышать то, что объективно уже есть.
          <br />
          <br />
          Да, я должен себя услышать, я не должен сочинить, я должен услышать.
          Это совершенно разные вещи. Поэтому все попытки трактовать то, что
          делается, как результат расчетов или чего-то сознательно придуманного,
          неправильны…
          <br />
          <br />
          …Вы стараетесь сосредоточиться не так, чтобы придумать, а так, чтобы
          услышать то, что уже иллюзорно существует. И поэтому получается, что
          работа состоит в контакте с эт им иллюзорно слышимом миром, который
          бесконечен. Если вы сегодня с н им имели контакт, то от этого крепнет
          уверенность, что этот контакт будет и завтра. От этого – ощущение все
          больше и больше внутренней реальности этого мира и доверие к нему.
          Поэтому оно и составляет главную мотивировку работы, что существует
          помимо вас, но в вашем внутреннем мире.
          <Link to="/shnitke/source">[11]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block>
        <>бесконечность нити</>
        <>
          Раньше я сочинял, исходя из сверху видимой формы, постепенно
          заполняемой деталями, а сейчас я больше сочиняю, исходя из ежесекундно
          живого момента, который может меняться. Для меня появилось сейчас то,
          чего я больше всего всегда хотел, но чего не было: появилась
          бесконечность каждой секунды. Появилась бесконечность нити. Я знаю,
          что сочинение формально заканчивается, но в действительности оно
          никогда не заканчивается. Нет последней точки.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={8} />

      <Col
        lg={{ offset: 1, span: 10 }}
        style={{ transform: "translateX(20px)" }}
      >
        <Title size={72}>время</Title>
        <Spacer size={2} />
      </Col>

      <Block>
        <>аккорд точек</>
        <>
          Например, нет никакой абсолютной временной точки. Эта временная точка
          – лишь логическая абстракция. На самом деле это, грубо говоря, аккорд
          точек, который дает не секунду, а часы и дни. Одно и то же – оно не
          одновременно. Существует какой-то способ охвата этого в
          одновременности, но не в физическом мире. И тогда можно представить
          секунду, в которой есть все – и прошлое, и будущее. Весь мир вдруг
          сворачивается в одну точку. А потом опять эти бесчисленные времена и
          места – расходятся, разбиваются, разворачиваются.
          <br />
          <br />
          В иллюзорном измерении я могу представить нечто, где все это сходится
          – и по времени, и по пространству, по всему – в точку. Но в реальном
          измерении этого нет...
          <br />
          <br />
          Оно (это ощущение) и присутствует, и отсутствует. Оно может вдруг
          почему-то открыться, и я сам удивляюсь, что в эту секунду все понимаю.
          Но потом я могу все это забыть.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block right>
        <>прошлое</>
        <>
          Для меня как бы возрастает реальность времени – оно все ближе
          подступает. И эта возрастающая реальность заставляет меня менять свое
          отношение ко времени. Раньше многое я писал случайно, в силу внешних
          причин: кто-то просил, и возникала стилизация или воспроизведение
          чего-то, что само уже не живо в современности. И вот постепенно во мне
          окрепло ощущение, что все эти бесчисленные миры других времен
          продолжают жить. Как бы от каждой точки – целый мир.
          <Link to="/shnitke/source">[37]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block>
        <>идеальный лес времен</>
        <>
          Это бесчисленное количество выхваченных из разных пространств точек. И
          вот возникает такое ощущение бесконечного леса времен, где каждая
          линия времени – другая, каждое дерево – растет по-своему. И все, что в
          прошлом возникло, возникло на разных деревьях, но относилось к
          деревьям, которые живут и сейчас. Другое дело, что в нашей сегодняшней
          реальности мы забыли о них. Потом опять вспомнили. А они-то продолжают
          жить, эти деревья. Поэтому и отношение ко всему в прошлом – не как к
          музейным экспонатам. Я как будто возвращаюсь в этот идеальный лес.
          Лес, конечно, очень грубое сравнение: я возвращаюсь в это идеальное
          скопление разнородных существ, и музыка там тоже растет. Вот эта ветвь
          – видимое, а эта – слышимое, но между ними нет коренной разницы, это
          ветви одного дерева. И вот поэтому я, имея это ощущение, считаю
          возможным возвращение ко всему прошлому. Это и не возвращение: что бы
          я ни делал, я все равно к чему-то возвращаюсь. Нового же нету, а все,
          что существует как якобы новое, вся сегодняшняя музыка – это уже было!
          …Сейчас у меня есть ощущение сосуществования всех времен и возможности
          их появления независимо друг от друга абсолютно всегда.
          <Link to="/shnitke/source">[38]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block right>
        <>сумма музыки за все времена</>
        <>
          Если для композиторов двадцатых и тридцатых годов… принадлежащих к
          «новой венской школе»… – Шенберга, Веберна и Берга, – если для них
          актуальной была сложность музыки и нахождение совершенно новых и
          неизвестных (и вместе с тем – точно подготовленных предшествующ им
          развитием музыки) новых средств…, то сейчас происходит попытка – у
          разных композиторов по-разному – завоевать некий «суммарный» взгляд на
          музыку, взгляд, при котором речь идет не столько о новизне, сколько о
          своего рода «сумме музыки»... Иными словами, та музыка, которая сейчас
          возникает, – она как бы не привязана только к нашему времени, она
          охватывает какую-то большую полосу в истории музыки, целую огромную
          полосу в истории ее развития, и «суммирует» эту полосу. Поэтому музыка
          сегодня не может исчерпать свое содержание, свой смысл только новизной
          языка; она имеет и другую очень важную задачу; суммировать весь тот
          путь, который прошло развитие музыки, выявить то, что есть общего в
          совершенно разных полосах этого развития. И вот это – актуальная
          задача сегодняшнего дня.
          <Link to="/shnitke/source">[38]</Link>
        </>
      </Block>

      <Spacer size={8} />

      <Col
        lg={{ offset: 1, span: 10 }}
        style={{ transform: "translateX(20px)" }}
      >
        <Title size={72}>интуиция</Title>
        <Spacer size={2} />
      </Col>

      <Block>
        <>антенна, направленная в будущее</>
        <>
          Интуиция – проявление надындивидуального знания, как бы подключение к
          внешнему чудесному источнику. Произведение как бы извечно существует,
          творец не создает его, а расшифровывает, улавливает. Поэтому так
          бесспорно и так знакомо каждое выдающееся произведение – мыего уже
          «знаем».
          <br />
          <br />
          Искусство особо зависимо от интуиции (история музыки – «антенна,
          направленная в будущее»).<Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={8} />

      <Col
        lg={{ offset: 1, span: 10 }}
        style={{ transform: "translateX(20px)" }}
      >
        <Title size={72}>замысел и воплощение</Title>
        <Spacer size={2} />
      </Col>

      <Block>
        <>две сферы: художник и тема</>
        <>
          Изначально… ты должен поделить себя на две сферы: это – ты в узком
          смысле, а это – то, что через тебя тебе открывается и что значительно
          больше, чем ты. И при этом хозяином являешься не ты, а то значительно
          большее, что тебе открывается... И, собственно, вся жизнь – есть
          попытка быть не собою, а орудием чего-то вне тебя. И вот это тебе
          диктует и форму, и слова, и вне тебя обусловленную мотивировку всего.
          Ты как будто бы не себе принадлежишь. И пока ты имеешь это ощущение,
          твоя работа тебя не тяготит. Не ты ведь себе предписываешь. Ты делаешь
          что-то, что кто-то другой предписывает тебе. «Кто-то» – это очень
          грубо сказано, – нечто, что важнее, чем ты. Раз оно предписывает, то
          оно изначально предопределяет и форму выражения. И поэтому та
          идеальная форма высказывания, которую ты стараешься реализовать,
          предопределена тем, изначальным ощущением.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block right>
        <>точная настройка: вслушивание в то, что уже есть</>
        <>
          И вся твоя работа – это попытка настроиться более точным образом на
          восприятие того, что есть, всегда было и будет. Настройка никогда не
          может произойти идеально, она может продолжиться час, даже полтора, но
          будет сбита. И тем не менее вся твоя работа – это не составление
          технических инструкций и их реализации, а как бы вслушивание в то, что
          уже есть. Эт им предопределено все – значение и деталей, и
          рациональной мотивированности.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block>
        <>реализация замысла как неизбежное упрощение</>
        <>
          Конечно, в какой-то момент, когда идеальная концепция должна быть
          реализована, – ты вынужден будешь прибегнуть к упрощающей рациональной
          расшифровке. Это упрощение. Ты спускаешься с более высокой ступени на
          ступень, которая огрубляет, искажает эту истину, но ты так им образом
          получаешь шанс ее высказать. Иначе ты даже и слова не имеешь права
          употребить. Они уже есть огрубление мысли. И вот ты идешь сознательно
          на это огрубление только потому, что таким образом ты можешь высказать
          что-то. Огрублением становятся слова, если ты высказываешь мысль, или
          ноты, если ты пишешь музыку.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block right>
        <>интуитивная музыка как попытка выхода из тупика</>
        <>
          Попытки выйти за эти пределы я видел, когда слышал записанные
          импровизации, подлинные импровизации, а не изображенные,
          квазиимпровизированные.
          <br />
          <br />
          Например, в Театр на Таганке приходил человек, который был божественно
          красив. И приносил с собой фотографии, где он сидит с нимбом вокруг
          головы, а какие-то почтительные мещанки стоят около него. Но, когда он
          заводил свои импровизации, я начинал смотреть на него иначе. Это были
          импровизации, которые могли длиться столько времени, сколько длится
          пленка. Они не содержали в себе беспомощных повторений или убогой
          метричности. В них не было гармонических банальностей, и они не пугали
          ежесекундно непостижимыми поворотами. Это было не изображение
          неожиданностей, но импровизация, не заботящаяся о том, как ее слушают
          и воспринимают. И в этот момент она становилась подлинной.
          <br />
          <br />
          Подобные импровизации были у (композитора) Алемдара Караманова, когда
          он садился за рояль и импровизировал фуги. Действительно фуги, а не
          мнимую полифонию. Это, конечно, было для него несерьезное дело, потому
          что в принципе у человека есть более высокий выход на этот уровень. Но
          я вижу в этом иное музыкальное существование, более совершенное чем
          то, которое мы привыкли иметь в европейском сочиненном музыкальном
          быту. И такое музыкальное существование напоминает мне то, что есть в
          серьезной восточной музыке, у индусов.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block>
        <>несопоставимость замысла и воплощения</>
        <>
          Готовое сочинение – отношение к нему очень сложное, противоречивое и
          мучительное: оно одновременно самое лучшее и самое худшее. Вот
          чувствуешьего как будто бы удавшимся и вместе с тем понимаешь, что в
          нем не получилось, где что-то не дотянуто. И самое главное:
          несопоставимость того, что получилось, с тем, что задумал. Мне уже
          приходилось об этом говорить: конечный результат всегда
          катастрофически несоизмер им с тем, что представлялось, потери
          огромны. Притом, когда сопоставишь это с тем, что вообще не
          реализовалось, что пришло в качестве миража, немножко помаячило и ушло
          – я его не сумел поймать, – вот тогда понимаешь ограниченность своих
          возможностей.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block right>
        <>вспомогательность технологий и неисчерпаемость замысла</>
        <>
          Все дело в том, что технология, внешний способ изложения мысли,
          является лишь некоей конструкцией, сетью, которая помогает поймать
          замысел, нек им вспомогательным инструментом, но не сам им носителем
          замысла.
          <br />
          <br />
          Если произведение действительно несет в себе некий замысел, оно
          неисчерпаемо; возможно, именно эти сочинения, которые сохранили свою
          жизнеспособность до нашего времени, отличаются от тех, которые
          представляют лишь «музейный» интерес. Эта незримая «подводная» часть и
          есть самое главное.
          <br />
          <br />
          Но должен ли композитор впасть в уныние от того, что эта неуловимая
          часть не дается ему в руки? Не означает ли это все, что рациональная
          техника бессмысленна, а музыкальная теория беспомощна, что все равно
          главное неуловимо и нужно ставить вопрос о бессилии, ограниченности
          рациональных исследований и рациональных приемов творчества? Нет,
          по-видимому, это не так. Однако если мы обратимся к каким-то основам
          музыки в природе, если попытаемся обосновать природу звука
          рационалистически и, исходя из нее, музыку в целом, то придем к
          выводу, что этого обоснования быть не может.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block>
        <>неизбежность ошибки дает музыке возможность существовать</>
        <>
          Вероятно, что всякая музыка является «ошибочной» по отношению к
          первоначальному замыслу природы – основным тонам, и аналогичная
          «ошибка» происходит в сознании каждого композитора, который
          представляет себе некий идеальный замысел и должен перевестиего на
          нотный язык. И лишь «темперированную» часть этого замысла он доносит
          до слушателей.
          <br />
          <br />
          Но неизбежность этой «ошибки» вместе с тем дает музыке возможность
          существовать дальше. Каждый пытается прорваться к непосредственному
          выражению некоей слышимой им прамузыки, которая еще не уловлена. Это
          толкает композитора на поиски новой техники, потому что он хочет с ее
          помощью услышать то, что в нем звучит. Возникают бесконечные попытки
          отбросить все условности и создать без них нечто новое.
          <br />
          <br />
          Вот эти многочисленные попытки приблизиться к непосредственному
          выражению музыки, непрерывное возвращение к «обертонам», постижение
          новых рациональных приемов и приближение к истине открывают все новые
          и новые поля недостижимости. Этот процесс продолжается бесконечно.
          <br />
          <br />
          Поэтому хотелось бы сказать, что воплощение замысла всегда является и
          некоторымего ограничением. Для иных композиторов наиболее идеальным
          выходом является «невоплощение» замысла.
          <Link to="/shnitke/source">[39]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block right>
        <>непознаваемость истины</>
        <>
          Я вспоминаю ответы Валентина Сильвестрова на встрече с н им
          Молодежного клуба в Доме композиторов: «Истинность не дается в руки.
          Она как бы всегда должна выступить в новом виде. Как только сознание
          закрепит ее за этой формой, она уходит в какую-нибудь другую. И в этом
          окончательная непознаваемость истины».
          <Link to="/shnitke/source">[39]</Link>
        </>
      </Block>

      <Spacer size={8} />

      <Col
        lg={{ offset: 1, span: 10 }}
        style={{ transform: "translateX(20px)" }}
      >
        <Title size={72}>знание</Title>
        <Spacer size={2} />
      </Col>

      <Block>
        <>конечность и бесконечность знания</>
        <>
          Знание, когда оно приходит к кристаллизации, очень многое теряет,
          приобретая. У меня появилось такое осознание некоторой конечности и
          вместе с тем бесконечности знания. Конечность проявляется в том, что
          ты как бы не можешь вместить больше, чем определенное количество: если
          ты узнал чего-то больше, то наверное ты потеряешь нечто другое, что ты
          прочитал или узнал раньше, оно уйдет немножко дальше в тень, оно
          перестанет иметь важное значение. Есть как бы конечная доза или норма
          знаний. Хотя, конечно, эта доза – относительна, потому что известно,
          что умирающие в последнюю секунду все, что они видели, знали,
          говорили, делали, слышали, – еще раз переживают. Оказывается, что они
          вспоминают все, в том числе и то, что их сознательная память уже не
          помнит. А подсознание все это помнит.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block right>
        <>осознаваемое и неосознаваемое</>
        <>
          Но независимо от этого я понял, что опора на систематизированное
          знание с цитатами, именами, книгами, с долговыстраиваемым внутренн им
          миром (ты как бы строишь в себе целое государство, целый мир) – для
          меня была бы ошибкой. Потому что человеческое сознание имеет одну
          особенность: чем больше из неосознанно знаемого человеком
          перетягивается в область осознанного знаемого, тем больше это
          осознанно познаваемое теряет некую незримую неуловимую часть, которая
          как тень сопровождает мысль, когда она еще не откристаллизовалась.
          Само слово «кристаллизация» – уже в известном смысле ограничение этого
          бесконечного стороннего мира. А когда он кристаллизуется, от него вся
          шелуха отходит. Но вместе с этой шелухой отходит и бесконечное
          количество нераскрытых возможностей, и в этом – опасность
          кристаллизации. Знание, когда оно приходит к кристаллизации, очень
          многое теряет, приобретая. Потому что кристалл – это яркое, сверкающее
          и неуязвимое нечто. Но вместе с тем это – кристалл, а не живое что-то,
          неорганическое, изменчивое. И поэтому я отдаю предпочтение не
          энциклопедически систематизированному знанию, а тому знанию, которое
          есть у человека как будто бы не знающего. Это знание приобрело для
          меня большее значение.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block>
        <>идеальная неполнота философии</>
        <>
          Я не могу назвать какой-то формально организованной системы взглядов,
          которая была бы для меня решающей и диктовала бы мне и образ жизни, и
          работу. Я, конечно, в разное время склонялся к тому, что я в тот
          момент читал и чем увлекался. А вот сейчас эта способность увлечься до
          такой степени читаемой книгой или изучаемой философией вообще отпала.
          Отпала потому, что я как бы ежесекундно вижу всю идеальную неполноту
          философии. В самых тонких случаях она все же проявляет свою
          бесконечную неполноценность. В этом смысле все наивные мистики – те,
          которые были не склонны к систематизации и ограничению своего знания,
          а просто излагали его, – имеют для меня большее значение, чем
          возводящие стройное знание. В этом смысле если начать с Христа и взять
          Евангелие от Иоанна, или Августина, или Мастера Экхарта, или
          Франциска, – во всех этих случаях мы имеем дело с тайной, которая
          всегда тайной останется, и даже в таком наивном солнечном проявлении,
          как у Франциска. Тайной, которую не объяснишь. И это для меня
          наивысший вид литературы.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={8} />

      <Col
        lg={{ offset: 1, span: 10 }}
        style={{ transform: "translateX(20px)" }}
      >
        <Title size={72}>добро и зло</Title>
        <Spacer size={2} />
      </Col>

      <Block>
        <>шлягер – проявление зла</>
        <>
          Естественно, что зло должно привлекать. Оно должно быть приятным,
          соблазнительным, принимать облик чего-то легко вползающего в душу,
          комфортабельного, приятного, во всяком случае – увлекающего. Шлягер –
          хорошая маска всякой чертовщины, способ влезть в душу. Поэтому я не
          вижу другого способа выражения зла в музыке, чем шлягерность.
          <br />
          <br />
          Сегодня шлягерность и есть наиболее прямое в искусстве проявление зла.
          Причем зла в обобщенном смысле. Потому что зло имеет локальную
          окраску. Общ им для любой локальности является стереотипизация мыслей,
          ощущений. Шлягерность – символ этой стереотипизации. Это – как
          консервы или таблетка с безошибочным действием: шлягер. И это и есть
          самое большое зло: паралич индивидуальности, уподобление всех всем.
          Причем шлягер является и продуктом, и причиной всего этого. Существует
          обратная связь между происхождением шлягера и влиянием его нa
          порождение новых шлягеров, на дальнейшую стереотипизацию. Конечно,
          какая-то механическая положительность в шлягерах есть: под аэробику
          крутят шлягеры, и это, наверное, хорошо (крутить Баха было бы плохо).
          Но в принципе шлягер в развитии искусства – это символ зла.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block right>
        <>зло как сломанное добро</>
        <>
          Изображение негативных эмоций – разорванная фактура, разорванные
          мелодические линии, которые выражают состояние несобранности
          взвинченности, скачущие мысли, – это тоже, конечно, отображение
          некоего зла, но зла не абсолютного. Это – зло сломанного добра.
          Разорванная душа – она, может быть, и хорошая. Но она – разорвана, и
          от этого стала плохой. Выражение истеричности, нервозности, злобы –
          есть выражение болезни, а не причины. А вот шлягерность – ближе к
          причине. Это – зло, которое посылается как наваждение, как испытание.
          Бороться с н им очень трудно.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block>
        <>зло не фатально</>
        <>
          «Дух дышит, где хочет», – так сказано в одной из Евангельских книг.
          Для меня нет ощущения фатальности зла даже в самый страшной ситуации.
          Его нет, потому что остается неизменной всегда проявляющаяся в
          человеке некая добрая суть. Скажем, сколько ни существовало способов
          демагогического, а в прошлом назвали бы как-нибудь иначе, извращения
          истины, никто из нас не сумел преодолеть того кроткого пожизненного
          собеседника, который есть в каждом – самого себя. Ты можешь бороться с
          ним, опровергать – и никогда не увидишь, никуда не сможешь от него
          убежать. Этого, слава Богу, никто еще – ни Гитлер, ни Сталин – в
          человеке не истребил.
          <Link to="/shnitke/source">[19]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block right>
        <>формула мировой гармонии</>
        <>
          Мне не только для того, чтобы я мог сочинять музыку, но и для того,
          чтобы я мог существовать, нужно исходить из идеи, что духовный мир
          упорядочен, структурирован от природы и в нем есть свои формулы и
          законы.
          <br />
          <br />
          Все, что порождает дисгармонию, все чудовищное, необъяснимое и
          страшное, чего не мог понять Иван Карамазов, – все это тоже входит в
          существующий миропорядок. И формула мировой гармонии, вероятнее всего,
          связана не с нейтрализацией зла, а с тем, что, вовлеченное в стройную
          картину целого, даже зло меняется: в какой-то комплементарной
          взаимозависимости негативные элементы погашают друг друга, и в итоге
          возникает нечто стройное и прекрасное.
          <Link to="/shnitke/source">[24]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block>
        <>почему не выражать добро?</>
        <>
          Можно задать вопрос: «Ну, а почему не выражать добро?» Дело в том, что
          некосвенное, непосредственное выражение добра в музыке – это и есть
          самое трудное, а порой просто невозможное. Вспомн им Фауст – симфонию
          Листа. Что в ней самое неинтересное? «Райский» финал – он постный и
          догматический. Но, может быть, Лист – особый композитор, склонный к
          сатанизму в музыке, – тот человек, который по-настоящему и ввел
          сатанизм в музыку.
          <br />
          <br />
          Если вспомним, и в современной музыке позитивное не связано с наиболее
          яркими страницами. У Шостаковича, например, если взять Седьмую или
          Восьмую симфонии, относительно блеклые позитивные первые страницы зло
          не перевешивают.
          <br />
          <br />
          Видимо, за эт им стоит что-то объективное. Другое дело: не связываться
          со злом, не делатьего персонажем искусства, пытатьсяего избежать.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block right>
        <>статическое благозвучие</>
        <>
          Появилась, например, новая возможность приближаться к добру:
          всевозможная медитативная, прострационная музыка на обертонах, на
          длительном вхождении в статическое благозвучие. Это как будто
          «гарантирует» некое добро. Но у меня есть большое сомнение, не
          очередная ли это маска, и действительно ли это – подлинное решение
          проблемы, потому что негативная реальность все равно продолжает
          существовать. Можно как бы отвернуться, «уйти в монастырь». Но я для
          себя такой возможности не вижу. Не принимая породившую этот
          музыкальный материал реальность, я все-таки вынужден все время иметь с
          ней дело.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block>
        <>зло как несовершенная степень добра</>
        <>
          Надо прежде всего отделить вопрос о добре и зле от того, как они
          выражаются в музыке. В литературе это, видимо, не требуется, потому
          что там все выражается словами и прямой мыслью. Но если композитор
          начнет сам анализировать себя во время работы, задумываться над тем,
          что он пишет «по добру», а что – «по злу», то он перестанет сочинять.
          При этом ведь часто бывает, что одно и то же имеет два лица (как,
          например, – в Третьем скрипичном концерте). Хотя добро и зло
          существуют как противоположные полюса и враждебные начала, они где-то
          сообщаются, и существует какая-то единая их природа. Августин писал,
          что зло есть несовершенная степень добра. Конечно, в этом есть элемент
          манихейства и внутренней дуалистичности. Но я это могу понять. Я
          понимаю, когда в «Докторе Фаустусе» Томас Манн пишет о воображаемой
          оратории по Апокалипсису, где музыка праведников, входящих в Царствие
          Небесное, и адская музыка написаны одними и теми же нотами, – это как
          бы негатив и позитив. Для меня это важно как структурная
          необходимость: произведение искусства должно иметь структурное
          единство. Оно может выражаться по-разному, на разном уровне.
          Механически, с помощью серийной, например, или какой-то полифонической
          техники. Или же в более скрытом, амбивалентном виде, когда один и тот
          же материал поворачивается полярным образом.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={8} />

      <Col
        lg={{ offset: 1, span: 10 }}
        style={{ transform: "translateX(20px)" }}
      >
        <Title size={72}>жизнь</Title>
        <Spacer size={2} />
      </Col>

      <Block>
        <>внимание дьявола к новому</>
        <>
          Для меня вся жизнь есть непрерывное взаимодействие рационального,
          божественно предопределенного – и непрерывного потока иррационального,
          как бы еще не «проросшего», совершенно нового. А ко всему новому –
          приковано особое внимание Дьявола (я говорю о Дьяволе как об удобном
          обозначении всей этой сферы). Я убежден, что существует некая темная
          иррациональная сфера, которая более всего всегда обращена к новому.
          Все наиболее страшные, чудовищные события в истории человечества –
          связаны с новым. Это страшная французская революция, Октябрьская
          революция, все страшное, что связано с реакцией на Октябрьскую
          революцию в лице фашизма и с тем, что проросло из этого. Все это
          наиболее страшно обнаруживается в первом воплощении. Дьявол бросается
          на то, что им еще не испытано.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block right>
        <>жизнь как меняющийся итог</>
        <>
          Я думаю, что у всего этого есть и другой, помимо связанного с
          Дьяволом, смысл. Существует накопление или уменьшение каких-то
          итоговых качеств. Вся жизнь – не моя личная и даже не наша за миллионы
          лет – имеет какой-то итоговый смысл, меняющийся итог.
          <br />
          <br />
          {/*  ------------------------------------------------------------- */}
          Я боюсь предполагать, но думаю, что дифференциация на Бога и Дьявола,
          на два мира, – не может в окончательном виде все выразить. Она лишь
          выражает нечто в том варианте, который нам, людям, понятен. Я думаю,
          что в отношении Бога и пониманииего сущности мы впадаем в ту же
          ошибку, в которую впадаем относительно компьютера: абсолютизируем то
          или иное частное, превращая его в измеритель всего. И единственное,
          что дает нам правоту, – это нерациональное сознание, некая сущность,
          которая подправляет нас, говоря, хорошо или плохо мы поступаем. И мы –
          независимо от того, как мы поступаем, – сами знаем, в чем мы были
          правы, а в чем – неправы. Идеальный оценщик сидит в каждом из нас, и я
          думаю, что он сидит и в грешнике, и в святом. И это делает невозможным
          святого в абсолютном смысле, так же, как и грешника в абсолютном
          смысле. Это свойство неизбежно присуще человеку как осколку или
          отражению общего.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block>
        <>жизнь как борьба за спасение</>
        <>
          Я думаю, что всю жизнь человек – с первого и до последнего мгновения –
          не может рассчитывать на то, что он очистился и может считать себя
          спасенным. Он всю жизнь должен бороться за свое спасение. Но это
          должно быть чем-то подлинным, связанным с соприкосновением с реальными
          опасностями, а не с чем-то демонстративно безопасным. Вот эта
          подлинность включает в себя ежесекундную новизну ситуации и новую
          опасность. Человек, всю жизнь ощущающий опасность за спиной и всю
          жизнь с ней борющийся, имеет, как мне кажется, больше шансов на
          преодоление опасности итоговой, чем человек, обманутый иллюзией, что
          он якобы очистился от опасности и продолжает образцово-показательно
          «доживать». Я не верю в такую образцово-показательную идеальную жизнь.
          Потому что, как только я в нее поверю, я потеряю критичность отношения
          к самому себе. И это – одно из искушений, которое человек сам себе
          ставит, – но он никогда не должен ему поддаться.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block right>
        <>ускользающее совершенство</>
        <>
          …Каждая ситуация есть по отношению к другой как плюс, так и минус.
          Если ты в чем-то выигрываешь, то неизбежно проигрываешь в другом.
          Поэтому я сначала огорчался, когда что-то терял. А потом перестал
          огорчаться. Потому что понял: все, что ни происходит в жизни, никогда
          не идет к совершенству, не достигает совершенства. Оно всегда
          перемещается от одного пути к идеальному – к другому, но никогда не
          достигает умножения идеальных качеств.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block>
        <>духовное и материальное существование художника</>
        <>
          Явления жизни имеют материальное и духовное существование. И если их
          духовное существование исчерпывается лишь тем, что они являются
          предметами нашего размышления и чувства, то и этого уже достаточно,
          чтобы установить тем самым наличие духовного мира – мира, даже в этом
          реалистическом воплощении гораздо более важного, чем материальный.
          Например, тридцать семь лет жизни Пушкина в однозначном материальном
          своем облике, со всеми реальными ее событиями – несоизмеримы с
          200-летним духовным существованием пушкинского мира, бесконечного по
          ассоциативно-образному наполнению и многозначности его интерпретаций,
          взаимоисключающих и тем самым взаимодополняющих его до
          неисчерпаемости. Более того, будучи фактом духовного мира, то есть
          лишь объектами мысли и чувства, произведения Пушкина через людей,
          соприкоснувшихся с ним, влияют на ход реальных событий, то есть на
          материальный мир. Поэтому можно смело утверждать, что духовное (то
          есть посмертное) существование людей – факт реальный и что это более
          длительное существование их (а потенциально – бессмертие) бесконечно
          важнее кратковременного физического существования.
          <br />
          <br />
          Отнимая художника у современников, смерть одновременно возводит его на
          тот уровень вечного духовного существования, где нет времени и
          развития, а есть бессмертная жизнь произведений искусства в их
          абсолютной неисчерпанности. Индивидуальное из разграничивающего
          превращается в объединяющее, отличие становится родством, и возникает
          связь между явлениями разных времен и мест.
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>

      <Spacer size={10} />

      <Block right>
        <>финал</>
        <>
          Я вообще много думал о проблеме финала. И пришел к выводу, что она
          возникла, когда воцарился атеизм. До этого проблемы финала все-таки не
          было. Была изначальная уверенность в том, что все будет хорошо —
          плохих финалов до Бетховена включительно не было. Когда появились
          плохие финалы, когда они стали хуже? В наше время: у Шостаковича,
          Прокофьева финалы иногда хуже, чем предыдущее.
          <br />
          <br />
          Я сейчас имею в виду такой финал, который должен все объяснить. Такого
          финала больше не бывает. Позитивный финал перестает существовать.
          <br />
          <br />
          {/* -------------------------------------------------------------- */}
          В моих сочинениях все часто уходит в многоточие или просто
          прекращается, кончаясь без финала.{" "}
          <Link to="/shnitke/source">[1]</Link>
        </>
      </Block>
    </SidesShnitke>
    <Footer />
  </TypographyWrapper>
);

export default Philosophy;
