import React from "react";
import styled, { css } from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import Text from "./Text";
import { mediaQuery, styles } from "../utils";

const Line = styled.div`
  background-color: #fff;
  display: inline-block;
  height: 1px;
  transform: translateY(-7px);
  width: 110px;

  ${mediaQuery(
    "phone",
    css`
      transform: translateY(-4px);
      width: 1px;
    `,
  )}
`;

const HyperLink = styled.a`
  color: #fff;
  margin: 0 17px;

  &:hover {
    color: #fff;
  }

  ${mediaQuery(
    "phone",
    css`
      font-size: 14px;
    `,
  )}
`;

const StyledContainer = styled(Container)`
  ${styles.flexCenter()};

  background-color: #000;
  padding: 70px 0;

  ${mediaQuery(
    "phone",
    css`
      text-align: center;
    `,
  )}
`;

const StyledLink = styled(Link)`
  color: #fff;
  margin: 0 17px;

  &:hover {
    color: #fff;
  }

  ${mediaQuery(
    "phone",
    css`
      font-size: 14px;
    `,
  )}
`;

const StyledText = styled(Text)`
  color: #fff;
`;

const Footer: React.FC = () => (
  <StyledContainer fluid>
    <Row>
      <Col>
        <StyledText size={24}>
          <StyledLink to="/about">о проекте</StyledLink>
          {/* <Line />
          <HyperLink href="https://www.facebook.com/3degreesoffreedom">
            fb
          </HyperLink> */}
          <Line />
          <HyperLink href="https://t.me/threedegreesoffreedom">
            telegram
          </HyperLink>
          <Line />
          <HyperLink href="https://www.youtube.com/channel/UC4sfaNktpPbzqPmoZfJjDtA">
            youtube
          </HyperLink>
          <Line />
          <HyperLink href="https://vk.com/3degreesoffreedom">vk</HyperLink>
          <Line />
          <HyperLink href="https://planeta.ru/campaigns/alfredshnittke">
            поддержать
          </HyperLink>
        </StyledText>
      </Col>
    </Row>
  </StyledContainer>
);

export default Footer;
