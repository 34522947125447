import React from "react";
import styled, { css } from "styled-components";

import Text from "./Text";

import { mediaQuery } from "../utils";

type Props = {
  name: string;
};

const Root = styled.div`
  display: none;
  position: absolute;
  right: 40px;
  top: 20px;

  ${mediaQuery(
    "phone",
    css`
      left: 0;
      position: relative;
      text-align: center;
      top: -15px;
    `,
  )}
`;

const Title = styled(Text)``;

const NamedHeader: React.FC<Props> = ({ name }: Props) => (
  <Root>
    <Title family="thin" size={52}>
      {name}
    </Title>
  </Root>
);

export default NamedHeader;
