import React from "react";
import styled, { css } from "styled-components";

import { mediaQuery } from "../utils";

type Props = {
  children: React.ReactNode;
  dots?: boolean;
  inverted?: boolean;
  right?: boolean;
};

const DotsRoot = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DotsWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  width: 200px;
`;

const DotRow = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    margin-right: 3px;
  }
`;

const Dot = styled.div<{ inverted?: boolean }>`
  background-color: ${({ inverted }) => (inverted ? "#fff" : "#000")};
  border-radius: 100%;
  height: 6px;
  width: 6px;
`;

type RootProps = {
  inverted: boolean;
  right: boolean;
};

const Root = styled.p<RootProps>`
  color: ${(props) => (props.inverted ? "#fff" : "#000")};
  font-size: 24px;
  line-height: 1.2;
  padding-left: 60px;
  padding-top: 16px;
  text-align: right;

  ${mediaQuery(
    "phone",
    css`
      font-size: 16px;
      padding-left: 20px;
    `,
  )}
`;

const QuoteCinema: React.FC<Props> = ({
  children,
  dots = true,
  inverted = false,
  right = false,
  ...rest
}: Props) => (
  <>
    <Root
      {...{
        inverted,
        right,
        ...rest,
      }}
    >
      {children}
    </Root>
    {dots && (
      <DotsRoot>
        <DotsWrapper>
          <DotRow>
            <Dot inverted={inverted} />
            <Dot inverted={inverted} />
          </DotRow>
          <Dot inverted={inverted} />
        </DotsWrapper>
      </DotsRoot>
    )}
  </>
);

export default QuoteCinema;
