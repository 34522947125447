/* eslint-disable react/jsx-no-comment-textnodes */

import React from "react";
import styled, { css } from "styled-components";
import { Col } from "react-bootstrap";

import {
  Block,
  Footer,
  Nav,
  PhoneMenu,
  SidesKaravajchuk,
  Spacer,
  Text,
  Title,
} from "../../../lib";
import { mediaQuery } from "../../../utils";

const PageTitle = styled(Text)`
  font-size: 80px;
  margin-bottom: 100px;
  margin-right: 7vw;
  text-align: right;

  ${mediaQuery(
    "phone",
    css`
      display: none;
    `,
  )}
`;

const Root = styled.div`
  ${mediaQuery(
    "phone",
    css`
      margin-top: 120px;
    `,
  )}
`;

const StyledPhoneMenu = styled(PhoneMenu)`
  margin-top: -50px;
`;

const Source: React.FC = () => (
  <Root>
    <Nav />
    <StyledPhoneMenu />
    <PageTitle>олег каравайчук (2)</PageTitle>

    <SidesKaravajchuk hideRight="source">
      <Col
        lg={{ offset: 1, span: 10 }}
        style={{ transform: "translateX(20px)" }}
      >
        <Spacer size={10} />
        <Title size={72}>источники</Title>
        <Spacer size={4} />
      </Col>

      <Spacer size={8} />

      <Block>
        <>[а] аудио- и видеоматериалы, фильмы, передачи</>
        <Text>
          [a—a] аудиозаписи б. алексеева из архива фонда олега каравайчука,
          2015.
          <Spacer size={4} />
          [a—b] «у нас на острове: олег каравайчук». вед. б. нечаев, 1990.
          <Spacer size={4} />
          [a—c] «большой вальс для колизея». реж. а. плахов, 1995.
          <Spacer size={4} />
          [a—d] genius loci. реж. с. ландо, 2003.
          <Spacer size={4} />
          [a—e] «монолог». реж. м. цирулёв, 2005–2010.
          <Spacer size={4} />
          [a—f] «острова: семен аранович». реж. а. шпагин, 2014.
          <Spacer size={4} />
          [a—g] «на ходу: олег каравайчук». реж. с. прокудина, 2016.
          <Spacer size={4} />
          [a—h] «олег и редкие искусства». реж. а. дюке, 2016.
          <Spacer size={4} />
          [a—i] «небесный тапер». реж. с. ландо, 2017.
          <Spacer size={4} />
          [a—j] «последний вальс». реж. ю. бобкова, 2017.
          <Spacer size={4} />
          [a—k] «те, с которыми я: олег каравайчук». реж. с. соловьёв, 2017.
          <Spacer size={4} />
          [a—l] «невский ковчег: олег каравайчук». реж. о. высоцкая, 2022.
          <Spacer size={4} />
          [a—m] «ретроспективы». реж. с. ландо, 2022.
        </Text>
      </Block>

      <Spacer size={8} />

      <Block>
        <>[b] интервью и монологи олега каравайчука, статьи о нем</>
        <Text>
          [b—a] каравайчук о. порядок нот // искусство кино. 1995. № 2
          <Spacer size={4} />
          [b—b] «музыка помогает макетировать будущее» // аргументы и факты.
          2004. № 23
          <Spacer size={4} />
          [b—c] быков д. [ПРИЗНАН МИНЮСТОМ РФ ИНОАГЕНТОМ]
          <br />
          рояль в устах // rolling stone. 2010. № 65
          <Spacer size={4} />
          [b—d] олег каравайчук // собака.ru. 2009. № 106
          <Spacer size={4} />
          [b—e] «па-де-де — это когда пах не эротика, а окно» [инт. е.
          антощенко] // time out. 2013. № 1
          <Spacer size={4} />
          [b—f] «фрейд научил искать патологические причины гениальности»
          <br />
          [инт. а. савельева] // interview. 2014. № 30
          <Spacer size={4} />
          [b—g] «не думай, что ты гений — и будешь в девяносто лет бегать»
          <br />
          [инт. е. ливси] // kp.ru. 2015. 13 мая
          <Spacer size={4} />
          [b—h] смородинова е. гениальная песня для помидора
          <br />
          // expert.ru. 2015. 24 декабря
          <Spacer size={4} />
          [b—i] занин а. «не по вашей земле – брожу по небесному лугу…» //
          искусство кино. 2016. №11
          <Spacer size={4} />
          [b—j] север а. другая форма наивности // story. 2016. № 11
          <Spacer size={4} />
          [b—k] кира муратова и редакция «собака.ru» вспоминают олега
          каравайчука // собака.ru. 2016. № 186
          <Spacer size={4} />
          [b—l] «мне всегда скучно, когда я сочиняю музыку» [инт. в. ивановой]
          // tass.ru. 2016. 5 апреля
          <Spacer size={4} />
          [b—m] «вообще любви нету. есть форма высшей независимости от причин»
          [инт. о. андреевой] // snob.ru. 2016. 13 июня
          <Spacer size={4} />
          [b—n] «человек огениаливает то, что усваивает» // ng.ru. 2016. 13 июня
          <Spacer size={4} />
          [b—o] ванина е. кто такой олег каравайчук // daily.afisha.ru. 2016. 14
          июня
          <Spacer size={4} />
          [b—p] «музыка может все сшибить к чертовой матери!» [инт. е.
          авраменко] // iz.ru. 2016. 14 июня
          <Spacer size={4} />
          [b—r] «музыку мою особо не продашь, а на воспоминания люди падки»
          <br />
          [инт. к. гощицкой и н. наговицыной] // sobaka.ru. 2016. 14 июня
          <Spacer size={4} />
          [b—s] шпагин а. вальсирующий // ск-новости. 2018. № 2
        </Text>
      </Block>

      <Spacer size={8} />

      <Block>
        <>[c] интервью, записанные для проекта</>
        <Text>
          [с—a] звукорежиссер б. алексеев
          <Spacer size={4} />
          [c—b] киновед п. багров
          <Spacer size={4} />
          [c—c] архивист с. гельвер
          <Spacer size={4} />
          [c—d] звукорежиссёр и. дельгядо
          <Spacer size={4} />
          [c—e] режиссер г. капицкая
          <Spacer size={4} />
          [c—f] режиссер и оператор с. ландо
          <Spacer size={4} />
          [c—g] режиссер а. муратов
          <Spacer size={4} />
          [c—h] киновед а. шпагин
          <Spacer size={4} />
          [c—i] сценарист н. рязанцева
        </Text>
      </Block>

      <Spacer size={8} />

      <Block>
        <>[d] книги</>
        <Text>
          [d—a] аранович / сост. и. павлова, ю. павлов. спб.: студия «панорама»,
          1998
          <Spacer size={4} />
          [d—b] савва / сост. в. арбузова-кулиш, к. арбузов. м.: зебра-е, 2005
          <Spacer size={4} />
          [d—c] мельников в. жизнь. кино. спб.: bhv, 2011
          <Spacer size={4} />
          [d—d] рязанцева н. не говори маме. м.: время, 2005
        </Text>
      </Block>

      <Spacer size={8} />

      <Block>
        <>[e] статьи, рецензии и другие источники</>
        <Text>
          [e—a] анисимова и. «одесса – аккумулятор, который дает энергию и силы»
          // newizv.ru. 2012. 24 мая
          <Spacer size={4} />
          [e—b] аркус л. [илья авербах] // новейшая история отечественного кино.
          1986–2000. кино и контекст. т. 4. спб.: сеанс, 2002
          <Spacer size={4} />
          [e—c] быков д. [ПРИЗНАН МИНЮСТОМ РФ ИНОАГЕНТОМ]
          <br />
          тоска ильи авербаха // киноведческие записки. 2005. № 69
          <Spacer size={4} />
          [e—d] бычков в. школа сказки // искусство кино. 1966. № 6
          <Spacer size={4} />
          [e—e] долинин д. свой голос // кадр. 1987. 17 августа
          <Spacer size={4} />
          [e—f] доморощенов с. спасавшие архангельск // pravdasevera.ru. 2017. 9
          мая
          <Spacer size={4} />
          [e—g] кичин в. [«черная курица, или подземные жители»] //
          <br />
          советский экран». 1981. № 11
          <Spacer size={4} />
          [e—h] клепиков ю. прерванный полет // искусство кино. 1999. № 3
          <Spacer size={4} />
          [e—i] кожушаная н., охлобыстин и., тягунов н. жизнь ноги //
          <br />
          экран. 1992. № 4
          <Spacer size={4} />
          [e—j] марголит е. рыцарь образа // seance.ru. 2014. 30 июня
          <Spacer size={4} />
          [e—k] муратова к. «я подыгрываю…» [инт. л. герсовой] // искусство
          кино. 2010. № 4
          <Spacer size={4} />
          [e—l] петров м. феномен авербаха // звезда. 2006. № 1
          <Spacer size={4} />
          [e—m] плахов а. он остался певцом вымирающего класса // коммерсантъ.
          1994. 2 августа
          <Spacer size={4} />
          [e—n] рязанцева н. плоды покаяния // искусство кино. 1998. № 11
          <Spacer size={4} />
          [e—o] рязанцева н. [предисловие к киносценарию «долгие проводы»] //
          киносценарий. 1988. № 1
          <Spacer size={4} />
          [e—p] тодоровский п. советы старейшин [инт. е. ваниной] //
          <br />
          афиша. 2013. № 337
          <Spacer size={4} />
          [e—q] тодоровский п. «этот фильм был мощным трамплином…»
          <br />
          [инт. н. мазур] // киноведческие записки. 2003. № 65
          <Spacer size={4} />
          [e—r] полынников а. [о съемках фильма «городской романс»] //
          chapaev.media. 2020
          <Spacer size={4} />
          [e—s] юрьев а. тема человеческого одиночества // chapaev.media. 2020
        </Text>
      </Block>
    </SidesKaravajchuk>

    <Spacer size={80} />

    <Footer />
  </Root>
);

export default Source;
