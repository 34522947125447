/* eslint react/jsx-one-expression-per-line: 0 */

import React from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";

import {
  Footer,
  NamedHeader,
  PageIntroShnitke,
  SidesShnitke,
  Spacer,
  Text,
  TopImage,
} from "../../../lib";

const { PUBLIC_URL } = process.env;

const Cover = styled.img`
  border: 1px solid #000;
  width: 50%;
`;

const Frame = styled.iframe`
  border: none;
  height: 450px;
  width: 100%;
`;

const Links = styled.div`
  position: sticky;
  top: 0px;
`;

const StyledTopImage = styled(TopImage)`
  margin-top: 30px;
`;

const Music: React.FC = () => (
  <>
    <NamedHeader name="музыка" />

    <PageIntroShnitke name="music" />

    <StyledTopImage url={`${PUBLIC_URL}/assets/images/shnitke/music.jpeg`} />

    <Spacer size={10} />

    <SidesShnitke hide="music">
      <Row>
        <Col lg={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
          <Frame
            frameBorder="0"
            height="450"
            src="https://music.yandex.ru/iframe/#album/19561139"
            // style="border:none;width:100%;height:450px;"
            title="Yandex"
            width="100%"
          >
            Слушайте{" "}
            <a href="https://music.yandex.ru/album/19561139">
              Три степени свободы. Музыка кино СССР. (1) Альфред Шнитке
            </a>{" "}
            —{" "}
            <a href="https://music.yandex.ru/artist/3255058">
              Государственный симфонический оркестр кинематографии СССР
            </a>{" "}
            на Яндекс.Музыке
          </Frame>
        </Col>

        <Col lg={{ span: 4 }} sm={{ span: 4 }} xs={{ span: 4 }}>
          <Links>
            <Text size={24}>
              <a
                href="https://music.apple.com/ru/album/%D1%82%D1%80%D0%B8-%D1%81%D1%82%D0%B5%D0%BF%D0%B5%D0%BD%D0%B8-%D1%81%D0%B2%D0%BE%D0%B1%D0%BE%D0%B4%D1%8B-%D0%BC%D1%83%D0%B7%D1%8B%D0%BA%D0%B0-%D0%BA%D0%B8%D0%BD%D0%BE-%D1%81%D1%81%D1%81%D1%80-1-%D0%B0%D0%BB%D1%8C%D1%84%D1%80%D0%B5%D0%B4-%D1%88%D0%BD%D0%B8%D1%82%D0%BA%D0%B5/1591600250"
                rel="noreferrer"
                target="_blank"
              >
                Apple Music
              </a>
              <br />
              <a
                href="https://open.spotify.com/album/1dYPYiCzITqhO36VuLJgmu"
                rel="noreferrer"
                target="_blank"
              >
                Spotify
              </a>
              {/* <br />
              <a
                href="https://music.yandex.ru/album/19561139"
                rel="noreferrer"
                target="_blank"
              >
                Яндекс.Музыка
              </a> */}
              <br />
              <a
                href="https://vk.com/music/album/-2000767078_13767078_8a40dd6dc5c723bc9a"
                rel="noreferrer"
                target="_blank"
              >
                VK Music
              </a>
              <br />
              <a href="https://vk.cc/c8hHrX" rel="noreferrer" target="_blank">
                Boom
              </a>
              <br />
              <a href="https://sber-zvuk.com/release/20584181">SberZvuk</a>
            </Text>
          </Links>

          <Spacer size={10} />

          <a
            href="https://www.ozon.ru/search/?from_global=true&text=Альфред+Шнитке.+Три+Степени+Свободы+%282+LP%29"
            rel="noreferrer"
            target="_blank"
          >
            <Cover
              src={`${PUBLIC_URL}/assets/images/shnitke/music/cover.jpg`}
            />
          </a>
          <Spacer size={2} />
          <Text family="sans" size={18}>
            LP (с марта 2022)
          </Text>
        </Col>
      </Row>
    </SidesShnitke>

    <Spacer size={140} />

    <Footer />
  </>
);

export default Music;
