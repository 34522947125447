import React from "react";
import styled, { css } from "styled-components";

import { mediaQuery } from "../utils";

import Text from "./Text";

type Props = {
  children: React.ReactNode;
  inverted?: boolean;
};

const StyledText = styled(Text)<{ inverted: boolean }>`
  color: ${(props) => (props.inverted ? "#fff" : "#000")};
  font-size: 36px;
  line-height: 0.8;
  text-transform: lowercase;

  ${mediaQuery(
    "phone",
    css`
      font-size: 20px;
    `,
  )}
`;

const SubTitle: React.FC<Props> = ({
  children,
  inverted = false,
  ...rest
}: Props) => (
  <StyledText
    {...{
      ...rest,
      inverted,
      // size: 36,
    }}
  >
    {children}
  </StyledText>
);

export default SubTitle;
