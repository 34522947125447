import * as React from "react";
import styled, { css } from "styled-components";

import { mediaQuery } from "../utils";

export type Props = {
  half?: boolean;
  size?: number;
  vertical?: boolean;
};

export type RootProps = {
  half: boolean;
  size: number;
  vertical: boolean;
};

const Root = styled.div<RootProps>`
  display: block;

  ${({ half, vertical, size }) =>
    vertical &&
    css`
      height: ${`${size * 4}px`};

      ${mediaQuery(
    "phone",
    css`
          height: ${`${size * (half ? 2 : 4)}px`};
        `,
  )}
    `}

  ${({ half, vertical, size }) =>
    !vertical &&
    css`
      display: inline-block;
      width: ${`${size * 4}px`};

      ${mediaQuery(
    "phone",
    css`
          /* height: ${`${size * (half ? 2 : 4)}px`}; */
        `,
  )}
    `}
`;

const Spacer: React.FC<Props> = ({
  half = false,
  size = 1,
  vertical = true,
  ...rest
}: Props) => (
  <Root
    {...{
      ...rest,
      half,
      size,
      vertical,
    }}
  />
);

export default Spacer;
