import React from "react";
import styled, { css } from "styled-components";
import * as R from "ramda";

import Logo from "./Logo";
import { mediaQuery } from "../utils";

type Props = {
  handleMenu: () => void;
  isMenuShowing: boolean;
};

const Cross = styled.div`
  margin-top: 10px;
`;

const Menu = styled.div<{ isMenuShowing: boolean }>`
  display: none;
  height: 26px;
  opacity: ${({ isMenuShowing }) => (isMenuShowing ? 0.7 : 1)};
  position: absolute;
  right: 20px;
  top: 28px;
  width: 26px;

  ${mediaQuery(
    "phone",
    css`
      display: block;
      justify-content: space-around;
    `,
  )}
`;

const Line = styled.div`
  background-color: #000;
  border-radius: 2px;
  height: 3px;
  margin: 3px 0;
  width: 100%;
`;

const FirstLine = styled(Line)`
  margin: 0;
  position: absolute;
  transform: rotate(45deg);
`;

const SecondLine = styled(Line)`
  margin: 0;
  position: absolute;
  transform: rotate(-45deg);
`;

const Root = styled.div`
  padding: 40px;
  position: relative;
  z-index: 100;

  ${mediaQuery(
    "phone",
    css`
      background-color: rgba(255, 255, 255, 0.98);
      border-bottom: 1px solid #000;
      padding: 20px 20px 37px;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 7;
    `,
  )}
`;

const Header: React.FC<Props> = ({ isMenuShowing, handleMenu }: Props) => (
  <Root>
    <a href="/">
      <Logo />
    </a>
    <Menu isMenuShowing={isMenuShowing} onClick={handleMenu}>
      {isMenuShowing ? (
        <Cross>
          <FirstLine />
          <SecondLine />
        </Cross>
      ) : (
        R.range(0, 3).map((i) => <Line key={i} />)
      )}
    </Menu>
  </Root>
);

export default Header;
