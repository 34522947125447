import React from "react";
import styled from "styled-components";

import Text from "./Text";

type Props = {
  children: React.ReactNode;
};

const StyledText = styled(Text)`
  line-height: 1.2;
  margin: 40px 0;
`;

const Director: React.FC<Props> = ({ children, ...rest }: Props) => (
  <StyledText
    {...{
      ...rest,
      family: "thin",
      size: 36,
    }}
  >
    {children}
  </StyledText>
);

export default Director;
