import React from "react";
import styled, { css } from "styled-components";

import Spacer from "./Spacer";
import { mediaQuery } from "../utils";

const { PUBLIC_URL } = process.env;

const LogoFigure = styled.img`
  width: 100px;

  ${mediaQuery(
    "phone",
    css`
      width: 50px;
    `,
  )}
`;

const LogoType = styled.img`
  transform: translateY(3px);
  width: 122px;

  ${mediaQuery(
    "phone",
    css`
      transform: translateY(10px);
      width: 120px;
    `,
  )}
`;

const StyledSpacer = styled(Spacer)`
  ${mediaQuery(
    "phone",
    css`
      width: 20px;
    `,
  )}
`;

const Root = styled.div``;

const Logo: React.FC = () => (
  <Root>
    <LogoFigure
      alt="три степени свободы"
      src={`${PUBLIC_URL}/assets/images/ui/logo.png`}
    />

    <StyledSpacer size={12} vertical={false} />

    <LogoType
      alt="три степени свободы"
      src={`${PUBLIC_URL}/assets/images/ui/logo-type.png`}
    />
  </Root>
);

export default Logo;
