import React from "react";
import styled from "styled-components";

type Props = {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

const Root = styled.button`
  font-size: 16px;
`;

const Button: React.FC<Props> = ({ children, ...rest }: Props) => (
  <Root {...{ ...rest }}>{children}</Root>
);

export default Button;
