import React from "react";
import styled, { css } from "styled-components";

import { mediaQuery } from "../utils";

const { PUBLIC_URL } = process.env;

const Root = styled.button`
  background-color: transparent;
  background-image: url(${`${PUBLIC_URL}/assets/images/ui/to-top-arrow.png`});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
  border: none;
  bottom: 30px;
  cursor: pointer;
  height: 100px;
  position: fixed;
  right: 30px;
  width: 100px;
  z-index: 100;

  ${mediaQuery(
    "phone",
    css`
      height: 50px;
      width: 50px;
    `,
  )}
`;

const ScrollToTopArrow: React.FC = () => {
  const [isVisible, setIsVisible] = React.useState(false);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    setIsVisible(currentScrollPos > 1000);
  };

  const scrollToTop = () => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return isVisible ? <Root onClick={scrollToTop} type="button" /> : <></>;
};

export default ScrollToTopArrow;
