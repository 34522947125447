import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Spacer from "./Spacer";
import Title from "./Title";
import Text from "./Text";

type Props = {
  // handleMenu: () => void;
  isMenuShowing: boolean;
};

const HyperLink = styled.a``;

const StyledLink = styled(Link)`
  display: block;
`;

const Root = styled.div<{ isMenuShowing: boolean }>`
  background-color: rgba(255, 255, 255, 0.98);
  display: ${(props) => (props.isMenuShowing ? "block" : "none")};
  max-height: 100vh;
  overflow: scroll;
  padding-bottom: 200px;
  padding-left: 30px;
  padding-top: 100px;
  position: fixed;
  text-align: left;
  top: 84px;
  width: 100%;
  z-index: 30;
`;

const NavMenu: React.FC<Props> = ({ isMenuShowing }: Props) => (
  <Root isMenuShowing={isMenuShowing}>
    <Text size={30}>
      <Title>Альфред Шнитке (1)</Title>
      <Link to="/shnitke/music">музыка</Link>
      <br />
      <Link to="/shnitke/cinema">кино</Link>
      <br />
      <Link to="/shnitke/time">время</Link>
      <br />
      <Link to="/shnitke/practicum">практиктикум</Link>
      <br />
      <Link to="/shnitke/philosophy">философия</Link>
      <br />
      <Link to="/shnitke/source">источники</Link>
    </Text>

    <Spacer size={4} />
    <hr />
    <Spacer size={8} />

    <Text size={30}>
      <Title>Олег Каравайчук (2)</Title>
      <Link to="/karavajchuk/music">музыка</Link>
      <br />
      <Link to="/karavajchuk/vunderkind">вундеркинд</Link>
      <br />
      <Link to="/karavajchuk/cinema">кино</Link>
      <br />
      <Link to="/karavajchuk/ermitazh">эрмитаж</Link>
      <br />
      <Link to="/karavajchuk/time">время</Link>
      <br />
      <Link to="/karavajchuk/source">источники</Link>
    </Text>

    <Spacer size={4} />
    <hr />
    <Spacer size={8} />

    <Text size={24}>
      <StyledLink to="/about">о проекте</StyledLink>
      {/* <Spacer />
      <HyperLink href="https://www.facebook.com/3degreesoffreedom">
        fb
      </HyperLink> */}
      <Spacer />
      <HyperLink href="https://vk.com/3degreesoffreedom">vk</HyperLink>
      <Spacer />
      <HyperLink href="https://t.me/threedegreesoffreedom">telegram</HyperLink>
      <Spacer />
      <HyperLink href="https://www.youtube.com/channel/UC4sfaNktpPbzqPmoZfJjDtA">
        youtube
      </HyperLink>
    </Text>
  </Root>
);

export default NavMenu;
