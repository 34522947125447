const cinemas = [
  {
    title: "АЛЕША ПТИЦЫН ВЫРАБАТЫВАЕТ ХАРАКТЕР",
    url: "https://www.youtube.com/watch?v=j_7N975wz3U",
    year: "1953",
  },
  {
    title: "ДВА КАПИТАНА",
    url: "https://www.youtube.com/watch?v=Fk4_gh6Zk1U&t=24s",
    year: "1955",
  },
  {
    title: "СОЛДАТЫ",
    url: "https://www.youtube.com/watch?v=-GnVtdK2tVU",
    year: "1956",
  },
  {
    title: "КРУТЫЕ ГОРКИ",
    url: "",
    year: "1956",
  },
  {
    title: "НА ОСТРОВЕ ДАЛЬНЕМ",
    url: "",
    year: "1957",
  },
  {
    title: "ПОД СТУК КОЛЕС",
    url: "https://www.youtube.com/watch?v=9bsSGInGNmI",
    year: "1958",
  },
  {
    title: "ДЕНЬ ПЕРВЫЙ",
    url: "",
    year: "1958",
  },
  {
    title: "ГОРОД ЗАЖИГАЕТ ОГНИ",
    url: "https://www.youtube.com/watch?v=hi7ivr3VTxY&list=PLaVercZoOg7dJmSlLnmDoWNG664W9Uoge&index=5",
    year: "1958",
  },
  {
    title: "В ТВОИХ РУКАХ ЖИЗНЬ",
    url: "https://www.youtube.com/watch?v=DTCPXqDETOU&list=PLaVercZoOg7fMX1B27vTjiadTXjrPLOOp&index=1&t=1s",
    year: "1959",
  },
  {
    title: "ЛИБЕРАЛ (короткометражный)",
    url: "https://www.youtube.com/watch?v=NVjC33AToDM",
    year: "1959",
  },
  {
    title: "ПОДНЯТАЯ ЦЕЛИНА",
    url: "https://www.youtube.com/watch?v=zB0rJc9H8qg",
    year: "1959",
  },
  {
    title: "ПЕСТРЫЕ РАССКАЗЫ (спектакль)",
    url: "",
    year: "1959",
  },
  {
    title: "РАССКАЗ ОДНОЙ ДЕВУШКИ (спектакль)",
    url: "",
    year: "1959",
  },
  {
    title: "ЛЮБЛЮ ТЕБЯ, ЖИЗНЬ",
    url: "https://www.youtube.com/watch?v=kJEaGXMvMms",
    year: "1960",
  },
  {
    title: "ЧЕМОДАН С НАКЛЕЙКАМИ (спектакль)",
    url: "",
    year: "1961",
  },
  {
    title: "ПОСЛЕ СВАДЬБЫ",
    url: "https://www.youtube.com/watch?v=V4FmovpYdqo",
    year: "1962",
  },
  {
    title: "НИКОГДА",
    url: "https://www.youtube.com/watch?v=thRH6ymITIc",
    year: "1962",
  },
  {
    title: "У КРУТОГО ЯРА",
    url: "https://www.youtube.com/watch?v=3NhY4ARAX-M",
    year: "1962",
  },
  {
    title: "ЛЕВ ГУРЫЧ СИНИЧКИН (спектакль)",
    url: "",
    year: "1962",
  },
  {
    title: "КЛОП (спектакль)",
    url: "",
    year: "1962",
  },
  {
    title: "МОЛОДОЖЁН (короткометражный)",
    url: "https://www.youtube.com/watch?v=E0PBf0ze080",
    year: "1963",
  },
  {
    title: "ТАЙНА",
    url: "https://www.youtube.com/watch?v=ZAOak1w_PQI",
    year: "1963",
  },
  {
    title: "ЦАРИ",
    url: "https://www.youtube.com/watch?v=s29y4My-zsc",
    year: "1964",
  },
  {
    title: "ДВЕНАДЦАТАЯ НОЧЬ (спектакль)",
    url: "",
    year: "1964",
  },
  {
    title: "ПОГОНЯ",
    url: "https://www.youtube.com/watch?v=z7vEyB50Zi4",
    year: "1965",
  },
  {
    title: "ГОРОД МАСТЕРОВ",
    url: "https://www.youtube.com/watch?v=-G1B_q_FXes",
    year: "1965",
  },
  {
    title: "АВДОТЬЯ ПАВЛОВНА",
    url: "https://www.youtube.com/watch?v=I-cGMHCsPG8",
    year: "1966",
  },
  {
    title: "ИСКУССТВО КОМЕДИИ (спектакль)",
    url: "",
    year: "1966",
  },
  {
    title: "ЖИТИЕ И ВОЗНЕСЕНИЕ ЮРАСЯ БРАТЧИКА",
    url: "https://www.youtube.com/watch?v=OilyMgbYIgQ",
    year: "1967",
  },
  {
    title: "КОРОТКИЕ ВСТРЕЧИ",
    url: "https://www.youtube.com/watch?v=28skxPwAvaM",
    year: "1967",
  },
  {
    title: "ЗВОНОК В ПУСТУЮ КВАРТИРУ (спектакль)",
    url: "",
    year: "1967",
  },
  {
    title: "ЛЮДИ ЗЕМЛИ И НЕБА",
    url: "https://www.youtube.com/watch?v=hZAI76yrgUE",
    year: "1969",
  },
  {
    title: "ВНИМАНИЕ, ЦУНАМИ",
    url: "https://www.youtube.com/watch?v=tPw1CrVUHII",
    year: "1969",
  },
  {
    title: "МАМА ВЫШЛА ЗАМУЖ",
    url: "https://www.youtube.com/watch?v=j-LNWkSCntM",
    year: "1969",
  },
  {
    title: "КАРЕЛИЯ (документальный)",
    url: "",
    year: "1969",
  },
  {
    title: "ГОРОДСКОЙ РОМАНС",
    url: "https://www.youtube.com/watch?v=b8dShMst32Y",
    year: "1970",
  },
  {
    title: "СЕКУНДОМЕР",
    url: "https://www.youtube.com/watch?v=f9ZdsP5-gXI",
    year: "1970",
  },
  {
    title: "УМЕЕТЕ ЛИ ВЫ ЖИТЬ?",
    url: "https://www.youtube.com/watch?v=WUelnHiZb4Q",
    year: "1970",
  },
  {
    title: "ПОКА СВОБОДОЮ ГОРИМ (документальный)",
    url: "https://www.net-film.ru/film-40625/",
    year: "1970",
  },
  {
    title: "ТОВАРИЩ СВЕРДЛОВ. СТРАНИЦЫ ЖИЗНИ (документальный)",
    url: "https://www.youtube.com/watch?v=rlv1ftkClfw",
    year: "1970",
  },
  {
    title: "ТЫСЯЧА СТО НОЧЕЙ (документальный)",
    type: "документальный",
    url: "",
    year: "1970",
  },
  {
    title: "РОМЕО И ДЖУЛЬЕТТА (спектакль)",
    type: "спектакль",
    url: "",
    year: "1970",
  },
  {
    title: "СЕЛО СТЕПАНЧИКОВО И ЕГО ОБИТАТЕЛИ (спектакль)",
    type: "спектакль",
    url: "",
    year: "1970",
  },
  {
    title: "ДРАМА ИЗ СТАРИННОЙ ЖИЗНИ",
    type: "",
    url: "https://www.youtube.com/watch?v=CGaMsK2a70s",
    year: "1971",
  },
  {
    title: "ДОЛГИЕ ПРОВОДЫ",
    type: "",
    url: "https://www.youtube.com/watch?v=KxNiyctaYRQ",
    year: "1971",
  },
  {
    title: "КОМИТЕТ 19",
    type: "",
    url: "https://www.youtube.com/watch?v=KRWVZh6gsWU",
    year: "1971",
  },
  {
    title: "КРАСНЫЙ ДИПЛОМАТ. СТРАНИЦЫ ЖИЗНИ ЛЕОНИДА КРАСИНА",
    type: "",
    url: "",
    year: "1971",
  },
  {
    title: "МОНОЛОГ",
    type: "",
    url: "https://www.youtube.com/watch?v=9gxjtwSXrxQ",
    year: "1972",
  },
  {
    title: "ИГРОК",
    type: "",
    url: "https://www.youtube.com/watch?v=9EIYMM1Cov0",
    year: "1972",
  },
  {
    title: "ПРИНЦ И НИЩИЙ",
    type: "",
    url: "https://www.youtube.com/watch?v=65ClHKu-TWY",
    year: "1972",
  },
  {
    title: "СТАРАЯ КРЕПОСТЬ (мини-сериал)",
    type: "мини-сериал",
    url: "https://www.yandex.ru/video/preview/16465087571360791213",
    year: "1972",
  },
  {
    title: "ОБЛАКА",
    type: "",
    url: "https://www.youtube.com/watch?v=Py-x2FaVfAk",
    year: "1973",
  },
  {
    title: "МОЛЬЕР (спектакль)",
    url: "",
    year: "1973",
  },
  {
    title: "ВРАЧА ВЫЗЫВАЛИ?",
    type: "",
    url: "https://www.youtube.com/watch?v=ui05z4qVOQI",
    year: "1974",
  },
  {
    title: "КСЕНИЯ, ЛЮБИМАЯ ЖЕНА ФЕДОРА",
    url: "https://www.youtube.com/watch?v=i9B8IEhaJJY",
    year: "1974",
  },
  {
    title: "ЧУЖИЕ ПИСЬМА",
    url: "https://www.youtube.com/watch?v=rksMK481KE0",
    year: "1975",
  },
  {
    title: "ЕГЕРЬ (короткометражный)",
    url: "",
    year: "1975",
  },
  {
    title: "..И ДРУГИЕ ОФИЦИАЛЬНЫЕ ЛИЦА",
    url: "https://www.youtube.com/watch?v=ytFv-si1X68",
    year: "1976",
  },
  {
    title: "МОЯ БАБУШКА (музыка к восстановленной версии 1929 года)",
    url: "",
    year: "1976",
  },
  {
    title: "АСЯ",
    url: "https://www.youtube.com/watch?v=z6uoH60RNj4",
    year: "1977",
  },
  {
    title: "ЖЕНИТЬБА",
    url: "https://www.youtube.com/watch?v=7vgL79kZY50",
    year: "1977",
  },
  {
    title: "БУДЕМ ЗНАКОМЫ (киноальманах)",
    url: "",
    year: "1977",
  },
  {
    title: "ГАРАНТИРУЮ ЖИЗНЬ",
    url: "https://www.youtube.com/watch?v=NOvG2FeH7OY",
    year: "1977",
  },
  {
    title: "ЛЕТНЯЯ ПОЕЗДКА К МОРЮ",
    url: "https://www.youtube.com/watch?v=_ohSbPD39aA",
    year: "1978",
  },
  {
    title: "ЧЕРТОВО СЕМЯ",
    url: "",
    year: "1979",
  },
  {
    title: "ВПЕРВЫЕ ЗАМУЖЕМ",
    url: "https://www.youtube.com/watch?v=sj5ZbajWVcA",
    year: "1979",
  },
  {
    title: "ВЗЛЕТ",
    url: "https://cinema.mosfilm.ru/films/34653/",
    year: "1979",
  },
  {
    title: "ЧЕРНАЯ КУРИЦА, ИЛИ ПОДЗЕМНЫЕ ЖИТЕЛИ",
    url: "https://www.youtube.com/watch?v=TflLRVCliTA",
    year: "1980",
  },
  {
    title: "ПУТЕШЕСТВИЕ В КАВКАЗСКИЕ ГОРЫ",
    url: "",
    year: "1981",
  },
  {
    title: "ШУРОЧКА",
    url: "https://www.youtube.com/watch?v=oJqkemxNo8M",
    year: "1982",
  },
  {
    title: "СЧАСТЬЕ НИКИФОРА БУБНОВА",
    url: "https://www.youtube.com/watch?v=eF7ow8j0Tm0",
    year: "1983",
  },
  {
    title: "ПЕРЕСТУПИТЬ ЧЕРТУ",
    url: "https://www.youtube.com/watch?v=rb_zxFQVL3g",
    year: "1985",
  },
  {
    title: "ФУЭТЕ",
    url: "https://www.youtube.com/watch?v=vaIviIZNzO4",
    year: "1986",
  },
  {
    title: "ОЧЕНЬ ВАС ВСЕХ ЛЮБЛЮ",
    url: "https://www.youtube.com/watch?v=0fMRP7DnvuU",
    year: "1987",
  },
  {
    title: "ДИКИЕ ЛЕБЕДИ",
    url: "https://www.youtube.com/watch?v=rXz_6U_5g64",
    year: "1987",
  },
  {
    title: "ЛИЦО",
    url: "https://www.net-film.ru/film-61982/",
    year: "1988",
  },
  {
    title: "НОВЫЕ ПРИКЛЮЧЕНИЯ ЯНКИ ПРИ ДВОРЕ КОРОЛЯ АРТУРА",
    url: "https://www.youtube.com/watch?v=mckHGWSksi8",
    year: "1988",
  },
  // {
  //   title: "НОВЫЕ ПРИКЛЮЧЕНИЯ ЯНКИ ПРИ ДВОРЕ КОРОЛЯ АРТУРА (2 серия)",
  //   url: "https://www.youtube.com/watch?v=ukPFB252CXQ",
  //   year: "1988",
  // },
  {
    title: "МУЖ И ДОЧЬ ТАМАРЫ АЛЕКСАНДРОВНЫ",
    url: "https://cinema.mosfilm.ru/films/34792/",
    year: "1988",
  },
  {
    title: "ПРОВИНЦИАЛЬНЫЙ АНЕКДОТ (короткометражный)",
    url: "https://www.youtube.com/watch?v=L1IiRmRepu8",
    year: "1990",
  },
  {
    title: "ДРУГАЯ ЖИЗНЬ. ПАСТЕРНАК",
    url: "https://www.youtube.com/watch?v=eSkcauvBJ5I",
    year: "1990",
  },
  {
    title: "НОГА",
    url: "https://www.youtube.com/watch?v=_OBiTN7mavM",
    year: "1991",
  },
  {
    title: "РЕКА ОККЕРВИЛЬ",
    url: "https://vimeo.com/109626066?embedded=true&source=vimeo_logo&owner=19173830",
    year: "1992",
  },
  {
    title: "СОНМ БЕЛЫХ КНЯЖЕН",
    url: "https://www.youtube.com/watch?v=oouWiVnoB7A",
    year: "1992",
  },
  {
    title: "БЕСЫ (спектакль)",
    url: "",
    year: "1992",
  },
  {
    title: "ОСТРОВА",
    url: "",
    year: "1993",
  },
  {
    title: "ГОД СОБАКИ",
    url: "https://www.youtube.com/watch?v=FawSq4cqK84",
    year: "1994",
  },
  {
    title: "ЖЕЛЕЗНЫЙ ЗАНАВЕС (мини-сериал)",
    url: "",
    year: "1994",
  },
  {
    title: "ЗДРАВСТВУЙ, ПЛЕМЯ МОЛОДОЕ (киноальманах)",
    url: "",
    year: "1996",
  },
  {
    title: "БАБУШКА (мультфильм)",
    url: "https://www.youtube.com/watch?v=oUC_akoTAuI",
    year: "1996",
  },
  {
    title: "НЕЖИВОЙ ЗВЕРЬ",
    url: "",
    year: "1996",
  },
  {
    title: "СИРЕНЕВЫЕ СУМЕРКИ",
    url: "https://www.youtube.com/watch?v=udMR9_VX1Eo",
    year: "2000",
  },
  {
    title: "СОБСТВЕННАЯ ТЕНЬ",
    url: "",
    year: "2001",
  },
  {
    title: "МАШИНА ПРИШЛА  (короткометражный)",
    url: "",
    year: "2001",
  },
  {
    title: "ТЕМНАЯ НОЧЬ",
    url: "",
    year: "2001",
  },
  {
    title: "БРОНЕНОСЕЦ «ПОТЕМКИН»",
    url: "https://music.yandex.ru/album/3813793",
    year: "2002",
  },
  {
    title: "ПУШКИН. ДУЭЛЬ. СМЕРТЬ (фильм-спектакль)",
    url: "",
    year: "2005",
  },
  {
    title: "КОЛЫБЕЛЬНАЯ (короткометражный, мультфильм)",
    url: "https://www.youtube.com/watch?v=Gv-8qfRJ60Y",
    year: "2007",
  },
  {
    title: "ИЗОТОВ (спектакль)",
    url: "",
    year: "2009",
  },
];

export default cinemas;
