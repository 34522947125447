/* eslint react/jsx-one-expression-per-line: 0 */

import React from "react";
import styled, { css } from "styled-components";

import {
  Block,
  Footer,
  Nav,
  PhoneMenu,
  Spacer,
  Text,
  Title,
  TypographyWrapper,
} from "../../lib";
import { mediaQuery } from "../../utils";

const { PUBLIC_URL } = process.env;

const Image = styled.img`
  width: 100%;
`;

const PageTitle = styled(Text)`
  font-size: 80px;
  margin-bottom: 100px;
  margin-right: 7vw;
  text-align: right;

  ${mediaQuery(
    "phone",
    css`
      display: none;
    `,
  )}
`;

const Root = styled(TypographyWrapper)`
  ${mediaQuery(
    "phone",
    css`
      padding-top: 150px;
    `,
  )}
`;

const StyledText = styled(Text)`
  margin-bottom: -8px;

  ${mediaQuery(
    "phone",
    css`
      /* padding-top: 150px; */
    `,
  )}
`;

const StyledTitle = styled(Title)`
  line-height: 0.7;

  ${mediaQuery(
    "phone",
    css`
      /* padding-top: 150px; */
    `,
  )}
`;

const StyledPhoneMenu = styled(PhoneMenu)`
  margin-top: -80px;
`;

const About: React.FC = () => (
  <Root>
    <Nav />
    <StyledPhoneMenu />
    <PageTitle>альфред шнитке (1)</PageTitle>

    {/* <Link to="/shnitke/music">
      <Photo />
    </Link> */}

    <Block>
      <StyledTitle>О ПРОЕКТЕ</StyledTitle>
      <Spacer half size={20} />

      <StyledTitle>сайт</StyledTitle>
      <Spacer size={2} />
      <StyledText>Автор идеи, продюсер: Олег Нестеров</StyledText>
      <StyledText>Разработка: Андрей Кеске</StyledText>
      <StyledText>Дизайн: Арина Журавлёва</StyledText>
      <StyledText>Редакторы: Наталья Нестерова, Кристина Сидорова</StyledText>
      <StyledText>Киновед: Маруся Марголит</StyledText>
      <StyledText>
        Исполнительные продюсеры: Алесандр Малахов, Ада Солвич
      </StyledText>
      <StyledText>
        Административная группа: Лена Нестерова, Дмитрий Червяков, Анна Малькова
      </StyledText>

      <Spacer half size={20} />

      <StyledTitle>фотокадры из фильмов </StyledTitle>
      <Spacer size={2} />
      <StyledText>Киноконцерн «Мосфильм»</StyledText>
      <StyledText>
        Государственный фонд кинофильмов Российской Федерации
      </StyledText>

      <Spacer half size={20} />

      <StyledTitle>архивные документы и фотографии</StyledTitle>
      <Spacer size={2} />
      <StyledText>
        Государственный фонд кинофильмов Российской Федерации
      </StyledText>
      <StyledText>МГИМ им. А.Г.Шнитке</StyledText>
      <StyledText>
        Личные архивы Альфреда Шнитке, Андрея Хржановского, Элема Климова и
        Ларисы Шепитько
      </StyledText>

      <Spacer half size={20} />

      <StyledTitle>музыка</StyledTitle>
      <Spacer size={2} />
      <StyledTitle size={28}>оригинальные фонограммы из фильмов</StyledTitle>
      <StyledText>Киноконцерн «Мосфильм»</StyledText>
      <StyledText>
        Государственный фонд кинофильмов Российской Федерации
      </StyledText>
      <StyledText>Киностудия «Союзмультфильм»</StyledText>
      <StyledText>Кинокомпания ТАМП</StyledText>

      <Spacer size={2} />

      <StyledText>(С) Ирина Шнитке</StyledText>
      <StyledText>(P) ФГУП Киноконцерн «Мосфильм» </StyledText>
      <StyledText>
        (P) Государственный фонд кинофильмов Российской Федерации /
        <br />
        комиссар, стеклянная гармоника, фантазии фарятьева
      </StyledText>
      <StyledText>(P) ТАМП мастер и маргарита</StyledText>
      <StyledText>
        (P) ООО «Международная книга Музыка» агония / вальс
      </StyledText>

      <Spacer size={2} />

      <StyledText>Государственный оркестр кинематографии СССР </StyledText>
      <StyledText>Дирижёры</StyledText>
      <StyledText>Вероника Дударова: похождения зубного врача</StyledText>
      <StyledText>
        Юрий Николаевский: стеклянная гармоника / восхождение
      </StyledText>
      <StyledText>
        Эмин Хачатурян: комиссар / осень / маленькие трагедии / мёртвые души
      </StyledText>
      <StyledText>
        Эри Клас: спорт, спорт, спорт! / и всё-таки я верю / ты и я / агония
      </StyledText>
      <StyledText>
        Марк Эрмлер: сказ про то, как царь пётр арапа женил / экипаж
      </StyledText>
      <StyledText>Константин Кримец: прощание</StyledText>

      <Spacer size={2} />

      <StyledText>
        Академический симфонический оркестр Московской филармонии / мастер и
        маргарита
      </StyledText>
      <StyledText>Дирижёр</StyledText>
      <StyledText>Frank Strobel</StyledText>

      <Spacer size={2} />
      <StyledText>Составитель и продюсер: Олег Нестеров</StyledText>
      <StyledText>Артворк: Кирилл Глущенко</StyledText>
      <StyledText>Музыкальный редактор: Александр Афанасьев</StyledText>
      <StyledText>
        Подготовка звуковых файлов: Тенгиз Дадов, Игорь Гостевский, Анна
        Малькова
      </StyledText>
      <StyledText>
        Реставрация и ремастеринг: Radu Marinesku, аt acoustic-entertainment,
        Cologne, Germany
      </StyledText>
      <StyledText>
        Административная группа: Лена Нестерова, Дмитрий Червяков, Дмитрий
        Зюликов
      </StyledText>
      <StyledText>©&℗ 2021 Снегири</StyledText>

      <Spacer half size={20} />
      <StyledTitle>книга</StyledTitle>
      <Spacer size={2} />
      <StyledText>Составитель: Олег Нестеров</StyledText>
      <StyledText>Редактор: Пётр Лезников</StyledText>
      <StyledText>Выпускающий редактор: Саша Ахмадщина</StyledText>
      <StyledText>Корректоры: Анна Изакар, Никита Сунгатов</StyledText>
      <StyledText>Дизайнер: Арина Журавлёва</StyledText>

      <Spacer half size={20} />
      <StyledTitle>live</StyledTitle>
      <Spacer size={2} />
      <StyledText>Автор идеи: Олег Нестеров</StyledText>
      <StyledText>Продюсер: Саша Ахмадщина</StyledText>
      <StyledText>
        Команда акусмониума: Антон Яхонтов, Armando Balice, Илья Syphocat, Борис
        Шершенков
      </StyledText>
      <StyledText>Технический директор: Александр Пушкин</StyledText>
      <StyledText>Сценограф, видеохудожник: Дина Караман</StyledText>
      <StyledText>Дизайнер: Арина Журавлева</StyledText>
      <StyledText>Координатор: Анастасия Погорелова</StyledText>
      <StyledText>
        Фонограммы академических произведений предоставлены фирмой «Мелодия»
      </StyledText>

      <Spacer half size={20} />
      <StyledTitle>особая благодарность</StyledTitle>
      <Spacer size={2} />
      <Text>
        Ирина Шнитке, Наум Клейман, Евгений Марголит, Андрей Хржановский, Андрей
        Смирнов, Александр Митта, Кирилл Разлогов, Карен Шахназаров, Александр
        Шпагин, Олег Томшинский, Алексей Мунипов, Сергей Скрипка, Владимир
        Скорый, Карина Абрамян, Андрей Кричевский, Гаянэ Амбрацумян, Екатерина
        Муравлёва, Галина Попова, Светлана Яковлева, Ольга Петренко, Павел
        Шерер, Даша Шадрина, Филипп Дзядко, Алик Каспаров, Рома Либеров, Антон
        Климов,
      </Text>

      <Spacer half size={20} />
      <StyledTitle>а также</StyledTitle>
      <Spacer size={2} />
      <StyledText>МГИМ им. А.Г.Шнитке</StyledText>
      <StyledText>Киностудия «Союзмультфильм»</StyledText>
      <StyledText>ВГТРК</StyledText>
      <StyledText>Союз Композиторов России</StyledText>
      <StyledText>Акционерное общество “Фирма Мелодия”</StyledText>

      <Spacer half size={20} />
      <StyledTitle>партнёры проекта</StyledTitle>
      <Spacer size={2} />
      <StyledText>ФГУП Киноконцерн «Мосфильм»</StyledText>
      <StyledText>
        Государственный фонд кинофильмов Российской Федерации
      </StyledText>
      <StyledText>Ленфильм</StyledText>
      <StyledText>Снегири-музыка</StyledText>
    </Block>

    {/*  */}

    <Spacer half size={30} />

    <PageTitle>олег каравайчук (2)</PageTitle>

    <Block>
      <StyledTitle>сайт</StyledTitle>
      <Spacer size={2} />
      <StyledText>Составитель и продюсер: Олег Нестеров</StyledText>
      <StyledText>Разработка: Андрей Кеске</StyledText>
      <StyledText>Дизайн: Арина Журавлёва</StyledText>
      <StyledText>Редактор: Наталья Нестерова</StyledText>

      <Spacer half size={20} />

      <StyledTitle>фотокадры из фильмов</StyledTitle>
      <Spacer size={2} />
      <StyledText>
        (с) Государственный фонд кинофильмов Российской Федерации
      </StyledText>
      <StyledText>(с) Киноконцерн «Мосфильм»</StyledText>
      <StyledText>(с) Киностудия «Беларусьфильм»</StyledText>

      <Spacer half size={20} />

      <StyledTitle>архивные документы и фотографии</StyledTitle>
      <Spacer size={2} />
      <StyledText>
        (с) Государственный фонд кинофильмов Российской Федерации
      </StyledText>
      <StyledText>(с) ЦГАЛИ СПб</StyledText>
      <StyledText>(с) Киностудия «Ленфильм»</StyledText>
      <StyledText>(c) Киностудия «Беларусьфильм»</StyledText>
      <StyledText>
        (с) Фонд Каравайчука: заглавное фото слоя МУЗЫКА и КИНО
      </StyledText>
      <StyledText>Личные архивы Галлы Капицкой, Натальи Рязанцевой</StyledText>

      <Spacer half size={20} />

      <StyledTitle>музыка</StyledTitle>
      <Spacer size={2} />
      <StyledTitle size={28}>оригинальные фонограммы</StyledTitle>
      <StyledText>
        Государственный фонд кинофильмов Российской Федерации
      </StyledText>
      <StyledText>Киностудия «Ленфильм»</StyledText>
      <StyledText>(P) ФГУП Киноконцерн «Мосфильм» </StyledText>
      <Spacer size={2} />
      <StyledText>Олег Каравайчук </StyledText>
      <StyledText>Ансамбль солистов Ленинградской филармонии</StyledText>
      <Spacer size={2} />
      <StyledText>Составитель и продюсер: Олег Нестеров</StyledText>
      <StyledText>Артворк: Кирилл Глущенко</StyledText>
      <StyledText>Подготовка звуковых файлов: Тенгиз Дадов</StyledText>
      <StyledText>
        Реставрация и ремастеринг: Борис Алексеев, (Classic Multimedia Group)
      </StyledText>
      <StyledText>
        Административная группа: Лена Нестерова, Дмитрий Зюликов
      </StyledText>
      <StyledText>©&℗ 2023 Снегири</StyledText>

      <Spacer half size={20} />

      <StyledTitle>книга</StyledTitle>
      <Spacer size={2} />
      <StyledText>Составитель: Олег Нестеров</StyledText>
      <StyledText>Редактор: Пётр Лезников</StyledText>
      <StyledText>Выпускающий редактор: Саша Ахмадщина</StyledText>
      <StyledText>Дизайнер: Арина Журавлёва</StyledText>

      <Spacer half size={20} />

      <StyledTitle>live</StyledTitle>
      <Spacer size={2} />
      <StyledText>Автор проекта: Олег Нестеров</StyledText>
      <StyledText>Креативный продюсер: Саша Ахмадщина</StyledText>
      <StyledText>Художник: Михаил Заиканов</StyledText>
      <StyledText>
        Архитектор пространственного звука: Антон Яхонтов{" "}
      </StyledText>
      <StyledText>Звукорежиссер, дирижер: Илья Symphocat </StyledText>
      <StyledText>
        Подготовка и реставрация аудиоматериалов: Борис Алексеев (Classic
        Multimedia Group
      </StyledText>
      <StyledText>Художник-экспозиционер: Татьяна Артамонова </StyledText>
      <StyledText>Продюсер: Константин Нафиков</StyledText>
      <StyledText>Продюсер: Наталия Фролова </StyledText>
      <StyledText>Координатор: Анастасия Погорелова</StyledText>
      <StyledText>Настройка акусмониума: Леонид Грищенко</StyledText>
      <StyledText>Художник по свету: Александр Патраш </StyledText>
      <StyledText>Светорежиссер: Евгений Потапенко</StyledText>
      <StyledText>Фотограф, координатор: Анастасия Blur</StyledText>
      <StyledText>Дизайнер: Арина Журавлева</StyledText>

      <Spacer half size={20} />

      <StyledTitle>благодарность</StyledTitle>
      <Spacer size={2} />
      <StyledText>
        Александр Шпагин, Евгений Марголит, Екатерина Муравлёва, Галина Попова,
        Ольга Петренко, Галла Капицкая, Сергей Ландо, Борис Алексеев, Сергей
        Гельвер, Анастасия Сорокина, Ольга Аграфенина, Константин Шавловский,
        Игорь Терехов, Алик Каспаров, Кирилл Долгих
      </StyledText>

      <Spacer half size={20} />

      <StyledTitle>особая благодарность</StyledTitle>
      <Spacer size={2} />
      <StyledText>Олег Томшинский </StyledText>

      <Spacer half size={20} />

      <StyledTitle>партнёры проекта</StyledTitle>
      <Spacer size={2} />
      <StyledText>
        При содействии «Фонда поддержки культурных проектов и сохранения
        наследия Олега Каравайчука»
      </StyledText>
      <StyledText>
        <a href="http://karavaychuk.com">karavaychuk.com</a>
      </StyledText>
      <StyledText>
        <a href="http://f-o-k.org">f-o-k.org</a>
      </StyledText>

      <Image
        alt="Альфред Шнитке"
        src={`${PUBLIC_URL}/assets/images/karavajchuk/logo.jpg`}
      />
    </Block>

    <Spacer half size={50} />

    <Footer />
  </Root>
);

export default About;
